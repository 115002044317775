<div
    role="progressbar"
    aria-valuemin="0"
    [attr.aria-valuenow]="value"
    aria-valuemax="100"
    class="progressbar"
>
  <div
      class="progressbar-value"
      [style.width]="value + '%'"
      #progressbarValue
  ></div>
</div>
