import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {User, UserService} from "../../../services/user.service";
import {
  ContactDetail,
  ContactQuickSearchItem,
} from "../../../services/contact.service";
import {ShareRequestService} from "../../../services/share-request.service";
import {OauthService, UserOauthAccount} from "../../../services/oauth.service";

import {faCaretDown} from "@fortawesome/free-solid-svg-icons";

export interface InviteModalConfig {
  isTeamInvite: boolean
  contacts: ContactQuickSearchItem[]
}

@Component({
  selector: 'intro-request-modal',
  templateUrl: './intro-request-modal.component.html',
  styleUrls: ['./intro-request-modal.component.scss']
})
export class IntroRequestModalComponent implements OnInit {

  faCaretDown = faCaretDown

  contact!: ContactDetail
  user!: User
  emailAccounts: UserOauthAccount[] = []

  contactsSelected: boolean[] = []
  sharerMessage: string = ''
  prospectMessage: string = ''
  emailOauthId: number = 0

  showAdvanced: boolean = false

  constructor(public dialogRef: MatDialogRef<IntroRequestModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ContactDetail,
              private userService: UserService,
              private oauthService: OauthService,
              private shareRequestService: ShareRequestService,
  ) {
  }

  ngOnInit() {
    this.contact = this.data
    this.contact.networkContacts.forEach(c => { this.contactsSelected.push(true) })
    this.userService.getIdentity().subscribe(
      user => {
        this.user = user
        this.sharerMessage = "Hello <#firstname#>,\n\nDo you mind making an intro to " + this.contact.profile.fullName + "?  You can click the link below to easily send the intro request to them.\n\n<#viewrequesturl#>\n\n<#signature#>"
        this.prospectMessage = "Hello " + this.contact.profile.firstName + ",\n\nMy colleague, " + this.user?.profile?.fullName + ", would like to get an intro to you.  If that's ok, just click the link below to trigger the introduction.\n\n<#introaccepturl#>\n\n<#signature#>"

        this.oauthService.userList$.subscribe(
          (accounts: UserOauthAccount[]) => {
            this.emailAccounts = this.oauthService.filterEmailAccounts(accounts)
            if (this.emailAccounts.length > 0) {
              this.emailOauthId = this.emailAccounts[0].id
            }
          }
        )
        this.oauthService.getAllForUser()
      }
    )
    this.userService.getIdentity()
  }

  emailOauthChange(emailOauthId: number) {
    this.emailOauthId = emailOauthId
  }

  close(sent: boolean = false) {
    this.dialogRef.close(sent)
  }

  canSend(): boolean {
    return this.emailOauthId > 0 && this.contactsSelected.filter(c => c).length > 0
  }

  send() {
    const sharerContactIds: number[] = []
    for (let i: number = 0; i < this.contactsSelected.length; i++) {
      if (this.contactsSelected[i]) {
        sharerContactIds.push(this.contact.networkContacts[i].id)
      }
    }
    this.shareRequestService.createIntroRequest(
      this.contact.profile.id,
      sharerContactIds,
      this.emailOauthId,
      this.sharerMessage,
      this.prospectMessage
    ).subscribe(
      shareRequest => {
        this.close(true)
      }
    )
    /*
    const newShareRequest: ShareRequest = this.shareRequestService.new()
    newShareRequest.name = "Intro to " + this.contact.profile.fullName
    newShareRequest.type = ShareRequestType.intro
    newShareRequest.status = ShareRequestStatus.pending
    newShareRequest.emailOauthId = this.emailOauthId
    newShareRequest.sharerMessageSubject = "Intro to " + this.contact.profile.fullName
    newShareRequest.sharerMessageContent = this.sharerMessage
    newShareRequest.prospectMessageSubject = "Intro to " + this.user?.profile?.fullName
    newShareRequest.prospectMessageContent = this.prospectMessage
    newShareRequest.lastWizardStep = 2
    this.shareRequestService.save(newShareRequest).subscribe(
      savedShareRequest => {
        const sharerContactIds: number[] = []
        for (let i: number = 0; i < this.contactsSelected.length; i++) {
          if (this.contactsSelected[i]) {
            sharerContactIds.push(this.contact.networkContacts[i].id)
          }
        }
        this.shareRequestService.addSharers(savedShareRequest, sharerContactIds, undefined).subscribe(
          shareRequestWithSharers => {
            const addProspectObservables: Observable<any>[] = []
            shareRequestWithSharers.sharers?.forEach(
              sharer => {
                const prospectAudience = this.audienceService.new()
                prospectAudience.profileIds = [this.contact.profile.id]
                addProspectObservables.push(this.sharerService.addProspects(sharer, [], prospectAudience))
              }
            )
            combineLatest(addProspectObservables).subscribe(
              results => {
                shareRequestWithSharers.status = ShareRequestStatus.active
                shareRequestWithSharers.inviteStatus = ShareRequestInviteStatus.sending
                this.shareRequestService.save(shareRequestWithSharers).subscribe(
                  finalShareRequest => {
                    this.close(true)
                  }
                )
              }
            )
          }
        )
      }
    )
    */
  }

}
