<mat-spinner color="accent" *ngIf="loading" class="width-100 margin-center"></mat-spinner>
<div *ngIf="!loading" class="flex-row-left width-100">
  <div class="flex-col-top width-100">
    <div class="text-align-center">
      <div class="intro-page-title flex-row-left">
        <div class="text-align-center horiz-margin" *ngIf="user.profile?.company && !!user.profile?.company?.logoUrl">
          <img [src]="logoUrl"
               onerror="this.src='../../../../assets/white_background.png'"
               class="company-logo"
          >
        </div>
        <div>Welcome to Wrollo</div>
      </div>
      <div class="intro-body">
        Wrollo helps your network help you.  <span fxHide.xs>Here's how it works.</span>
      </div>
    </div>

    <!--
    <div>
      <mat-card class="text-align-center margin-center flex-col-top intro-panel">
        <h2>Welcome to Wrollo</h2>
        <div>
          Wrollo helps your network help you.  Here's how it works.
        </div>
        <br>
        <div>
          <img src="../../../../assets/wrollo_overview.png" class="overview-image">
        </div>
      </mat-card>
    </div>
    -->
    <!--
    <div fxHide.xs>
      <mat-card class="text-align-center margin-center flex-col-top intro-panel">
        <div>
          <img src="../../../../assets/wrollo_intro.gif" class="overview-image">
        </div>
      </mat-card>
    </div>
    -->
    <div class="vert-margin" ngClass.gt-md="width-60" ngClass.md="width-80" ngClass.sm="width-80" ngClass.lt-sm="width-100">
      <div class="margin-center" ngClass.xs="flex-row-wrap-reverse" ngClass.gt-xs="flex-row-left">
        <div ngClass.gt-xs="width-60" ngClass.xs="width-100" class="horiz-padding step-desc">
          <div class="step-panel" (click)="clickStepPanel(0)" *ngIf="!showOnlyCurrentStep || step === 0">
            <div class="bold intro-section-subtitle">Build your company and professional network</div>
            <div class="vert-margin intro-section-body">
              Import and enrich your contacts or employee directory and/or send invites to your contacts and employees.
            </div>
            <progress-bar-timer id="0"
                                *ngIf="step === 0 && !stepTimerStopped"
                                [time]="stepTime"
                                (completed)="stepCompleted()"
            ></progress-bar-timer>
          </div>
          <div class="step-panel" (click)="clickStepPanel(1)" *ngIf="!showOnlyCurrentStep || step === 1">
            <div class="bold intro-section-subtitle">Request help to exponentially boost your reach</div>
            <div class="vert-margin intro-section-body">
              Your network can help repost your X posts, like your LinkedIn posts, and even email your message.
            </div>
            <progress-bar-timer id="1"
                                *ngIf="step === 1 && !stepTimerStopped"
                                [time]="stepTime"
                                (completed)="stepCompleted()"
            ></progress-bar-timer>
          </div>
          <div class="step-panel" (click)="clickStepPanel(2)" *ngIf="!showOnlyCurrentStep || step === 2">
            <div class="bold intro-section-subtitle">Easy for your colleagues<span fxHide.xs> to help</span></div>
            <div class="vert-margin intro-section-body">
              Your network can review and help you in 2 clicks and even opt-in to automate future reposts or likes.
            </div>
            <progress-bar-timer id="2"
                                *ngIf="step === 2 && !stepTimerStopped"
                                [time]="stepTime"
                                (completed)="stepCompleted()"
            ></progress-bar-timer>
          </div>
          <div class="oauth-panel">
            <div class="bold intro-section-subtitle">Get started for free...</div>
            <div class="vert-margin intro-section-body" fxHide.xs>No credit card required</div>
            <div class="vert-margin cta-row" ngClass.xs="flex-col-top" *ngIf="hasEmailAccount || hasSocialAccount">

              <button mat-flat-button
                      color="primary"
                      ngClass.gt-xs="horiz-margin-right"
                      ngClass.xs="margin-center"
                      (click)="addShareRequest()"
                      *ngIf="canCreateShareRequest"
              >Create Request</button>
              <oauth-all-button label="Add Accounts"
                             [returnUrl]="returnUrl"
                             (onAdd)="goToDashboard()"
                             ngClass.gt-sm="horiz-margin-right"
                             ngClass.lt-sm="margin-center vert-margin"
              ></oauth-all-button>
              <button mat-raised-button
                      ngClass.lt-sm="margin-center vert-margin"
                      (click)="goToDashboard()"
              >{{canCreateShareRequest ? 'Later' : 'Done'}}</button>
            </div>
            <div fxHide.gt-xs class="vert-margin">
              <oauth-all-button label="Add Accounts"
                             [returnUrl]="returnUrl"
                             (onAdd)="goToDashboard()"
                             ngClass.gt-sm="horiz-margin-1x"
                             ngClass.lt-sm="margin-center vert-margin"
              ></oauth-all-button>
            </div>
            <div fxHide.xs *ngIf="!hasEmailAccount && !hasSocialAccount">
              <div class="flex-row-left">
                <oauth-button [type]="OauthType.google"
                              [action]="OauthAction.add"
                              [label]="currentBreakpoint === Breakpoints.XSmall ? 'Add' : 'Add Gmail Account'"
                ></oauth-button>
                <oauth-button [type]="OauthType.microsoft"
                              [action]="OauthAction.add"
                              [label]="currentBreakpoint === Breakpoints.XSmall ? 'Add' : 'Add Outlook Account'"
                              class="vert-margin horiz-margin-2x"
                ></oauth-button>
              </div>
              <div class="flex-row-left">
                <oauth-button [type]="OauthType.twitter"
                              [action]="OauthAction.add"
                              [label]="currentBreakpoint === Breakpoints.XSmall ? 'Add' : 'Add X Account'"
                              class="vert-margin"
                ></oauth-button>
                <oauth-button [type]="OauthType.linkedin"
                              [action]="OauthAction.add"
                              [label]="currentBreakpoint === Breakpoints.XSmall ? 'Add' : 'Add LinkedIn Account'"
                              class="vert-margin horiz-margin-2x"
                ></oauth-button>
              </div>
            </div>
          </div>
        </div>
        <div ngClass.gt-xs="width-40" ngClass.xs="width-100" class="text-align-center">
          <div ngClass.xs="width-60" class="margin-center">
            <img src="../../../../assets/wrollo_overview_0.png" class="step-image" *ngIf="step === 0">
            <img src="../../../../assets/wrollo_overview_1.png" class="step-image" *ngIf="step === 1">
            <img src="../../../../assets/wrollo_overview_2.png" class="step-image" *ngIf="step === 2">
          </div>
        </div>
      </div>
      <!--
      <div class="margin-center" *ngIf="step === 0" ngClass.xs="flex-row-wrap-reverse" ngClass.gt-xs="flex-row-left">
        <div ngClass.gt-xs="width-60" ngClass.xs="width-100" class="horiz-padding step-desc">
          <div class="bold intro-section-title">Build your corporate and professional network easily</div>
          <ul>
            <li class="vert-margin intro-body">Import and enrich your contacts or employee directory</li>
            <li class="vert-margin intro-body">Send invites to your contacts and employees</li>
          </ul>
          <div class="vert-margin vert-padding">
            <button mat-flat-button
                    color="primary"
                    (click)="step = step + 1"
            >Next</button>
          </div>
        </div>
        <div ngClass.gt-xs="width-40" ngClass.xs="width-100" class="text-align-center">
          <div ngClass.xs="width-60" class="margin-center">
            <img src="../../../../assets/wrollo_overview_1.png" class="step-image">
          </div>
        </div>
      </div>
      <div class="margin-center" *ngIf="step === 1" ngClass.xs="flex-row-wrap-reverse" ngClass.gt-xs="flex-row-left">
        <div ngClass.gt-sm="width-60" ngClass.lt-sm="width-90" class="horiz-padding step-desc">
          <div class="bold intro-section-title">Request help from your network to boost your reach</div>
          <ul>
            <li class="vert-margin intro-body">Email my message</li>
            <li class="vert-margin intro-body">Repost my X post</li>
            <li class="vert-margin intro-body">Like my LinkedIn post</li>
          </ul>
          <div class="vert-margin vert-padding">
            <button mat-flat-button
                    color="primary"
                    (click)="step = step + 1"
            >Next</button>
          </div>
        </div>
        <div ngClass.gt-xs="width-40" ngClass.xs="width-100" class="text-align-center">
          <div ngClass.xs="width-60" class="margin-center">
            <img src="../../../../assets/wrollo_overview_2.png" class="step-image">
          </div>
        </div>
      </div>
      <div class="margin-center" *ngIf="step === 2" ngClass.xs="flex-row-wrap-reverse" ngClass.gt-xs="flex-row-left">
        <div ngClass.gt-sm="width-60" ngClass.lt-sm="width-90" class="horiz-padding step-desc">
          <div class="bold intro-section-title">Easy for your colleagues<span fxHide.xs> to help</span></div>
          <ul>
            <li class="vert-margin intro-body">2 clicks for your contacts to review and help you</li>
            <li class="vert-margin intro-body">Opt-in to automate future reposts or post likes</li>
          </ul>
          <div class="vert-padding">
            <div class="intro-section-title bold vert-margin-top">
              <span fxHide.gt-xs>Get started</span>
              <span fxHide.xs>
                Start by creating your first request<span *ngIf="!hasEmailAccount"> or add your contacts</span>.
              </span>
            </div>
            <div class="intro-body vert-margin-top" *ngIf="!hasEmailAccount">
              Remember only you can see your contacts' info.
            </div>
            <div class="vert-margin cta-row" ngClass.xs="flex-col-top">
              <button mat-flat-button
                      color="primary"
                      ngClass.gt-xs="horiz-margin-right"
                      ngClass.xs="margin-center"
                      (click)="addShareRequest()"
                      *ngIf="canCreateShareRequest"
              >Create Request</button>
              <oauth-all-button label="Add Contacts"
                             [returnUrl]="returnUrl"
                             (onAdd)="goToDashboard()"
                             ngClass.gt-sm="horiz-margin-1x"
                             ngClass.lt-sm="margin-center vert-margin"
                             *ngIf="!hasEmailAccount"
              ></oauth-all-button>
              <button mat-raised-button
                      ngClass.gt-sm="horiz-margin-1x"
                      ngClass.lt-sm="margin-center vert-margin"
                      (click)="goToDashboard()"
              >{{canCreateShareRequest ? 'Later' : 'Done'}}</button>
            </div>
          </div>
        </div>
        <div ngClass.gt-xs="width-40" ngClass.xs="width-100" class="text-align-center">
          <div ngClass.xs="width-60" class="margin-center">
            <img src="../../../../assets/wrollo_overview_3.png" class="step-image">
          </div>
        </div>
      </div>
      -->
      <!--
      <div class="flex-col-top margin-center vert-margin vert-padding" *ngIf="step < 2">
        <button mat-flat-button
                color="primary"
                (click)="step = step + 1"
        >Next</button>
      </div>
      -->
    </div>
    <!--
    <div>
      <div class="width-100 subscription-panel flex-row-left">
        <div class="subscription-label-container width-25">
          <span class="subscription-label">Free</span>
        </div>
        <div class="subscription-label-container width-75">
          <span class="subscription-label">Subscription Required</span>
        </div>
      </div>

      <mat-card class="intro-panel" *ngIf="step >= 0">
        <div class="intro-panel-title text-align-center">
          4 ways for your network to help
        </div>
        <div class="flex-row-left-top">
          <div class="flex-col-top width-25 intro-col intro-col-border-right">
            <div class="text-align-center intro-col-title intro-font">Get an intro</div>
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faUserPlus" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Identify your 2nd degree contact
            </div>
          </div>
          <div class="flex-col-top width-25 intro-col intro-col-border-right">
            <div class="text-align-center intro-col-title intro-font">Email my message<span fxHide.xs> to targets</span></div>
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faEdit" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Define your targets & write your message to them
            </div>
          </div>
          <div class="flex-col-top width-25 intro-col intro-col-border-left intro-col-border-right">
            <div class="text-align-center intro-col-title intro-font">Repost my posts</div>
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faXTwitter" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Select one of your recent posts
            </div>
          </div>
          <div class="flex-col-top width-25 intro-col intro-col-border-left">
            <div class="text-align-center intro-col-title intro-font">Like my<span fxHide.xs> LinkedIn</span> posts</div>
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faLinkedin" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Select one of your recent posts
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="intro-panel" *ngIf="step >= 1">
        <div class="intro-panel-title text-align-center">
          Share the request with my network
        </div>
        <div class="flex-row-left-top">
          <div class="flex-col-top width-25 intro-col intro-col-border-right">
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faEnvelope" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Choose your contacts who know them
            </div>
          </div>
          <div class="flex-col-top width-37 intro-col intro-col-border-left">
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faEnvelope" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Invite matching contacts to help
            </div>
          </div>
          <div class="flex-col-top width-37 intro-col">
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faShare" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Create a share link
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="intro-panel" *ngIf="step >= 2">
        <div class="intro-panel-title text-align-center">
          My contacts review and decide to help
        </div>
        <div class="flex-row-left-top">
          <div class="flex-col-top width-25 intro-col intro-col-border-right">
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faHandshake" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Wrollo will make the intro once approved automatically
            </div>
          </div>
          <div class="flex-col-top width-25 intro-col intro-col-border-right">
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faAddressCard" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Wrollo emails your message to their matching contacts
            </div>
          </div>
          <div class="flex-col-top width-25 intro-col intro-col-border-left intro-col-border-right">
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faRetweet" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Connect their X account to repost my post
            </div>
          </div>
          <div class="flex-col-top width-25 intro-col intro-col-border-left">
            <div class="intro-col-icon-container">
              <fa-icon [icon]="faThumbsUp" class="fa-2x intro-col-icon"></fa-icon>
            </div>
            <div class="text-align-center intro-col-desc intro-font">
              Connect their LinkedIn account to like my post
            </div>
          </div>
        </div>
        <div class="flex-row-left width-100">
          <div class="width-33"></div>
          <div class=" flex-row-left width-60 social-auto-panel">
            <fa-icon [icon]="faToggleOn" class="fa-2x intro-col-icon"></fa-icon>
            <div class="intro-font horiz-margin-1x">
              My contacts can opt-in to automatically repost new X posts or like new LinkedIn posts
            </div>
          </div>
        </div>
      </mat-card>

      <div class="flex-col-top margin-center vert-margin vert-padding" *ngIf="step < 2">
        <button mat-flat-button
                color="primary"
                (click)="step = step + 1"
        >Next</button>
      </div>
    </div>
    -->
    <!--
    <div class="flex-col-top" *ngIf="step >= 2" ngClass.gt-xs="vert-padding vert-margin margin-center" ngClass.xs="vert-margin">
      <div class="intro-section-title bold text-align-center">
        Get started<span *ngIf="currentBreakpoint !== Breakpoints.XSmall"> by creating your first request<span *ngIf="!hasEmailAccount"> or add your contacts</span>.</span>
      </div>
      <div class="intro-body text-align-center vert-margin-top">
        Remember only you can see your contacts' details.
      </div>
      <div class="vert-margin cta-row" ngClass.xs="flex-col-top" ngClass.gt-xs="flex-row-space-between">
        <button mat-flat-button
                  color="primary"
                  ngClass.gt-xs="horiz-margin-1x"
                  ngClass.xs="margin-center"
                  (click)="addShareRequest()"
                  *ngIf="canCreateShareRequest"
        >Create Request</button>
        <oauth-all-button label="Add Contacts"
                       [returnUrl]="returnUrl"
                       (onAdd)="goToDashboard()"
                       ngClass.gt-sm="horiz-margin-1x"
                       ngClass.lt-sm="margin-center vert-margin"
        ></oauth-all-button>
        <button mat-raised-button
                ngClass.gt-sm="horiz-margin-1x"
                ngClass.lt-sm="margin-center vert-margin"
                (click)="goToDashboard()"
        >{{canCreateShareRequest ? 'Later' : 'Done'}}</button>
      </div>
    </div>
    -->
  </div>
</div>
