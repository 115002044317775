import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import {Observable, Subject} from "rxjs"
import {Injectable} from "@angular/core"
import {JweService} from "./auth/jwe.service";
import {FormatUtils} from "./utilities/format.utilities";
import {environment} from "../../environments/environment";

@Injectable()
export class ApiService {

  private baseUrl = 'https://xdrk-d43y-i7o2.n7c.xano.io/api:reh2wqBU/'

  constructor(private http: HttpClient,
              private jweService: JweService,
  ) {
  }

  private apiRequest<T>(endpoint: string,
                        method: string,
                        params?: any,
                        headers?: any,
                        responseType?: string,
                        stateFunc?: Function,
  ): Observable<T> {
    const options = {}

    if (endpoint[0] === '/') {
      endpoint = endpoint.slice(1)
    }
    const apiUrl = this.baseUrl + endpoint

    if (stateFunc) {
      stateFunc(true)
    }

    if (["GET", "DELETE"].includes(method) && params) {
      let httpParams: HttpParams = new HttpParams()
      for (let i in params) {
        if (typeof (params as any)[i] == "object") {
          httpParams = httpParams.set(i, JSON.stringify((params as any)[i]))
        } else {
          httpParams = httpParams.set(i, (params as any)[i])
        }
      }
      (options as any)['params'] = httpParams
    }

    let httpHeaders = new HttpHeaders
    if (headers) {
      Object.keys(headers).forEach(key => {
        httpHeaders = httpHeaders.set(key, (headers as any)[key])
      })
    }
    if (!environment.production) {
      httpHeaders = httpHeaders.set('X-Data-Source', 'test')
    }

    const localJWE = this.jweService.getJWE()
    if (!!localJWE) {
      httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + localJWE)
    }

    (options as any)["headers"] = httpHeaders;
    (options as any)["responseType"] = responseType

    let observable: Observable<T>
    switch (method) {
      case "GET":
        observable = this.http.get<T>(apiUrl, options)
        break
      case "POST":
        observable = this.http.post<T>(apiUrl, params, options)
        break
      case "PUT":
        observable = this.http.put<T>(apiUrl, params, options)
        break
      case "PATCH":
        observable = this.http.patch<T>(apiUrl, params, options)
        break
      case "DELETE":
        observable = this.http.delete<T>(apiUrl, options)
        break
    }

    return new Observable(newObserver => {
      observable.subscribe(
        item => {
          newObserver.next(item)
          newObserver.complete()
        },
        err => {
          newObserver.error(err)
        }
      ).add(() => {
        if (stateFunc) {
          stateFunc(false)
        }
      })
    })
  }

  get<T>(endpoint: string,
      params?: any,
      headers?: any,
      responseType?: string,
      stateFunc?: Function,
  ): Observable<T> {
    return this.apiRequest<T>(endpoint, "GET", params, headers, responseType, stateFunc)
  }

  post<T>(endpoint: string,
          params?: any,
          headers?: any,
          responseType?: string,
          stateFunc?: Function,
  ): Observable<T> {
    return this.apiRequest<T>(endpoint, "POST", params, headers, responseType, stateFunc)
  }

  put<T>(endpoint: string,
         params?: any,
         headers?: any,
         responseType?: string,
         stateFunc?: Function,
  ): Observable<T> {
    return this.apiRequest<T>(endpoint, "PUT", params, headers, responseType, stateFunc)
  }

  patch<T>(endpoint: string,
         params?: any,
         headers?: any,
         responseType?: string,
         stateFunc?: Function,
  ): Observable<T> {
    return this.apiRequest<T>(endpoint, "PATCH", params, headers, responseType, stateFunc)
  }

  delete<T>(endpoint: string,
            params?: any,
            headers?: any,
            responseType?: string,
            stateFunc?: Function,
  ): Observable<T> {
    return this.apiRequest<T>(endpoint, "DELETE", params, headers, responseType, stateFunc)
  }

  save<T>(endpoint: string,
          obj: any,
  ): Observable<T> {
    const formatResults = (results: any) => {
      resultsSubject.next(results)
    }
    const resultsSubject: Subject<T> = new Subject<T>()
    const apiObj = FormatUtils.camelToSnakeCaseKeys(obj)

    if (!obj.id || obj.id <= 0) {
      delete obj.id
      this.post(endpoint, apiObj).subscribe(formatResults)
    } else {
      this.put(endpoint + '/' + obj.id, apiObj).subscribe(formatResults)
    }
    return resultsSubject.asObservable()
  }

}
