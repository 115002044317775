import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {combineLatest} from "rxjs";
import {DialogService} from "../../../services/utilities/dialog.service";
import {SharerService} from "../../../services/sharer.service";

@Component({
  selector: 'accept-intro-request',
  templateUrl: './accept-intro-request.component.html',
  styleUrls: ['./accept-intro-request.component.scss']
})
export class AcceptIntroRequestComponent implements OnInit {

  prospectId: number = 0
  isAuthenticated: boolean = false
  loading: boolean = true

  constructor(private authService: AuthService,
              private sharerService: SharerService,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    combineLatest([this.route.url, this.route.queryParams]).subscribe(
      ([urlSegments, queryParams]) => {
        if (queryParams) {
          this.prospectId = parseInt(urlSegments[1].toString())
        }
        this.isAuthenticated = this.authService.isAuthenticated()
        if (this.prospectId > 0) {
          this.sharerService.acceptIntroRequest(this.prospectId).subscribe(
            success => {
              if (success) {
                this.loading = false
              } else {
                this.onError()
              }
            },
            this.onError
          )
        } else {
          this.onError()
        }
      }
    )
  }

  onError() {
    this.dialogService.messageDialog(
      'Intro Request Not Found',
      "Sorry, we couldn't find this introduction request"
    ).subscribe(
      () => {
        this.goToDashboard()
      }
    )
  }

  goToDashboard() {
    this.router.navigate(['dashboard'])
  }

  goToSignup() {
    this.router.navigate(['signup'])
  }

}
