<div ngClass.gt-xs="form-container"
     [ngClass.gt-xs]="{'filter-container': isFilterMode(), 'edit-container': !isFilterMode(), 'modal-container': isModal()}"
>
  <h2 *ngIf="isModal()">{{!audience || !audience.id || audience.id === 0 ? 'Add' : 'Edit'}} Audience</h2>
  <mat-card expanded class="filter-panel">
    <mat-card-content>
      <div *ngIf="audience.type === AudienceType.system" class="vert-margin">
        This is a system-generated audience, so it can not be edited or deleted.
      </div>
      <div [ngClass]="{'flex-row-left': !isFilterMode()}" ngClass.xs="flex-wrap">
        <div *ngIf="!showAudienceNameField">
          <mat-form-field class="audience-select">
            <mat-label>Audiences</mat-label>
            <mat-select [value]="audience"
                        (selectionChange)="selectAudienceEvent($event)"
                        [compareWith]="compareAudiences"
            >
              <mat-option *ngFor="let audienceOption of audienceList"
                          [value]="audienceOption"
              >
                {{audienceOption.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="showAudienceNameField">
          <div *ngIf="!readOnly">
            <mat-form-field>
              <mat-label>Audience Name</mat-label>
              <input matInput
                     id="name-search"
                     type="text"
                     data-lpignore="true"
                     [value]="audience.label"
                     [formControl]="audienceNameControl"
                     [readonly]="readOnly"
                     [disabled]="readOnly"
                     (blur)="isDirty = true"
              >
            </mat-form-field>
            <mat-error *ngIf="audienceNameControl.hasError('required')">
              Please enter a name
            </mat-error>
          </div>
          <div *ngIf="readOnly" class="flex-row-left">
            <div class="bold">Audience Name: </div>
            <div class="horiz-padding">{{audience.label}}</div>
          </div>
        </div>
        <div [ngClass.gt-xs]="{'horiz-margin-1x': !isFilterMode()}" *ngIf="!readOnly">
          <button mat-flat-button
                  color="primary"
                  (click)="saveAudience(false, false)"
                  class="saved-search-btn"
                  [disabled]="!isDirty"
                  *ngIf="audienceSelected() || showAudienceNameField"
          >Save</button>
          <button mat-flat-button
                  color="primary"
                  (click)="saveAudience(true, true)"
                  class="saved-search-btn"
                  *ngIf="!audienceSelected() && !showAudienceNameField"
          >Save As...</button>
          <button mat-mini-fab
                  type="button"
                  [matMenuTriggerFor]="actionsMenu"
                  class="audience-btn white-btn horiz-margin"
                  *ngIf="audienceSelected()"
          ><mat-icon>more_horiz</mat-icon></button>
          <mat-menu #actionsMenu="matMenu">
            <button mat-menu-item (click)="renameAudience()">
              Rename
            </button>
            <button mat-menu-item (click)="saveAudience(true, false)">
              New
            </button>
            <button mat-menu-item (click)="saveAudience(true, true)">
              Clone
            </button>
            <button mat-menu-item (click)="confirmDeleteAudience()">
              Delete
            </button>
          </mat-menu>
          <button mat-mini-fab
                  type="button"
                  (click)="close(false)"
                  class="audience-btn white-btn horiz-margin"
                  *ngIf="isModal()"
                  matTooltip="Cancel"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="flex-row-space-between" *ngIf="isFilterMode() && !isModal()">
    <div class="filter-title">Filters</div>
    <button mat-flat-button
            (click)="resetFilters()"
    >Reset</button>
  </div>

  <mat-card expanded class="filter-panel" *ngIf="!readOnly || hasPersonFilters()">
    <mat-card-header *ngIf="readOnly">
      <mat-card-title class="card-header">Target Prospects</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div ngClass.xs="flex-col-left" ngClass.gt-xs="flex-row-space-between flex-wrap">
        <div *ngIf="!readOnly || keywordFilters.title.length > 0">
          <input-chip title="Title"
                      [values]="keywordFilters.title"
                      [readOnly]="readOnly"
                      (onChange)="applyFilters()"
          ></input-chip><br>
        </div>
        <div *ngIf="!readOnly || audience.seniority.length > 0">
          <select-chip title="Seniority"
                       [values]="audience.seniority"
                       [allOptions]="seniorityOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
        <div *ngIf="!readOnly || audience.bizFunction.length > 0">
          <select-chip title="Business Function"
                       [values]="audience.bizFunction"
                       [allOptions]="bizFunctionOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
        <div *ngIf="(!isEditShortMode() && !readOnly) || (audience.connection !== '' && audience.connection !== '0')">
          <mat-form-field>
            <mat-label>Connection</mat-label>
            <mat-select [(ngModel)]="audience.connection"
                        (selectionChange)="applyFilters()"
                        [disabled]="readOnly"
            >
              <mat-option *ngFor="let option of connectionOptions"
                          [value]="option.value"
              >
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="(isFilterMode() && !readOnly) || keywordFilters.title.length > 0">
          <input-chip title="Person's Name"
                      [values]="keywordFilters.personName"
                      [readOnly]="readOnly"
                      (onChange)="applyFilters()"
          ></input-chip>
        </div>
        <div *ngIf="(!isEditShortMode() && !readOnly) || (audience.emailType !== '' && audience.emailType !== 'any')">
          <mat-form-field>
            <mat-label>Email Type</mat-label>
            <mat-select [(ngModel)]="audience.emailType"
                        (selectionChange)="applyFilters()"
                        [disabled]="readOnly"
            >
              <mat-option *ngFor="let option of emailTypeOptions"
                          [value]="option.value"
              >
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="(isFilterMode() && !readOnly) || keywordFilters.emailAddress.length > 0">
          <input-chip title="Email Address"
                      [values]="keywordFilters.emailAddress"
                      [readOnly]="readOnly"
                      (onChange)="applyFilters()"
          ></input-chip>
        </div>
        <div *ngIf="(!isEditShortMode() && !readOnly) || audience.contactType !== ''">
          <mat-form-field>
            <mat-label>Contact Type</mat-label>
            <mat-select [(ngModel)]="audience.contactType"
                        (selectionChange)="applyFilters()"
                        [disabled]="readOnly"
            >
              <mat-option *ngFor="let option of contactTypeOptions"
                          [value]="option.value"
              >
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="(!isEditShortMode() && !readOnly) || audience.lastEmailedAt !== ''">
          <mat-form-field>
            <mat-label>Last Request Sent</mat-label>
            <mat-select [(ngModel)]="audience.lastEmailedAt"
                        (selectionChange)="applyFilters()"
                        [disabled]="readOnly"
            >
              <mat-option *ngFor="let option of lastEmailedOptions"
                          [value]="option.value"
              >
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="(!isEditShortMode() && !readOnly) || audience.neverEmailed">
          <mat-checkbox [checked]="audience.neverEmailed"
                        (change)="applyFilters()"
                        [(ngModel)]="audience.neverEmailed"
                        class=""
          >Or never sent request</mat-checkbox>
        </div>
      </div>
      <div *ngIf="contacts.length > 0">
        <div class="contacts-title">Contacts</div>
        <div *ngFor="let contact of contacts"
             class="width-100 flex-row-left vert-margin"
             [matTooltip]="contact.fullName + (contact.title ? '\n' + contact.title : '') + (contact.companyName ? '\n' + contact.companyName : '')"
             matTooltipClass="multiline-tooltip"
        >
          <profile-avatar [contact]="contact" class="width-10"></profile-avatar>
          <div class="width-70">{{contact.fullName}}</div>
          <div class="width-20" *ngIf="!readOnly">
            <fa-icon [icon]="faCircleXmark"
                     (click)="removeProfile(contact.profileId)"
                     class="pointer"
            ></fa-icon>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="filter-panel"
            *ngIf="!readOnly || hasCompanyFilters()"
  >
    <mat-card-header *ngIf="readOnly">
      <mat-card-title class="card-header">Target Companies</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div ngClass.xs="flex-col-left" ngClass.gt-xs="flex-row-space-between flex-wrap">
        <div *ngIf="!readOnly || keywordFilters.companyName.length > 0">
          <input-chip title="Company Name"
                      [values]="keywordFilters.companyName"
                      [readOnly]="readOnly"
                      (onChange)="applyFilters()"
          ></input-chip>
        </div>
        <div *ngIf="!readOnly || keywordFilters.companyDomain.length > 0">
          <input-chip title="Company Domain"
                      [values]="keywordFilters.companyDomain"
                      [readOnly]="readOnly"
                      (onChange)="applyFilters()"
          ></input-chip>
        </div>
        <div *ngIf="!readOnly || audience.companySize.length > 0">
          <select-chip title="Company Size"
                       [values]="audience.companySize"
                       [allOptions]="companySizeOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
        <div *ngIf="!readOnly || audience.companyRevenue.length > 0">
          <select-chip title="Company Revenue"
                       [values]="audience.companyRevenue"
                       [allOptions]="companyRevenueOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
        <div *ngIf="!readOnly || industryOptionsSelected.length > 0">
          <autocomplete-chip title="Industry"
                       [values]="industryOptionsSelected"
                       [options]="industryOptionsAutocomplete$"
                       [readOnly]="readOnly"
                       (onChange)="industryChange($event)"
                       (onAutocomplete)="industryAutocomplete($event)"
          ></autocomplete-chip>
        </div>
        <div *ngIf="!readOnly || audience.companyType.length > 0">
          <select-chip title="Company Type"
                       [values]="audience.companyType"
                       [allOptions]="companyTypeOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
        <!--
        <div *ngIf="!readOnly || audience.companyStage.length > 0">
          <select-chip title="Stage"
                       [values]="audience.companyStage"
                       [allOptions]="companyStageOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
        -->
        <div *ngIf="!readOnly || audience.investorType.length > 0">
          <select-chip title="Investor Type"
                       [values]="audience.investorType"
                       [allOptions]="investorTypeOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
        <div *ngIf="!readOnly || audience.investmentStage.length > 0">
          <select-chip title="Investor Stage"
                       [values]="audience.investmentStage"
                       [allOptions]="investmentStageOptions"
                       [readOnly]="readOnly"
                       (onChange)="applyFilters()"
          ></select-chip>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
