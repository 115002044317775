<div class="dialog-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile>{{data.title}}</h2>
    <div>{{data.message}}</div>
    <div class="form-row" *ngIf="data.inputLabel">
      <mat-form-field appearance="fill" class="dialog-field">
        <mat-label>{{data.inputLabel}}</mat-label>
        <input matInput
               id="field-search"
               type="text"
               data-lpignore="true"
               required
               [(ngModel)]="inputValue"
        >
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="data.inputChipLabel">
      <input-chip [title]="data.inputChipLabel"
                  [values]="inputChipValues"
                  [allowNegativeFilters]="false"
      ></input-chip>
    </div>
  </div>
  <div mat-dialog-actions class="action-row vert-margin">
    <button mat-flat-button color="primary"  (click)="submit()" cdkFocusInitial class="action-button">{{data.submitLabel || 'Ok'}}</button>
    <button mat-raised-button mat-dialog-close (click)="cancel()" *ngIf="data.cancelLabel" class="action-button">{{data.cancelLabel || 'Cancel'}}</button>
  </div>
</div>
