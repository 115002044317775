<div class="flex-row-left">
  <h2>Invites</h2>
</div>

<div>
  <mat-tab-group [selectedIndex]="selectedTab.value"
                 (selectedIndexChange)="setTab($event)"
  >

    <mat-tab label="Send Invites">
      <div class="tab-container">
        <invite-modal [isTeamInvite]="false" class="vert-margin"></invite-modal>
      </div>
    </mat-tab>

    <mat-tab label="Invites Sent">
      <div class="tab-container">
        <invite-list [invites]="invites" [isTeamInvite]="false" class="vert-margin"></invite-list>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>

