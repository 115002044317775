import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
  Prospect,
  Sharer,
  SharerService,
  SharerEmailStatus,
  ProspectStatus
} from "../../../services/sharer.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDrawer} from "@angular/material/sidenav";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'sharer-prospect-list',
  templateUrl: './sharer-prospect-list.component.html',
  styleUrls: ['./sharer-prospect-list.component.scss']
})
export class SharerProspectListComponent implements OnInit {

  @Input() sharer: Sharer = this.sharerService.new()

  loading: boolean = false
  addingProspects: boolean = false
  displayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['avatar', 'name', 'title', 'company', 'status']
    : ['select', 'actions', 'avatar', 'name', 'title', 'company', 'status', 'repliedOn']
  prospectTable: MatTableDataSource<Prospect> = new MatTableDataSource()
  selection = new SelectionModel<Prospect>(true, []);
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('prospectListSort') prospectListSort!: MatSort

  viewProfileIndex: number = -1
  @ViewChild('contactDrawer') contactDrawer!: MatDrawer
  minItems: number = 0

  constructor(private sharerService: SharerService,
              private dialogService: DialogService,
              private notificationService: NotificationService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
   this.sharerService.detail$.subscribe((
     sharer: Sharer) => {
      this.setSharer(sharer)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sharer']) {
      this.setSharer(changes['sharer'].currentValue)
    }
  }

  setSharer(sharer: Sharer) {
    this.sharer = sharer

    this.prospectTable = new MatTableDataSource(this.sharer.prospects)
    this.prospectTable.sort = this.prospectListSort
    setTimeout(() => {
      if (this.paginator) {
        this.prospectTable.paginator = this.paginator
        this.prospectTable.paginator.pageSize = 25
        this.minItems = Math.min(this.prospectTable.filteredData.length, this.paginator.pageSize)
      }
    })

    this.addingProspects = this.sharer.emailStatus === SharerEmailStatus.waiting
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.prospectTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.prospectTable.data.forEach(row => this.selection.select(row));
  }

  deleteSelectedProspects() {
    this.dialogService.confirmDialog(
      'Remove Selected Prospects',
      'Are you sure you want to try and remove ' + this.selection.selected.length + ' prospect' + (this.selection.selected.length !== 1 ? 's' : '') + '?'
    ).subscribe(
      result => {
        if (result) {
          const validProspects = this.selection.selected.filter(p => {
            return p.status === ProspectStatus.pending
          })
          if (validProspects.length > 0) {
            this.sharerService.deleteProspects(
              this.sharer,
              validProspects.map(p => { return p.contact })
            ).subscribe(sharer => {
              this.setSharer(sharer)
              this.notificationService.success(this.selection.selected.length + ' prospect' + (this.selection.selected.length !== 1 ? 's' : '') + ' deleted.')
              this.selection.clear()
            })
          } else {
            this.notificationService.success('No valid prospects were selected.')
          }
        }
      }
    )
  }

  confirmDeleteProspect(prospect: Prospect) {
    this.dialogService.confirmDialog(
      'Delete Prospect',
      'Are you sure you want to delete this prospect?'
    ).subscribe(result => {
      if (result) {
        this.sharerService.deleteProspects(
          this.sharer,
          [prospect.contact]
        ).subscribe(sharer => {
          this.setSharer(sharer)
          this.notificationService.success('1 prospect deleted.')
        })
      }
    })
  }

  viewContact(index: number) {
    this.viewProfileIndex = index
    if (this.viewProfileIndex >= 0) {
      this.contactDrawer.open()
    }
  }

}
