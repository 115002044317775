<form [formGroup]="messageForm" autocomplete="off">
  <div class="flex-row-left" *ngIf="!readOnly && !hideMessageTemplates && messageTemplateId > 0">
    <mat-form-field class="width-50 name-field">
      <mat-label>Name</mat-label>
      <input matInput
             id="name-search"
             type="text"
             data-lpignore="true"
             formControlName="nameControl"
             (blur)="change()"
      >
    </mat-form-field>
    <button mat-flat-button
            type="submit"
            color="primary"
            (click)="saveMessageTemplate(false)"
            class="action-button"
    >Save</button>
    <button mat-flat-button
            type="button"
            color="primary"
            (click)="saveMessageTemplate(true)"
            class="action-button save-as-button"
            *ngIf="messageForm.value.messageTemplateControl"
    >Save As</button>
    <button mat-mini-fab
            type="button"
            (click)="confirmDeleteMessageTemplate()"
            class="action-button white-btn"
            *ngIf="messageForm.value.messageTemplateControl"
            matTooltip="Delete message template"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div class="flex-row-space-between" ngClass.xs="flex-wrap-reverse">
    <mat-form-field class="subject-field" ngClass.xs="width-100" [ngClass]="{'width-100': hideMessageTemplates || messageTemplateId > 0, 'width-70': !hideMessageTemplates && messageTemplateId <= 0}">
      <mat-label>Subject</mat-label>
      <input matInput
             id="subject-search"
             type="text"
             data-lpignore="true"
             formControlName="subjectControl"
             [readonly]="readOnly"
             (blur)="change()"
      >
    </mat-form-field>
    <div class="flex-row-left">
      <mat-form-field class="template-field" *ngIf="!readOnly && !hideMessageTemplates && messageTemplateId <= 0">
        <mat-label>Message Template</mat-label>
        <mat-select formControlName="messageTemplateControl"
                    (selectionChange)="selectMessageTemplate()"
                    [compareWith]="compareMessageTemplates"
        >
          <mat-option *ngFor="let messageTemplate of messageTemplates"
                      [value]="messageTemplate"
          >
            {{messageTemplate.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-mini-fab
              (click)="saveMessageTemplate(true)"
              class="message-template-button white-btn"
              matTooltip="Save message as a template"
              *ngIf="!messageForm.value.messageTemplateControl && !readOnly && !hideMessageTemplates && messageTemplateId <= 0"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button mat-mini-fab
              [matMenuTriggerFor]="addActionsMenu"
              class="message-template-button white-btn"
              matTooltip="Save message as a template"
              *ngIf="messageForm.value.messageTemplateControl && !readOnly && !hideMessageTemplates && messageTemplateId <= 0"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #addActionsMenu="matMenu">
        <button mat-menu-item (click)="saveMessageTemplate(false)">
          Save
        </button>
        <button *ngIf="messageTemplateId > 0" mat-menu-item (click)="saveMessageTemplate(true)">
          Save As
        </button>
        <button *ngIf="messageTemplateId > 0" mat-menu-item (click)="confirmDeleteMessageTemplate()">
          Delete
        </button>
        <button *ngIf="messageForm.value.messageTemplateControl" mat-menu-item (click)="clearMessageTemplate()">
          Clear
        </button>
      </mat-menu>
    </div>
  </div>
  <!--
  <div *ngIf="readOnly">
    <mat-form-field class="width-100">
      <mat-label>Message Content</mat-label>
      <textarea matInput
                role="textbox"
                rows="8"
                formControlName="contentControl"
                [readOnly]="true"
      ></textarea>
    </mat-form-field>
  </div>
  -->
  <div>
    <editor
        [inline]="false"
        [apiKey]="apiKey"
        [init]="editorConfig"
        formControlName="contentControl"
        (onBlur)="change()"
    ></editor>
  </div>
  <br>

</form>
