<div *ngIf="og && !hideAll" [ngClass]="{'og-container': !imageOnly}">
  <div>
    <a [href]="og.url">
      <img [src]="og.image" [ngClass]="{'og-image': !imageOnly, 'og-image-only': imageOnly}">
    </a>
  </div>
  <div class="og-link-container" *ngIf="!imageOnly">
    <div class="og-domain">
      {{domain}}
    </div>
    <div class="og-title">
      {{og.title}}
    </div>
    <div class="og-description" *ngIf="og.description">
      {{og.description}}
    </div>
  </div>
</div>
