import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

export function tokenGetter() {
  const jwe = localStorage.getItem("wrollo.jwe")
  const cleaned = jwe ? jwe.replaceAll("\"", "") : ""
  return cleaned
}

@Injectable({
  providedIn: 'root'
})
export class JweService {

  private localTokenName = 'wrollo.jwe'
  private tokenEndName = 'wrollo.jwe.end'

  constructor(public jweHelper: JwtHelperService,
  ) {
  }

  public isTokenExpired(token: string) {
    try {
      return this.jweHelper.isTokenExpired(token)
    } catch (err) {
      return true
    }
  }

  public getJWE(): string | null {
    return localStorage.getItem(this.localTokenName)
  }

  public setJWE(authToken: string, expDatetime?: string, jweSubject?: Subject<string>) {
    const jwe = authToken
    localStorage.setItem(this.localTokenName, jwe)
    if (expDatetime)
      localStorage.setItem(this.tokenEndName, expDatetime)
    if (jweSubject)
      jweSubject.next(jwe)
  }

}
