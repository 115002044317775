<div class="dialog-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile>{{(!contact.id || contact.id <= 0) ? 'Add' : 'Edit'}} Contact</h2>
    <form [formGroup]="editForm" autocomplete="off">
      <div>
        <div class="flex-row-left field-row">
          <mat-form-field class="horiz-padding setup-field width-100">
            <mat-label>First Name</mat-label>
            <input matInput
                   id="firstName-search"
                   type="text"
                   data-lpignore="true"
                   formControlName="firstName"
                   required
            >
          </mat-form-field>
        </div>
        <div class="flex-row-left field-row">
          <mat-form-field class="horiz-padding setup-field width-100">
            <mat-label>Last Name</mat-label>
            <input matInput
                   id="lastName-search"
                   type="text"
                   data-lpignore="true"
                   formControlName="lastName"
            >
          </mat-form-field>
        </div>
        <div class="flex-row-left field-row">
          <mat-form-field class="horiz-padding setup-field width-100">
            <mat-label>Title</mat-label>
            <input matInput
                   id="title-search"
                   type="text"
                   data-lpignore="true"
                   formControlName="title"
            >
          </mat-form-field>
        </div>
        <div class="flex-row-left field-row">
          <mat-form-field class="horiz-padding setup-field width-100">
            <mat-label>Company</mat-label>
            <input matInput
                   id="company-search"
                   type="text"
                   data-lpignore="true"
                   formControlName="companyName"
            >
          </mat-form-field>
        </div>
        <div class="flex-row-left field-row">
          <mat-form-field class="horiz-padding setup-field width-100">
            <mat-label>Primary Email</mat-label>
            <input matInput
                   id="email-search"
                   type="email"
                   data-lpignore="true"
                   formControlName="primaryEmail"
                   required
            >
          </mat-form-field>
        </div>
        <div>{{errMessage}}</div>
      </div>
      <div mat-dialog-actions class="action-row">
        <button mat-flat-button
                color="primary"
                (click)="save()"
                class="action-button"
                [disabled]="editForm.value.firstName === '' || editForm.value.primaryEmail === ''"
        >Save</button>
        <button mat-raised-button
                mat-dialog-close
                (click)="close()"
                class="action-button"
        >Cancel</button>
      </div>
    </form>
  </div>
</div>
