<div class="flex-row-left width-100">
  <div class="flex-col-top width-100">
    <h2 *ngIf="!loading" class="text-align-center">Success!</h2>
    <mat-spinner color="accent" *ngIf="loading"></mat-spinner>
    <mat-card class="auto-create-card" *ngIf="!loading && askAutoCreate && account">
      <mat-card-header>
        <mat-card-title>Do you want to auto-create network requests for each new {{type === OauthType.twitter ? 'X post' : 'LinkedIn post'}}?</mat-card-title>
        <mat-card-subtitle>You can always change this in your settings as well.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="vert-margin">
          <mat-slide-toggle
              color="primary"
              [ngModel]="account.autoCreateShareRequest"
              (change)="account.autoCreateShareRequest = !account.autoCreateShareRequest"
          >
            <span>Auto-create network requests</span>
          </mat-slide-toggle>
        </div>
        <div class="vert-margin-2x filters-field" *ngIf="account.autoCreateShareRequest">
          <div class="vert-margin">
            Optionally, you can add some filters to only create network requests where the content matches any of these keywords or hash tags.
          </div>
          <input-chip title="Filters"
                      [values]="autoCreateFiltersConverted"
                      [allowNegativeFilters]="false"
          ></input-chip>
        </div>
        <div class="flex-row-left vert-margin-2x">
          <button mat-flat-button
                  color="primary"
                  (click)="saveAutoCreate()"
          >Save</button>
          <button mat-raised-button
                  (click)="goToNext()"
                  class="horiz-margin-2x"
          >Not now</button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="auto-create-card" *ngIf="!loading && askForEmail">
      <mat-card-header>
        <mat-card-title>Please enter an email address for your account</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="vert-padding">
          <mat-form-field>
            <mat-label>Email Address</mat-label>
            <input matInput
                   type="email"
                   data-lpignore="true"
                   [formControl]="emailControl"
                   required
            >
          </mat-form-field>
        </div>
        <div class="flex-row-left">
          <button mat-flat-button
                  color="primary"
                  (click)="saveUser()"
                  [disabled]="emailControl.value === ''"
          >Save</button>
          <button mat-raised-button
                  (click)="logout()"
                  [disabled]="emailControl.value === ''"
                  class="horiz-margin-1x"
          >Logout</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
