<div ngClass="flex-row-left width-100">
  <oauth-button [type]="OauthType.google"
                [action]="OauthAction.add"
                [label]="gmailLabel"
  ></oauth-button>
  <oauth-button [type]="OauthType.microsoft"
                [action]="OauthAction.add"
                [label]="outlookLabel"
                class="vert-margin horiz-margin-2x"
  ></oauth-button>
</div>
<div class="width-100">
  <table mat-table [dataSource]="accountTable">

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let account; let i = index;">{{account.type | titlecase}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let account; let i = index;">{{account.name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let account; let i = index;">{{account.email}}</td>
    </ng-container>

    <ng-container matColumnDef="contacts">
      <th mat-header-cell *matHeaderCellDef>Contacts</th>
      <td mat-cell *matCellDef="let account; let i = index;">{{getLastImportProgressLabel(account)}}</td>
    </ng-container>

    <ng-container matColumnDef="importDate">
      <th mat-header-cell *matHeaderCellDef>Last Import Date</th>
      <td mat-cell *matCellDef="let account; let i = index;">{{getLastImportDateLabel(account)}}</td>
    </ng-container>

    <ng-container matColumnDef="teamMemberOauths">
      <th mat-header-cell *matHeaderCellDef>Team Access</th>
      <td mat-cell *matCellDef="let account; let i = index;">
        <div *ngFor="let teamMemberOauth of account.teamMemberOauths">
          <a (click)="editTeamMemberOauths(account)"
             class="pointer">
            {{teamMemberOauth.teamMember.team.name}}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="thin-col"></th>
      <td mat-cell *matCellDef="let account; let i = index;">
        <button mat-icon-button
                [matMenuTriggerFor]="accountActionMenu"
                class="action-button">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #accountActionMenu="matMenu">
          <button mat-menu-item (click)="refreshGmailAccount(account)" [disabled]="!getLastImportCompleted(account)">
            Refresh
          </button>
          <button mat-menu-item (click)="editTeamMemberOauths(account)">
            Edit Team Access
          </button>
          <button mat-menu-item (click)="confirmDeleteAccount(account)" [disabled]="!getLastImportCompleted(account)">
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let account; let i = index; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="numDisplayedColumns">
        <div *ngIf="!loading" class="no-table-data">No email accounts</div>
        <div class="loading-overlay" *ngIf="loading">
          <div class="loading-wrapper">
            <mat-spinner color="accent"></mat-spinner>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
