import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth/auth.service";
import {
  ShareRequest,
  ShareRequestService,
  ShareRequestStatus,
  ShareRequestType
} from "../../../services/share-request.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MessageEditorComponent} from "../../messages/message-editor/message-editor.component";
import {
  ProspectStatus,
  Sharer,
  SharerEmailStatus,
  SharerService,
  SharerStatus,
  SharerWizardStatus
} from "../../../services/sharer.service";
import {
  AddOauthToObject,
  OauthAction,
  OauthService,
  OauthType,
  UserOauthAccount
} from "../../../services/oauth.service";
import {combineLatest, Observable, Subject} from "rxjs";
import {MatStepper} from "@angular/material/stepper";
import {DialogService} from "../../../services/utilities/dialog.service";
import {UserService} from "../../../services/user.service";
import {AudienceEditorMode} from "../../../services/audience.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {ContactService} from "../../../services/contact.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

import {faGoogle, faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'share-request-sharer-detail',
  templateUrl: './sharer-detail.component.html',
  styleUrls: ['./sharer-detail.component.scss']
})
export class SharerDetailComponent implements OnInit {

  faCaretDown = faCaretDown
  faGoogle = faGoogle
  faMicrosoft = faMicrosoft

  @ViewChild('prospectMessage') prospectMessage!: MessageEditorComponent
  @ViewChild('stepper') stepper!: MatStepper

  tabs: string[] = ['overview', 'prospects', 'feed', 'analytics']
  selectedTab: FormControl = new FormControl(0)

  shareRequest: ShareRequest = this.shareRequestService.new()
  sharer: Sharer = this.sharerService.new()
  prospectsSent: number = 0
  prospectsTotal: number = 0
  addOauthTo!: AddOauthToObject

  OauthType = OauthType
  OauthAction = OauthAction

  ShareRequestType = ShareRequestType
  ShareRequestStatus = ShareRequestStatus

  SharerStatus = SharerStatus
  SharerEmailStatus = SharerEmailStatus
  SharerWizardStatus = SharerWizardStatus
  wizardStatuses = [
    SharerWizardStatus.messageReview as string,
    SharerWizardStatus.emailConnected as string,
    SharerWizardStatus.contactsAdded as string,
  ]

  audienceEditorMode: string = AudienceEditorMode.singular

  emailAccounts: UserOauthAccount[] = []
  gmailLabel: string = (
    this.breakpointObserver.isMatched(Breakpoints.XSmall) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)
  ) ? 'Add' : 'Add Gmail Account'
  outlookLabel: string = (
    this.breakpointObserver.isMatched(Breakpoints.XSmall) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)
  ) ? 'Add' : 'Add Outlook Account'
  twitterAccounts: UserOauthAccount[] = []
  twitterAccountLabel: string = ''
  linkedinAccounts: UserOauthAccount[] = []
  linkedinAccountLabel: string = ''

  loading: boolean = true
  addingProspects: boolean = false
  showWizard: boolean = false
  isAuthenticated: boolean = false
  isPreview: boolean = false

  stepperIndex: number = 0

  senderControl: FormControl = new FormControl()

  formatDate = FormatUtils.formatDate
  getDisplayName = this.userService.getDisplayName

  constructor(private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private authService: AuthService,
              private userService: UserService,
              private contactService: ContactService,
              private shareRequestService: ShareRequestService,
              private sharerService: SharerService,
              private oauthService: OauthService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    // 3 scenarios of this controller (TODO: Split up sharer-detail use cases
    // 1) Non-user is viewing preview of sharer detail via share link
    // 2) Loggedin user is viewing preview of sharer detail (e.g. after oauth flow), so needs to get redirected to logged in sharer detail
    // 3) Loggedin user is viewing normal sharer detail page

    combineLatest([this.route.url, this.route.queryParams]).subscribe(
      ([urlSegments, queryParams]) => {
        this.isAuthenticated = this.authService.isAuthenticated()
        const type: string = urlSegments[0].toString()
        const id: string = urlSegments[1].toString()

        if (this.loading) {
          const index = this.tabs.findIndex(tab => { return tab == queryParams['tab'] })
          if (index >= 0) {
            this.setTab(index)
          }

          if (queryParams && queryParams['preview']) {
            this.isPreview = true
            this.isAuthenticated = false // force unauthentication if doing preview for a logged in user
          }
        }

        this.shareRequestService.detail$.subscribe(
          shareRequest => {
            this.shareRequest = shareRequest

            if (this.shareRequest.type === ShareRequestType.xPost) {
              this.twitterAccountLabel = this.getDisplayName(this.shareRequest.createdByUser, "Requestor") + "'s X Account"
            } else if (this.shareRequest.type === ShareRequestType.linkedinPost) {
              this.linkedinAccountLabel = this.getDisplayName(this.shareRequest.createdByUser, "Requestor") + "'s LinkedIn Account"
            }

            if (type === 'sharer' && this.isAuthenticated) {
              // Logged in user viewing normal share detail
              this.sharer = this.shareRequest.sharers?.find(s => s.id === parseInt(id)) || this.sharerService.new()
              this.addOauthTo = {
                type: 'share_request_sharer',
                field: 'user_oauth_id',
                id: this.sharer.id,
              }

              if (this.loading) {
                if (
                  queryParams &&
                  queryParams['showWizard'] >= 0 &&
                  this.shareRequest.type === ShareRequestType.email
                ) {
                  this.showWizard = true
                  this.stepperIndex = queryParams['showWizard']
                } else if (
                  this.sharer.status !== SharerStatus.completed &&
                  this.sharer.lastWizardStep >= 0 &&
                  this.shareRequest.type === ShareRequestType.email
                ) {
                  this.showWizard = true
                  this.initStepper()
                }
              }

              if (!this.sharer.subjectOverride || this.sharer.subjectOverride === '') {
                this.sharer.subjectOverride = this.shareRequest.prospectMessageSubject
              }
              if (!this.sharer.contentOverride || this.sharer.contentOverride === '') {
                this.sharer.contentOverride = this.shareRequest.prospectMessageContent
              }
              this.addingProspects = this.sharer.emailStatus === SharerEmailStatus.waiting
              this.prospectsTotal = this.sharer.prospects.length
              this.prospectsSent = this.sharer.prospects.filter(
                s => {
                  return s.status !== ProspectStatus.pending
                }
              ).length
              this.sharerService.setDetail(this.sharer)
              if (this.sharer.status === SharerStatus.requestSent) {
                this.sharer.status = SharerStatus.requestViewed
                this.sharerService.save(this.sharer).subscribe(
                  sharer => {
                    this.sharer = sharer
                    this.sharerService.setDetail(this.sharer)
                  }
                )
              }

              this.oauthService.userList$.subscribe((accounts: UserOauthAccount[]) => {
                this.emailAccounts =this.oauthService.filterEmailAccounts(accounts)
                this.twitterAccounts = accounts.filter(a => {
                  return a.type === OauthType.twitter
                })
                this.linkedinAccounts = accounts.filter(a => {
                  return a.type === OauthType.linkedin
                })
                if (this.sharer && this.sharer.userOauthId <= 0) {
                  if (
                    (this.shareRequest.type === ShareRequestType.email || this.shareRequest.type === ShareRequestType.intro) &&
                    this.emailAccounts.length === 1
                  ) {
                    this.sharer.userOauthId = this.emailAccounts[0].id
                    this.save()
                  } else if (this.shareRequest.type === ShareRequestType.xPost && this.twitterAccounts.length === 1) {
                    this.sharer.userOauthId = this.twitterAccounts[0].id
                    this.save()
                  } else if (this.shareRequest.type === ShareRequestType.linkedinPost && this.linkedinAccounts.length === 1) {
                    this.sharer.userOauthId = this.linkedinAccounts[0].id
                    this.save()
                  }
                }
                this.senderControl.setValue(this.sharer?.userOauthId)
                this.loading = false
              })
              this.oauthService.getAllForUser()
            } else if (this.isAuthenticated && this.shareRequest.sharers && this.shareRequest.sharers.length > 0) {
              // Logged in user viewing preview, redirect to normal share detail page
              const extras = (this.shareRequest.type === ShareRequestType.email || this.shareRequest.type === ShareRequestType.intro)
                ? { queryParams: { showWizard: 0 } }
                : undefined
              this.router.navigate(['sharer', this.shareRequest.sharers[0].id], extras)
            } else {
              // Logged out preview view
              this.addOauthTo = {
                type: 'new_sharer_user',
                field: 'user_oauth_id',
                id: this.shareRequest.id,
              }
              this.loading = false
            }
          }, err => {
            this.dialogService.messageDialog(
              "Network Request Not Found",
              "Sorry, we could not find this network request."
            ).subscribe(
              () => {
                this.router.navigate([''])
              }
            )
          }
        )
        if (type === 'sharer') {
          this.shareRequestService.getDetailBySharerId(parseInt(id), true)
        } else {
          this.shareRequestService.getDetailByPlug(id, !this.isPreview && this.isAuthenticated)
        }
        if (this.isAuthenticated) {
          this.userService.getIdentity()
        }
      }
    )
  }

  hasSent(): boolean {
    return [
      SharerEmailStatus.sending as string,
      SharerEmailStatus.paused as string,
      SharerEmailStatus.done as string,
    ].indexOf(this.sharer.emailStatus) >= 0
  }

  stepIsCompleted(wizardStatus: string): boolean {
    const stepIndex = this.wizardStatuses.indexOf(wizardStatus)
    return this.sharer.lastWizardStep > stepIndex
  }

  initStepper() {
    // Get stepper to the earliest incomplete step
    this.wizardStatuses.forEach((stepStatus, stepIndex) => {
      if (this.stepIsCompleted(stepStatus)) {
        this.stepperIndex = stepIndex + 1
      }
    })
    this.setWizardStep()
  }

  completeStep(currentStep: string, goNext: boolean = true) {
    const currentStepIndex = this.wizardStatuses.indexOf(currentStep)
    let nextStepIndex = goNext
      ? currentStepIndex + 1
      : currentStepIndex - 1
    if (this.stepper && nextStepIndex >= this.stepper.steps.length) {
      nextStepIndex = -1
    }
    this.sharer.lastWizardStep = nextStepIndex
    this.sharerService.save(this.sharer).subscribe(
      sharer => {
        if (nextStepIndex >= 0) {
          this.stepperIndex = nextStepIndex
          this.setWizardStep()
        } else {
          this.skipWizard()
        }
      }
    )
  }

  skipWizard() {
    this.showWizard = false
    this.setWizardStep()
  }

  setWizardStep() {
    const url: string = this.router.createUrlTree(
      [],
      {
        relativeTo: this.route,
        queryParams: { showWizard: (this.showWizard && this.stepperIndex >= 0) ? this.stepperIndex : null },
        queryParamsHandling: 'merge'
      }
    ).toString()
    this.location.go(url)
  }

  setTab(index: any){
    this.selectedTab.setValue(index)
    const url: string = this.router.createUrlTree(
      [],
      {
        relativeTo: this.route,
        queryParams: { tab: this.tabs[index] },
        queryParamsHandling: 'merge'
      }
    ).toString()
    this.location.go(url)
    this.prospectMessage?.redrawEditor()
  }

  canSend(): boolean {
    return this.sharerService.canSend(this.sharer)
  }

  waitingOnImport(): boolean {
    return this.sharer.numProspects === 0 && this.sharer.emailStatus === SharerEmailStatus.waiting
  }

  waitOnImport(sendOnCompletion: boolean) {
    if (sendOnCompletion) {
      this.sharer.sendAfterImport = true
    }
    this.completeStep(SharerWizardStatus.emailConnected)
  }

  userOauthChange(userOauthId: number) {
    this.sharer.userOauthId = userOauthId
    this.save()
  }

  prospectMessageChange(messageEvent: any) {
    if (this.sharer) {
      this.sharer.subjectOverride = messageEvent.subject
      this.sharer.contentOverride = messageEvent.content
      this.save()
    }
  }

  postAddProspects() {
    if (!this.showWizard) {
      this.setTab(1)
    }
  }

  addProspects() {
    if (this.sharer && this.shareRequest.prospectAudience) {
      this.addingProspects = true
      this.sharerService.addProspects(this.sharer, [], this.shareRequest.prospectAudience).subscribe(
        sharer => {
          this.sharer = sharer
          if (this.sharer.emailStatus !== SharerEmailStatus.waiting) {
            this.addingProspects = false
            this.notificationService.success('Prospects added.')
            this.postAddProspects()
          } else {
            this.dialogService.messageDialog(
              "Active Contact Import",
              "You have a contact import still in progress, so the matching contacts will be added after its completed.  You can choose to review the list after the import is done or just send the emails automatically after the import."
            ).subscribe(() => {
              this.postAddProspects()
            })
          }
        },
        err => {
          this.addingProspects = false
          this.notificationService.error(err.message)
        }
      )
    }
  }

  save(message: string = 'Network request saved.'): Observable<any> {
    const saveSubject = new Subject()
    this.sharerService.save(this.sharer).subscribe(sharer => {
      this.notificationService.success(message)
      saveSubject.next(true)
    })
    return saveSubject.asObservable()
  }

  send() {
    this.sharer.emailStatus = SharerEmailStatus.sending
    this.sharer.lastWizardStep = -1
    this.skipWizard()
    this.save('Started emailing contacts.')
  }

  pause() {
    this.sharer.emailStatus = SharerEmailStatus.paused
    this.sharer.lastWizardStep = -1
    this.skipWizard()
    this.save('Paused emailing contacts.')
  }

  resume() {
    this.sharer.emailStatus = SharerEmailStatus.sending
    this.sharer.lastWizardStep = -1
    this.skipWizard()
    this.save('Resumed emailing contacts.')
  }

  retweet() {
    this.sharerService.retweet(this.sharer).subscribe(
      sharer => {
        this.shareRequestService.getDetailBySharerId(sharer.id, true)
      }
    )
  }

  twitterOauthChange(userOauthId: number) {
    this.sharer.userOauthId = userOauthId
    this.save('X account saved.')
  }

  likeLinkedInPost() {
    this.sharerService.likeLinkedInPost(this.sharer).subscribe(
      sharer => {
        this.shareRequestService.getDetailBySharerId(sharer.id, true)
      }
    )
  }

  linkedinOauthChange(userOauthId: number) {
    this.sharer.userOauthId = userOauthId
    this.save('LinkedIn account saved.')
  }

  saveAutoCompletePreference() {
    this.contactService.save(this.sharer.contact).subscribe(
      contact => {
        this.notificationService.success('Preference saved.')
      }
    )
  }

  autoCompleteTwitterOauthChange(userOauthId: number) {
    this.sharer.contact.autoCompleteTwitterOauthId = userOauthId
    this.saveAutoCompletePreference()
  }

  autoCompleteTweetChange() {
    this.sharer.contact.autoCompleteTweetRequests = !this.sharer.contact.autoCompleteTweetRequests
    this.saveAutoCompletePreference()
  }

  autoCompleteLinkedinOauthChange(userOauthId: number) {
    this.sharer.contact.autoCompleteLinkedinOauthId = userOauthId
    this.saveAutoCompletePreference()
  }

  autoCompleteLinkedinPostChange() {
    this.sharer.contact.autoCompleteLinkedinPostRequests = !this.sharer.contact.autoCompleteLinkedinPostRequests
    this.saveAutoCompletePreference()
  }

  doOauthGoogle() {
    this.oauthService.doOauthGoogle(
      OauthAction.continue,
      document.location.href,
      'auto',
      this.addOauthTo,
    )
  }

  getSharerStatusLabel() {
    return this.sharerService.getSharerStatusLabel(this.shareRequest, this.sharer)
  }

  doOauth(type: string) {
    this.oauthService.doOauth(
      type,
      OauthAction.add,
      document.location.href,
      'auto',
      this.addOauthTo,
    )
  }

}
