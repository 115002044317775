import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {
  ContactDetail,
  ContactDetailResult,
  NetworkSummary,
  NetworkSummaryResult
} from "./contact.service";
import {RawXPost} from "./x-twitter.service";
import {RawLinkedInPost} from "./linkedin.service";

export interface Company {
  id: number
  createdAt: number
  name: string
  description: string
  logoUrl: string
  domains: string[]
  companyType: string
  companySize: string
  revenue: string
  primaryIndustry: string
  industries: string[]
  fundingStage: string
  fundsRaised: number
  technologies: string[]
  technologyCategories: string[]
  investorType: string
  investmentStage: string[]
  twitterUsername: string
  linkedinUrl: string
  myContacts: ContactDetail[]
  myContactsCount: number
  networkContacts: NetworkSummary[]
  networkContactsCount: number
}

export interface CompanyResult {
  id: number
  created_at: number
  name: string
  description: string
  logo_url: string
  domains: string[]
  company_type: string
  company_size: string
  revenue: string
  primary_industry: string
  industries: string[]
  funding_stage: string
  funds_raised: number
  technologies: string[]
  technology_categories: string[]
  investor_type: string
  investor_stage: string[]
  twitter_username: string
  linkedin_url: string
  my_contacts: ContactDetailResult[]
  my_contacts_count: number
  network_contacts: NetworkSummaryResult[]
  network_contacts_count: number
}


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private endpoint: string = 'companies'

  constructor(private api: ApiService,
  ) {
  }

  getCompanyByDomain(domain: string): Observable<Company> {
    return this.api.get<Company>(this.endpoint + '/' + domain + '/enrich', {}).pipe(
      map(company => FormatUtils.snakeToCamelCaseKeys(company))
    )
  }

  getCompanyTweets(domain: string): Observable<RawXPost[]> {
    return this.api.get<RawXPost[]>(this.endpoint + '/' + domain + '/x_posts', {}).pipe(
      map(tweets => FormatUtils.snakeToCamelCaseKeys(tweets))
    )
  }

  getCompanyLinkedInPosts(domain: string): Observable<RawLinkedInPost[]> {
    return this.api.get<RawLinkedInPost[]>(this.endpoint + '/' + domain + '/linkedin_posts', {}).pipe(
      map(posts => FormatUtils.snakeToCamelCaseKeys(posts))
    )
  }

}
