import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactListItem, ContactService} from "../../../services/contact.service";

@Component({
  selector: 'add-edit-contact',
  templateUrl: './add-edit-contact.component.html',
  styleUrls: ['./add-edit-contact.component.scss']
})
export class AddEditContactComponent implements OnInit {

  contact!: ContactListItem

  errMessage: string = ''

  editForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    title: new FormControl(),
    companyName: new FormControl(),
    primaryEmail: new FormControl(),
  })

  constructor(public dialogRef: MatDialogRef<AddEditContactComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ContactListItem,
              private contactService: ContactService,
  ) {
  }

  ngOnInit() {
    this.contact = this.data ? this.data : this.contactService.new()
    this.editForm.patchValue({
      firstName: this.contact.firstName,
      lastName: this.contact.lastName,
      title: this.contact.title,
      companyName: this.contact.companyName,
      primaryEmail: this.contact.primaryEmail,
    })
  }

  close(contact?: ContactListItem) {
    this.dialogRef.close(contact)
  }

  save() {
    this.contact.firstName = this.editForm.value.firstName
    this.contact.lastName = this.editForm.value.lastName
    this.contact.title = this.editForm.value.title
    this.contact.companyName = this.editForm.value.companyName
    this.contact.primaryEmail = this.editForm.value.primaryEmail
    this.contactService.save(this.contact).subscribe(
      newContact => {
        this.contact = newContact
        this.close(this.contact)
      },
      err => {
        this.errMessage = err ? err.message : 'There was an error saving the contact.'
        setTimeout(() => {
          this.errMessage = ''
        }, 5000)
      }
    )
  }

}
