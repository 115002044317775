<mat-form-field class="width-100">
  <mat-label>{{label}}</mat-label>
  <input type="search"
         data-lpignore="true"
         id="newValueInput"
         matInput
         [formControl]="newValueControl"
         [matAutocomplete]="auto"
         (keyup)="updateAutocompleteOptions($event)"
         (blur)="checkClearInput()"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let contact of contacts | async"
                [value]="contact"
                (onSelectionChange)="selectContact($event)"
    >
      {{getDisplayName(contact)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
