import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {OauthService, UserOauthAccount, AddOauthToObject, OauthType} from "../../../services/oauth.service";
import {ShareRequest, ShareRequestService} from "../../../services/share-request.service";
import {XPost, XTwitterService} from "../../../services/x-twitter.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'x-post-request-setup',
  templateUrl: './x-post-request-setup.component.html',
  styleUrls: ['./x-post-request-setup.component.scss']
})
export class XPostRequestSetupComponent implements OnInit, OnChanges, AfterViewInit {

  @Input({required: true}) shareRequest!: ShareRequest
  @Input() readOnly: boolean = false
  @Input() accountLabel: string = 'X Account'

  @Output() onChange = new EventEmitter()

  addOauthTo!: AddOauthToObject
  twitterAccounts: UserOauthAccount[] = []
  xPosts: XPost[] = []
  xPost: XPost = this.xTwitterService.new()

  tweetIdControl = new FormControl()
  tweetControl = new FormControl()

  loading: boolean = true

  formatDate = FormatUtils.formatDate

  constructor(private xTwitterService: XTwitterService,
              private oauthService: OauthService,
  ) {
  }

  ngOnInit() {
    if (this.shareRequest) {
      this.setShareRequest(this.shareRequest)
    }
  }

  ngAfterViewInit() {
    this.oauthService.userList$.subscribe((accounts: UserOauthAccount[]) => {
      this.twitterAccounts = accounts.filter(a => {
        return a.type === OauthType.twitter
      })
      this.checkTwitterOauth()
      this.loading = false
    })
    if (!this.readOnly) {
      this.oauthService.getAllForUser()
    }

    this.xTwitterService.list$.subscribe(
      (posts: XPost[]) => {
        this.xPosts = posts
        this.setTweet()
      }
    )
    if (!this.readOnly) {
      this.xTwitterService.getAll(this.shareRequest.twitterOauthId)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shareRequest']) {
      this.setShareRequest(changes['shareRequest'].currentValue)
    }
  }

  setShareRequest(shareRequest: ShareRequest) {
    this.shareRequest = shareRequest
    this.addOauthTo = {
      type: 'share_request',
      field: 'twitter_oauth_id',
      id: this.shareRequest.id,
    }
    this.checkTwitterOauth()
  }

  checkTwitterOauth() {
    // Default ouath account if not set
    if (this.shareRequest.twitterOauthId <= 0 &&
      this.twitterAccounts.length === 1
    ) {
      this.shareRequest.twitterOauthId = this.twitterAccounts[0].id
      this.xTwitterService.getAll(this.shareRequest.twitterOauthId)
    }

    // If read only, get X post details from network request
    if (this.readOnly) {
      this.setTweet()
      this.loading = false
    }
  }

  twitterOauthChange(twitterOauthId: number) {
    this.shareRequest.twitterOauthId = twitterOauthId
    this.change()
  }

  tweetChange() {
    if (this.tweetIdControl.value > 0) {
      this.shareRequest.tweetId = this.tweetIdControl.value
      this.setTweet()
      this.change()
    } else {
      this.tweetControl.setValue('')
    }
  }

  setTweet() {
    const xPost = this.xPosts?.find(t => { return t.id === this.shareRequest.tweetId })
    if (xPost) {
      this.xPost = xPost
    } else {
      this.xPost = this.shareRequest.tweet || this.xTwitterService.new()
    }
    this.tweetIdControl.setValue(this.xPost.id)
    this.tweetControl.setValue(this.xPost.postContent)
  }

  change() {
    if (!this.loading && this.onChange) {
      this.shareRequest.tweet = this.xPost
      this.onChange.emit(this.shareRequest)
    }
  }

  post() {
    this.xTwitterService.post(this.shareRequest.id, this.shareRequest.twitterOauthId, this.tweetControl.value).subscribe(
      post => {
        this.xTwitterService.getAll(this.shareRequest.twitterOauthId, true).subscribe(
          allPosts => {
            this.xPost = post
            this.shareRequest.tweetId = this.xPost.id
            this.shareRequest.tweet = this.xPost
            this.tweetIdControl.setValue(this.xPost.id)
            this.tweetControl.setValue(this.xPost.postContent)
            this.change()
          }
        )
      }
    )
  }

  truncateTweet(tweet: string) {
    return tweet.substring(0, 30)
  }

}
