<button mat-flat-button
        color="primary"
        [matMenuTriggerFor]="actionMenu"
>
  {{label}}
  <fa-icon [icon]="faCaretDown"></fa-icon>
</button>
<mat-menu #actionMenu="matMenu">
  <a mat-menu-item (click)="doOauth(OauthType.google)">
    <fa-icon [icon]="faGoogle" class="horiz-margin"></fa-icon>
    Add Google Account
  </a>
  <a mat-menu-item (click)="doOauth(OauthType.microsoft)">
    <fa-icon [icon]="faMicrosoft" class="horiz-margin"></fa-icon>
    Add Outlook Account
  </a>
  <a mat-menu-item (click)="doOauth(OauthType.twitter)">
    <fa-icon [icon]="faXTwitter" class="horiz-margin"></fa-icon>
    Add X Account
  </a>
  <a mat-menu-item (click)="doOauth(OauthType.linkedin)">
    <fa-icon [icon]="faLinkedin" class="horiz-margin"></fa-icon>
    Add LinkedIn Account
  </a>
</mat-menu>
