<div class="flex-col-top">
  <mat-spinner color="primary" *ngIf="loading"></mat-spinner>
</div>
<div *ngIf="!loading" class="reg-container">
  <h1 *ngIf="!company" class="text-align-center">Join Wrollo</h1>
  <div *ngIf="company" class="vert-margin">
    <div class="text-align-center" *ngIf="company">
      <img [src]="'https://logo.clearbit.com/'+company.domains[0] + '?size=256'"
           onerror="this.src='../../../../assets/white_background.png'"
           class="company-logo"
      >
    </div>
    <h1 class="text-align-center">Welcome to Wrollo{{company ? ' @ ' + company.name : ''}}</h1>
    <h3 class="text-align-center">Enhance your company's reach by joining other employees who like and follow your company's social media.</h3>
  </div>
  <div class="flex-row-space-between-top">
    <mat-card class="width-45 horiz-margin-2x">
      <mat-card-content>
        <h2>Recent X Posts</h2>
        <!--
        <div *ngIf="company && company.twitterUsername">
          <ngx-x-twitter-timeline [source]="{sourceType: 'profile', screenName: company.twitterUsername}"
                                [options]="{height: '800', chrome: ['noheader', 'nofooter'], tweetLimit : 10}"
          >
          </ngx-x-twitter-timeline>
        </div>
        -->
        <div class="width-100 flex-row-left vert-margin">
          <div class="width-20">
            <mat-checkbox (change)="selectAllXPosts()"
                          [checked]="likeAllXPosts"
            >
              Like All
            </mat-checkbox>
          </div>
          <div class="width-20">
            <mat-checkbox (change)="followXAccount = !followXAccount"
                          [checked]="followXAccount"
            >
              Follow
            </mat-checkbox>
          </div>
          <div class="width-60">
            <button mat-flat-button
                    color="primary"
                    (click)="engageWithXAccount()"
                    matTooltip="Select some or all X posts to act on"
                    class="horiz-margin-left"
                    [disabled]="getNumXPostsSelected() == 0 && !followXAccount"
            >
              {{followXAccount && getNumXPostsSelected() > 0 ? 'Follow & Like' : (followXAccount ? 'Follow' : 'Like')}}
            </button>
          </div>
        </div>
        <div *ngFor="let xPost of xPosts; let i = index">
          <div class="flex-row-left-top width-100">
            <div class="width-10 vert-margin">
              <mat-checkbox (change)="xPostSelection[i] = !xPostSelection[i]"
                            [checked]="xPostSelection[i]"
              ></mat-checkbox>
            </div>
            <div class="width-90">
              <x-post-display [post]="xPost"
                              [accountName]="company ? company.name : ''"
                              [accountUsername]="company ? company.twitterUsername : ''"
              ></x-post-display>
              <!--
              <ngx-twitter-tweet
                  [tweetID]="xPost.id"
              ></ngx-twitter-tweet>
              -->
            </div>
          </div>
        </div>
        <div *ngIf="xPosts.length === 0" class="vert-margin-2x vert-padding-2x text-align-center">
          Sorry, no X posts were found.
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="width-45 horiz-margin-2x">
      <mat-card-content>
        <h2>Recent LinkedIn Posts</h2>
        <div class="width-100 flex-row-left vert-margin">
          <div class="width-20">
            <mat-checkbox (change)="selectAllLIPosts()"
                          [checked]="likeAllLIPosts"
            >
              Like All
            </mat-checkbox>
          </div>
          <div class="width-60">
            <button mat-flat-button
                    color="primary"
                    (click)="engageWithLinkedInAccount()"
                    matTooltip="Select some or all LinkedIn posts to act on"
                    class="horiz-margin-left"
                    [disabled]="getNumLIPostsSelected() == 0"
            >
              Like
            </button>
          </div>
        </div>
        <div *ngFor="let liPost of liPosts; let i = index">
          <div class="flex-row-left-top width-100">
            <div class="width-10 vert-margin">
              <mat-checkbox (change)="liPostSelection[i] = !liPostSelection[i]"
                            [checked]="liPostSelection[i]"
              ></mat-checkbox>
            </div>
            <div class="width-90">
              <linkedin-post-display [post]="liPost"
                                     [accountName]="company ? company.name : ''"
              ></linkedin-post-display>
            </div>
          </div>
        </div>
        <div *ngIf="liPosts.length === 0" class="vert-margin-2x vert-padding-2x text-align-center">
          Sorry, no LinkedIn posts were found.
        </div>
        <!--
        <div>Please connect your LinkedIn account to view your company's LinkedIn posts.</div>
        <div class="vert-margin">
          <oauth-button [type]="OauthType.linkedin"
                        [action]="OauthAction.continue"
                        label="Connect to LinkedIn"
                        [redirectUri]="returnUrl"
          ></oauth-button>

        </div>
        -->
      </mat-card-content>
    </mat-card>
  </div>
  <!--
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.google"
                  [action]="OauthAction.continue"
                  label="Sign up with Google"
                  [redirectUri]="returnUrl"
    ></oauth-button>
  </div>
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.microsoft"
                  [action]="OauthAction.continue"
                  label="Sign up with Outlook"
                  [redirectUri]="returnUrl"
    ></oauth-button>
  </div>
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.x-twitter"
                   [action]="OauthAction.continue"
                   label="Sign up with X"
                  [redirectUri]="returnUrl"
    ></oauth-button>
  </div>
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.linkedin"
                   [action]="OauthAction.continue"
                   label="Sign up with LinkedIn"
                   [redirectUri]="returnUrl"
    ></oauth-button>
  </div>
  <div class="text-align-center vert-padding">OR</div>
  <div>
    <form [formGroup]="form">
      <div>
        <mat-form-field class="reg-field">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName" required>
          <mat-error *ngIf="form.get('firstName')?.hasError('required')">This field is required.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="reg-field">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName" required>
          <mat-error *ngIf="form.get('lastName')?.hasError('required')">This field is required.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="reg-field">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required>
          <mat-error *ngIf="form.get('email')?.hasError('required')">This field is required.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="reg-field">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" required>
          <mat-error *ngIf="form.get('password')?.hasError('required')">This field is required.</mat-error>
          <mat-error *ngIf="form.get('password')?.hasError('pattern')"
                     matTooltip="Password must contain at least 1 number, 1 uppercase and 1 lowercase letter, 1 special character, and at least 8 characters."
          >Password is not strong enough.</mat-error>
        </mat-form-field>
      </div>
      <div>{{errorMsg}}</div>
      <div class="vert-margin">
        <button mat-flat-button
                color="primary"
                (click)="signup()"
                [disabled]="!form.valid"
        >Sign up!</button>
      </div>
      <div class="vert-margin">
        Already have an account? <a class="reg-link" href="/login">Log in</a>
      </div>
    </form>
  </div>
  -->
</div>
