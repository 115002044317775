import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core'
import {ContactDetail, ContactListItem, ContactService, PhoneNumber} from "../../../services/contact.service";
import {faLinkedin, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faPhone, faMobile, faHouse, faBuilding, faUserMinus, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {InviteService} from "../../../services/invite.service";
import {ShareRequest, ShareRequestService, ShareRequestStatus} from "../../../services/share-request.service";
import {Audience, AudienceService} from "../../../services/audience.service";
import {SharerService} from "../../../services/sharer.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {IntroRequestModalComponent} from "../../share-requests/intro-request-modal/intro-request-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'contact-profile-detail',
  templateUrl: './contact-profile-detail.html',
  styleUrls: ['./contact-profile-detail.scss']
})
export class ContactProfileDetail implements OnChanges {

  @Input() contact!: ContactDetail
  @Input() showActions: boolean = true

  @Output() onDelete = new EventEmitter()
  @Output() onChange = new EventEmitter()

  faEnvelope = faEnvelope
  faLinkedin = faLinkedin
  faXTwitter = faXTwitter
  faUserMinus = faUserMinus
  faPhone = faPhone
  faMobile = faMobile
  faHouse = faHouse
  faBuilding = faBuilding

  contactListItem!: ContactListItem

  shareRequests: ShareRequest[] = []
  audiences: Audience[] = []

  ShareRequestStatus = ShareRequestStatus

  constructor(private contactService: ContactService,
              private inviteService: InviteService,
              private shareRequestService: ShareRequestService,
              private sharerService: SharerService,
              private audienceService: AudienceService,
              private dialogService: DialogService,
              private dialog: MatDialog,
              private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.shareRequestService.list$.subscribe(
      shareRequests => {
        this.shareRequests = shareRequests
      }
    )
    this.shareRequestService.getAll()

    this.audienceService.list$.subscribe(
      audiences => {
        this.audiences = audiences
      }
    )
    this.audienceService.getAll()
  }

  ngOnChanges() {
    this.contactListItem = this.contactService.convertToContactListItem(this.contact)
  }

  getPhoneIcon(phone: PhoneNumber): IconDefinition {
    if (!phone.type) {
      return faPhone
    } else if (phone.type.indexOf('work') >= 0) {
      return faBuilding
    } else if (phone.type.indexOf('mobile') >= 0) {
      return faMobile
    } else if (phone.type.indexOf('home') >= 0 || phone.type.indexOf('personal') >= 0) {
      return faHouse
    } else {
      return faPhone
    }
  }

  openInviteDialog(inviteContact: boolean = false) {
    this.inviteService.openInviteDialog(
      false,
      inviteContact ? this.contactService.convertToQuickSearchItem([this.contactListItem]) : []
    )
  }

  inviteTeamMember() {
    this.inviteService.openInviteDialog(
      true,
      this.contactService.convertToQuickSearchItem([this.contactListItem])
    )
  }

  contactChanged() {
    if (this.onChange) {
      this.onChange.emit()
    }
  }

  addContactToShareRequest(shareRequest: ShareRequest) {
    const successMessage = 'The contact has been added to the network request'
    if (shareRequest.role === 'sharer' && shareRequest.sharers) {
      this.sharerService.addProspects(
        shareRequest.sharers[0],
        [this.contactListItem.id],
        undefined
    ).subscribe(
        newSharer => {
          this.notificationService.success(successMessage)
          this.contactChanged()
        }
      )
    } else {
      this.shareRequestService.addSharers(
        shareRequest,
        [this.contactListItem.id],
        [],
        undefined
      ).subscribe(
        newShareRequest => {
          this.notificationService.success(successMessage)
          this.contactChanged()
        }
      )
    }
  }

  removeContactFromShareRequest(shareRequest: ShareRequest) {
    const successMessage = 'The contact has been removed from the network request'
    if (shareRequest.role === 'sharer' && shareRequest.sharers) {
      this.sharerService.deleteProspects(shareRequest.sharers[0], [this.contactListItem]).subscribe(
        newSharer => {
          this.notificationService.success(successMessage)
          this.contactChanged()
        }
      )
    } else {
      this.shareRequestService.deleteSharers(shareRequest, [this.contactListItem]).subscribe(
        newShareRequest => {
          this.notificationService.success(successMessage)
          this.contactChanged()
        }
      )
    }
  }

  addContactToAudience(audience?: Audience) {
    if (!audience) {
      this.dialogService.inputDialog(
        'Create New Audience',
        'Please enter a name for your new audience',
        'Audience Name',
        '',
      ).subscribe(
        audienceName => {
          if (audienceName && audienceName !== '') {
            const newAudience = this.audienceService.new()
            newAudience.label = audienceName
            this.audienceService.save(newAudience).subscribe(
              savedAudience => {
                this.finishAddContactToAudience(savedAudience)
              }
            )
          }
        }
      )
    } else {
      this.finishAddContactToAudience(audience)
    }
  }

  removeContactFromAudience(audience: Audience) {
    this.finishRemoveContactFromAudience(audience)
  }

  finishAddContactToAudience(audience: Audience) {
    this.audienceService.addContacts(audience, [this.contactListItem]).subscribe(
      newSharer => {
        this.notificationService.success('1 contact has been added to the audience')
        this.contactChanged()
      }
    )
  }

  finishRemoveContactFromAudience(audience: Audience) {
    this.audienceService.deleteContacts(audience, [this.contactListItem]).subscribe(
      newSharer => {
        this.notificationService.success('1 contact has been removed from the audience')
        this.contactChanged()
      }
    )
  }

  confirmDeleteContact() {
    if (this.contact.audiences.length > 0) {
      this.dialogService.messageDialog(
        'Contact Added to Audiences',
        'Please remove this contact from all audiences before you delete it.'
      )
    } else if (this.contact.shareRequestsCreatedByMe.length > 0) {
      this.dialogService.messageDialog(
        'Contact Added to Network Requests',
        'Please remove this contact from all network requests before you delete it.'
      )
    } else {
      this.dialogService.confirmDialog(
        'Delete Contact',
        'Are you sure you want to delete this contact?'
      ).subscribe(
        result => {
          if (result) {
            this.contactService.delete(this.contactListItem).subscribe(
              result => {
                this.notificationService.success('Contact deleted.')
                if (this.onDelete) {
                  this.onDelete.emit()
                }
              }
            )
          }
        }
      )
    }
  }

  openIntroDialog() {
    this.dialog.open(IntroRequestModalComponent, {
      data: this.contact
    }).afterClosed().subscribe(
      sent => {
        if (sent) {
          this.notificationService.success('Your intro request has been sent.')
        }
      }
    )
  }

}
