<mat-card class="margin-center card-panel">
  <mat-card-content>
    <div *ngIf="loading">
      <mat-spinner color="accent" class="margin-center"></mat-spinner>
    </div>
    <div *ngIf="!loading">
      <div class="flex-row-left-top" *ngIf="readOnly">
        <div class="width-20">Account:</div>
        <div class="width-75">{{shareRequest.twitterOauth?.username}}</div>
      </div>
      <div *ngIf="!readOnly">
        <select-x-account [label]="accountLabel"
                          [accounts]="twitterAccounts"
                          [userOauthId]="shareRequest.twitterOauthId"
                          [addOauthTo]="addOauthTo"
                          (onChange)="twitterOauthChange($event)"
        ></select-x-account>
      </div>

      <div *ngIf="!readOnly && shareRequest.twitterOauthId > 0">
        <mat-form-field class="setup-field"  class="tweet-width">
          <mat-label>Select Post</mat-label>
          <mat-select [formControl]="tweetIdControl" (selectionChange)="tweetChange()">
            <mat-option [value]="-1">New Post</mat-option>
            <mat-option *ngFor="let post of xPosts"
                        [value]="post.id"
            >
              {{formatDate(post.createdAt || -1)}} {{truncateTweet(post.postContent)}}...
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="tweetIdControl.value !== null && tweetIdControl.value < 0">
        <mat-form-field class="tweet-width">
          <mat-label>Post</mat-label>
          <textarea matInput
                    role="textbox"
                    rows="4"
                    [formControl]="tweetControl"
                    [readonly]="tweetIdControl.value > 0 || readOnly"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="flex-row-left-top vert-margin" *ngIf="tweetIdControl.value > 0 && xPost.postContent !== ''">
        <div class="width-20">Post:</div>
        <div class="width-75">{{xPost.postContent}}</div>
      </div>

      <div class="flex-row-left-top vert-margin" *ngIf="tweetIdControl.value > 0">
        <div class="width-20">Posted At:</div>
        <div class="width-75">{{formatDate(xPost.createdAt || -1)}}</div>
      </div>

      <div *ngIf="tweetIdControl.value < 0 && !readOnly">
        <button mat-flat-button
                color="primary"
                (click)="post()"
                [disabled]="tweetControl.value === ''"
        >Post</button>
      </div>

    </div>
  </mat-card-content>
</mat-card>
