
<div class="signup-container">
  <h1 class="text-align-center">Forgot Your Password?</h1>
  <div>
    <form [formGroup]="form">
      <p>
        <mat-form-field class="login-field">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required>
        </mat-form-field>
      </p>
      <div>{{statusMsg}}</div>

      <div class="action-row-container flex-row-space-between">
        <button mat-flat-button
                color="primary"
                (click)="sendForgetPasswordLink()"
        >Send Link</button>
        <a href="/login" class="pad3 signup-link">Login</a>
      </div>
    </form>
  </div>

</div>
