import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {OauthAction, OauthService, OauthType, UserOauthAccount} from "../../../services/oauth.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {User, UserService} from "../../../services/user.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";


@Component({
  selector: 'email-account-list',
  templateUrl: './email-account-list.component.html',
  styleUrls: ['./email-account-list.component.scss']
})
export class EmailAccountListComponent implements OnInit {

  loading: boolean = true

  user: User = this.userService.new()
  allUserOauths: UserOauthAccount[] = []

  displayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['type', 'name', 'email']
    : ['type', 'name', 'email', 'contacts', 'importDate', 'teamMemberOauths', 'actions']
  numDisplayedColumns: number = this.displayedColumns.length
  accountTable: MatTableDataSource<UserOauthAccount> = new MatTableDataSource()

  gmailLabel: string = this.breakpointObserver.isMatched(Breakpoints.XSmall) ? 'Add' : 'Add Gmail Account'
  outlookLabel: string = this.breakpointObserver.isMatched(Breakpoints.XSmall) ? 'Add' : 'Add Outlook Account'

  OauthType = OauthType
  OauthAction = OauthAction

  constructor(private oauthService: OauthService,
              private userService: UserService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.oauthService.userList$.subscribe(accounts => {
      this.allUserOauths = accounts
      this.accountTable.data = this.oauthService.filterEmailAccounts(this.allUserOauths)
      this.loading = false
    })
    this.oauthService.getAllForUser()

    this.userService.user$.subscribe(
      user => {
        this.user = user
      }
    )
  }

  refreshGmailAccount(account: UserOauthAccount) {
    this.oauthService.doOauthGoogle(
      OauthAction.add,
      document.location.href,
      'force'
    )
  }

  confirmDeleteAccount(account: UserOauthAccount) {
    if (!this.user.hasPassword && this.allUserOauths.length === 1) {
      this.dialogService.messageDialog(
        "Set Your Password",
        "You haven't set your password yet, and this is your last method to login.  Please set a password before you delete this account."
      )
    } else {
      this.dialogService.confirmDialog(
        'Delete Email Account',
        'Are you sure you want to delete this email account?'
      ).subscribe(result => {
        if (result) {
          this.oauthService.deleteUserOauth(account).subscribe(
            results => {
              this.loading = true
              this.oauthService.getAllForUser(true)
              this.notificationService.success('Account has been deleted.')
            }
          )
        }
      })
    }
  }

  getLastImportProgressLabel(account: UserOauthAccount): string {
    return this.oauthService.getLastImportProgressLabel(account)
  }

  getLastImportDateLabel(account: UserOauthAccount): string {
    return this.oauthService.getLastImportDateLabel(account)
  }

  getLastImportCompleted(account: UserOauthAccount): boolean {
    return this.oauthService.getLastImportCompleted(account)
  }

  editTeamMemberOauths(account: UserOauthAccount) {
    this.oauthService.editTeamMemberOauths(account)
  }

}
