<div class="dialog-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile>
      {{isTeamInvite ? 'Invite Team Members' : 'Expand My Network'}}
    </h2>
    <div *ngIf="!isTeamInvite">
      Remember, anyone who accepts your invite can't see your individual contacts.  They just see the number of contacts that match their search criteria.
    </div>
    <mat-card class="vert-margin" *ngIf="showInviteLink">
      <mat-card-header>
        <mat-card-title>Use my invite link</mat-card-title>
        <mat-card-subtitle>This is your personal invite link.  Anyone who signs up via the link will join your network.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <div class="flex-row-left vert-margin" ngClass.xs="flex-wrap">
            <mat-form-field class="invite-field">
              <mat-label>Invite Link</mat-label>
              <input matInput
                     id="link-search"
                     type="text"
                     data-lpignore="true"
                     readonly
                     [ngModel]="inviteLink"
              >
            </mat-form-field>
            <button mat-flat-button
                    color="primary"
                    (click)="copyInviteLink()"
                    [disabled]="inviteLinkCopied"
                    class="copy-button"
            >{{inviteLinkCopied ? 'Copied' : 'Copy'}}</button>
          </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="vert-margin">
      <mat-card-header>
        <mat-card-title>
          {{!showInviteLink ? '' : 'Or send invite emails'}}
        </mat-card-title>
        <mat-card-subtitle>Do a quick search for existing contacts or enter in a list of email addresses separated by spaces, commas, semicolons, or new lines to send out invite emails.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="flex-row-left-top vert-padding" ngClass.xs="flex-wrap">
          <div class="flex-col-left" ngClass.xs="width-100" ngClass.gt-xs="width-33">
            <mat-form-field class="width-100">
              <mat-label>Add Invitees</mat-label>
              <mat-chip-grid #valueChips>
                <mat-chip-row *ngFor="let invitee of invitees" (removed)="deleteInvitee(invitee)" class="chip-row">
                  {{getDisplayName(invitee)}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input matInput
                       type="search"
                       data-lpignore="true"
                       [matChipInputFor]="valueChips"
                       id="newValueInput"
                       [formControl]="newInviteeControl"
                       [matAutocomplete]="auto"
                       (keyup)="addInviteeKeyup($event)"
                       (blur)="checkClearInput()"
                />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let contact of contacts | async"
                              [value]="contact"
                              (onSelectionChange)="selectContact(contact)"
                  >
                    {{getDisplayName(contact)}}
                  </mat-option>
                </mat-autocomplete>
              </mat-chip-grid>
            </mat-form-field>
          </div>
          <div class="flex-col-left invite-panel" ngClass.xs="width-100" ngClass.gt-xs="width-33">
            <mat-form-field *ngIf="showNote" class="invite-field">
              <mat-label>Note (Optional)</mat-label>
              <textarea matInput
                        role="textbox"
                        rows="3"
                        [formControl]="noteControl"
              ></textarea>
            </mat-form-field>
            <a (click)="showNote = !showNote" *ngIf="!showNote" class="pointer horiz-margin-1x">Add a note</a>
          </div>
          <div class="flex-col-left invite-panel" ngClass.xs="width-100" ngClass.gt-xs="width-33">
            <div class="flex-row-left">
              <button mat-flat-button
                      color="primary"
                      (click)="invite()"
                      class="vert-margin"
                      [disabled]="(invitees.length === 0) || invitesSending"
              >Invite</button>
            </div>
          </div>
        </div>
        <div *ngIf="invitesSent > 0">{{(invitesSent | number) + ' ' + pluralize('invite', invitesSent) + ' sent'}}</div>
      </mat-card-content>
    </mat-card>

    <div class="vert-padding" *ngIf="isModal()">
      <button mat-raised-button
              mat-dialog-close
              (click)="close()"
      >Close</button>
    </div>
  </div>
</div>
