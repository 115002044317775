import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogComponent} from "../../components/dialog/dialog.component";
import {InputChipOption} from "../../components/form/input-chip/input-chip.component";

@Injectable()
export class DialogService {

  constructor(public dialog: MatDialog) {
  }

  dialogRef?: MatDialogRef<DialogComponent>

  confirmDialog(title: string, message: string, submitLabel: string = 'Yes', cancelLabel: string = 'No'): Observable<boolean> {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: {title: title, message: message, submitLabel: submitLabel, cancelLabel: cancelLabel}
    })
    return this.dialogRef.afterClosed()
  }

  messageDialog(title: string, message: string): Observable<boolean> {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: {title: title, message: message}
    })
    return this.dialogRef.afterClosed()
  }

  inputDialog(title: string, message: string, inputLabel: string, defaultValue: string, saveLabel?: string, cancelLabel?: string): Observable<string> {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: title,
        message: message,
        inputLabel: inputLabel,
        defaulInputValue: defaultValue,
        submitLabel: saveLabel || 'Save',
        cancelLabel: cancelLabel || 'Cancel',
      },
    })
    return this.dialogRef.afterClosed()
  }

  inputChipDialog(title: string, message: string, inputLabel: string, defaultValues: string[], saveLabel?: string, cancelLabel?: string): Observable<string[]> {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: title,
        message: message,
        inputChipLabel: inputLabel,
        defaulInputChipValues: defaultValues,
        submitLabel: saveLabel || 'Save',
        cancelLabel: cancelLabel || 'Cancel',
      },
    })
    return this.dialogRef.afterClosed()
  }

}

export interface DialogData {
  title: string
  message: string
  inputLabel?: string
  submitLabel?: string
  cancelLabel?: string
}
