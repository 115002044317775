<div>
  <mat-drawer-container class="width-100 list-container">
    <mat-drawer #contactDrawer mode="over" position="end" [opened]="viewProfileIndex >= 0" fixedInViewport="true">
      <contact-profile-nav [viewIndex]="viewProfileIndex"
                           [minItems]="minItems"
                           (onNav)="viewContact($event)"
      ></contact-profile-nav>
      <contact-profile [contactId]="sharerTable.filteredData[viewProfileIndex] ? sharerTable.filteredData[viewProfileIndex].contact.id : -1"
                       [showActions]="false"
      ></contact-profile>
    </mat-drawer>
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <div>
            <table mat-table [dataSource]="sharerTable" matSort #sharerListSort="matSort">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="thin-col">
                  <mat-checkbox (change)="$event ? selectAll() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                  ></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" class="thin-col">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                  ></mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell
                    *matHeaderCellDef
                    matTooltip="Select some or all sharers to act on"
                    class="thin-col"
                >
                  <button mat-icon-button
                          [matMenuTriggerFor]="bulkActionsMenu"
                          [disabled]="selection.selected.length === 0"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #bulkActionsMenu="matMenu">
                    <button mat-menu-item (click)="deleteSelectedSharers()">
                      Delete
                    </button>
                  </mat-menu>
                </th>
                <td mat-cell *matCellDef="let sharer; let i = index;" class="thin-col">
                  <button mat-icon-button
                          [matMenuTriggerFor]="actionsMenu"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #actionsMenu="matMenu">
                    <a mat-menu-item target="_blank" [routerLink]="['/sharer', sharer.id]">
                      Preview
                    </a>
                    <button mat-menu-item *ngIf="viewProfileIndex != i" (click)="viewContact(i)">
                      View Contact
                    </button>
                    <button mat-menu-item (click)="confirmDeleteSharer(sharer)" *ngIf="sharer.status === SharerStatus.pending">
                      Delete
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <ng-container matColumnDef="avatar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let sharer; let i = index;" (click)="viewContact(i)" class="pointer">
                  <profile-avatar [contact]="sharer.contact"></profile-avatar>
                </td>
              </ng-container>

              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let sharer; let i = index;" (click)="viewContact(i)" class="pointer">
                  {{sharer.contact.profile.fullName}}
                </td>
              </ng-container>
              <!--
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let sharer; let i = index;" (click)="viewContact(i)" class="pointer">
                  <short-label [label]="sharer.contact.profile.title?.title"></short-label>
                </td>
              </ng-container>
              -->
              <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                <td mat-cell *matCellDef="let sharer; let i = index;" (click)="viewContact(i)" class="pointer">
                  <short-label [label]="sharer.contact.profile.company?.name"></short-label>
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
                <td mat-cell *matCellDef="let sharer; let i = index;" (click)="viewContact(i)" class="pointer">
                  {{sharer.contactEmail ? sharer.contactEmail : sharer.contact.profile.primaryEmail}}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let sharer; let i = index;" (click)="viewContact(i)" class="pointer">
                  {{sharer.status | titlecase}}
                </td>
              </ng-container>

              <ng-container matColumnDef="contactType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let sharer; let i = index;" (click)="viewContact(i)" class="pointer">
                  {{sharer.contact.contactType | titlecase}}
                </td>
              </ng-container>

              <ng-container matColumnDef="numProspects">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header"># of Prospects</th>
                <td mat-cell *matCellDef="let sharer; let i = index;" class="text-align-center pointer" (click)="viewContact(i)">
                  {{sharer.numProspects}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row
                  *matRowDef="let sharer; let i = index; columns: displayedColumns;"
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="numDisplayedColumns">
                  <div *ngIf="!loading" class="no-table-data">
                    <span *ngIf="!addingSharers">No contacts added. Select an audience to add matching contacts or add contacts manually.</span>
                    <span *ngIf="addingSharers">Your contacts are being imported and matching contacts will then be added.  We'll email you when it's done.</span>
                  </div>
                  <div class="loading-overlay" *ngIf="loading">
                    <div class="loading-wrapper">
                      <mat-spinner color="accent"></mat-spinner>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <mat-paginator #paginator
                           [pageSizeOptions]="[25, 50, 100]"
                           showFirstLastButtons
                           *ngIf="sharerTable.data.length > 25"
            ></mat-paginator>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-drawer-container>
</div>
