import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ContactService} from "../../services/contact.service";
import {
  ShareRequest,
  ShareRequestRole,
  ShareRequestService,
  ShareRequestSummary,
  ShareRequestType
} from "../../services/share-request.service";
import {MatTableDataSource} from "@angular/material/table";
import {AccessRight, User, UserService} from "../../services/user.service";
import {FormatUtils} from "../../services/utilities/format.utilities";
import {Router} from "@angular/router";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, MatSortable} from "@angular/material/sort";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {InviteService} from "../../services/invite.service";
import {DialogService} from "../../services/utilities/dialog.service";

import {faEnvelope, faHandshake} from '@fortawesome/free-solid-svg-icons';
import {faLinkedin, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {Sharer, SharerService} from "../../services/sharer.service";


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  faEnvelope = faEnvelope
  faXTwitter = faXTwitter
  faLinkedin = faLinkedin
  faHandshake = faHandshake

  user: User = this.userService.new()
  canMutateShareRequests: boolean = false
  canViewShareRequests: boolean = false

  loading: boolean = true
  importing: boolean = false

  createdByColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['name', 'status']
    : ['name', 'status', 'numSharers', 'createdAt']
  createdByNumCols = this.createdByColumns.length
  createdByTableData: MatTableDataSource<ShareRequest> = new MatTableDataSource()
  @ViewChild('createdByPaginator') createdByPaginator!: MatPaginator
  @ViewChild('createdBySort') createdBySort!: MatSort

  requestedOfColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['name', 'status']
    : ['name', 'status', 'createdBy', 'createdAt']
  requestedOfNumCols: number = this.requestedOfColumns.length
  requestedOfTableData: MatTableDataSource<ShareRequest> = new MatTableDataSource()
  @ViewChild('requestedOfPaginator') requestedOfPaginator!: MatPaginator
  @ViewChild('requestedOfSort') requestedOfSort!: MatSort

  helpingMeColumns: string[] = ['avatar', 'contactName', 'numShareRequests', 'lastHelpedAt']
  helpingMeTableData: MatTableDataSource<ShareRequestSummary> = new MatTableDataSource()
  @ViewChild('helpingMePaginator') helpingMePaginator!: MatPaginator
  @ViewChild('helpingMeSort') helpingMeSort!: MatSort

  helpingThemColumns: string[] = ['avatar', 'contactName', 'numShareRequests', 'lastHelpedAt']
  helpingThemTableData: MatTableDataSource<ShareRequestSummary> = new MatTableDataSource()
  @ViewChild('helpingThemPaginator') helpingThemPaginator!: MatPaginator
  @ViewChild('helpingThemSort') helpingThemSort!: MatSort

  totalContacts: number = -1

  formatDate = FormatUtils.formatDate
  getUserDisplayName = this.userService.getDisplayName
  getContactDisplayName = this.contactService.getDisplayName

  ShareRequestType = ShareRequestType

  constructor(private userService: UserService,
              private contactService: ContactService,
              private shareRequestService: ShareRequestService,
              private sharerService: SharerService,
              private inviteService: InviteService,
              private dialogService: DialogService,
              private router: Router,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.userService.user$.subscribe(user => {
      this.user = user
      this.canMutateShareRequests = this.userService.hasAccessRight(this.user, AccessRight.mutateShareRequests)
      this.canViewShareRequests = this.userService.hasAccessRight(this.user, AccessRight.viewShareRequests)

      this.shareRequestService.list$.subscribe(
        shareRequests => {
          const createdShareRequests = shareRequests.filter(s => s.role === ShareRequestRole.creator)
          const requestedShareRequests = shareRequests.filter(s => s.role === ShareRequestRole.sharer)
          this.initDashboardTable('createdBy',
            createdShareRequests,
            {id: 'createdAt', start: 'desc'} as MatSortable
          )
          this.initDashboardTable('requestedOf',
            requestedShareRequests,
            {id: 'createdAt', start: 'desc'} as MatSortable
          )

          this.contactService.summary$.subscribe(summary => {
            this.loading = false
            this.importing = summary.activeImportCount > 0
            this.totalContacts = summary.contactCount
          })
          this.contactService.getSummary()

          this.shareRequestService.summary$.subscribe(
            summaries => {
              const helpingMeContacts = summaries.filter(s => s.role === ShareRequestRole.creator)
              const helpingThemContacts = summaries.filter(s => s.role === ShareRequestRole.sharer)
              this.initDashboardTable(
                'helpingMe',
                helpingMeContacts,
                {id: 'lastHelpedAt', start: 'desc'} as MatSortable
              )
              this.initDashboardTable(
                'helpingThem',
                helpingThemContacts,
                {id: 'lastHelpedAt', start: 'desc'} as MatSortable
              )
            }
          )
          this.shareRequestService.getSummary(true)
        }
      )
      this.shareRequestService.getAll(true)

    })
    this.userService.getIdentity()
  }

  ngOnDestroy() {
  }

  initDashboardTable(tableName: string, tableData: any[], defaultSort: MatSortable) {
    const tableKey = (tableName + 'TableData') as keyof DashboardComponent
    const paginatorKey = (tableName + 'Paginator') as keyof DashboardComponent
    const sortKey = (tableName + 'Sort') as keyof DashboardComponent

    (this[tableKey] as MatTableDataSource<any>) = new MatTableDataSource(tableData)

    setTimeout(() => {
      if (this[paginatorKey]) {
        ((this[tableKey] as MatTableDataSource<any>).paginator as MatPaginator) = this[paginatorKey] as MatPaginator
        ((this[tableKey] as MatTableDataSource<any>).paginator as MatPaginator).pageSize = 5
      }
      (this[sortKey] as MatSort).sort(defaultSort);
      (this[tableKey] as MatTableDataSource<any>).sort = this[sortKey] as MatSort
    })
  }

  addShareRequest(skipGetAll: boolean = false){
    if (this.canMutateShareRequests) {
      const newShareRequest = this.shareRequestService.new()
      this.shareRequestService.save(newShareRequest, skipGetAll).subscribe(
        savedSharedRequest => {
          this.userService.getIdentity(true)
          this.editShareRequest(savedSharedRequest)
        }
      )
    } else {
      this.dialogService.messageDialog(
        'Need More Access',
        "You do not have access to create network requests on this team.  Please ask your admin to change your role or change your current team selected."
      )
    }
  }

  goToContactList() {
    this.router.navigate(['contacts'])
  }

  editSharer(shareRequest: ShareRequest){
    if (shareRequest.sharers && shareRequest.sharers.length > 0) {
      this.router.navigate(['sharer', shareRequest.sharers[0].id])
    }
  }

  getSharerStatusLabel(shareRequest: ShareRequest, sharer: Sharer) {
    return this.sharerService.getSharerStatusLabel(shareRequest, sharer)
  }

  editShareRequest(shareRequest: ShareRequest){
    this.router.navigate(['share-request', shareRequest.id], { replaceUrl: true })
  }

  openInviteDialog() {
    this.inviteService.openInviteDialog(false, [])
  }

  refreshContactData() {
    this.contactService.getSummary()
  }

  onboardingCompleted(): boolean {
    return this.userService.onboardingCompleted(this.user)
  }

}
