import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatOptionSelectionChange} from "@angular/material/core";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {MatPaginator} from "@angular/material/paginator";
import {MatSelect} from "@angular/material/select";
import {FormControl} from "@angular/forms";

export interface SelectChipOption {
  label: string,
  value: string,
  count?: number,
}

@Component({
  selector: 'select-chip',
  templateUrl: './select-chip.component.html',
  styleUrls: ['./select-chip.component.scss']
})
export class SelectChipComponent implements OnChanges {

  @Input() title: string = ''
  @Input() values: string[] = []
  @Input() allOptions: SelectChipOption[] = []
  @Input() readOnly: boolean = false

  @Output() onChange = new EventEmitter()

  multiSelectControl = new FormControl()

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.multiSelectControl.setValue(this.values)
  }

  getSelectedOptions(): SelectChipOption[] {
    return this.allOptions.filter(
      (o: SelectChipOption) => this.values.indexOf(o.value) >= 0
    )
  }

  optionSelected(event: MatOptionSelectionChange): void {
    const filter = event.source.value
    if (event.source.selected) {
      if (this.values.indexOf(filter) < 0) {
        this.values.push(filter)
      }
    } else {
      const i = this.values.indexOf(filter)
      if (i >= 0) this.values.splice(i)
    }
    this.onChange.emit()
  }

}
