import {ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core'
import {Router} from "@angular/router";
import {ShareRequestService} from "../../../services/share-request.service";
import {AccessRight, OnboardingStep, User, UserService} from "../../../services/user.service";
import {faGoogle, faLinkedin, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faEdit, faShare, faAddressCard, faRetweet, faThumbsUp, faToggleOn, faHandshake, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {OauthAction, OauthType} from "../../../services/oauth.service";
import {ProgressBarTimerComponent} from "../../common/progress-bar-timer/progress-bar-timer.component";

@Component({
  selector: 'signup-intro',
  templateUrl: './signup-intro.component.html',
  styleUrls: ['./signup-intro.component.scss']
})
export class SignupIntroComponent implements OnInit {
  faGoogle = faGoogle
  faLinkedin = faLinkedin
  faXTwitter = faXTwitter
  faEnvelope = faEnvelope
  faHandshake = faHandshake
  faUserPlus = faUserPlus
  faEdit = faEdit
  faShare = faShare
  faAddressCard = faAddressCard
  faRetweet = faRetweet
  faThumbsUp = faThumbsUp
  faToggleOn = faToggleOn

  user: User = this.userService.new()
  canCreateShareRequest: boolean = true
  hasEmailAccount: boolean = false
  hasSocialAccount: boolean = false
  returnUrl: string = window.location.origin + '\\'
  logoUrl: string = ''

  step: number = -1
  stepTime: number = 4
  stepTimerPaused: boolean = false
  stepTimerStopped: boolean = false
  @ViewChildren(ProgressBarTimerComponent) stepTimers!: QueryList<ProgressBarTimerComponent>

  currentBreakpoint: string = ''
  Breakpoints = Breakpoints
  showOnlyCurrentStep: boolean = false

  OauthType = OauthType
  OauthAction = OauthAction

  loading = true

  constructor(private shareRequestService: ShareRequestService,
              private userService: UserService,
              private router: Router,
              private breakpointObserver: BreakpointObserver,
              private changeDetectorRef: ChangeDetectorRef
  ) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
        this.currentBreakpoint = Breakpoints.XLarge
      } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
        this.currentBreakpoint = Breakpoints.Large
      } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
        this.currentBreakpoint = Breakpoints.Medium
      } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
        this.currentBreakpoint = Breakpoints.Small
      } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
        this.currentBreakpoint = Breakpoints.XSmall
      }
      this.showOnlyCurrentStep = this.currentBreakpoint === Breakpoints.XSmall
    })
  }

  ngOnInit() {
    this.userService.user$.subscribe(
      user => {
        this.user = user
        this.logoUrl = user.profile?.company?.logoUrl || ''
        if (
          this.logoUrl === '' &&
          this.user.profile?.company &&
          this.user.profile.company.domains &&
          this.user.profile.company.domains.length > 0
        ) {
          this.logoUrl = 'https://logo.clearbit.com/' + this.user.profile.company.domains[0] + '?size=48'
        }
        this.canCreateShareRequest = this.userService.hasAccessRight(this.user, AccessRight.mutateShareRequests)
        this.hasEmailAccount = this.userService.onboardingStepCompleted(this.user, OnboardingStep.addedEmailAccount)
        this.hasSocialAccount = this.userService.onboardingStepCompleted(this.user, OnboardingStep.addedSocialAccount)
        this.loading = false
        this.stepCompleted()
      }
    )
    this.userService.getIdentity()
  }

  ngAfterViewInit() {
  }

  stepCompleted() {
    this.step += 1
    if (this.step > 2) {
      this.step = 0

      // Stop loop after first, if user restarts it let it keep going
      if (!this.stepTimerStopped) {
        this.stepTimerStopped = true
        this.stepTimers.first.pause()
      }
    }
    this.changeDetectorRef.detectChanges()
    if (!this.stepTimerStopped) {
      this.stepTimers.first.start()
    }
  }

  clickStepPanel(stepNumber: number) {
    if (stepNumber !== this.step) {
      this.step = stepNumber
      if (this.stepTimerStopped) {
        this.stepTimerStopped = false
      }
      this.changeDetectorRef.detectChanges()
      this.stepTimers.first.start()
    } else {
      if (this.stepTimerStopped) {
        this.stepTimerStopped = false
        this.changeDetectorRef.detectChanges()
        this.stepTimers.first.start()
      } else if (this.stepTimerPaused) {
        this.stepTimerPaused = false
        this.changeDetectorRef.detectChanges()
        this.stepTimers.first.resume()
      } else {
        this.stepTimerPaused = true
        this.changeDetectorRef.detectChanges()
        this.stepTimers.first.pause()
      }
    }
  }

  addShareRequest() {
    const newShareRequest = this.shareRequestService.new(true)
    this.shareRequestService.save(newShareRequest).subscribe(
      savedSharedRequest => {
        this.userService.getIdentity(true)
        this.router.navigate(['share-request', savedSharedRequest.id], { replaceUrl: true })
      }
    )
  }

  goToDashboard() {
    this.router.navigate([''])
  }

}
