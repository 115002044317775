<button mat-flat-button
        color="primary"
        [matMenuTriggerFor]="actionMenu"
>
  {{label}}
  <fa-icon [icon]="faCaretDown"></fa-icon>
</button>
<mat-menu #actionMenu="matMenu">
  <a mat-menu-item (click)="doOauth(OauthType.google)">
    <fa-icon [icon]="faGoogle" class="horiz-margin"></fa-icon>
    Add Google Account
  </a>
  <a mat-menu-item (click)="doOauth(OauthType.microsoft)">
    <fa-icon [icon]="faMicrosoft" class="horiz-margin"></fa-icon>
    Add Outlook Account
  </a>
  <a mat-menu-item (click)="openContactDialog()">
    <fa-icon [icon]="faPlus" class="horiz-margin"></fa-icon>
    Add Manually
  </a>
  <a mat-menu-item [href]="getCsvImportUrl()" target="_blank">
    <fa-icon [icon]="faFileImport" class="horiz-margin"></fa-icon>
    Import File
  </a>
</mat-menu>
