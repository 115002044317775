<div class="flex-col-top">
  <mat-spinner color="primary" *ngIf="loading"></mat-spinner>
</div>
<div *ngIf="!loading" class="reg-container">
  <h1 *ngIf="!company" class="text-align-center">Join Wrollo</h1>
  <div *ngIf="company" class="vert-margin">
    <div class="text-align-center" *ngIf="company">
      <img [src]="company.logoUrl || 'https://logo.clearbit.com/'+company.domains[0] + '?size=48'"
           onerror="this.src='../../../../assets/white_background.png'"
           class="company-logo"
      >
    </div>
    <h1 class="text-align-center">Welcome to Wrollo</h1>
  </div>
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.google"
                  [action]="OauthAction.continue"
                  label="Sign up with Google"
                  [redirectUri]="returnUrl"
    ></oauth-button>
    <!--
    <div (click)="doGoogleOauth()"
         class="google-oauth-btn"
    >
      <img src="assets/btn_google_signin_dark_normal_web.png"/>
    </div>
    -->
  </div>
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.microsoft"
                  [action]="OauthAction.continue"
                  label="Sign up with Outlook"
                  [redirectUri]="returnUrl"
    ></oauth-button>
  </div>
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.twitter"
                   [action]="OauthAction.continue"
                   label="Sign up with X"
                  [redirectUri]="returnUrl"
    ></oauth-button>
  </div>
  <div class="oauth-btn-container">
    <oauth-button [type]="OauthType.linkedin"
                   [action]="OauthAction.continue"
                   label="Sign up with LinkedIn"
                   [redirectUri]="returnUrl"
    ></oauth-button>
  </div>
  <div class="text-align-center vert-padding">OR</div>
  <div>
    <form [formGroup]="form">
      <div>
        <mat-form-field class="reg-field">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName" required>
          <mat-error *ngIf="form.get('firstName')?.hasError('required')">This field is required.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="reg-field">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName" required>
          <mat-error *ngIf="form.get('lastName')?.hasError('required')">This field is required.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="reg-field">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required>
          <mat-error *ngIf="form.get('email')?.hasError('required')">This field is required.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="reg-field">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" required>
          <mat-error *ngIf="form.get('password')?.hasError('required')">This field is required.</mat-error>
          <mat-error *ngIf="form.get('password')?.hasError('pattern')"
                     matTooltip="Password must contain at least 1 number, 1 uppercase and 1 lowercase letter, 1 special character, and at least 8 characters."
          >Password is not strong enough.</mat-error>
        </mat-form-field>
      </div>
      <div>{{errorMsg}}</div>
      <div class="vert-margin">
        <button mat-flat-button
                color="primary"
                (click)="signup()"
                [disabled]="!form.valid"
        >Sign up!</button>
      </div>
      <div class="vert-margin">
        Already have an account? <a class="reg-link" href="/login">Log in</a>
      </div>
    </form>
  </div>
</div>
