import {Component, Input} from '@angular/core'
import {OauthService, AddOauthToObject, OauthAction} from "../../../services/oauth.service";
import {faLinkedin, faXTwitter, faGoogle, faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'oauth-button',
  templateUrl: './oauth-button.component.html',
  styleUrls: ['./oauth-button.component.scss']
})
export class OauthButtonComponent {

  @Input({ required: true }) type: string = ''
  @Input({ required: true }) action: string = ''
  @Input({ required: true }) label: string = ''
  @Input() redirectUri: string = ''
  @Input() addOauthTo: AddOauthToObject | undefined

  faLinkedin = faLinkedin
  faXTwitter = faXTwitter
  faGoogle = faGoogle
  faMicrosoft = faMicrosoft

  oauthIcons = [
    { label: "linkedin", icon: faLinkedin },
    { label: "twitter", icon: faXTwitter },
    { label: "google", icon: faGoogle },
    { label: "microsoft", icon: faMicrosoft },
  ]

  constructor(private oauthService: OauthService,
  ) {
  }

  getIcon(): IconDefinition {
    const oauthIcon = this.oauthIcons.find(i => { return i.label === this.type})
    return oauthIcon ? oauthIcon.icon : faGoogle
  }

  doOauth() {
    const approvalPrompt: string = this.action !== OauthAction.add ? 'auto' : 'force'
    this.oauthService.doOauth(
      this.type,
      this.action,
      this.redirectUri === '' ? document.location.href : this.redirectUri,
      approvalPrompt,
      this.addOauthTo
    )
  }

}
