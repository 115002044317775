import {assign} from "lodash";

export class FormatUtils {
  constructor() {
  }

  static addQueryParam(url: string, param: string) {
    return url + (url.indexOf("?") < 0 ? "?" : "&") + param
  }

  static overwriteObj(sourceObj: any, newObj: any) {
    return assign(sourceObj, newObj)
  }

  static sortArrayByAttr(arr: any[], attr: string, asc: boolean = true): any[] {
    return arr.sort((a, b) => {
      if (typeof(a[attr]) === 'string') {
        if (a[attr].toLowerCase() < b[attr].toLowerCase()) {
          return asc ? -1 : 1
        } else if (a[attr].toLowerCase() > b[attr].toLowerCase()) {
          return asc ? 1 : -1
        } else {
          return 0
        }
      } else {
        return asc ? a[attr] - b[attr] : b[attr] - a[attr]
      }
    })
  }

  static dedupeArray(a: any[]): any[] {
    const seen: any = {}
    const out = []
    let j = 0
    for(let i = 0; i < a.length; i++) {
      var item = a[i]
      if(seen[item] !== 1) {
        seen[item] = 1
        out[j++] = item
      }
    }
    return out
  }

  static dedupeObjectArray(arr: any[], attr: string): any[] {
    if (!arr || !attr) return arr
    return arr.reduce((acc, current) => {
      const x = acc.find((item: any) => item[attr] === current[attr]);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [])
  }

  /*
  static removeDupesFromArray(a: string[]): string[] {
    const seen: any = {};
    const out = [];
    let j = 0;
    for(let i = 0; i < a.length; i++) {
      var item = a[i];
      if(seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
    return array
        .slice() // slice makes copy of array before sorting it
        .sort(function(a, b) {
            return a > b ? 1 : a < b ? -1 : 0;
        })
        .reduce(function(a, b) {
            if (a.slice(-1)[0] !== b) a.push(b); // slice(-1)[0] means last item in array without removing it (like .pop())
            return a;
        }, []); // this empty array becomes the starting value for a
 }
  */

  static mergeUniqueArrays(a: any[], b: any[]): any[] {
    return a.concat(b.filter((item) => a.indexOf(item) < 0))
  }

  static removeFromArray(array: any[], oldElem: any, attr: string,): any[] {
    if (!array || !oldElem) return array

    for (let i: number = 0; i < array.length; i++) {
      if (
        array.hasOwnProperty(i) &&
        (
          (
            !!attr &&
            array[i][attr] === oldElem[attr]
          ) ||
          (
            !attr &&
            array[i] === oldElem
          )
        )
      ) {
        array.splice(i, 1)
        break
      }
    }
    return array
  }

  static indexOfObjectInArray(a: any[], attr: string, value: any): number {
    let i = -1
    a.map(
      (o, index) => {
        if (o.hasOwnProperty(attr) && o[attr] === value) {
          i = index
        }
      }
    )
    return i
  }

  static flattenEdges(arr: any[]): any[] {
    return arr.map(a => a.node)
  }

  static formatDate(date: number): string {
    const finalDate = date < 9999999999 ? date * 1000 : date
    return new Date(finalDate).toLocaleString('en-US', {
      dateStyle: "short",
    })
  }

  static formatDateLong(date: number): string {
    const finalDate = date < 9999999999 ? date * 1000 : date
    return new Date(finalDate).toLocaleString('en-US', {
      dateStyle: "medium"
    })
  }

  static formatTime(date: number): string {
    const finalDate = date < 9999999999 ? date * 1000 : date
    return new Date(finalDate).toLocaleString('en-US', {
      timeStyle: "short"
    })
  }

  static formatDateTime(date: number): string {
    const finalDate = date < 9999999999 ? date * 1000 : date
    return new Date(finalDate).toLocaleString('en-US', {
      dateStyle: "short",
      timeStyle: "short"
    })
  }

  static formatPrice(price: number): string {
    return price.toLocaleString('en-US', {
      minimumSignificantDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  static snakeToCamelCase(str: string): string {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replaceAll('-', '')
        .replaceAll('_', '')
    )
  }

  static snakeToCamelCaseKeys(obj: any) {
    if (obj instanceof Array) {
      return obj.map(attr => {
        if (typeof attr === "object") {
          attr = FormatUtils.snakeToCamelCaseKeys(attr)
        }
        return attr
      })
    } else {
      const newObj: any = {}
      for (let origKey in obj) {
        if (obj.hasOwnProperty(origKey)) {
          const newKey: string = FormatUtils.snakeToCamelCase(origKey)
          let value = obj[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = FormatUtils.snakeToCamelCaseKeys(value)
          }
          newObj[newKey] = value
        }
      }
      return newObj
    }
  }

  static camelToSnakeCase(str: string): string {
    const result = str.replace( /([A-Z])/g, " $1" );
    return result.split(' ').join('_').toLowerCase();
  }

  static camelToSnakeCaseKeys(obj: any) {
    if (obj instanceof Array) {
      return obj.map(attr => {
        if (typeof attr === "object") {
          attr = FormatUtils.camelToSnakeCaseKeys(attr)
        }
        return attr
      })
    } else {
      const newObj: any = {}
      for (let origKey in obj) {
        if (obj.hasOwnProperty(origKey)) {
          const newKey: string = FormatUtils.camelToSnakeCase(origKey)
          let value = obj[origKey]
          if (value instanceof Array || (value !== null && !!value && value.constructor === Object)) {
            value = FormatUtils.camelToSnakeCaseKeys(value)
          }
          newObj[newKey] = value
        }
      }
      return newObj
    }
  }

  static toTitleCase(text: string): string {
    return text.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    )
  }

  static toLowerCase(text: string): string {
    return text.toLowerCase()
  }

  static toUpperCase(text: string): string {
    return text.toUpperCase()
  }

  static convertDashedText(text: string) {
    return FormatUtils.toTitleCase(text.replace(/-/g, ' '))
  }

  static testContains(value: string, filter: string): boolean {
    return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0
  }

  static testEquals(value: string, filter: string): boolean {
    return value.toLowerCase() === filter.toLowerCase()
  }

  static testFilter(value: string, filters: string[], testFunc: Function, pos: boolean = true): boolean {
    if (!filters || filters.length === 0) {
      return true
    } else {
      if (!value) {
        return false
      } else {
        return filters.filter((filter: string) => {
          const testPassed = testFunc(value, filter)
          return pos ? testPassed : !testPassed
        }).length > 0
      }
    }
  }

  static isValidEmail(email: string) {
    return email.match(/^[\x00-\x7F]+@[\x00-\x7F]+\.[\x00-\x7F]+$/)
  }

  static isValidDomain(domain: string) {
    return domain.match(/^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/)
  }

  static isString(v: any): boolean {
    return typeof v === 'string' || v instanceof String
  }

  static isNumber(v: any): boolean {
    return typeof v === 'number'
  }

  static isEmpty(v: any): boolean {
    return (
      typeof v === 'undefined' ||
      (Array.isArray(v) && v.length === 0) ||
      (FormatUtils.isString(v) && v === '') ||
      (FormatUtils.isNumber(v) && v === 0) ||
      (typeof v === "boolean" && !v) ||
      v == null
    )
  }

  static encodeBase64URLSafe = (s: string) => {
    const ENCODE: any = {
      '+': '-',
      '/': '_'
    }
    return btoa(s.replace(/[+/]/g, (m: string) => ENCODE[m]))
  }

  /**
   * decode url-safe-base64 string to base64
   * @param {String} safe - url-safe-base64 string
   * @return {String} base64 encoded
   */
  static decodeBase64URLSafe = (s: string) => {
    const DECODE: any = {
      '-': '+',
      _: '/',
      '.': '='
    }
    return atob(s.replace(/[-_.]/g, (m: string) => DECODE[m]))
  }

  static pluralize(label: string, count: number) {
    return label + (count === 1 ? '' : 's')
  }

  static getInitials(firstName: string, lastName: string, email: string): string {
    let initials = ""
    initials = firstName ? firstName.charAt(0) : ""
    if (initials === "") {
      initials = email.charAt(0)
    } else {
      initials += lastName ? lastName.charAt(0) : ""
    }
    return initials.toUpperCase()
  }

  static truncateString(s: string, numChars: number = 30) {
    return s.substring(0, numChars) + (s.length > numChars ? '...' : '')
  }

  static extractDomainFromUrl(url: string): string {
    const urlObj = new URL(url)
    return urlObj.hostname.replace("www.", "")
  }

}
