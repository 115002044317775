import {Component, OnInit} from '@angular/core'
import {
  AddOauthToObject,
  UserOauthAccount,
  OauthService,
  OauthType,
  OauthAction,
  TeamMemberOauthAccount
} from "../../../services/oauth.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {AuthService} from "../../../services/auth/auth.service";
import {FormControl} from "@angular/forms";
import {User, UserService} from "../../../services/user.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {InputChipOption} from "../../form/input-chip/input-chip.component";
import {AudienceService, ConvertedKeywordFilters} from "../../../services/audience.service";
import {combineLatest} from "rxjs";

@Component({
  selector: 'oauth-success',
  templateUrl: './oauth-success.component.html',
  styleUrls: ['./oauth-success.component.scss']
})
export class OauthSuccessComponent implements OnInit {

  user!: User
  userOauths: UserOauthAccount[] = []
  account: TeamMemberOauthAccount | undefined

  loading: boolean = true
  type: string = ''
  isAuthenticated: boolean = false

  newUserOauthId: number = 0

  askAutoCreate: boolean = false
  autoCreateFiltersConverted: InputChipOption[] = []

  askForEmail: boolean = false
  emailControl = new FormControl()

  OauthType = OauthType

  redirectUri: string = ''

  constructor(private oauthService: OauthService,
              private authService: AuthService,
              private userService: UserService,
              private audienceService: AudienceService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private route: ActivatedRoute,
              private router: Router,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        if (params && params['error']) {
          this.dialogService.messageDialog(
            "Error",
            "Sorry, there was error authenticating your account"
          ).subscribe(
            () => {
              this.router.navigate([''])
            }
          )
        } else if (params && params['state']) {
          const stateParamString = FormatUtils.decodeBase64URLSafe(params['state'])
          const stateParams = JSON.parse(stateParamString)
          if (params['code']) {
            const addToOauth: AddOauthToObject | undefined = (
              stateParams.add_to_type &&
              stateParams.add_to_field &&
              stateParams.add_to_id
            ) ? {
              type: stateParams.add_to_type,
              field: stateParams.add_to_field,
              id: stateParams.add_to_id,
            } : undefined

            this.type = stateParams.type
            this.redirectUri = decodeURIComponent(stateParams.redirect_uri)

            this.oauthService.finishOauth(
              params['code'],
              stateParams.type,
              stateParams.action,
              addToOauth
            ).subscribe(
              userOauthId => {
                this.newUserOauthId = userOauthId
                this.isAuthenticated = this.authService.isAuthenticated()
                this.checkCompleteOauth(stateParams.action)
              }, err => {
                this.goToNext()
              }
            )
          } else {
            this.goToNext()
          }
        } else {
          this.router.navigate([''])
        }
      }
    )
  }

  setAccount() {
    const userOauth = this.userOauths.find(a => a.id === this.newUserOauthId)
    if (userOauth) {
      this.account = userOauth.teamMemberOauths.find(a => a.teamMember?.teamId === this.user.currentTeamId)
    }
  }

  checkCompleteOauth(oauthAction: string) {
    if (
      this.newUserOauthId > 0 &&
      (this.type === OauthType.twitter || this.type === OauthType.linkedin)
    ) {
      if (oauthAction === OauthAction.add && this.redirectUri.indexOf('sharer') < 0) {
        this.askAutoCreate = true
        combineLatest(
          [this.userService.getIdentity(true), this.oauthService.getAllForUser(true)]
        ).subscribe(
          ([user, userOauths]) => {
            this.user = user
            this.userOauths = userOauths
            this.setAccount()
            this.autoCreateFiltersConverted = this.audienceService.convertSearchToKeywordFilters(this.account?.autoCreateFilters || [], [])
            this.loading = false
          }
        )
      } else if (oauthAction === OauthAction.continue) {
        this.askForEmail = true
        this.userService.getIdentity(true).subscribe(
          user => {
            this.user = user
            this.loading = false
          }
        )
      } else {
        this.goToNext()
      }
    } else {
      this.goToNext()
    }
  }

  goToNext() {
    const returnUrl = new URL(this.redirectUri)
    if (
      returnUrl.protocol === window.location.protocol &&
      returnUrl.port === window.location.port &&
      returnUrl.hostname === window.location.hostname
    ) {
      // Need to pass XSS Fortify scan
      window.location.href = returnUrl.href
    }
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['login'])
  }

  saveAutoCreate() {
    if (!this.account) {
      this.setAccount()
    }
    if (this.account) {
      const convertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.autoCreateFiltersConverted)
      this.account.autoCreateFilters = convertedFilters.posValues
      this.oauthService.saveTeamMemberOauth(this.account).subscribe(
        savedAccount => {
          this.goToNext()
        }
      )
    }
  }

  saveUser() {
    this.loading = true
    this.user.email = this.emailControl.value
    this.userService.save(this.user).subscribe(
      user => {
        this.goToNext()
      }, err => {
        this.loading = false
        this.notificationService.error(err.message)
      }
    )
  }

}
