import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {UserOauthAccount} from "../../../services/oauth.service";
import {ShareRequestType} from "../../../services/share-request.service";
import {faXTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {InputChipOption} from "../../form/input-chip/input-chip.component";
import {AudienceService, ConvertedKeywordFilters} from "../../../services/audience.service";

@Component({
  selector: 'auto-help-settings',
  templateUrl: './auto-help-settings.component.html',
  styleUrls: ['./auto-help-settings.component.scss']
})
export class AutoHelpSettingsComponent implements OnInit, OnChanges {

  @Input() type: string = ''
  @Input() profileName: string = ''
  @Input() accounts: UserOauthAccount[] = []
  @Input() autoCompleteShareRequests: boolean = false
  @Input() autoCompleteOauthId: number = 0
  @Input() autoCompleteFilters: string[] = []

  @Output() onSettingChange = new EventEmitter()
  @Output() onOauthChange = new EventEmitter()
  @Output() onFiltersChange = new EventEmitter()

  faXTwitter = faXTwitter
  faLinkedin = faLinkedin

  autoCompleteFiltersConverted: InputChipOption[] = []

  ShareRequestType = ShareRequestType

  constructor(private audienceService: AudienceService,
  ) {
  }

  ngOnInit() {
    this.autoCompleteFiltersConverted = this.audienceService.convertSearchToKeywordFilters(this.autoCompleteFilters || [], [])
    this.profileName = this.profileName === "" ? "their" : this.profileName + "'s"
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  autoCompleteOauthChange(userOauthId: number) {
    this.onOauthChange.emit(userOauthId)
  }

  autoCompleteSettingsChange() {
    this.onSettingChange.emit()
  }

  autoCompleteFiltersChange() {
    const convertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.autoCompleteFiltersConverted)
    this.autoCompleteFilters = convertedFilters.posValues
    this.onFiltersChange.emit(this.autoCompleteFilters)
  }

}
