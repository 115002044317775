<div class="profile-container">
  <div *ngIf="!contactDetail || loading">
    <mat-spinner color="accent"
                 [diameter]="48"
                 class="margin-center vert-margin-2x"
    ></mat-spinner>
  </div>
  <div *ngIf="contactDetail && !loading">
    <mat-tab-group>
      <mat-tab label="Contact">
        <div class="tab-container">
          <contact-profile-detail [contact]="contactDetail"
                                  [showActions]="showActions"
                                  (onDelete)="onDeletePassthrough()"
                                  (onChange)="onChange()"
          ></contact-profile-detail>
        </div>
      </mat-tab>

      <mat-tab label="Company" *ngIf="contactDetail.profile.company">
        <div class="tab-container">
          <company-profile-detail [company]="contactDetail.profile.company"></company-profile-detail>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
