import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {FormatUtils} from "../../../services/utilities/format.utilities";

export interface AutocompleteChipOption {
  label: string,
  value: string,
}

@Component({
  selector: 'autocomplete-chip',
  templateUrl: './autocomplete-chip.component.html',
  styleUrls: ['./autocomplete-chip.component.scss']
})
export class AutocompleteChipComponent {

  @Input() title: string = ''
  @Input() values: AutocompleteChipOption[] = []
  @Input() options: Observable<AutocompleteChipOption[]> = new Observable<AutocompleteChipOption[]>()
  @Input() readOnly: boolean = false

  @Output() onChange = new EventEmitter()
  @Output() onAutocomplete = new EventEmitter()

  newValueControl = new FormControl()
  selectedOption!: AutocompleteChipOption | null

  constructor() {
  }

  updateAutocompleteOptions(event: any) {
    this.onAutocomplete.emit(this.newValueControl.value)
  }

  selectOption(event: any): void {
    this.selectedOption = event.source.value
    if (this.selectedOption && this.values.indexOf(this.selectedOption) < 0) {
      this.values.push(this.selectedOption)
      this.clearInput()
      this.onChange.emit(this.values)
    }
  }

  removeValue(value: AutocompleteChipOption) {
    const i = this.values.findIndex(v => { return v.value === value.value })

    if (i >= 0) {
      this.values.splice(i, 1)
      this.onChange.emit(this.values)
    }
  }

  clearInput() {
    this.newValueControl.setValue(null)
    const inputElement = document?.getElementById('value-search')
    if (inputElement) (inputElement as HTMLInputElement).value = ''
  }

  checkClearInput() {
    setTimeout(() => {
      if (!this.selectedOption ||
        !this.selectedOption.value ||
        this.selectedOption.label !== this.newValueControl.value
      ) {
        this.clearInput()
        this.selectedOption = null
      }
    }, 500)
  }

}
