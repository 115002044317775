<div *ngIf="!company" class="text-align-center vert-margin vert-padding">
  No company found
</div>
<div *ngIf="company">
  <div>
    <div class="flex-row-left-top">
      <div class="flex-col-top">
        <img [src]="company.logoUrl || 'https://logo.clearbit.com/'+company.domains[0] + '?size=48'"
             onerror="this.src='../../../../assets/white_background.png'"
             class="avatar-icon"
        >
      </div>
      <div class="flex-col-left">
        <div class="section-title">{{company.name}}</div>
      </div>
    </div>
    <div class="normal-text vert-margin">
      {{company.description}}
    </div>
  </div>

  <div class="section-panel">
    <div class="section-title">Company Details</div>
    <div class="contact-info-row flex-row-left-top" *ngFor="let field of populatedCompanyFields">
      <div class="width-50 company-field-label">{{field.label}}: </div>
      <div class="width-50">{{getCompanyField(field)}}</div>
    </div>
  </div>

  <div class="section-panel" *ngIf="showStartupDetails">
    <div class="section-title">Startup Details</div>
    <div class="contact-info-row flex-row-left-top">
      <div class="width-50 company-field-label">Funding Stage: </div>
      <div class="width-50">{{company.fundingStage}}</div>
    </div>
    <div class="contact-info-row flex-row-left-top">
      <div class="width-50 company-field-label">Raised Amount: </div>
      <div class="width-50">${{company.fundsRaised | number}}</div>
    </div>
  </div>

  <div class="section-panel" *ngIf="showInvestorDetails">
    <div class="section-title">Investor Details</div>
    <div class="contact-info-row flex-row-left-top">
      <div class="width-50 company-field-label">Investor Type: </div>
      <div class="width-50">{{company.investorType}}</div>
    </div>
    <div class="contact-info-row flex-row-left-top">
      <div class="width-50 company-field-label">Investor Stage: </div>
      <div class="width-50">{{getCompanyFieldValue(company.investmentStage)}}</div>
    </div>
  </div>

  <div class="section-panel">
    <div class="section-title">My Contacts <span *ngIf="company.myContactsCount > 0">({{company.myContactsCount | number}})</span></div>
    <div *ngFor="let contact of company.myContacts"
         class="flex-row-left contact-info-row"
    >
      <div class="width-50">
        {{contact.profile.fullName}}
      </div>
      <div class="width-50">
        {{contact.profile.title?.title}}
      </div>
    </div>
    <div *ngIf="company.myContactsCount > company.myContacts.length" class="contact-info-row">
      ...plus {{(company.myContactsCount - company.myContacts.length) | number}} more.
    </div>
    <div *ngIf="!company.myContacts || company.myContacts.length === 0">
      <div class="normal-text">You don't know anyone at this company.</div>
    </div>
  </div>

  <div class="section-panel">
    <div class="section-title">Network Contacts  <span *ngIf="company.networkContactsCount > 0">({{company.networkContactsCount | number}})</span></div>
    <div *ngFor="let contact of company.networkContacts"
         class="flex-row-left contact-info-row"
    >
      <div class="width-50">
        {{contact.user.profile?.fullName}}
      </div>
      <div class="width-50">
        {{contact.numContacts + pluralize(' contact', contact.numContacts)}}
      </div>
    </div>
    <div *ngIf="!company.networkContacts || company.networkContacts.length === 0">
      <div class="normal-text">Your network doesn't know anyone at this company.</div>
    </div>
  </div>
</div>
