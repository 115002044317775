import {Component} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService, MagicLinkType} from "../../../services/auth/auth.service";

@Component({
  selector: 'forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {

  statusMsg: string = ''

  form = new FormGroup({
    email: new FormControl(),
  })

  constructor(private authService: AuthService,
  ) {
  }

  clearError() {
    this.statusMsg = ''
  }

  sendForgetPasswordLink(){
    this.authService.sendMagicLinkEmail(this.form.value.email, MagicLinkType.forgetPassword).subscribe(
      result => {
        if (result) {
          this.statusMsg = 'Success! Please check your email inbox.'
        }
      },
      err => {
        this.statusMsg = err.message
        setTimeout(this.clearError, 3000)
      }
    )
  }

}
