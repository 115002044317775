import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  filter,
  Subscription,
  takeWhile,
  timer,
  withLatestFrom,
} from 'rxjs';

@Component({
  selector: 'progress-bar-timer',
  exportAs: 'progressBarTimer',
  templateUrl: './progress-bar-timer.component.html',
  styleUrls: ['./progress-bar-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarTimerComponent implements AfterViewInit, OnDestroy {
  @Input()
  get time(): number {
    return this._time;
  }

  set time(value: number) {
    this._time = coerceNumberProperty(value);
  }

  @Output() completed = new EventEmitter<void>();

  protected value: number = 100;

  private _time: number = 0;

  @ViewChild('progressbarValue', { static: true })
  progressbarValueEl!: ElementRef<HTMLDivElement>;

  private paused$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private subscription: Subscription | undefined;

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this._renderer.addClass(
      this.progressbarValueEl.nativeElement,
      'progressbar-value-width-animate'
    );
  }

  ngOnDestroy(): void {
    this.cleanUpSubscription();
  }

  start(): void {
    this.cleanUpSubscription();
    this.paused$.next(false);
    this.value = 0;
    this._cdr.markForCheck();
    if (this.time > 0) {
      this.subscription = this.subscribeTimer();
    }
  }

  pause(): void {
    this.paused$.next(true);
  }

  resume(): void {
    this.paused$.next(false);
  }

  private subscribeTimer(): Subscription {
    const period = this.time * 10;
    this._elementRef.nativeElement.style.setProperty(
      '--time-duration',
      `${period / 1000}s`
    );

    return timer(0, period)
      .pipe(
        withLatestFrom(this.paused$),
        filter(([_, paused]) => !paused),
        takeWhile(() => this.value <= 100)
      )
      .subscribe(() => {
        this.value += 1;
        this._cdr.markForCheck();
        if (this.value >= 100) {
          this.completed.emit();
        }
      });
  }

  private cleanUpSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
