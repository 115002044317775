<div class="flex-row-left">
  <h2>Network Requests</h2>

  <button mat-flat-button
          color="primary"
          (click)="addShareRequest()"
          aria-label="Create network request"
          class="action-button"
  >Create<span fxHide.xs> Network Request</span></button>
</div>

<mat-tab-group class="tabs-container" [selectedIndex]="selectedTab.value">
  <mat-tab *ngIf="canViewShareRequests">
    <ng-template mat-tab-label>
      Created By My Team{{createdByTable.data.length > 0 ? ' (' + createdByTable.data.length + ')' : ''}}
    </ng-template>
    <div class="vert-padding">
      <table mat-table [dataSource]="createdByTable" matSort #createdBySort="matSort">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="thin-col" [hidden]="!canMutateShareRequests">
            <mat-checkbox (change)="$event ? selectAll() : null"
                          [checked]="createdBySelection.hasValue() && isAllSelected()"
                          [indeterminate]="createdBySelection.hasValue() && !isAllSelected()"
            ></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" [hidden]="!canMutateShareRequests">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? createdBySelection.toggle(row) : null"
                          [checked]="createdBySelection.isSelected(row)"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell
              *matHeaderCellDef
              matTooltip="Select some or all network requests to act on"
              class="thin-col"
              [hidden]="!canMutateShareRequests"
          >
            <button mat-icon-button
                    [matMenuTriggerFor]="shareRequestBulkActionsMenu"
                    [disabled]="createdBySelection.selected.length === 0"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #shareRequestBulkActionsMenu="matMenu">
              <button mat-menu-item (click)="deleteSelectedShareRequests()">
                Delete
              </button>
            </mat-menu>
          </th>
          <td mat-cell
              *matCellDef="let shareRequest; let i = index;"
              [hidden]="!canMutateShareRequests"
          >
            <button mat-icon-button
                    [matMenuTriggerFor]="shareRequestActionsMenu"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #shareRequestActionsMenu="matMenu">
              <button mat-menu-item (click)="editShareRequest(shareRequest)">
                Edit
              </button>
              <button mat-menu-item
                      (click)="activateShareRequest(shareRequest)"
                      *ngIf="shareRequest.status !== ShareRequestStatus.active"
              >
                Activate
              </button>
              <button mat-menu-item
                      (click)="deactivateShareRequest(shareRequest)"
                      *ngIf="shareRequest.status === ShareRequestStatus.active"
              >
                Deactivate
              </button>
              <button mat-menu-item (click)="confirmDeleteShareRequest(shareRequest)"
                      *ngIf="!hasSent(shareRequest)"
              >
                Delete
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            {{shareRequest.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            {{getTypeLabel(shareRequest.type)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            {{shareRequest.status | titlecase}}
          </td>
        </ng-container>

        <ng-container matColumnDef="sharerAudience">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contacts Audience</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            {{shareRequest.sharerAudience?.label}}
          </td>
        </ng-container>

        <ng-container matColumnDef="numSharers">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="num-col"># of Contacts</th>
          <td mat-cell
              *matCellDef="let shareRequest; let i = index;"
              (click)="editShareRequest(shareRequest)"
              class="text-align-center"
          >
            {{shareRequest.numSharers}}
          </td>
        </ng-container>

        <ng-container matColumnDef="metrics">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Metrics</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            <div *ngIf="shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro">
              {{(shareRequest.numProspects | number) + ' ' + pluralize('prospect', shareRequest.numProspects)}}
            </div>
            <div *ngIf="shareRequest.type === ShareRequestType.xPost && shareRequest.xPost">
              <x-post-metrics [post]="shareRequest.tweet"></x-post-metrics>
            </div>
            <div *ngIf="shareRequest.type === ShareRequestType.linkedinPost && shareRequest.linkedinPost">
              <linkedin-post-metrics [post]="shareRequest.linkedinPost"></linkedin-post-metrics>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="prospectAudience">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Prospect Audience</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            {{shareRequest.prospectAudience?.label}}
          </td>
        </ng-container>

        <ng-container matColumnDef="numProspects">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="num-col"># of Prospects</th>
          <td mat-cell
              *matCellDef="let shareRequest; let i = index;"
              (click)="editShareRequest(shareRequest)"
              class="text-align-center"
          >
            {{shareRequest.numProspects}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            {{formatDate(shareRequest.createdAt)}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="createdByColumns"></tr>
        <tr mat-row
            *matRowDef="let shareRequest; let i = index; columns: createdByColumns;"
            class="table-row pointer"
            [class.table-row-selected]="isSelected(shareRequest)"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="createdByNumCols">
            <div *ngIf="!loading" class="no-table-data">No network requests created yet</div>
            <div class="loading-overlay" *ngIf="loading" >
              <div class="loading-wrapper">
                <mat-spinner color="accent"></mat-spinner>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <mat-paginator #createdByPaginator
                     [pageSizeOptions]="[25, 50, 100]"
                     showFirstLastButtons
                     *ngIf="createdByTable.data.length > 25"
      ></mat-paginator>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      Requested Of Me{{requestedOfTable.data.length > 0 ? ' (' + requestedOfTable.data.length + ')' : ''}}
    </ng-template>

    <div class="vert-padding">
      <table mat-table [dataSource]="requestedOfTable" matSort #requestedOfSort="matSort">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
            {{shareRequest.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
            {{shareRequest.type | titlecase}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
            {{shareRequest.sharers[0].status | titlecase}}
          </td>
        </ng-container>

        <ng-container matColumnDef="metrics">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Metrics</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
            <div *ngIf="shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro">
              {{(shareRequest.sharers[0].numProspects | number) + ' ' + pluralize('prospect', shareRequest.sharers[0].numProspects)}}
            </div>
            <div *ngIf="shareRequest.type === ShareRequestType.xPost && shareRequest.xPost">
              <x-post-metrics [post]="shareRequest.tweet"></x-post-metrics>
            </div>
            <div *ngIf="shareRequest.type === ShareRequestType.linkedinPost && shareRequest.linkedinPost">
              <linkedin-post-metrics [post]="shareRequest.linkedinPost"></linkedin-post-metrics>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="prospectAudience">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Target Audience</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
            {{shareRequest.prospectAudience?.label}}
          </td>
        </ng-container>

        <ng-container matColumnDef="numProspects">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="num-col"># of Prospects</th>
          <td mat-cell
              *matCellDef="let shareRequest; let i = index;"
              (click)="editSharer(shareRequest)"
              class="text-align-center"
          >
            {{shareRequest.sharers[0].numProspects}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Requested By</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
            {{getDisplayName(shareRequest.createdByUser)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
            {{formatDate(shareRequest.createdAt)}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="requestedOfColumns"></tr>
        <tr mat-row
            *matRowDef="let shareRequest; let i = index; columns: requestedOfColumns;"
            class="table-row pointer"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="requestedOfNumCols">
            <div *ngIf="!loading" class="no-table-data">No network requests have been requested yet</div>
            <div class="loading-overlay" *ngIf="loading">
              <div class="loading-wrapper">
                <mat-spinner color="accent"></mat-spinner>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <mat-paginator #requestedOfPaginator
                     [pageSizeOptions]="[25, 50, 100]"
                     showFirstLastButtons
                     *ngIf="requestedOfTable.data.length > 25"
      ></mat-paginator>
    </div>
  </mat-tab>
</mat-tab-group>

