<h3 *ngIf="type === ShareRequestType.linkedinPost">
  <fa-icon [icon]="faLinkedin" class="social-icon"></fa-icon>Do you want to auto-like {{profileName}} LinkedIn posts?
</h3>
<h3 *ngIf="type === ShareRequestType.xPost">
  <fa-icon [icon]="faXTwitter" class="social-icon"></fa-icon>Do you want to auto-repost {{profileName}} X posts?
</h3>
<div class="vert-margin">
  <mat-slide-toggle
      color="primary"
      [ngModel]="autoCompleteShareRequests"
      (change)="autoCompleteSettingsChange()"
  >
    <span *ngIf="type === ShareRequestType.linkedinPost">Auto-like {{profileName}} LinkedIn posts</span>
    <span *ngIf="type === ShareRequestType.xPost">Auto-repost {{profileName}} X posts</span>
  </mat-slide-toggle>
</div>
<div class="flex-row-space-between" *ngIf="autoCompleteShareRequests">
  <div class="width-45">
    <select-linkedin-account label="My LinkedIn Account"
                             [accounts]="accounts"
                             [userOauthId]="autoCompleteOauthId"
                             (onChange)="autoCompleteOauthChange($event)"
                             *ngIf="type === ShareRequestType.linkedinPost"
    ></select-linkedin-account>
    <select-x-account label="My X Account"
                      [accounts]="accounts"
                      [userOauthId]="autoCompleteOauthId"
                      (onChange)="autoCompleteOauthChange($event)"
                      *ngIf="type === ShareRequestType.xPost"
    ></select-x-account>
  </div>
  <div class="width-45"
       matTooltip="Optionally, you can add some filters to only share where the content matches any of these keywords or hash tags."
  >
    <input-chip title="Filters"
                [values]="autoCompleteFiltersConverted"
                [allowNegativeFilters]="false"
                (onChange)="autoCompleteFiltersChange()"
    ></input-chip>
  </div>
</div>
