import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {UserOauthAccount, OauthService, TeamMemberOauthAccount, OauthType} from "../../../services/oauth.service";
import {MatTableDataSource} from "@angular/material/table";
import {InputChipOption} from "../../form/input-chip/input-chip.component";
import {AudienceService, ConvertedKeywordFilters} from "../../../services/audience.service";
import {UserService} from "../../../services/user.service";
import {TeamMember} from "../../../services/team.service";
import {combineLatest, Observable} from "rxjs";

export interface TeamMemberOauthSettings {
  includeAccount: boolean
  teamMember: TeamMember
  teamMemberOauth?: TeamMemberOauthAccount
  autoCreateShareRequest: boolean
  autoCreateFiltersConverted: InputChipOption[]
  editFilters: boolean
}

@Component({
  selector: 'team-member-oauths-modal',
  templateUrl: './team-member-oauths-modal.html',
  styleUrls: ['./team-member-oauths-modal.scss']
})
export class TeamMemberOauthsModal implements OnInit {

  userOauthAccount!: UserOauthAccount
  isSocialAccount: boolean = true

  loading: boolean = true
  errMessage: string = ''

  displayedColumns: string[] = ['team', 'select', 'autoCreate', 'filters']
  teamTable: MatTableDataSource<TeamMemberOauthSettings> = new MatTableDataSource()

  constructor(public dialogRef: MatDialogRef<TeamMemberOauthsModal>,
              @Inject(MAT_DIALOG_DATA) public data: UserOauthAccount,
              private userService: UserService,
              private oauthService: OauthService,
              private audienceService: AudienceService,
  ) {
  }

  ngOnInit() {
    this.userOauthAccount = this.data
    this.isSocialAccount = this.userOauthAccount.type === OauthType.twitter || this.userOauthAccount.type === OauthType.linkedin
    this.userService.user$.subscribe(
      user => {
        this.teamTable.data = user.teamMembers.map(
          t => {
            const teamMemberOauth = this.userOauthAccount.teamMemberOauths.find(
              a => t.id === a.teamMember?.id
            )
            return {
              includeAccount: !!teamMemberOauth,
              teamMember: t,
              teamMemberOauth: teamMemberOauth,
              autoCreateShareRequest: teamMemberOauth ? teamMemberOauth.autoCreateShareRequest : false,
              autoCreateFiltersConverted: teamMemberOauth
                ? this.audienceService.convertSearchToKeywordFilters(teamMemberOauth.autoCreateFilters || [], [])
                : [],
              editFilters: false,
            }
          }
        )
        this.loading = false
      }
    )
    this.userService.getIdentity()
  }

  save() {
    if (this.teamTable.data.filter(a => a.includeAccount).length === 0) {
      this.errMessage = 'Please included at least one team for this account.'
      setTimeout(() => { this.errMessage = '' }, 5000)
    } else {
      const saveObservables: Observable<TeamMemberOauthAccount>[] = []
      this.teamTable.data.forEach(a => {
        if (a.includeAccount) {
          const teamMemberOauth: TeamMemberOauthAccount = a.teamMemberOauth || {
            id: 0,
            userOauthId: this.userOauthAccount.id,
            teamMemberId: a.teamMember.id,
            autoCreateShareRequest: false,
            autoCreateFilters: [],
          }
          teamMemberOauth.autoCreateShareRequest = a.autoCreateShareRequest
          const convertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(a.autoCreateFiltersConverted)
          teamMemberOauth.autoCreateFilters = convertedFilters.posValues
          saveObservables.push(this.oauthService.saveTeamMemberOauth(teamMemberOauth))
        } else if (!a.includeAccount && a.teamMemberOauth) {
          saveObservables.push(this.oauthService.deleteTeamMemberOauth(a.teamMemberOauth))
        }
      })
      combineLatest(saveObservables).subscribe(
        results => {
          this.close(true)
        }
      )
    }
  }

  close(saved: boolean) {
    this.dialogRef.close(saved)
  }

}
