import {Component, ElementRef, Input, OnInit, SimpleChanges} from '@angular/core';
import Chart from 'chart.js/auto';
import {ContactSummaryValue} from "../../../services/contact.service";

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

  @Input() label: string = ''
  @Input() data: ContactSummaryValue[] = []

  public chart: any

  private colors: string[] = [
    '#311B92',
    '#4527A0',
    '#512DA8',
    '#5E35B1',
    '#673AB7',
    '#7E57C2',
    '#9575CD',
    '#B39DDB',
    '#D1C4E9',
    '#EDE7F6',
  ]
  /*
  private colors: string[] = [
    '#41921b',
    '#48a31e',
    '#50b321',
    '#57c424',
    '#5ed427',
    '#6ada35',
    '#76dd46',
    '#82e056',
    '#8fe367',
    '#9be678',
  ]
  */

  constructor(private elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes['data'].currentValue
    this.createChart()
  }

  createChart(){
    if (this.chart) {
      this.chart.destroy()
    }
    this.chart = new Chart(this.elementRef.nativeElement.querySelector(`#myChart`), {
      type: 'doughnut', //this denotes tha type of chart
      data: { // values on X-Axis
        labels: this.data ? this.data.map(o => { return o.value }) : [],
        datasets: [{
          label: this.label,
          data: this.data ? this.data.map(o => { return o.count }) : [],
          backgroundColor: this.colors,
          hoverOffset: 4
        }],
      },
      options: {
        aspectRatio: 4,
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: this.label
          }
        }
      },
    })
  }


}
