import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {NotificationService} from "../../../services/utilities/notification.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {User, UserService} from "../../../services/user.service";
import {ApiKey, ApiKeyService} from "../../../services/api-key.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'api-key-list',
  templateUrl: './api-key-list.component.html',
  styleUrls: ['./api-key-list.component.scss']
})
export class ApiKeyListComponent implements OnInit {

  loading: boolean = true

  apiKeys: ApiKey[] = []

  user: User = this.userService.new()

  displayedColumns: string[] = ['label', 'apiKey', 'createdAt', 'actions']
  numDisplayedColumns: number = this.displayedColumns.length
  apiKeyTable: MatTableDataSource<ApiKey> = new MatTableDataSource()

  formatDate = FormatUtils.formatDate


  constructor(private apiKeyService: ApiKeyService,
              private userService: UserService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.apiKeyService.list$.subscribe(apiKeys => {
      this.updateList(apiKeys)
    })
    this.apiKeyService.getAll()

    this.userService.user$.subscribe(
      user => {
        this.user = user
      }
    )
  }

  updateList(apiKeys: ApiKey[]): void {
    this.apiKeys = apiKeys
    this.apiKeyTable.data = this.apiKeys
    this.loading = false
  }

  createApiKey() {
    this.dialogService.inputDialog(
      'Create API Key',
      'Please enter a label for this API key.',
      'Label',
      '',
    ).subscribe(label => {
      if (label) {
        this.apiKeyService.add(label).subscribe(
          apiKeys => {
            this.notificationService.success('API key has been crated.')
          }
        )
      }
    })
  }

  editApiKey(apiKey: ApiKey) {
    this.dialogService.inputDialog(
      'Edit API Key',
      'Please enter a new label for this API key.',
      'Label',
      apiKey.label,
    ).subscribe(label => {
      if (label) {
        apiKey.label = label
        this.apiKeyService.edit(apiKey).subscribe(
          apiKeys => {
            this.notificationService.success('API key has been edited.')
          }
        )
      }
    })
  }

  confirmDeleteApiKey(apiKey: ApiKey) {
    this.dialogService.confirmDialog(
      'Delete API Key',
      'Are you sure you want to delete this API key?'
    ).subscribe(result => {
      if (result) {
        this.apiKeyService.delete(apiKey).subscribe(
          results => {
            this.notificationService.success('API key has been deleted.')
          }
        )
      }
    })
  }

}
