<div class="dialog-container">
  <div mat-dialog-content class="dialog-content-container" ngClass.xs="dialog-content-container-xs">
    <h2 mat-dialog-tile *ngIf="isModal()">Invite My Contacts</h2>

    <select-email-account label="From Email"
                          [accounts]="emailAccounts"
                          [userOauthId]="shareRequest.emailOauthId"
                          (onChange)="userOauthChange($event)"
    ></select-email-account>

    <div class="flex-row-left">
      <mat-form-field class="to-field"
                      [matTooltip]="numPendingSharers === 0 ? 'Add contacts to this network request on the My Contacts tab' : ''"
      >
        <mat-label>To (# of Contacts Pending)</mat-label>
        <input matInput
               type="text"
               data-lpignore="true"
               readonly
               value="{{(numPendingSharers | number) + ' ' + pluralize('contact', numPendingSharers)}}"
        >
      </mat-form-field>
      <mat-spinner *ngIf="shareRequest.inviteStatus === ShareRequestInviteStatus.waiting"
                   color="accent"
                   [diameter]="24"
                   class="btn-spinner-icon"
                   matTooltip="You have an ongoing contact import.  We'll email you when it's done."
      ></mat-spinner>
    </div>

    <message-editor [subject]="shareRequest.sharerMessageSubject"
                    [content]="shareRequest.sharerMessageContent"
                    (onChange)="messageChange($event)"
                    #inviteMessage
    ></message-editor>

    <div mat-dialog-actions class="flex-row-left" *ngIf="isModal()">
      <div matTooltip="You can send personalized invites once you've selected your email account, written the message, and contacts have been added."
           [matTooltipDisabled]="canSend()"
           fxHide.xs
      >
        <button mat-flat-button
                color="primary"
                (click)="send()"
                class="horiz-margin-1x"
                [disabled]="!canSend()"
        >Send</button>
      </div>
      <button mat-mini-fab
              color="primary"
              (click)="send()"
              class="horiz-margin-1x"
              [disabled]="!canSend()"
              fxHide.gt-xs
      ><mat-icon>email</mat-icon></button>
      <button mat-flat-button
              color="primary"
              (click)="save(true)"
              class="horiz-margin-1x"
              [disabled]="!canSend()"
              fxHide.xs
              *ngIf="isModal()"
      >Save</button>
      <button mat-mini-fab
              color="primary"
              (click)="save(true)"
              class="horiz-margin-1x"
              [disabled]="!canSend()"
              fxHide.gt-xs
              *ngIf="isModal()"
      ><mat-icon>save</mat-icon></button>
      <button mat-raised-button
              mat-dialog-close
              (click)="close(false)"
              class="horiz-margin-1x"
              fxHide.xs
              *ngIf="isModal()"
      >Cancel</button>
      <button mat-mini-fab
              mat-dialog-close
              (click)="close(false)"
              class="horiz-margin-1x"
              fxHide.gt-xs
              *ngIf="isModal()"
      ><mat-icon>cancel</mat-icon></button>
    </div>
  </div>
</div>
