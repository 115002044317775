<div ngClass.gt-xs="flex-row-space-between" ngClass.xs="flex-row-left flex-wrap" *ngIf="!loading">
  <div class="flex-row-left">
    <h2>{{team.name}}</h2>
    <button mat-mini-fab
            (click)="renameTeam()"
            class="horiz-margin-2x edit-btn white-btn"
            matTooltip="Rename this team"
            *ngIf="canMutateTeam"
    >
      <mat-icon>edit</mat-icon>
    </button>

  </div>

  <div class="flex-row-left">
    <h3 *ngIf="hasSubscription && canMutateTeam"
        ngClass.gt-xs="horiz-margin-2x"
        ngClass.xs="horiz-margin-right"
        matTooltip="Every admin or requester in your team needs to have a seat assigned to them."
    >
      {{numSeatsUsed}} of {{numSeatsTotal}} {{pluralize('Seat', numSeatsTotal)}} Used
    </h3>
    <button mat-flat-button
            color="primary"
            (click)="openInviteDialog()"
            class="action-button"
            *ngIf="canMutateTeam"
    >Invite<span fxHide.xs> Team Members</span></button>
  </div>
</div>

<mat-drawer-container class="width-100 vert-margin-2x drawer-container">
  <mat-drawer #drawer
              mode="side"
              position="end"
              opened
              fxHide.xs
              class="horiz-padding team-info-drawer"
  >
    <h2>About Teams and Roles</h2>
    <div>
      <ul>
        <li class="vert-margin"><b>Admins</b> can create network requests, manage team members, and manage billing.</li>
        <li class="vert-margin"><b>Requesters</b> can create network requests.</li>
        <li class="vert-margin"><b>Members</b> can help with network requests.</li>
        <li class="vert-margin">Admins and Requesters require a subscription seat to activate network requests.</li>
        <li class="vert-margin">Every member of the team can leverage the contacts of every other team member.</li>
      </ul>
    </div>
  </mat-drawer>

  <mat-tab-group class="tabs-container" [selectedIndex]="selectedTab.value">
    <mat-tab>
      <ng-template mat-tab-label>
        Active{{teamMemberTable.data.length > 0 ? ' (' + teamMemberTable.data.length + ')' : ''}}
      </ng-template>
      <div class="vert-padding">
        <table mat-table [dataSource]="teamMemberTable" matSort #teamMemberSort="matSort">
          <ng-container matColumnDef="select">
            <th mat-header-cell
                *matHeaderCellDef
                class="thin-col"
                [hidden]="!canMutateTeam || team.teamMembers.length === 1"
            >
              <mat-checkbox (change)="$event ? selectAllTeamMembers() : null"
                            [checked]="teamMemberSelection.hasValue() && areAllTeamMembersSelected()"
                            [indeterminate]="teamMemberSelection.hasValue() && !areAllTeamMembersSelected()"
              ></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" [hidden]="!canMutateTeam || team.teamMembers.length === 1">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? teamMemberSelection.toggle(row) : null"
                            [checked]="teamMemberSelection.isSelected(row)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell
                *matHeaderCellDef
                matTooltip="Select some or all team members to act on"
                class="thin-col"
                [hidden]="!canMutateTeam || team.teamMembers.length === 1"
            >
              <button mat-icon-button
                      [matMenuTriggerFor]="bulkActionsMenu"
                      [disabled]="teamMemberSelection.selected.length === 0"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #bulkActionsMenu="matMenu">
                <button mat-menu-item (click)="deleteSelectedTeamMembers()">
                  Delete
                </button>
              </mat-menu>
            </th>
            <td mat-cell
                *matCellDef="let teamMember; let i = index;"
                [hidden]="!canMutateTeam || team.teamMembers.length === 1"
            >
              <button mat-icon-button
                      [matMenuTriggerFor]="actionsMenu"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item
                        [matMenuTriggerFor]="changeRoleMenu"
                        *ngIf="canMutateTeam && canAdminChangeRole(teamMember)"
                >
                  Change Role
                </button>
                <button mat-menu-item (click)="confirmDeleteTeamMember(teamMember)">
                  Delete
                </button>
              </mat-menu>
              <mat-menu #changeRoleMenu="matMenu">
                <button mat-menu-item
                        *ngFor="let role of filterRoleList(teamMember)"
                        (click)="changeRoleTo(teamMember, role)"
                >
                  {{role | titlecase}}
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let teamMember; let i = index;">
              {{getDisplayName(teamMember.user)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let teamMember; let i = index;">
              {{teamMember.user.email}}
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
            <td mat-cell *matCellDef="let teamMember; let i = index;">
              {{teamMember.role | titlecase}}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
            <td mat-cell *matCellDef="let teamMember; let i = index;">
              {{formatDate(teamMember.createdAt)}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="teamMemberColumns"></tr>
          <tr mat-row
              *matRowDef="let teamMember; let i = index; columns: teamMemberColumns;"
              class="table-row"
              [class.table-row-selected]="isTeamMemberSelected(teamMember)"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colSpan]="numTeamMemberCols">
              <div *ngIf="!loading" class="no-table-data">No team members</div>
              <div class="loading-overlay" *ngIf="loading" >
                <div class="loading-wrapper">
                  <mat-spinner color="accent"></mat-spinner>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <mat-paginator #teamMemberPaginator
                       [pageSizeOptions]="[25, 50, 100]"
                       showFirstLastButtons
                       *ngIf="teamMemberTable.data.length > 25"
        ></mat-paginator>
      </div>
    </mat-tab>

    <mat-tab *ngIf="canMutateTeam">
      <ng-template mat-tab-label>
        Pending{{invites.length > 0 ? ' (' + invites.length + ')' : ''}}
      </ng-template>
      <div class="vert-padding">
        <invite-list [invites]="invites" [isTeamInvite]="true"></invite-list>
      </div>
    </mat-tab>

  </mat-tab-group>
</mat-drawer-container>

