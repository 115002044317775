<mat-card class="margin-center card-panel">
  <mat-card-content>
    <div *ngIf="loading">
      <mat-spinner color="accent" class="margin-center"></mat-spinner>
    </div>
    <div *ngIf="!loading">
      <div class="flex-row-left-top vert-margin" *ngIf="readOnly">
        <div class="width-20">Account:</div>
        <div class="width-75">{{shareRequest.linkedinOauth?.name}}</div>
      </div>

      <div *ngIf="!readOnly">
        <select-linkedin-account [label]="accountLabel"
                                 [accounts]="linkedinAccounts"
                                 [userOauthId]="shareRequest.linkedinOauthId"
                                 [addOauthTo]="addOauthTo"
                                 (onChange)="linkedinOauthChange($event)"
        ></select-linkedin-account>
      </div>

      <div class="flex-row-left-top vert-margin" *ngIf="readOnly">
        <div class="width-20">Organization:</div>
        <div class="width-75">{{shareRequest.linkedinPost?.linkedinOrganization?.name}}</div>
      </div>

      <div *ngIf="!readOnly && shareRequest.linkedinOauthId > 0">
        <mat-form-field class="setup-field"  class="post-width">
          <mat-label>Select LinkedIn Organization</mat-label>
          <mat-select [formControl]="linkedinOrgIdControl"
                      (selectionChange)="orgChange()"
                      [disabled]="readOnly"
          >
            <mat-option *ngFor="let org of linkedinOrganizations"
                        [value]="org.id"
            >
              {{org.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!readOnly && linkedinOrgIdControl.value > 0">
        <mat-form-field class="setup-field"  class="post-width">
          <mat-label>Select LinkedIn Post</mat-label>
          <mat-select [formControl]="linkedinPostIdControl" (selectionChange)="postChange()">
            <mat-option [value]="-1">New LinkedIn Post</mat-option>
            <mat-option *ngFor="let post of linkedinPosts"
                        [value]="post.id"
            >
              {{formatDate(post.createdAt || -1)}} {{truncateString(post.title !== '' ? post.title : post.postContent)}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="linkedinPostIdControl.value < 0">
        <mat-form-field class="post-width">
          <mat-label>Post</mat-label>
          <textarea matInput
                    role="textbox"
                    rows="4"
                    [formControl]="linkedinPostDescControl"
                    [readonly]="linkedinPostIdControl.value > 0 || readOnly"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="flex-row-left-top" *ngIf="shareRequest.linkedinPostId > 0 && linkedinPost.postContent !== ''">
        <div class="width-20">Content:</div>
        <div class="width-75">{{linkedinPost.postContent}}</div>
      </div>

      <div class="flex-row-left-top vert-margin" *ngIf="shareRequest.linkedinPostId > 0 && linkedinPost.title !== ''">
        <div class="width-20">Title:</div>
        <div class="width-75">{{linkedinPost.title}}</div>
      </div>

      <div class="flex-row-left-top vert-margin" *ngIf="shareRequest.linkedinPostId > 0 && linkedinPost.description !== ''">
        <div class="width-20">Description:</div>
        <div class="width-75">{{linkedinPost.description}}</div>
      </div>

      <div class="flex-row-left-top vert-margin" *ngIf="shareRequest.linkedinPostId > 0 && linkedinPost.source !== ''">
        <div class="width-20">Source:</div>
        <a href="{{linkedinPost.source}}"
           target="_blank"
           class="pointer width-75"
           matTooltip="{{linkedinPost.source}}"
        >{{truncateString(linkedinPost.source, 45)}}</a>
      </div>
      <div class="flex-row-left-top vert-margin" *ngIf="shareRequest.linkedinPostId > 0">
        <div class="width-20">Posted At:</div>
        <div class="width-75">{{formatDate(linkedinPost.createdAt || -1)}}</div>
      </div>
      <div *ngIf="linkedinPostIdControl.value < 0 && !readOnly">
        <button mat-flat-button
                color="primary"
                (click)="post()"
                [disabled]="linkedinPostDescControl.value === ''"
        >Post</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
