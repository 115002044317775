import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core'
import {ContactQuickSearchItem, ContactService} from "../../../services/contact.service";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";

@Component({
  selector: 'contact-quick-search',
  templateUrl: './contact-quick-search.component.html',
  styleUrls: ['./contact-quick-search.component.scss']
})
export class ContactQuickSearchComponent implements AfterViewInit {

  @Input() label: string = ''

  @Output() onSelect = new EventEmitter()

  newValueControl = new FormControl()
  contacts: Observable<ContactQuickSearchItem[]> = new Observable<ContactQuickSearchItem[]>()
  selectedContact!: ContactQuickSearchItem | null

  constructor(private contactService: ContactService,
  ) {
  }

  ngAfterViewInit() {
  }

  updateAutocompleteOptions(event: any) {
    this.contacts = this.contactService.quickSearch(this.newValueControl.value)
  }

  selectContact(event: any): void {
    this.selectedContact = event.source.value
    if (this.selectedContact) {
      this.clearInput()
      this.onSelect.emit(this.selectedContact)
    }
  }

  clearInput() {
    this.newValueControl.setValue(null)
    const inputElement = document?.getElementById('newValueInput')
    if (inputElement) (inputElement as HTMLInputElement).value = ''
  }

  checkClearInput() {
    setTimeout(() => {
      if (
        !this.selectedContact ||
        this.selectedContact.id !== this.newValueControl.value.id
      ) {
        this.clearInput()
        this.selectedContact = null
      }
    }, 500)
  }

  getDisplayName(contact: ContactQuickSearchItem): string {
    const displayName = this.contactService.getDisplayName(contact)
    return displayName +
      ((!!contact.email && contact.email !== '' && displayName !== contact.email) ? ' <' + contact.email + '>' : '')
  }

}
