<div class="flex-row-left">
  <div class="tweet-metric" matTooltip="Replies">
    <fa-icon [icon]="faComment" class="tweet-metric-icon"></fa-icon>{{(post.numReplies || 0) | number}}
  </div>
  <div class="tweet-metric" matTooltip="Reposts">
    <fa-icon [icon]="faRetweet" class="tweet-metric-icon"></fa-icon>{{(post.numRetweets || 0) | number}}
  </div>
  <div class="tweet-metric" matTooltip="Likes">
    <fa-icon [icon]="faHeart" class="tweet-metric-icon"></fa-icon>{{(post.numLikes || 0) | number}}
  </div>
</div>
