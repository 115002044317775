import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {AuthService, Registration} from "../../../services/auth/auth.service";
import {UserService} from "../../../services/user.service";
import {Company, CompanyService} from "../../../services/company.service";
import {OauthService, OauthType, OauthAction} from "../../../services/oauth.service";
import {combineLatest} from "rxjs";
import {RawXPost} from "../../../services/x-twitter.service";
import {RawLinkedInPost} from "../../../services/linkedin.service";

@Component({
  selector: 'company-landing',
  templateUrl: './company-landing.component.html',
  styleUrls: ['./company-landing.component.scss']
})
export class CompanyLandingComponent {

  loading: boolean = true

  OauthType = OauthType
  OauthAction = OauthAction

  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(this.userService.passwordValidationPattern)
    ]),
  })

  errorMsg: string = ''
  returnUrl: string = window.location.origin + '/intro'

  company: Company | undefined = undefined

  xPosts: RawXPost[] = []
  followXAccount: boolean = true
  likeAllXPosts: boolean = false
  xPostSelection: boolean[] = []

  liPosts: RawLinkedInPost[] = []
  likeAllLIPosts: boolean = false
  liPostSelection: boolean[] = []

  constructor(private route: ActivatedRoute,
              private authService: AuthService,
              private userService: UserService,
              private oauthService: OauthService,
              private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params && params['returnUrl']) {
        this.returnUrl = window.location.origin + params['returnUrl']
      }
      if (this.authService.isAuthenticated()) {
        this.goToNext()
      }
      if (params && params['domain']) {
        combineLatest([
          this.companyService.getCompanyByDomain(params['domain']),
          this.companyService.getCompanyTweets(params['domain']),
          this.companyService.getCompanyLinkedInPosts(params['domain'])
        ]).subscribe(
          ([company, xPosts, liPosts]) => {
            if (company) {
              this.company = company
            }
            if (xPosts && xPosts.length > 0) {
              this.xPosts = xPosts
              this.xPosts.map(p => this.xPostSelection.push(false))
            }
            if (liPosts && liPosts.length > 0) {
              this.liPosts = liPosts
              this.liPosts.map(p => this.liPostSelection.push(false))
            }
            this.loading = false
          },
          err => {
            this.loading = false
          }
        )
      } else {
        this.loading = false
      }
    })
  }

  selectAllXPosts() {
    this.likeAllXPosts = !this.likeAllXPosts
    this.xPostSelection = this.xPostSelection.map(p => this.likeAllXPosts)
  }

  getNumXPostsSelected() {
    return this.xPostSelection.filter(p => p).length
  }

  selectAllLIPosts() {
    this.likeAllLIPosts = !this.likeAllLIPosts
    this.liPostSelection = this.liPostSelection.map(p => this.likeAllLIPosts)
  }

  getNumLIPostsSelected() {
    return this.liPostSelection.filter(p => p).length
  }

  engageWithXAccount() {

  }

  engageWithLinkedInAccount() {

  }

  clearError() {
    this.errorMsg = ''
  }

  goToNext(): void {
    window.location.href = this.returnUrl
  }

  signup(){
    const rego = this.form.value as Registration
    this.authService.register(rego).subscribe(
      jwe => {
        this.goToNext()
      }, err => {
        this.errorMsg = err.message
        setTimeout(this.clearError, 3000)
      }
    )
  }

  doGoogleOauth() {
    this.oauthService.doOauth(
      OauthType.google,
      OauthAction.continue,
      this.returnUrl,
    )
  }
  
}
