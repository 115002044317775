<mat-form-field *ngIf="readOnly" class="width-100">
  <mat-label>{{title}}</mat-label>
  <mat-chip-grid #readOnlyChips>
    <mat-chip-row *ngFor="let value of values" class="chip-row">
      {{(value.not ? 'NOT ' : '') + value.value}}
    </mat-chip-row>
    <input [matChipInputFor]="readOnlyChips"
           [readonly]="true"
           class="read-only-input"
    />
  </mat-chip-grid>
</mat-form-field>
<mat-form-field *ngIf="!readOnly" class="width-100">
  <mat-label>{{title}}</mat-label>
  <mat-chip-grid #valueChips>
    <mat-chip-row *ngFor="let value of values" (removed)="removeValue(value)" class="chip-row">
      {{(value.not ? 'NOT ' : '') + value.value}}
      <button (click)="notValue(value)" matChipTrailingIcon class="not-icon-btn" *ngIf="allowNegativeFilters">
        <mat-icon class="not-icon">block_rounded</mat-icon>
      </button>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input matInput
           id="input-search"
           type="text"
           data-lpignore="true"
           [matChipInputFor]="valueChips"
           [formControl]="newValueControl"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="true"
           (matChipInputTokenEnd)="addValue($event)"
    />
  </mat-chip-grid>
</mat-form-field>
