<mat-card class="chart-card">
  <div class="flex-row-left flex-wrap">
    <div class="width-40 chart-panel">
      <h3>Company Size</h3>
      <doughnut-chart [data]="companySizeData"
      ></doughnut-chart>
    </div>
    <div class="width-40 chart-panel">
      <h3>Company Revenue</h3>
      <doughnut-chart [data]="companyRevenueData"
      ></doughnut-chart>
    </div>
  </div>
  <div class="flex-row-left flex-wrap">
    <div class="width-40 chart-panel">
      <h3>Company Industry</h3>
      <doughnut-chart [data]="companyIndustryData"
      ></doughnut-chart>
    </div>
    <div class="width-40 chart-panel">
      <h3>Company Type</h3>
      <doughnut-chart [data]="companyTypeData"
      ></doughnut-chart>
    </div>
  </div>
</mat-card>
<mat-card class="chart-card">
  <div class="flex-row-left flex-wrap">
    <div class="width-40 chart-panel">
      <h3>Seniority</h3>
      <doughnut-chart [data]="seniorityData"
      ></doughnut-chart>
    </div>
    <div class="width-40 chart-panel">
      <h3>Business Function</h3>
      <doughnut-chart [data]="bizFunctionData"
      ></doughnut-chart>
    </div>
  </div>
</mat-card>
