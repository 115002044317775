import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class NotificationService {

  duration: number = 3000

  constructor(
    public snackBar: MatSnackBar,
  ) {}

  private show(message: string, panelClass: string) {
    return this.snackBar.open(
      message,
      'Ok',
      {
        panelClass: [panelClass],
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      }
    )
  }

  error(message: string) {
    return this.show(message, 'snackbar-error')
  }

  success(message: string) {
    return this.show(message, 'snackbar-success')
  }

  info(message: string) {
    return this.show(message, 'snackbar-info')
  }
}
