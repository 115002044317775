<mat-drawer-container class="width-100">
  <mat-drawer #networkContactDrawer mode="over" position="end" [opened]="viewProfileIndex >= 0" fixedInViewport="true">
    <contact-profile-nav [viewIndex]="viewProfileIndex"
                         [minItems]="minItems"
                         (onNav)="viewContact($event)"
    ></contact-profile-nav>
    <contact-profile [contactId]="networkResultsTable.filteredData[viewProfileIndex] ? networkResultsTable.filteredData[viewProfileIndex].contactId : -1"
                     (onDelete)="contactDeletedFromDetail()"
    ></contact-profile>
  </mat-drawer>

  <div class="card-container">
    <mat-card>
      <mat-card-content>
        <div *ngIf="showTable">
          <div class="flex-row-left">
            <h3>
              My Network
              <span *ngIf="!loading && contactSum > 0" class="contact-count"> ({{(networkResults.length | number) + pluralize(' user', networkResults.length) + ' can reach ' + (contactSum | number) + pluralize(' contact', contactSum)}})</span>
            </h3>
            <button mat-raised-button
                    color="primary"
                    (click)="openInviteDialog()"
                    class="horiz-margin-1x"
            >Invite</button>
          </div>

          <table mat-table [dataSource]="networkResultsTable" matSort #sort="matSort" class="width-100">

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef class="thin-col">
                <mat-checkbox (change)="$event ? selectAll() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                ></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let contact" class="thin-col">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(contact) : null"
                              [checked]="selection.isSelected(contact)"
                ></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell
                  *matHeaderCellDef
                  class="thin-col"
              >
                <!--
                <button mat-icon-button
                        [matMenuTriggerFor]="contactBulkActionsMenu"
                        [disabled]="selection.selected.length === 0"
                        matTooltip="Select some or all contacts to act on"
                >
                  <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #contactBulkActionsMenu="matMenu">
                  <button mat-menu-item (click)="editAutoHelpPreferences()">
                    Edit My Auto Help Preferences
                  </button>
                </mat-menu>
                -->
              </th>
              <td mat-cell *matCellDef="let contact; let i = index;" class="thin-col">
                <button mat-icon-button
                        (click)="editAutoHelpPreferences(contact)"
                        matTooltip="Edit the auto-sharing preferences for this contact."
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                {{contact.user.profile?.fullName.trim() !== '' ? contact.user.profile?.fullName : contact.user.email}}
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                <short-label [label]="contact.user.profile?.title?.title"></short-label>
              </td>
            </ng-container>

            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                <short-label [label]="contact.user.profile?.company?.name"></short-label>
              </td>
            </ng-container>

            <ng-container matColumnDef="primaryEmail">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                {{contact.user.profile?.primaryEmail}}
              </td>
            </ng-container>

            <ng-container matColumnDef="numContacts">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header"># of Contacts</th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer text-align-center">
                {{!noFilters ? contact.numContacts.toLocaleString('en') + ' / ' : ''}}{{(contact.user.numContacts | number)}}
              </td>
            </ng-container>

            <ng-container matColumnDef="hasIntegrated">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header"
                  matTooltip="What type of network requests this user can help with."
              >
                They Can Help With
                <fa-icon [icon]="faCircleInfo" class="info-icon"></fa-icon>
              </th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer text-align-center">
                <fa-icon *ngIf="hasEmailAccount(contact.user.userOauths)"
                         [icon]="faEnvelope"
                         matTooltip="{{getDisplayName(contact.user, 'This contact')}} has added an email account."
                         class="horiz-margin"
                ></fa-icon>
                <fa-icon *ngIf="hasTwitterAccount(contact.user.userOauths)"
                         [icon]="faXTwitter"
                         matTooltip="{{getDisplayName(contact.user, 'This contact')}} has added an X account."
                         class="horiz-margin"
                ></fa-icon>
                <fa-icon *ngIf="hasLinkedInAccount(contact.user.userOauths)"
                         [icon]="faLinkedin"
                         matTooltip="{{getDisplayName(contact.user, 'This contact')}} has added a LinkedIn account."
                         class="horiz-margin"
                ></fa-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="autoHelpingThem">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header"
                  matTooltip="What type of network requests this user is automatically helping you with."
              >
                They're Auto-Sharing
                <fa-icon [icon]="faCircleInfo" fxHide.lt-lg class="info-icon"></fa-icon>
              </th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer text-align-center">
                <fa-icon *ngIf="contact.mirrorContact.autoCompleteTweetRequests"
                         [icon]="faXTwitter"
                         matTooltip="{{getDisplayName(contact.user, 'This contact')}} is automatically reposting your X posts."
                         class="horiz-margin"
                ></fa-icon>
                <fa-icon *ngIf="contact.mirrorContact.autoCompleteLinkedinPostRequests"
                         [icon]="faLinkedin"
                         matTooltip="{{getDisplayName(contact.user, 'This contact')}} is automatically liking your LinkedIn posts."
                         class="horiz-margin"
                ></fa-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="autoHelpingMe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header"
                  matTooltip="What type of network requests are you automatically helping this user with."
              >
                I'm Auto-Sharing
                <fa-icon [icon]="faCircleInfo" fxHide.lt-lg class="info-icon"></fa-icon>
              </th>
              <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer text-align-center">
                <fa-icon *ngIf="contact.autoCompleteTweetRequests"
                         [icon]="faXTwitter"
                         matTooltip="You are automatically reposting {{getDisplayName(contact.user, 'this contact')}}'s X posts."
                         class="horiz-margin"
                ></fa-icon>
                <fa-icon *ngIf="contact.autoCompleteLinkedinPostRequests"
                         [icon]="faLinkedin"
                         matTooltip="You are automatically liking {{getDisplayName(contact.user, 'this contact')}}'s LinkedIn posts."
                         class="horiz-margin"
                ></fa-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                *matRowDef="let user; let i = index; columns: displayedColumns;"
            ></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [colSpan]="numDisplayColumns">
                <div *ngIf="!loading" class="no-table-data">No contacts</div>
                <div class="loading-overlay" *ngIf="loading">
                  <div class="loading-wrapper">
                    <mat-spinner color="accent"></mat-spinner>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <mat-paginator #paginator
                         [length]="networkResults.length"
                         showFirstLastButtons
                         *ngIf="networkResults.length > 10"
          ></mat-paginator>
        </div>
        <div *ngIf="!showTable" class="flex-row-left flex-wrap">
          <h3 ngClass.gt-xs="width-20" ngClass.xs="width-100">My Network</h3>
          <div ngClass.gt-xs="width-80" ngClass.xs="width-100">
            <div *ngIf="loading">
              <mat-spinner *ngIf="loading" class="margin-center" color="accent" [diameter]="48"></mat-spinner>
            </div>
            <div *ngIf="!loading">
              <div *ngIf="networkResults.length === 0" class="flex-row-left">
                <div class="width-70">
                  <div *ngIf="noFilters">None of your contacts have joined Wrollo yet.<br><span fxHide.xs>Invite some colleagues to expand your reach!</span></div>
                  <div *ngIf="!noFilters">None of your contacts have matching contacts.<br><span fxHide.xs>Invite more colleagues to expand your network!</span></div>
                </div>
                <!--
                <div class="width-20 flex-col-top" ngClass.xs="horiz-margin-1x">
                  <button mat-raised-button
                          (click)="openInviteDialog()"
                  >Invite more!</button>
                </div>
                -->
              </div>
              <div *ngIf="networkResults.length > 0" ngClass.xs="flex-col-top" ngClass.gt-xs="flex-row-left">
                <div class="flex-col-top" ngClass.gt-xs="width-40" ngClass.xs="vert-padding">
                  <div class="summary-metric">{{networkResults.length}}</div>
                  <div class="text-align-center">of your contacts have joined</div>
                </div>

                <div class="flex-col-top" ngClass.gt-xs="width-40" ngClass.xs="vert-padding">
                  <div class="summary-metric">{{contactSum | number}}</div>
                  <div class="text-align-center">{{pluralize('contact', contactSum)}} in your extended network</div>
                </div>
                <!--
                <div class="flex-col-top" ngClass.gt-xs="width-20" ngClass.xs="vert-padding">
                  <button mat-raised-button
                          (click)="openInviteDialog()"
                  >Invite more!</button>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-drawer-container>
