import {
  AfterViewInit,
  Component, EventEmitter,
  Inject, Input, OnChanges,
  OnInit, Optional, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {OauthService, UserOauthAccount} from "../../../services/oauth.service";
import {MessageTemplate, MessageTemplateService} from "../../../services/message-template.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {ShareRequest, ShareRequestService, ShareRequestInviteStatus} from "../../../services/share-request.service";
import {SharerService, SharerStatus} from "../../../services/sharer.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MessageEditorComponent} from "../../messages/message-editor/message-editor.component";

@Component({
  selector: 'sharer-invite-campaign',
  templateUrl: './sharer-invite-campaign.component.html',
  styleUrls: ['./sharer-invite-campaign.component.scss']
})
export class SharerInviteCampaignComponent implements AfterViewInit, OnChanges {

  @Input() shareRequest: ShareRequest = this.shareRequestService.new()

  @Output() onSend = new EventEmitter()

  @ViewChild('inviteMessage') inviteMessage!: MessageEditorComponent

  numPendingSharers: number = 0

  ShareRequestInviteStatus = ShareRequestInviteStatus

  emailAccounts: UserOauthAccount[] = []
  messageTemplates: MessageTemplate[] = []

  loading: boolean = false
  errMessage: string = ''

  pluralize = FormatUtils.pluralize

  constructor(private oauthService: OauthService,
              private messageTemplateService: MessageTemplateService,
              private shareRequestService: ShareRequestService,
              private sharerService: SharerService,
              private notificationService: NotificationService,
              @Optional() public dialogRef: MatDialogRef<SharerInviteCampaignComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: ShareRequest,
  ) {
  }

  ngAfterViewInit() {
    if (this.data) {
      this.shareRequest = this.data
    }

    this.shareRequestService.detail$.subscribe(
      shareRequest => {
        this.setShareRequest(shareRequest)
      }
    )

    this.oauthService.userList$.subscribe(
      accounts => {
        this.emailAccounts = this.oauthService.filterEmailAccounts(accounts)
        this.checkDefaultEmailAccount()
      }
    )
    this.oauthService.getAllForUser()

    this.messageTemplateService.list$.subscribe(
      messageTemplates => this.messageTemplates = messageTemplates
    )
    this.messageTemplateService.getAll()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shareRequest']) {
      this.setShareRequest(changes['shareRequest'].currentValue)
    }
  }

  setShareRequest(shareRequest: ShareRequest) {
    this.shareRequest = shareRequest
    this.numPendingSharers = this.shareRequest.sharers ? this.shareRequest.sharers.filter(
      s => { return s.status === SharerStatus.pending }
    ).length : 0
    this.checkDefaultEmailAccount()
  }

  checkDefaultEmailAccount() {
    if (
      this.shareRequest.id > 0 &&
      (!this.shareRequest.emailOauthId || this.shareRequest.emailOauthId <= 0) &&
      this.emailAccounts.length === 1
    ) {
      this.shareRequest.emailOauthId = this.emailAccounts[0].id
    }
  }

  redrawEditor() {
    this.inviteMessage.redrawEditor()
  }

  userOauthChange(userOauthId: number) {
    this.shareRequest.emailOauthId = userOauthId
    if (!this.isModal()) {
      this.save()
      this.notificationService.success('Network request saved.')
    }
  }

  messageChange(event: any) {
    this.shareRequest.sharerMessageSubject = event.subject
    this.shareRequest.sharerMessageContent = event.content
    if (!this.isModal()) {
      this.save()
      this.notificationService.success('Network request saved.')
    }
  }

  canSend() {
    return this.shareRequestService.canSend(this.shareRequest) && this.numPendingSharers > 0
  }

  isModal() {
    return this.data !== null && typeof this.data != 'undefined'
  }

  close(send: boolean) {
    this.dialogRef.close(send)
  }

  save(closeIfModal: boolean = false) {
    this.shareRequestService.save(this.shareRequest).subscribe(
      shareRequest => {
        if (closeIfModal && this.isModal()) {
          this.close(false)
        }
      }
    )
  }

  send() {
    this.shareRequestService.save(this.shareRequest).subscribe(
      shareRequest => {
        if (this.isModal()) {
          this.close(true)
        } else if (this.onSend) {
          this.onSend.emit()
        }
      }
    )
  }

}
