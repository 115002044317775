import {Component, Input, OnInit} from '@angular/core';
import {OpenGraphPreview} from "../../../services/opengraph.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'opengraph-display',
  templateUrl: './opengraph-display.component.html',
  styleUrls: ['./opengraph-display.component.scss']
})
export class OpenGraphDisplayComponent implements OnInit {

  @Input({ required: true} ) og: OpenGraphPreview | undefined

  hideAll: boolean = false
  imageOnly: boolean = false
  domain: string = ''

  constructor(
  ) {
  }

  ngOnInit() {
    if (this.og) {
      this.domain = FormatUtils.extractDomainFromUrl(this.og.url)
      this.hideAll = this.domain === 'lnkd.in'
      this.imageOnly = !this.domain || this.domain === 'x.com' || !this.og.title
    }
  }
}
