<div>
  <mat-drawer-container class="width-100 list-container">
    <mat-drawer #contactDrawer mode="over" position="end" [opened]="viewProfileIndex >= 0" fixedInViewport="true">
      <contact-profile-nav [viewIndex]="viewProfileIndex"
                           [minItems]="minItems"
                           (onNav)="viewContact($event)"
      ></contact-profile-nav>
      <contact-profile [contactId]="prospectTable.filteredData[viewProfileIndex] ? prospectTable.filteredData[viewProfileIndex].contact.id : -1"
                       [showActions]="false"
      ></contact-profile>
    </mat-drawer>
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <div *ngIf="addingProspects">
            <div class="text-align-center">
              Your contacts are being added and matched.
              <br>
              <span *ngIf="sharer.sendAfterImport">We'll send this email to your matched contacts after the import is complete.</span>
              <span *ngIf="!sharer.sendAfterImport">We'll email you when they're done, so you can review and then send this message.</span>
            </div>
            <div *ngIf="sharer.lastWizardStep >= 0" class="text-align-center vert-margin">
              <a href="/" class="pointer">Go to my dashboard</a>
            </div>
          </div>
          <div *ngIf="!addingProspects">
            <table mat-table [dataSource]="prospectTable" matSort #prospectListSort="matSort">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="thin-col">
                  <mat-checkbox (change)="$event ? selectAll() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                  ></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" class="thin-col">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                  ></mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell
                    *matHeaderCellDef
                    matTooltip="Select some or all prospects to act on"
                    class="thin-col"
                >
                  <button mat-icon-button
                          [matMenuTriggerFor]="bulkActionsMenu"
                          [disabled]="selection.selected.length === 0"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #bulkActionsMenu="matMenu">
                    <button mat-menu-item (click)="deleteSelectedProspects()">
                      Delete
                    </button>
                  </mat-menu>
                </th>
                <td mat-cell *matCellDef="let prospect; let i = index;" class="thin-col">
                  <button mat-icon-button
                          [matMenuTriggerFor]="actionsMenu"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #actionsMenu="matMenu">
                    <button mat-menu-item *ngIf="viewProfileIndex != i" (click)="viewContact(i)">
                      View Contact
                    </button>
                    <button mat-menu-item (click)="confirmDeleteProspect(prospect)">
                      Delete
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <ng-container matColumnDef="avatar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let prospect; let i = index;" (click)="viewContact(i)" class="pointer">
                  <profile-avatar [contact]="prospect.contact"></profile-avatar>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let prospect; let i = index;" (click)="viewContact(i)" class="pointer">
                  {{prospect.contact.profile.fullName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let prospect; let i = index;" (click)="viewContact(i)" class="pointer">
                  <short-label [label]="prospect.contact.profile.title?.title"></short-label>
                </td>
              </ng-container>

              <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                <td mat-cell *matCellDef="let prospect; let i = index;" (click)="viewContact(i)" class="pointer">
                  <short-label [label]="prospect.contact.profile.company?.name"></short-label>
                </td>
              </ng-container>

              <ng-container matColumnDef="emailAddresses">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
                <td mat-cell *matCellDef="let prospect; let i = index;" (click)="viewContact(i)" class="pointer">
                  {{prospect.contact.profile.primaryEmail}}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let prospect; let i = index;" (click)="viewContact(i)" class="pointer">
                  {{prospect.status | titlecase}}
                </td>
              </ng-container>

              <ng-container matColumnDef="repliedOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Replied On</th>
                <td mat-cell *matCellDef="let prospect; let i = index;" (click)="viewContact(i)" class="pointer">
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row
                  *matRowDef="let prospect; let i = index; columns: displayedColumns;"
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="displayedColumns.length">
                  <div *ngIf="!loading && !addingProspects" class="no-table-data">
                    No contacts yet.<br>Please connect an email account <br>or you may just not have any matching contacts for this request.
                  </div>
                  <div class="loading-overlay" *ngIf="loading">
                    <div class="loading-wrapper">
                      <mat-spinner color="accent"></mat-spinner>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <mat-paginator #paginator
                           [pageSizeOptions]="[25, 50, 100]"
                           showFirstLastButtons
                           *ngIf="prospectTable.data.length > 25"
            ></mat-paginator>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-drawer-container>
</div>
