import {Component, OnInit, ViewChild} from '@angular/core';
import {User, UserService} from "../../../services/user.service";
import {ContactService, ContactListItem} from "../../../services/contact.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Audience, AudienceService, AudienceEditorMode, SerializedAudience} from "../../../services/audience.service";
import {ShareRequest, ShareRequestService} from "../../../services/share-request.service";
import {combineLatest, map, merge, mergeMap, Observable, startWith, Subject} from "rxjs";
import {SharerService} from "../../../services/sharer.service";
import {AddEditContactComponent} from "../add-edit-contact/add-edit-contact.component";
import {MatDialog} from "@angular/material/dialog";
import {InviteService} from "../../../services/invite.service";

import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faXTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {Location} from "@angular/common";
import {MatDrawer} from "@angular/material/sidenav";
import {IntroRequestModalComponent} from "../../share-requests/intro-request-modal/intro-request-modal.component";
import {AudienceEditorComponent} from "../../audiences/audience-editor/audience-editor.component";

@Component({
  selector: 'contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {

  faEnvelope = faEnvelope
  faXTwitter = faXTwitter
  faLinkedin = faLinkedin

  user: User = this.userService.new()

  tabs = ['myContacts', 'myNetwork', 'analytics', 'contactImports', 'unsubscribeList']
  selectedTab = new FormControl(0)

  loading: boolean = true
  importing: boolean = false

  audienceEditorMode = AudienceEditorMode.filters

  displayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['avatar', 'fullName', 'title', 'companyName']
    : ['select', 'actions', 'avatar', 'fullName', 'title', 'companyName', 'relationship_strength']
  numDisplayColumns: number = this.displayedColumns.length
  contactTable: MatTableDataSource<ContactListItem> = new MatTableDataSource()
  selection = new SelectionModel<ContactListItem>(true, [])
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('sort') sort!: MatSort

  viewProfileIndex: number = -1
  minItems: number = 0
  @ViewChild('contactDrawer') contactDrawer!: MatDrawer

  totalCount: number = 0
  filteredCount: number = 0
  filteredNetworkCount: number = 0

  filters: Audience = this.audienceService.new()
  filtersSubject: Subject<Audience> = new Subject<Audience>()
  filters$: Observable<Audience> = this.filtersSubject.asObservable()
  @ViewChild('audienceEditor') audienceEditor!: AudienceEditorComponent

  shareRequests: ShareRequest[] = []
  audiences: Audience[] = []

  pluralize = FormatUtils.pluralize

  constructor(private userService: UserService,
              private contactService: ContactService,
              private audienceService: AudienceService,
              private shareRequestService: ShareRequestService,
              private sharerService: SharerService,
              private inviteService: InviteService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.userService.user$.subscribe(
      user => {
        this.user = user

        this.shareRequestService.list$.subscribe(
          shareRequests => {
            this.shareRequests = shareRequests
          }
        )
        this.shareRequestService.getAll()

        this.contactService.summary$.subscribe(summary => {
          this.loading = false
          this.importing = summary.activeImportCount > 0
        })
        //this.contactService.getSummary()

        this.audienceService.list$.subscribe(
          audiences => {
            this.audiences = audiences
          }
        )
        this.audienceService.getAll()
      }
    )
    this.userService.getIdentity()
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      if (!!params) {
        const index = this.tabs.findIndex(tab => { return tab == params['tab'] })
        if (index >= 0) this.setTab(index)

        this.filters = this.audienceService.updateAudienceFromUrlParams(this.filters, params)
        //this.filtersSubject.next(this.filters)
        if (this.audienceEditor) {
          this.audienceEditor.setAudience(this.filters)
        }
      }
    })

    this.contactTable = new MatTableDataSource<ContactListItem>()
    this.contactTable.sort = this.sort
    const sortState: Sort = {active: 'fullName', direction: 'asc'}
    this.sort.active = sortState.active
    this.sort.direction = sortState.direction
    this.sort.sortChange.emit(sortState)
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))
    this.contactTable.paginator = this.paginator
    this.contactTable.paginator.pageSize = 25

    merge(this.filters$, this.contactTable.sort.sortChange, this.contactTable.paginator.page).pipe(
      startWith({}),
      mergeMap(
        () => {
          this.contactTable = new MatTableDataSource<ContactListItem>([])
          this.loading = true
          return this.contactService.getPage(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.filters,
            this.sort.active,
            this.sort.direction,
          )
        }
      ),
      map(contacts => {
        if (!contacts) return []
        this.totalCount = this.contactService.totalCount
        this.filteredCount = this.contactService.filteredCount
        this.contactTable = new MatTableDataSource<ContactListItem>(contacts)
        this.minItems = Math.min(this.filteredCount, this.paginator.pageSize)
        this.loading = false
        return contacts
      })
    ).subscribe(contacts => {})

    this.filters$.subscribe(
      filters => {
        this.contactService.getSummary(true, this.filters)
      }
    )
    this.contactService.getSummary()
  }

  setTab(index: any){
    this.selectedTab.setValue(index)
    const url: string = this.router.createUrlTree(
      [],
      {
        relativeTo: this.route,
        queryParams: { tab: this.tabs[index] },
        queryParamsHandling: 'merge'
      }
    ).toString()
    this.location.go(url)
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.contactTable.data.length
    return numSelected === numRows
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.contactTable.data.forEach(row => this.selection.select(row))
  }

  removeSelectedContacts() {
    this.dialogService.confirmDialog(
      'Delete Selected Contacts',
      'Are you sure you want to try and remove ' + this.selection.selected.length + ' contact' + (this.selection.selected.length !== 1 ? 's' : '') + '?'
    ).subscribe(result => {
      if (result) {
        const deleteObservables: Observable<any>[] = []
        this.selection.selected.forEach(contact => {
          deleteObservables.push(this.deleteContact(contact))
        })
        combineLatest(deleteObservables).subscribe(
          results => {
            this.notificationService.success(this.selection.selected.length + ' contact' + (this.selection.selected.length !== 1 ? 's' : '') + ' deleted.')
            this.selection.clear()
            this.filterContacts()
          }
        )
      }
    })
  }

  deleteContact(contact: ContactListItem) {
    return this.contactService.delete(contact)
  }

  confirmDeleteContact(contact: ContactListItem) {
    this.dialogService.confirmDialog(
      'Delete Contact',
      'Are you sure you want to delete this contact?'
    ).subscribe(result => {
      if (result) {
        this.deleteContact(contact).subscribe(
          result => {
            this.notificationService.success('Contact deleted.')
            this.filterContacts()
          }
        )
      }
    })
  }

  addSelectedToShareRequest(shareRequest: ShareRequest) {
    this.dialogService.confirmDialog(
      'Add Selected Contacts to Network Request',
      'Are you sure you want to add ' + this.selection.selected.length + ' contact' + (this.selection.selected.length !== 1 ? 's' : '') + ' to the network request?'
    ).subscribe(result => {
      if (result) {
        this.addContactsToShareRequest(shareRequest, this.selection.selected)
      }
    })
  }

  removeSelectedFromShareRequest(shareRequest: ShareRequest) {
    this.dialogService.confirmDialog(
      'Remove Selected Contacts from Network Request',
      'Are you sure you want to remove ' + this.selection.selected.length + ' contact' + (this.selection.selected.length !== 1 ? 's' : '') + ' from the network request?'
    ).subscribe(result => {
      if (result) {
        this.removeContactsFromShareRequest(shareRequest, this.selection.selected)
      }
    })
  }

  addContactsToShareRequest(shareRequest: ShareRequest, contacts: ContactListItem[]) {
    const contactIds = contacts.map(c => c.id)
    const successMessage = 'The contact' + (contacts.length === 1 ? ' has' : 's have') + ' been added to the network request'
    if (shareRequest.role === 'sharer' && shareRequest.sharers) {
      this.sharerService.addProspects(shareRequest.sharers[0], contactIds, undefined).subscribe(
        newSharer => { this.notificationService.success(successMessage) }
      )
    } else {
      this.shareRequestService.addSharers(shareRequest, contactIds, [],undefined).subscribe(
        newShareRequest => { this.notificationService.success(successMessage) }
      )
    }
  }

  removeContactsFromShareRequest(shareRequest: ShareRequest, contacts: ContactListItem[]) {
    const successMessage = 'The contact' + (contacts.length === 1 ? ' has' : 's have') + ' been removed from the network request'
    if (shareRequest.role === 'sharer' && shareRequest.sharers) {
      this.sharerService.deleteProspects(shareRequest.sharers[0], contacts).subscribe(
        newSharer => { this.notificationService.success(successMessage) }
      )
    } else {
      this.shareRequestService.deleteSharers(shareRequest, contacts).subscribe(
        newShareRequest => { this.notificationService.success(successMessage) }
      )
    }
  }

  addContactsToAudience(contacts: ContactListItem[], audience?: Audience) {
    if (!audience) {
      this.dialogService.inputDialog(
        'Create New Audience',
        'Please enter a name for your new audience',
        'Audience Name',
        '',
      ).subscribe(
        audienceName => {
          if (audienceName && audienceName !== '') {
            const newAudience = this.audienceService.new()
            newAudience.label = audienceName
            this.audienceService.save(newAudience).subscribe(
              savedAudience => {
                this.finishAddContactsToAudience(contacts, savedAudience)
              }
            )
          }
        }
      )
    } else {
      this.finishAddContactsToAudience(contacts, audience)
    }
  }

  removeContactsFromAudience(contacts: ContactListItem[], audience: Audience) {
    this.finishRemoveContactsFromAudience(contacts, audience)
  }

  finishAddContactsToAudience(contacts: ContactListItem[], audience: Audience) {
    this.audienceService.addContacts(audience, contacts).subscribe(
      newSharer => {
        this.notificationService.success(contacts.length + ' contact' + (contacts.length === 1 ? ' has' : 's have') + ' been added to the audience')
      }
    )
  }

  finishRemoveContactsFromAudience(contacts: ContactListItem[], audience: Audience) {
    this.audienceService.deleteContacts(audience, contacts).subscribe(
      newSharer => {
        this.notificationService.success(contacts.length + ' contact' + (contacts.length === 1 ? ' has' : 's have') + ' been removed from the audience')
      }
    )
  }

  confirmInviteSelectedContacts(isTeamInvite: boolean) {
    this.inviteContacts(isTeamInvite, this.selection.selected)
  }

  inviteContacts(isTeamInvite: boolean, contacts: ContactListItem[]) {
    this.inviteService.openInviteDialog(isTeamInvite, this.contactService.convertToQuickSearchItem(contacts))
  }

  openIntroDialog(contact: ContactListItem) {
    this.contactService.getDetail(contact.id).subscribe(
      contactDetail => {
        this.dialog.open(IntroRequestModalComponent, {
          data: contactDetail
        }).afterClosed().subscribe(
          sent => {
            if (sent) {
              this.notificationService.success('Your intro request has been sent.')
            }
          }
        )
      }
    )
  }

  filterContacts(filters?: Audience) {
    if (filters) {
      this.filters = {...filters}
    }

    const queryParams: Params = this.audienceService.convertAudienceToUrlParams(this.filters)
    if (this.route.snapshot.queryParams['tab']) {
      queryParams['tab'] = this.route.snapshot.queryParams['tab']
    }
    const url: string = this.router.createUrlTree(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      }
    ).toString()
    this.location.go(url)

    this.selection.clear()
    this.viewProfileIndex = -1
    this.paginator.pageIndex = 0
    this.filtersSubject.next(this.filters)
  }

  openContactDialog() {
    this.dialog.open(AddEditContactComponent, {
      data: undefined
    }).afterClosed().subscribe(
      (newContact: ContactListItem) => {
        if (newContact) {
          this.filterContacts(this.filters)
          this.notificationService.success('Contact saved.')
        }
      }
    )
  }

  setNetworkCount(count: number) {
    this.filteredNetworkCount = count
  }

  viewContact(index: number) {
    this.viewProfileIndex = index
    if (this.viewProfileIndex >= 0) {
      this.contactDrawer.open()
    }
  }

  contactDeletedFromDetail() {
    this.filterContacts()
  }

}
