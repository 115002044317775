import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'all-settings',
  templateUrl: './all-settings.component.html',
  styleUrls: ['./all-settings.component.scss']
})
export class AllSettingsComponent implements OnInit {

  tabs = ['account', 'socialAccounts', 'emailAccounts', 'contactImports', 'unsubscribeList', 'apiKeys']
  selectedTab = new FormControl(0)

  constructor(private userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (!!params) {
        const index = this.tabs.findIndex(tab => { return tab == params['tab'] })
        if (index >= 0) this.setTab(index)
      }
    })

    this.userService.getIdentity()
  }

  setTab(index: any){
    this.selectedTab.setValue(index)
    const url: string = this.router.createUrlTree(
      [],
      {
        relativeTo: this.route,
        queryParams: { tab: this.tabs[index] },
        queryParamsHandling: 'merge'
      }
    ).toString()
    this.location.go(url)
  }

}
