import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Audience, AudienceService} from "../../../services/audience.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MatDialog} from "@angular/material/dialog";
import {AudienceEditorComponent} from "../../audiences/audience-editor/audience-editor.component";
import {DialogService} from "../../../services/utilities/dialog.service";

@Component({
  selector: 'select-audience',
  templateUrl: './select-audience.component.html',
  styleUrls: ['./select-audience.component.scss']
})
export class SelectAudienceComponent implements OnInit {

  @Input() label: string = ''
  @Input() audiences: Audience[] = []
  @Input() audienceId: number = 0

  @Output() onChange = new EventEmitter()

  audienceControl = new FormControl()

  constructor(private audienceService: AudienceService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.audienceControl.setValue(this.audienceId)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['audiences']) {
      this.audiences = changes['audiences'].currentValue
    }
    if (changes['audienceId']) {
      this.audienceId = changes['audienceId'].currentValue
      this.audienceControl.setValue(this.audienceId)
    }
  }

  change() {
    this.audienceId = this.audienceControl.value
    this.onChange.emit(this.audienceId)
  }

  audienceIsSet() {
    return typeof this.audienceId !== 'undefined' && this.audienceId > 0
  }

  addAudience() {
    const newAudience = this.audienceService.new()
    this.dialogService.inputDialog(
      "New Audience",
      "Please enter a name for your new audience",
      "Audience Name",
      ""
    ). subscribe(
      name => {
        if (!!name && name !== '') {
          newAudience.label = name
          this.audienceService.save(newAudience).subscribe(
            savedAudience => {
              if (savedAudience.id) {
                this.audienceId = savedAudience.id
                this.audienceControl.setValue(this.audienceId)
                this.editAudience()
              }
            }
          )
        }
      }
    )
  }

  editAudience() {
    this.dialog.open(AudienceEditorComponent, {
      data: this.audienceId
    }).afterClosed().subscribe(editAudience => {
      if (editAudience) {
        this.notificationService.success('Audience saved.')
        this.change()
      }
    })
  }

  deleteAudience() {
    this.dialogService.confirmDialog(
      'Delete Audience',
      'Are you sure you want to delete this audience?'
    ).subscribe(result => {
      if (result) {
        const index = this.audiences.findIndex(s => s.id === this.audienceId)
        this.audienceService.delete(this.audiences[index]).subscribe(
          () => {
            this.notificationService.success('Audience deleted.')
            this.audienceId = 0
            this.change()
          }
        )
      }
    })
  }

}
