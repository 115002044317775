<div *ngIf="loading" class="width-100">
  <mat-spinner color="accent" class="margin-center"></mat-spinner>
</div>
<div *ngIf="!loading" class="horiz-padding">
  <div class="flex-row-left" ngClass.xs="flex-wrap">
    <h2>Wrollo Professional for {{user.currentTeamMember.team.name}}</h2>
    <div *ngIf="!!subscription && hasActiveTrial" class="notification-bar">
      Your Wrollo Professional trial ends on {{formatDate(subscription.trialEnd)}}
    </div>
    <div *ngIf="!!subscription && !hasActiveTrial && !hasActiveSubscription"
         class="notification-bar pointer"
         (click)="goToBillingPortal()"
    >
      Your subscription status is {{getSubscriptionStatusLabel(subscription.status)}}.  Please update your payment method.
    </div>
  </div>
  <div class="billing-portal-container flex-row-left vert-margin" *ngIf="hasActiveSubscription || hasActiveTrial">
    <button mat-raised-button
            class="horiz-margin-right"
            (click)="goToBillingPortal()"
    ><span fxHide.xs>Change </span>Payment Method</button>
    <button mat-raised-button
            class="horiz-margin-1x"
            (click)="goToBillingPortal()"
    ><span fxHide.xs>View </span>Invoice History</button>
    <button mat-raised-button
            class="horiz-margin-1x"
            (click)="goToBillingPortal()"
    >Cancel<span fxHide.xs> Subscription</span></button>
  </div>


  <div class="main-panel-row flex-row-left flex-wrap">
    <mat-card *ngFor="let opt of planOptions"
              class="plan-option-card plan-option-card-width"
              ngClass.gt-xs="horiz-margin-2x"
              [ngClass]="{'plan-option-card-selected': opt.selected}"
    >
      <mat-card-content class="plan-option-content">
        <div class="flex-row-left vert-padding">
          <div class="plan-option-checkbox-container"
               ngClass.xs="width-15"
               ngClass.gt-xs="width-20"
          >
            <mat-checkbox (change)="changePlanOption(opt)"
                          [checked]="opt.selected"
                          class="plan-option-checkbox checkbox-2x checkbox-2x-spacing"
            ></mat-checkbox>
          </div>
          <div ngClass.xs="width-85"
               ngClass.gt-xs="width-80"
          >
            <div class="plan-option-title">
              <fa-icon [icon]="getIcon(opt.icon)" class="icon"></fa-icon>
              {{opt.label}}
            </div>
            <div class="plan-option-price">${{(opt.price / 100) | number: '1.2-2'}} per month</div>
            <div class="plan-option-stats flex-row-left" fxHide.xs>
              <div>Network Requests:</div>
              <div class="horiz-margin-1x flex-row-left" matTooltip="{{opt.numActiveShareRequests | number}} active network requests">
                <mat-icon class="horiz-margin">toggle_on</mat-icon>
                <div>{{opt.numActiveShareRequests | number}}</div>
              </div>
              <div class="horiz-margin-1x flex-row-left" matTooltip="{{opt.numPendingShareRequests | number}} pending network requests">
                <mat-icon class="horiz-margin">toggle_off</mat-icon>
                <div>{{opt.numPendingShareRequests | number}}</div>
              </div>
            </div>
            <div class="plan-option-desc">{{opt.description}}</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="total-price-row flex-row-left" ngClass.xs="flex-wrap">
    <mat-form-field class="quantity_input"
                    matTooltip="Every admin or requester in your team needs to have a seat assigned to them. You currently need {{numRequesters + pluralize(' seat', numRequesters)}}."
    >
      <mat-label># of Seats</mat-label>
      <input matInput
             type="number"
             [min]="numRequesters"
             [formControl]="quantityControl"
      >
      <mat-error *ngIf="quantityControl.hasError('min')">Your team has {{numRequesters + pluralize(' user', numRequesters)}}.</mat-error>
    </mat-form-field>
    <h3 class="horiz-margin-1x">
      Monthly Total: ${{(totalPrice * (quantityControl.value || 1) / 100) | number: '1.2-2'}}
    </h3>
    <div [matTooltip]="hasValidOptions() ? '' : 'Please select at least 1 type first and have 1 or more seats entered.'"
         ngClass.gt-xs="horiz-margin-1x"
    >
      <button mat-flat-button
              color="primary"
              class="checkout-btn"
              ngClass.gt-xs="horiz-margin-2x"
              [disabled]="!hasValidOptions()"
              (click)="checkout()"
      >
        {{!!subscription ? 'Update' : (trialDays > 0 ? 'Start Trial' : 'Subscribe')}}
      </button>
    </div>
  </div>
</div>
