import {Injectable} from '@angular/core';
import {mergeMap, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {Team, TeamService} from "./team.service";

export interface MessageTemplate {
  id?: number
  name: string
  subject: string
  content: string
  createdAt?: number
  createdByUserId?: number
  teamId?: number
  modifiedAt?: number
  modified_by_user_id?: number
}

export interface MessageTemplateResult {
  id?: number
  name: string
  subject: string
  content: string
  created_at?: string
  created_by_user_id?: number
  team_id?: number
  modified_at?: number
  modifiedByUserId?: number
}

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {
  public list!: MessageTemplate[]
  private listSubject: Subject<MessageTemplate[]> = new Subject<MessageTemplate[]>()
  list$: Observable<MessageTemplate[]> = this.listSubject.asObservable()

  private endpoint: string = 'message_templates'

  constructor(private api: ApiService,
              private teamService: TeamService,
  ) {
  }

  getAll(forceGet: boolean = false): Observable<MessageTemplate[]> {
    if (this.list && !forceGet) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<MessageTemplate[]>()
      this.api.get<MessageTemplateResult[]>('my_current_team/' + this.endpoint, {}).subscribe(
        (results: MessageTemplateResult[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        }
      )
      return resultSubject.asObservable()
    }
  }

  save(messageTemplate: MessageTemplate): Observable<MessageTemplate> {
    return this.update(
      this.api.save<MessageTemplate>(this.endpoint, messageTemplate)
    )
  }

  delete(messageTemplate: MessageTemplate) {
    return this.update(
      this.api.delete(this.endpoint + '/' + messageTemplate.id)
    )
  }

  update(update$: Observable<MessageTemplate>): Observable<MessageTemplate> {
    const updateSubject: Subject<MessageTemplate> = new Subject<MessageTemplate>()
    update$.subscribe(
      (results: MessageTemplate) => {
        this.getAll(true).subscribe(list => {
          updateSubject.next(FormatUtils.snakeToCamelCaseKeys(results))
        })
      }
    )
    return updateSubject.asObservable()
  }

  new(): MessageTemplate{
    return {
      id: 0,
      name: "New Message Template",
      subject: "",
      content: "",
    }
  }

}
