import {Injectable} from '@angular/core';
import {mergeMap, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {Team, TeamService} from "./team.service";

export interface UnsubscribeEntry {
  id?: number
  emailAddress: string
  domain: string
  requestType: string
  createdAt?: number
  createdByUserId?: number
  teamId?: number
}

export interface UnsubscribeEntryResult {
  id?: number
  email_address: string
  domain: string
  request_type: string
  created_at?: string
  created_by_user_id?: number
  team_id?: number
}

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {
  public list!: UnsubscribeEntry[]
  private listSubject: Subject<UnsubscribeEntry[]> = new Subject<UnsubscribeEntry[]>()
  list$: Observable<UnsubscribeEntry[]> = this.listSubject.asObservable()

  private endpoint: string = 'unsubscribe_list'

  constructor(private api: ApiService,
              private teamService: TeamService,
  ) {
  }

  getAll(forceGet: boolean = false): Observable<UnsubscribeEntry[]> {
    if (this.list && !forceGet) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<UnsubscribeEntry[]>()
      this.api.get<UnsubscribeEntryResult[]>('my_current_team/' + this.endpoint, {}).subscribe(
        (results: UnsubscribeEntryResult[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        }
      )
      return resultSubject.asObservable()
    }
  }

  parseUnsubscribeEntries(entries: string[]): UnsubscribeEntry[] {
    const unsubscribeEntries: UnsubscribeEntry[] = []
    if (entries) {
      entries.forEach(e => {
        if (FormatUtils.isValidEmail(e)) {
          unsubscribeEntries.push({
            emailAddress: e,
            domain: '',
            requestType: 'manual',
          })
        } else if (FormatUtils.isValidDomain(e)) {
          unsubscribeEntries.push({
            emailAddress: '',
            domain: e,
            requestType: 'user',
          })
        }
      })
    }
    return unsubscribeEntries
  }

  add(entries: string[]): Observable<UnsubscribeEntry[]> {
    const unsubscribeEntries = this.parseUnsubscribeEntries(entries)
    return this.update(
      this.api.post<UnsubscribeEntry[]>(this.endpoint, {
        entries: FormatUtils.camelToSnakeCaseKeys(unsubscribeEntries),
      })
    )
  }

  delete(unsubscribeEntry: UnsubscribeEntry) {
    return this.update(
      this.api.delete(this.endpoint + '/' + unsubscribeEntry.id)
    )
  }

  update(update$: Observable<UnsubscribeEntry[]>): Observable<UnsubscribeEntry[]> {
    const updateSubject: Subject<UnsubscribeEntry[]> = new Subject<UnsubscribeEntry[]>()
    update$.subscribe(
      (results: UnsubscribeEntry[]) => {
        this.getAll(true).subscribe(list => {
          updateSubject.next(FormatUtils.snakeToCamelCaseKeys(results))
        })
      }
    )
    return updateSubject.asObservable()
  }

}
