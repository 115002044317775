import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {InputChipOption} from "../form/input-chip/input-chip.component";
import {AudienceService, ConvertedKeywordFilters, KeywordFilter} from "../../services/audience.service";

export interface DialogData {
  title: string
  message: string
  inputLabel: string
  defaulInputValue: string
  inputChipLabel: string
  defaulInputChipValues: string[]
  submitLabel?: string
  cancelLabel?: string
}

@Component({
  selector: 'utilities-modal',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {

  inputValue: string = ""
  inputChipValues: KeywordFilter[] = []

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private audienceService: AudienceService,
  ) {
  }

  ngOnInit() {
    if (!!this.data.defaulInputValue) {
      this.inputValue = this.data.defaulInputValue
    } else if (!!this.data.defaulInputChipValues) {
      const convertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.inputChipValues)
      this.inputChipValues = this.audienceService.convertSearchToKeywordFilters(this.data.defaulInputChipValues, [])
    }
  }

  submit() {
    if (this.data.inputChipLabel) {
      const convertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.inputChipValues)
      this.dialogRef.close(convertedFilters.posValues)
    } else {
      this.dialogRef.close(this.data.inputLabel ? this.inputValue : true)
    }
  }

  cancel() {
    this.dialogRef.close((this.data.inputLabel || this.data.inputChipLabel) ? undefined : false)
  }

}
