import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  ShareRequest,
  ShareRequestInviteStatus,
  ShareRequestService,
  ShareRequestType
} from "../../../services/share-request.service";
import {Sharer, SharerStatus} from "../../../services/sharer.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {MatDrawer} from "@angular/material/sidenav";

@Component({
  selector: 'share-request-sharer-list',
  templateUrl: './share-request-sharer-list.component.html',
  styleUrls: ['./share-request-sharer-list.component.scss']
})
export class ShareRequestSharerListComponent implements OnInit {

  @Input() fullDisplay: boolean = true

  shareRequest: ShareRequest = this.shareRequestService.new()
  ShareRequestInviteStatus = ShareRequestInviteStatus
  SharerStatus = SharerStatus

  loading: boolean = false
  addingSharers: boolean = false

  shortDisplayedColumns: string[] = ['avatar', 'fullName', 'company', 'email']
  fullDisplayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? this.shortDisplayedColumns
    : ['select', 'actions', 'avatar', 'fullName', 'company', 'email', 'status', 'contactType']
  fullEmailDisplayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? this.shortDisplayedColumns
    : ['select', 'actions', 'avatar', 'fullName', 'company', 'email', 'status', 'contactType', 'numProspects']
  displayedColumns: string[] = []
  numDisplayedColumns: number = 0
  sharerTable: MatTableDataSource<Sharer> = new MatTableDataSource()
  selection = new SelectionModel<Sharer>(true, []);
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('sharerListSort') sharerListSort!: MatSort

  viewProfileIndex: number = -1
  @ViewChild('contactDrawer') contactDrawer!: MatDrawer
  minItems: number = 0

  constructor(private shareRequestService: ShareRequestService,
              private dialogService: DialogService,
              private notificationService: NotificationService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.shareRequestService.detail$.subscribe((shareRequest: ShareRequest) => {
      this.setShareRequest(shareRequest)
    })
    this.shareRequestService.getDetail(this.shareRequest.id)
  }

  setShareRequest(shareRequest: ShareRequest) {
    this.shareRequest = shareRequest
    this.addingSharers = this.shareRequest.inviteStatus === ShareRequestInviteStatus.waiting
    this.displayedColumns = this.fullDisplay
      ? (this.shareRequest.type === ShareRequestType.email ? this.fullEmailDisplayedColumns : this.fullDisplayedColumns)
      : this.shortDisplayedColumns
    this.numDisplayedColumns = this.displayedColumns.length
    this.sharerTable = new MatTableDataSource(this.shareRequest.sharers)
    this.sharerTable.sort = this.sharerListSort
    setTimeout(() => {
      if (this.paginator) {
        this.sharerTable.paginator = this.paginator
        this.sharerTable.paginator.pageSize = 25
        this.minItems = Math.min(this.sharerTable.filteredData.length, this.paginator.pageSize)
      }
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.sharerTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.sharerTable.data.forEach(row => this.selection.select(row));
  }

  deleteSelectedSharers() {
    this.dialogService.confirmDialog(
      'Remove Selected Contacts',
      'Are you sure you want to try and remove ' + this.selection.selected.length + ' contact' + (this.selection.selected.length !== 1 ? 's' : '') + '?'
    ).subscribe(result => {
      if (result) {
        const validSharers = this.selection.selected.filter(s => {
          return s.status === SharerStatus.pending
        })
        if (validSharers.length > 0) {
          this.shareRequestService.deleteSharers(
            this.shareRequest,
            validSharers.map(s => { return s.contact })
          ).subscribe(shareRequest => {
            this.setShareRequest(shareRequest)
            this.notificationService.success(validSharers.length + ' sharer' + (this.selection.selected.length !== 1 ? 's' : '') + ' deleted.')
            this.selection.clear()
          })
        } else {
          this.notificationService.success('No valid contacts were selected.')
        }
      }
    })
  }

  confirmDeleteSharer(sharer: Sharer) {
    this.dialogService.confirmDialog(
      'Delete Sharer',
      'Are you sure you want to delete this sharer?'
    ).subscribe(result => {
      if (result) {
        this.shareRequestService.deleteSharers(
          this.shareRequest,
          [sharer.contact]
        ).subscribe(shareRequest => {
          this.setShareRequest(shareRequest)
          this.notificationService.success('1 sharer deleted.')
        })
      }
    })
  }

  viewContact(index: number) {
    this.viewProfileIndex = index
    if (this.viewProfileIndex >= 0) {
      this.contactDrawer.open()
    }
  }

}
