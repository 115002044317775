<div class="flex-row-left">
  <h2>Admin</h2>
</div>

<mat-tab-group [selectedIndex]="selectedTab.value"
               (selectedIndexChange)="setTab($event)"
>

  <mat-tab label="Users">
    <div class="tab-container">
      <admin-user-list></admin-user-list>
    </div>
  </mat-tab>

  <mat-tab label="Teams">
    <div class="tab-container">
      <admin-team-list></admin-team-list>
    </div>
  </mat-tab>

</mat-tab-group>
