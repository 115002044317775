import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {User, UserService} from "../../../services/user.service";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordComponent} from "../change-password/change-password.component";
import {ActivatedRoute} from "@angular/router";
import {NotificationService} from "../../../services/utilities/notification.service";

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  user: User | undefined

  forceChangePassword: boolean = false
  imageFile: any = null

  settingsForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    title: new FormControl(),
    companyName: new FormControl(),
    email: new FormControl(),
    password: new FormControl(),
    oldPassword: new FormControl(),
    newPassword: new FormControl(),
    confirmPassword: new FormControl(),
  })

  constructor(private userService: UserService,
              private notificationService: NotificationService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.userService.user$.subscribe(
      user => {
        this.user = user
        if (this.user) {
          this.settingsForm.patchValue({
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            title: this.user.title,
            companyName: this.user.companyName,
            email: this.user.email,
          })
        }

        this.route.queryParams.subscribe(params => {
          if (!!params && params['resetPassword'] && !this.forceChangePassword) {
            this.forceChangePassword = true
            this.changePassword()
          }
        })

      }
    )
    this.userService.getIdentity()

  }

  onFileSelected(event: any): void {
    this.imageFile = event.target.files[0] ?? null;
  }

  save() {
    if (this.user) {
      this.user.firstName = this.settingsForm.value.firstName
      this.user.lastName = this.settingsForm.value.lastName
      this.user.title = this.settingsForm.value.title
      this.user.companyName = this.settingsForm.value.companyName
      this.user.emailVerified = this.user.email === this.settingsForm.value.email
      this.user.email = this.settingsForm.value.email
      this.userService.save(
        this.user,
        this.imageFile,
      ).subscribe(
        user => {
          this.notificationService.success('User has been saved.')
        }
      )
    }
  }

  changePassword() {
    this.dialog.open(ChangePasswordComponent, {
      data: {
        user: this.user,
        hideOldPassword: this.forceChangePassword || !this.user?.hasPassword,
      }
    }).afterClosed().subscribe(
      (passwordChanged: boolean) => {
        if (passwordChanged) {
          this.notificationService.success('Your password has been changed.')
          this.userService.getIdentity(true)
        }
      }
    )
  }

}
