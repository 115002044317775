import {Component, OnInit} from '@angular/core'
import {Router} from "@angular/router";
import {AccessRight, OnboardingStep, User, UserService} from "../../../services/user.service";
import {OauthAction, OauthService, OauthType} from "../../../services/oauth.service";
import {faLinkedin, faXTwitter, faGoogle, faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import {ShareRequestService} from "../../../services/share-request.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {InviteService} from "../../../services/invite.service";

@Component({
  selector: 'onboarding-steps',
  templateUrl: './onboarding-steps.component.html',
  styleUrls: ['./onboarding-steps.component.scss']
})
export class OnboardingStepsComponent implements OnInit {

  faGoogle = faGoogle
  faMicrosoft = faMicrosoft
  faXTwitter = faXTwitter
  faLinkedin = faLinkedin

  user: User = this.userService.new()

  loading: boolean = true

  addedEmailAccount: boolean = false
  addedSocialAccount: boolean = false
  createdShareRequest: boolean = false
  activatedShareRequest: boolean = false
  invitedColleagues: boolean = false
  setupAutomation: boolean = false

  OnboardingStep = OnboardingStep
  OauthType = OauthType

  constructor(private userService: UserService,
              private oauthService: OauthService,
              private shareRequestService: ShareRequestService,
              private inviteService: InviteService,
              private router: Router,
              private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.userService.user$.subscribe(
      user => {
        this.user = user
        this.addedEmailAccount = this.userService.onboardingStepCompleted(this.user, OnboardingStep.addedEmailAccount)
        this.addedSocialAccount = this.userService.onboardingStepCompleted(this.user, OnboardingStep.addedSocialAccount)
        this.createdShareRequest = this.userService.onboardingStepCompleted(this.user, OnboardingStep.createdShareRequest)
        this.activatedShareRequest = this.userService.onboardingStepCompleted(this.user, OnboardingStep.activatedShareRequest)
        this.invitedColleagues = this.userService.onboardingStepCompleted(this.user, OnboardingStep.invitedColleagues)
        this.setupAutomation = this.userService.onboardingStepCompleted(this.user, OnboardingStep.setupAutomation)
        this.loading = false
      }
    )
    //this.userService.getIdentity()
  }

  skipStep(step: string) {
    this.userService.completeOnboardingStep(this.user, step)
  }

  doOauth(type: string) {
    this.oauthService.doOauth(
      type,
      OauthAction.add,
      document.location.href,
    )
  }

  addShareRequest(){
    if (this.userService.hasAccessRight(this.user, AccessRight.mutateShareRequests)) {
      const newShareRequest = this.shareRequestService.new()
      this.shareRequestService.save(newShareRequest).subscribe(
        savedSharedRequest => {
          this.userService.getIdentity(true)
          this.router.navigate(['share-request', savedSharedRequest.id], { replaceUrl: true })
        }
      )
    } else {
      this.dialogService.messageDialog(
        'Need More Access',
        "You do not have access to create network requests on this team.  Please ask your admin to change your role or change your current team selected."
      )
    }
  }

  openInviteDialog() {
    this.inviteService.openInviteDialog(false, [])
  }

  editFirstShareRequest() {
    this.shareRequestService.getAll().subscribe(
      shareRequests => {
        if (shareRequests.length > 0) {
          this.router.navigate(['share-request', shareRequests[0].id], { replaceUrl: true })
        } else {
          this.addShareRequest()
        }
      }
    )
  }

  goToSettings() {
    this.router.navigate(
      ['settings'],
      {
        queryParams: { tab: 'socialAccounts' },
        replaceUrl: true,
      }
    )
  }

}
