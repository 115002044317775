import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {UnsubscribeEntry, UnsubscribeService} from "../../../services/unsubscribe.service";
import {FormControl} from "@angular/forms";
import {SelectionModel} from "@angular/cdk/collections";
import {DialogService} from "../../../services/utilities/dialog.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {UserService} from "../../../services/user.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'unsubscribe-list',
  templateUrl: './unsubscribe-list.component.html',
  styleUrls: ['./unsubscribe-list.component.scss']
})
export class UnsubscribeListComponent implements OnInit {

  loading: boolean = true

  addEntriesControl:FormControl = new FormControl()

  displayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['email', 'domain', 'createdAt']
    : ['select', 'actions', 'email', 'domain', 'createdAt']
  numDisplayedCols = this.displayedColumns.length
  unsubscribeTable: MatTableDataSource<UnsubscribeEntry> = new MatTableDataSource()
  selection = new SelectionModel<UnsubscribeEntry>(true, [])
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('listSort') listSort!: MatSort

  formatDate = FormatUtils.formatDate

  constructor(private userService: UserService,
              private unsubscribeService: UnsubscribeService,
              private dialogService: DialogService,
              private notificationService: NotificationService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.unsubscribeService.list$.subscribe(unsubscribeList => {
      this.unsubscribeTable = new MatTableDataSource(unsubscribeList)
      setTimeout(() => {
        if (this.paginator) {
          this.unsubscribeTable.paginator = this.paginator
          this.unsubscribeTable.paginator.pageSize = 25
        }
      })
      this.unsubscribeTable.sort = this.listSort

      this.loading = false
    })
    this.unsubscribeService.getAll()

    this.userService.getIdentity()
  }

  addUnsubscribeEntry(){
    const entries = this.addEntriesControl.value
    this.unsubscribeService.add(entries.split('\n')).subscribe(
      list => {
        this.addEntriesControl.setValue('')
      }
    )
  }

  confirmDeleteUnsubscribeEntry(entry: UnsubscribeEntry) {
    this.dialogService.confirmDialog(
      'Delete Entry',
      'Are you sure you want to remove this entry?'
    ).subscribe(result => {
      if (result) {
        this.unsubscribeService.delete(entry)
        this.notificationService.success('Entry deleted.')
      }
    })
  }

  confirmDeleteSelected() {
    this.dialogService.confirmDialog(
      'Delete Selected Entries',
      'Are you sure you want to remove ' + this.selection.selected.length + (this.selection.selected.length !== 1 ? ' entries' : ' entry') + '?'
    ).subscribe(result => {
      if (result) {
        this.selection.selected.forEach(entry => {
          this.unsubscribeService.delete(entry)
        })
        this.notificationService.success(this.selection.selected.length + (this.selection.selected.length !== 1 ? ' entries' : 'entry') + ' deleted.')
        this.selection.clear()
      }
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.unsubscribeTable.data.length
    return numSelected === numRows
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.unsubscribeTable.data.forEach(row => this.selection.select(row))
  }

}
