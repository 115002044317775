<div>
  <div class="flex-row-left-top">
    <div class="flex-col-top">
      <profile-avatar [contact]="contactListItem" [large]="true" class="avatar-icon"></profile-avatar>
    </div>
    <div class="flex-col-left">
      <div class="section-title">{{contact.profile.fullName}}</div>
      <div class="profile-detail-row" *ngIf="contact.profile.title">{{contact.profile.title.title}}</div>
      <div class="profile-detail-row" *ngIf="contact.profile.company">{{contact.profile.company.name}}</div>
      <div class="profile-detail-row flex-row-left" *ngIf="contact.connection">
        <div class="connection-label">{{contact.connection === 1 ? '1st degree' : '2nd degree'}}</div>
        <relationship-strength [contact]="contact"></relationship-strength>
      </div>
    </div>
  </div>
  <div class="flex-row-left main-btn-row" *ngIf="showActions">
    <button mat-raised-button
            (click)="openInviteDialog(true)"
            *ngIf="contact.contactType === 'contact' && contact.connection === 1"
            matTooltip="Invite contact to my network"
    >Invite</button>
    <button mat-raised-button
            (click)="openIntroDialog()"
            *ngIf="contact.contactType === 'contact' && contact.connection === 2"
            matTooltip="Invite contact to my network"
    >Get an Intro</button>
    <button mat-icon-button
            [matMenuTriggerFor]="contactActionsMenu"
            [ngClass]="{'horiz-margin-1x': contact.contactType === 'contact'}"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #contactActionsMenu="matMenu">
      <button mat-menu-item *ngIf="shareRequests.length > 0 && contact.connection === 1" [matMenuTriggerFor]="addToShareRequestsMenu">
        Add to Network Request
      </button>
      <button mat-menu-item *ngIf="shareRequests.length > 0 && contact.connection === 1" [matMenuTriggerFor]="removeFromShareRequestMenu">
        Remove from Network Request
      </button>
      <button mat-menu-item *ngIf="contact.connection === 2" (click)="openIntroDialog()">
        Get an Intro
      </button>
      <button mat-menu-item [matMenuTriggerFor]="addToAudienceMenu">
        Add to Audience
      </button>
      <button mat-menu-item [matMenuTriggerFor]="removeFromAudienceMenu">
        Remove from Audience
      </button>
      <button mat-menu-item *ngIf="contact.connection === 1" (click)="openInviteDialog(true)">
        Invite
      </button>
      <button mat-menu-item *ngIf="contact.connection === 1" (click)="inviteTeamMember()">
        Add to Team
      </button>
      <button mat-menu-item *ngIf="contact.connection === 1" (click)="confirmDeleteContact()">
        Delete
      </button>
    </mat-menu>
    <mat-menu #addToShareRequestsMenu="matMenu">
      <button mat-menu-item *ngFor="let shareRequest of shareRequests" (click)="addContactToShareRequest(shareRequest)">
        {{shareRequest.name}}
      </button>
    </mat-menu>
    <mat-menu #removeFromShareRequestMenu="matMenu">
      <button mat-menu-item *ngFor="let shareRequest of shareRequests" (click)="removeContactFromShareRequest(shareRequest)">
        {{shareRequest.name}}
      </button>
    </mat-menu>
    <mat-menu #addToAudienceMenu="matMenu">
      <button mat-menu-item (click)="addContactToAudience()">New</button>
      <button mat-menu-item *ngFor="let audience of audiences" (click)="addContactToAudience(audience)">
        {{audience.label}}
      </button>
    </mat-menu>
    <mat-menu #removeFromAudienceMenu="matMenu">
      <button mat-menu-item *ngFor="let audience of audiences" (click)="removeContactFromAudience(audience)">
        {{audience.label}}
      </button>
    </mat-menu>
  </div>
</div>

<div class="section-panel">
  <div class="section-title">Contact Info</div>
  <!--
  <div class="flex-row-left contact-info-row">
    <fa-icon [icon]="faEnvelope" class="contact-info-icon"></fa-icon>
    {{contact.profile.primaryEmail}}
  </div>
  -->
  <div *ngIf="contact.connection === 1">
    <div *ngFor="let email of contact.profile.emailAddresses"
         class="flex-row-left contact-info-row"
         [ngClass]="{'alt-email': email.email !== contact.profile.primaryEmail}"
    >
      <fa-icon [icon]="faEnvelope" class="contact-info-icon"></fa-icon>
      {{email.email}}
    </div>
    <div *ngFor="let phone of contact.profile.phoneNumbers" class="flex-row-left contact-info-row">
      <fa-icon [icon]="getPhoneIcon(phone)" class="contact-info-icon"></fa-icon>
      {{phone.phoneNumber}}
    </div>
  </div>
  <div *ngIf="contact.connection === 2" class="normal-text">
    You can't see contact info for your network's contacts.<br>Ask them for an intro instead.
  </div>
</div>

<div class="section-panel">
  <div class="section-title">Known By</div>
  <div *ngFor="let networkContact of contact.networkContacts"
       class="flex-row-left contact-info-row"
  >
    <div class="width-70">{{networkContact.profile.fullName}}</div>
    <relationship-strength class="width-30" [contact]="networkContact"></relationship-strength>
  </div>
  <div *ngIf="contact.networkContacts.length === 0">
    <div class="normal-text">No one in your network knows them.</div>
  </div>
  <div>
    <button mat-raised-button
            class="vert-margin"
            (click)="openInviteDialog()"
    >Expand Your Network</button>
  </div>
</div>

<div class="section-panel">
  <div class="section-title">Audiences</div>
  <div *ngFor="let audience of contact.audiences"
       class="flex-row-left contact-info-row"
  >
    <div class="width-90">
      {{audience.label}}
    </div>
    <div class="width-10">
      <fa-icon [icon]="faUserMinus"
               (click)="removeContactFromAudience(audience)"
               class="pointer remove-icon"
               matTooltip="Remove from audience"
      ></fa-icon>
    </div>
  </div>
  <div *ngIf="contact.audiences.length === 0">
    <div class="normal-text">Not added to any audiences.</div>
  </div>
</div>

<div class="section-panel" *ngIf="contact.connection === 1">
  <div class="section-title">Network Requests</div>
  <div *ngFor="let shareRequest of contact.shareRequestsCreatedByMe"
       class="flex-row-left contact-info-row"
  >
    <div class="width-90">
      {{shareRequest.name}}
    </div>
    <div class="width-10">
      <fa-icon [icon]="faUserMinus"
               *ngIf="shareRequest.status === ShareRequestStatus.pending"
               (click)="removeContactFromShareRequest(shareRequest)"
               class="pointer remove-icon"
               matTooltip="Remove from network request"
      ></fa-icon>
    </div>
  </div>
  <div *ngIf="contact.shareRequestsCreatedByMe.length === 0">
    <div class="normal-text">Not added to any network requests.</div>
  </div>
</div>

<!--
<div class="section-panel">
  <div class="section-title">Network Requests Shared With Me</div>
  <div *ngFor="let shareRequest of contact.shareRequestsSharedWithMe"
       class="flex-row-left contact-info-row"
  >
    <div class="width-90">
      {{shareRequest.name}}
    </div>
    <div class="width-10">
      <fa-icon [icon]="faUserMinus"
               (click)="removeContactsFromShareRequest(shareRequest,[contactListItem])"
               class="pointer remove-icon"
               matTooltip="Remove from network request"
      ></fa-icon>
    </div>
  </div>
  <div *ngIf="contact.shareRequestsSharedWithMe.length === 0" class="flex-col-top">
    <div class="normal-text text-align-center">Not added to any network requests.</div>
  </div>
</div>
-->
