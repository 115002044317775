import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core'
import {ContactDetail, ContactService} from "../../../services/contact.service";

@Component({
  selector: 'contact-profile',
  templateUrl: './contact-profile.html',
  styleUrls: ['./contact-profile.scss']
})
export class ContactProfile implements OnChanges {

  @Input() contactId: number = 0
  @Input() showActions: boolean = true

  @Output() onDelete = new EventEmitter()

  contactDetail!: ContactDetail
  loading: boolean = true

  constructor(private contactService: ContactService,
  ) {
  }

  ngOnChanges() {
    this.onChange()
  }

  onChange() {
    if (this.contactId > 0) {
      this.loading = true
      this.contactService.getDetail(this.contactId).subscribe(
        contact => {
          this.contactDetail = contact
          this.loading = false
        }
      )
    }
  }

  onDeletePassthrough() {
    if (this.onDelete) {
      this.onDelete.emit()
    }
  }

}
