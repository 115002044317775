import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ShareRequest, ShareRequestService, ShareRequestStatus, ShareRequestType} from "../../../services/share-request.service";
import {AudienceFilterOption, AudienceService} from "../../../services/audience.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable, Subject} from "rxjs";
import {AutocompleteChipOption} from "../../form/autocomplete-chip/autocomplete-chip.component";

import {faEnvelope, faHandshake} from '@fortawesome/free-solid-svg-icons';
import {faXTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'share-request-setup',
  templateUrl: './share-request-setup.component.html',
  styleUrls: ['./share-request-setup.component.scss']
})
export class ShareRequestSetupComponent implements OnInit, AfterViewInit {

  faEnvelope = faEnvelope
  faXTwitter = faXTwitter
  faLinkedin = faLinkedin
  faHandshake = faHandshake

  shareRequest: ShareRequest = this.shareRequestService.new()
  ShareRequestType = ShareRequestType

  loading: boolean = true
  showAdvanced: boolean = false
  setupForm = new FormGroup({
    name: new FormControl(),
    category: new FormControl(),
    otherCategory: new FormControl(),
  })

  industryOptionsSelected: AudienceFilterOption[] = []
  industryOptionsAutocompleteSubject: Subject<AudienceFilterOption[]> = new Subject<AutocompleteChipOption[]>()
  industryOptionsAutocomplete$: Observable<AutocompleteChipOption[]> = this.industryOptionsAutocompleteSubject.asObservable()

  categoryOptions = this.shareRequestService.categoryOptions
  typeOptions = this.shareRequestService.typeOptions

  constructor(private shareRequestService: ShareRequestService,
              private audienceService: AudienceService,
              private notificationService: NotificationService,
              @Optional() public dialogRef: MatDialogRef<ShareRequestSetupComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: ShareRequest,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.shareRequestService.detail$.subscribe((shareRequest: ShareRequest) => {
      this.setShareRequest(shareRequest)
    })
    if (this.data) {
      this.setShareRequest(this.data)
    } else {
      this.shareRequestService.getDetail(this.shareRequest.id)
    }
  }

  setShareRequest(shareRequest: ShareRequest) {
    this.shareRequest = shareRequest
    this.typeOptions = this.shareRequestService.typeOptions.filter(
      o => {
        return (o.visible && this.shareRequest.status === ShareRequestStatus.pending) ||
          o.value === this.shareRequest.type
      }
    )
    if (this.shareRequest.tags.length > 0) {
      this.audienceService.getIndustryLabels(this.shareRequest.tags).subscribe(
        industryOptionsSelected => {
          this.industryOptionsSelected = industryOptionsSelected
        }
      )
    }

    const categoryValue = this.categoryOptions.filter(
      o => { return o.value === this.shareRequest.category }
    ).length > 0 ? this.shareRequest.category : 'other'
    this.setupForm.patchValue({
      name: this.shareRequest.name,
      category: categoryValue,
      otherCategory: categoryValue === 'other' ? this.shareRequest.category : undefined,
    })

    this.loading = false
  }

  save(forceSave: boolean = false) {
    // Avoid saving on blur when in modal
    if (!this.isModal() || forceSave) {
      //this.shareRequest.name = this.setupForm.value.name
      this.shareRequest.category = this.setupForm.value.category === 'other'
        ? this.setupForm.value.otherCategory
        : this.setupForm.value.category
      this.shareRequestService.save(this.shareRequest, true).subscribe(
        shareRequest => {
          //this.notificationService.success('Network request saved.')
        },
        err => {
          this.notificationService.error(err.message)
        }
      )
    }
  }

  industryChange(industryOptionsSelected: AutocompleteChipOption[]) {
    this.industryOptionsSelected = industryOptionsSelected
    this.shareRequest.tags = this.industryOptionsSelected.map(o => { return o.value })
    this.save()
  }

  industryAutocomplete(partial: string) {
    if (!partial || partial === '') {
      this.industryOptionsAutocompleteSubject.next([])
    } else {
      this.audienceService.getIndustryAutocompleteOptions(partial).subscribe(
        industryOptionsAutocomplete => {
          this.industryOptionsAutocompleteSubject.next(industryOptionsAutocomplete)
        }
      )
    }
  }

  selectType(value: string) {
    this.shareRequest.type = value
    if (!this.isModal()) {
      if (this.shareRequest.type === ShareRequestType.email) {
        this.shareRequest.name = 'New Email Request'
      } else if (this.shareRequest.type === ShareRequestType.xPost) {
        this.shareRequest.name = 'New X Post Request'
      } else if (this.shareRequest.type === ShareRequestType.linkedinPost) {
        this.shareRequest.name = 'New LinkedIn Post Request'
      }
    }
    this.save()
  }

  getTypeIcon(icon: string) {
    return this[icon as keyof this] as IconProp
  }

  isModal() {
    return this.data !== null && typeof this.data != 'undefined'
  }

  close(save: boolean) {
    if (save) {
      this.save(true)
    }
    this.dialogRef.close(save)
  }

}
