import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes';
import {FormatUtils} from "../../../services/utilities/format.utilities";

export interface InputChipOption {
  value: string,
  not: boolean,
}

@Component({
  selector: 'input-chip',
  templateUrl: './input-chip.component.html',
  styleUrls: ['./input-chip.component.scss']
})
export class InputChipComponent {

  @Input() title: string = ''
  @Input() values: InputChipOption[] = []
  @Input() readOnly: boolean = false
  @Input() allowNegativeFilters: boolean = true

  @Output() onChange = new EventEmitter()

  separatorKeysCodes: number[] = [ENTER, COMMA]
  newValueControl = new FormControl()

  constructor() {
  }

  addValue(event: MatChipInputEvent): void {
    const value = (event.value || '').trim()

    if (value && FormatUtils.indexOfObjectInArray(this.values, 'value', value) < 0) {
      this.values.push({value: value, not: false})
      this.onChange.emit()
    }

    // Clear the input value
    event.chipInput!.clear()

    this.newValueControl.setValue(null)
  }

  removeValue(value: InputChipOption) {
    const i = this.values.findIndex(v => { return v.value === value.value })

    if (i >= 0) {
      this.values.splice(i, 1)
      this.onChange.emit()
    }
  }

  notValue(value: InputChipOption) {
    value.not = !value.not
    this.onChange.emit()
  }

}
