import {Component, OnInit, ViewChild} from '@angular/core';
import {User, UserService} from "../../../services/user.service";
import {BillingService, PlanOption, Product, Subscription, SubscriptionStatus} from "../../../services/billing.service";
import {combineLatest} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NotificationService} from "../../../services/utilities/notification.service";
import {ShareRequestService, ShareRequestStatus} from "../../../services/share-request.service";
import {DialogService} from "../../../services/utilities/dialog.service";

import {faEnvelope, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {faXTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {TeamService, Team, TeamMemberRole} from "../../../services/team.service";
import {FormControl} from "@angular/forms";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss']
})
export class SubscriptionDetailComponent implements OnInit {

  faEnvelope = faEnvelope
  faXTwitter = faXTwitter
  faLinkedin = faLinkedin

  loading: boolean = true

  user!: User
  team!: Team
  returnUrl: string = window.location.origin

  hasActiveSubscription: boolean = false
  hasActiveTrial: boolean = false
  totalPrice: number = 0
  subscription: Subscription | undefined = undefined
  trialDays: number = 30

  quantityControl = new FormControl()
  numRequesters: number = 1

  planOptions: PlanOption[] = JSON.parse(JSON.stringify(this.billingService.PLAN_OPTIONS))
  getSubscriptionStatusLabel = this.billingService.getSubscriptionStatusLabel

  pluralize = FormatUtils.pluralize
  formatDate = FormatUtils.formatDate

  constructor(private userService: UserService,
              private teamService: TeamService,
              private billingService: BillingService,
              private shareRequestService: ShareRequestService,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.userService.user$,
      this.route.queryParams,
      this.billingService.getProducts(),
      this.shareRequestService.list$,
    ]).subscribe(
      ([user, queryParams, products, shareRequests]) => {
        this.user = user
        this.hasActiveSubscription = this.billingService.hasActiveSubscription(this.user)
        this.hasActiveTrial = this.billingService.hasActiveTrial(this.user)
        this.subscription = this.billingService.getUserSubscription(this.user)
        this.quantityControl.setValue(this.subscription ? this.subscription.quantity : 1)

        this.teamService.team$.subscribe(
          team => {
            this.team = team
            this.numRequesters = this.teamService.getNumRequesters(this.team)
            this.loading = false
          }
        )
        this.teamService.getTeam(this.user.currentTeamId)

        if (queryParams && queryParams['returnUrl']) {
          this.returnUrl = queryParams['returnUrl'].indexOf(window.location.origin) < 0
            ? window.location.origin + queryParams['returnUrl']
            : queryParams['returnUrl']
        }

        this.planOptions.forEach(
          o => {
            const product: Product | undefined = products.find(p => p.shareRequestType === o.type)
            if (!!product) {
              o.price = product.defaultPrice
              o.selected = this.billingService.hasValidProductAccess(this.user, o.type)
              if (!o.selected && queryParams && queryParams['type'] && queryParams['type'] === o.type) {
                o.selected = true
              }
              o.numActiveShareRequests = shareRequests.filter(s => {
                return s.type === o.type && s.status === ShareRequestStatus.active
              }).length
              o.numPendingShareRequests = shareRequests.filter(s => {
                return s.type === o.type && s.status === ShareRequestStatus.pending
              }).length
            }
          }
        )
      }
    )
    this.userService.getIdentity()
    this.shareRequestService.getAll()
  }

  finishChangePlanOption(opt: PlanOption) {
    opt.selected = !opt.selected
    this.totalPrice = 0
    this.planOptions.forEach(o => {
      this.totalPrice += o.selected ? o.price : 0
    })
  }

  changePlanOption(opt: PlanOption) {
    if (opt.selected && opt.numActiveShareRequests > 0) {
      this.dialogService.confirmDialog(
        "Active Network Requests",
        "You have active network requests for this type.  If you remove it, those network requests will be deactivated.  Is that ok?"
      ).subscribe(
        result => {
          if (result) {
            this.finishChangePlanOption(opt)
          }
        }
      )
    } else {
      this.finishChangePlanOption(opt)
    }
  }

  hasValidOptions() {
    let numSelected = 0
    this.planOptions.forEach(o => {
      numSelected += o.selected ? 1 : 0
    })
    return numSelected > 0 && this.quantityControl.value && this.quantityControl.value > 0
  }

  goToBillingPortal() {
    this.billingService.getPortalLink(
      this.userService.user,
      this.returnUrl,
    ).subscribe(
      url => {
        window.location.href = url
      }
    )
  }

  showSubscriptionError(error: any) {
    this.dialogService.confirmDialog(
      "Subscription Error",
      "There was an error starting your " + (this.trialDays > 0 ? "trial" : "subscription") + ". Would you like to try again?"
    ).subscribe(
      result => {
        if (!result) {
          window.location.href = this.returnUrl
        }
      }
    )
  }

  finishCheckout() {
    const products = this.planOptions.filter(o => o.selected)
      .map(o => o.type)
    if (!!this.subscription) {
      this.billingService.updateSubscription(this.user, products, this.quantityControl.value || 1).subscribe(
        subscription => {
          if (subscription) {
            this.notificationService.success('Subscription updated.')
            window.location.href = this.returnUrl
          }
        }
      )
    } else if (this.totalPrice === 0 || this.trialDays > 0) {
      this.billingService.createOrUpdateSubscription(
        this.user,
        products,
        this.quantityControl.value || 1,
        this.trialDays,
      ).subscribe(
        subscription => {
          if (subscription) {
            this.dialogService.messageDialog(
              this.trialDays > 0 ? "Trial Started" : "Subscription Started",
              "Your " + (this.trialDays > 0 ? "trial" : "subscription") +" has started!"
            ).subscribe(
              () => {
                window.location.href = this.returnUrl
              }
            )
          } else {
            this.showSubscriptionError(subscription)
          }
        },
        error => {
          this.showSubscriptionError(error)
        }
      )
    } else {
      this.billingService.getCheckoutLink(
        this.user,
        products,
        this.quantityControl.value || 1,
        this.trialDays,
        this.returnUrl,
      ).subscribe(
        url => {
          window.location.href = url
        }
      )
    }
  }

  checkout() {
    const unselectedTypeHasPending: boolean = this.planOptions.filter(
      o => { return !o.selected && o.numPendingShareRequests > 0 }
    ).length > 0
    if (unselectedTypeHasPending) {
      this.dialogService.confirmDialog(
        "Pending Network Requests",
        "You have pending network requests for a type that wasn't selected.  You can go back and select that type or do you prefer to continue?"
      ). subscribe(
        result => {
          if (result) {
            this.finishCheckout()
          }
        }
      )
    } else {
      this.finishCheckout()
    }
  }

  getIcon(iconLabel: string): IconDefinition {
    return (this[iconLabel as keyof SubscriptionDetailComponent] ) as IconDefinition
  }

}
