import {Injectable} from '@angular/core';
import {combineLatest, map, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {ContactListItemResult, Profile, ProfileResult} from "./contact.service";
import {OauthAccountListItem} from "./oauth.service";
import {TeamMember, TeamMemberResult, TeamMemberRole, TeamService} from "./team.service";

export interface User {
  id: number
  email: string
  profileId: number
  profile?: Profile
  firstName: string
  lastName: string
  title: string
  companyName: string
  emailVerified: boolean
  hasPassword: boolean
  onboardingCompleted: string[]
  isSuperAdmin: boolean
  numContacts: number
  numActiveContactsGoogle: number
  numActiveContactsTwitter: number
  numActiveContactsLinkedin: number
  numActiveContactImports: number
  userOauths: OauthAccountListItem[]
  inviteLinkPlug: string
  currentTeamId: number
  currentTeamMember: TeamMember
  teamMembers: TeamMember[]
  createdAt: number
}

export interface UserResult {
  id: number
  email: string
  profile_id: number
  profile?: ProfileResult
  first_name: string
  last_name: string
  title: string
  companyName: string
  email_verified: boolean
  has_password: boolean
  onboarding_completed: string[]
  is_super_admin: boolean
  num_contacts: number
  num_active_contacts_google: number
  num_active_contacts_twitter: number
  num_active_contacts_linkedin: number
  num_active_contact_imports: number
  user_oauths: OauthAccountListItem[]
  invite_link_plug: string
  current_team_id: number
  current_team_member: TeamMemberResult
  team_members: TeamMemberResult[]
  created_at: number
}

export interface UserPagination {
  itemsReceived: number
  curPage: number
  nextPage: number
  prevPage: number
  offset: number
  itemsTotal: number
  pageTotal: number
  items: User[]
}

export interface UserPaginationResult {
  itemsReceived: number
  curPage: number
  nextPage: number
  prevPage: number
  offset: number
  itemsTotal: number
  pageTotal: number
  items: UserResult[]
}

export enum AccessRight {
  mutateShareRequests = 'mutate_share_requests',
  viewShareRequests = 'view_share_requests',
  mutateSubscription = 'mutate_subscription',
  mutateTeam = 'mutate_team',
  viewTeam = 'view_team',
  mutateAudiences = 'mutate_audiences',
  viewAudiences = 'view_audiences',
  mutateMessageTemplates = 'mutate_message_templates',
  viewMessageTemplates = 'view_message_templates',
  mutateUnsubscribeList = 'mutate_unsubscribe_list',
  viewUnsubscribeList = 'view_unsubscribe_list',
}

export enum OnboardingStep {
  addedEmailAccount = 'added_email_account',
  addedSocialAccount = 'added_social_account',
  createdShareRequest = 'created_share_request',
  activatedShareRequest = 'activated_share_request',
  invitedColleagues = 'invited_colleagues',
  setupAutomation = 'setup_automation',
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User = this.new()
  private userSubject = new Subject<User>()
  user$: Observable<User> = this.userSubject.asObservable()

  private endpoint: string = 'users'

  public passwordValidationPattern: string = "(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*#?&^_-]).*$"

  constructor(private api: ApiService,
              private teamService: TeamService,
  ) {
  }

  getIdentity(forceGet: boolean = false): Observable<User> {
    if (this.user && this.user.id > 0 && !forceGet) {
      this.userSubject.next(this.user)
      return of(this.user)
    } else {
      const resultSubject: Subject<User> = new Subject<User>()
      this.api.get<UserResult>('auth/me').subscribe(
        userResult => {
          resultSubject.next(FormatUtils.snakeToCamelCaseKeys(userResult))
          this.setUser(userResult)
        }
      )
      return resultSubject.asObservable()
    }

  }

  getUser(userId: number): Observable<User> {
    return this.api.get<UserResult>('user/' + userId).pipe(
      map(
        (userResult: UserResult) => {
          return FormatUtils.snakeToCamelCaseKeys(userResult)
        }
      )
    )
  }

  getPage(
    teamId: number,
    searchTerm: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ): Observable<UserPagination> {
    return this.api.get<UserPaginationResult>(
      this.endpoint,
      {
        team_id: teamId,
        search_term: searchTerm,
        page_number: pageNumber,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
      }
    ).pipe(
      map(
        (userPaginationResults: UserPaginationResult) => {
          return Object.assign(
            userPaginationResults,
            { items: FormatUtils.snakeToCamelCaseKeys(userPaginationResults.items) }
          )
        }
      )
    )
  }

  setUser(userResult: UserResult, convertResult: boolean = true) {
    this.user = convertResult ? FormatUtils.snakeToCamelCaseKeys(userResult) : userResult
    this.userSubject.next(this.user)
  }

  save(user: User, imageFile?: File): Observable<User> {
    const saveObservables: Observable<UserResult>[] = []
    saveObservables.push(
      this.api.save<UserResult>(this.endpoint, {
        id: user.id,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        title: user.title,
        company_name: user.companyName,
        current_team_id: user.currentTeamId,
        onboarding_completed: user.onboardingCompleted,
      })
    )
    if (!!imageFile) {
      saveObservables.push(
        this.updateProfilePicture(user, imageFile)
      )
    }
    return combineLatest(saveObservables).pipe(
      map(
      userResults => {
          this.setUser(FormatUtils.snakeToCamelCaseKeys(userResults[userResults.length - 1]), false)
          return this.user
        }
      )
    )
  }

  delete(user: User) {
    return this.api.delete<boolean>(this.endpoint + "/" + user.id)
  }

  updateProfilePicture(user: User, imageFile: File): Observable<UserResult> {
    const uploadFile = new FormData()
    uploadFile.append('image_file', imageFile, imageFile.name)
    return this.api.put<UserResult>(this.endpoint + "/" + user.id + "/profile_picture", uploadFile)
  }

  changePassword(user: User, oldPassword: string, newPassword: string, confirmPassword: string): Observable<User> {
    return this.api.post<UserResult>('auth/change_password', {
      user_id: user.id,
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    }).pipe(
      map(
        userResult => {
          this.setUser(userResult)
          return this.user
        }
      )
    )
  }

  hasAccessRight(user: User, accessRight: AccessRight): boolean {
    return user.currentTeamMember.accessRights.indexOf(accessRight) >= 0
  }

  completeOnboardingStep(user: User, step: string) {
    user.onboardingCompleted.push(step)
    this.save(user).subscribe(() => {})
  }

  onboardingStepCompleted(user: User, step: string): boolean {
    return user.onboardingCompleted.indexOf(step) >= 0
  }

  onboardingCompleted(user: User): boolean {
    return Object.values(OnboardingStep).filter(
      (step: string) => {
        return !this.onboardingStepCompleted(user, step)
      }
    ).length === 0
  }

  getDisplayName(user: User | undefined, defaultValue: string = ''): string {
    return !!user
      ? (
        user.firstName !== ''
          ? (user.firstName + ' ' + user.lastName).trim()
          : user.email
      ) : defaultValue
  }

  getInitials(user: User | undefined): string {
    let initials = ""
    if (user) {
      initials = FormatUtils.getInitials(user.firstName, user.lastName, user.email)
    }
    return initials.toUpperCase()
  }

  new(): User {
    return {
      id: 0,
      profileId: 0,
      firstName: '',
      lastName: '',
      title: '',
      companyName: '',
      email: '',
      emailVerified: false,
      hasPassword: false,
      onboardingCompleted: [],
      isSuperAdmin: false,
      numContacts: 0,
      numActiveContactsGoogle: 0,
      numActiveContactsTwitter: 0,
      numActiveContactsLinkedin: 0,
      numActiveContactImports: 0,
      inviteLinkPlug: '',
      currentTeamId: 0,
      currentTeamMember: {
        id: 0,
        userId: 0,
        teamId: 0,
        role: TeamMemberRole.member,
        accessRights: [],
        team: this.teamService.new()
      },
      teamMembers: [],
      userOauths: [],
      createdAt: 0
    }
  }

}
