import {Injectable} from "@angular/core";
import {Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {ContactDetail, ContactDetailResult, ContactQuickSearchItem} from "./contact.service";
import {FormatUtils} from "./utilities/format.utilities";
import {MatDialog} from "@angular/material/dialog";
import {InviteModalComponent} from "../components/invite/invite-modal/invite-modal.component";

export interface Invite {
  id: number
  userId: number
  teamId: number
  contactId: number
  contact: ContactDetail
  contactEmail: string
  note: string
  status: InviteStatus
  createdAt: number
  createdByUserId: number
  acceptedAt: number
}

export interface InviteResult {
  id: number
  user_id: number
  team_id: number
  contact_id: number
  contact: ContactDetailResult
  contact_email: string
  note: string
  status: InviteStatus
  created_at: number
  created_by_user_id: number
  accepted_at: number
}

export enum InviteStatus {
  pending = 'pending',
  sent = 'sent',
  accepted = 'accepted',
  expired = 'expired',
}

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  public teamList!: Invite[]
  private teamListSubject: Subject<Invite[]> = new Subject<Invite[]>()
  teamList$: Observable<Invite[]> = this.teamListSubject.asObservable()

  public personalList!: Invite[]
  private personalListSubject: Subject<Invite[]> = new Subject<Invite[]>()
  personalList$: Observable<Invite[]> = this.personalListSubject.asObservable()

  private endpoint: string = 'invites'

  constructor(private api: ApiService,
              private dialog: MatDialog,
  ) {
  }

  getAllTeamInvites(forceGet: boolean = false): Observable<Invite[]> {
    if (this.teamList && !forceGet) {
      this.teamListSubject.next(this.teamList)
      return of(this.teamList)
    } else {
      const resultSubject = new Subject<Invite[]>()
      this.api.get<InviteResult[]>('my_current_team/' + this.endpoint, {}).subscribe(
        (results: InviteResult[]) => {
          this.teamList = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.teamListSubject.next(this.teamList)
          resultSubject.next(this.teamList)
        }
      )
      return resultSubject.asObservable()
    }
  }

  getAllPersonalInvites(forceGet: boolean = false): Observable<Invite[]> {
    if (this.personalList && !forceGet) {
      this.personalListSubject.next(this.personalList)
      return of(this.personalList)
    } else {
      const resultSubject = new Subject<Invite[]>()
      this.api.get<InviteResult[]>('my/' + this.endpoint, {}).subscribe(
        (results: InviteResult[]) => {
          this.personalList = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.personalListSubject.next(this.personalList)
          resultSubject.next(this.personalList)
        }
      )
      return resultSubject.asObservable()
    }
  }

  add(emails: string[], note: string = '', teamId: number = 0): Observable<Invite[]> {
    const addObservable: Observable<Invite[]> = this.api.post(this.endpoint, {
      emails: emails,
      note: (note || '').replaceAll('\n','<br>'),
      team_id: teamId,
    })
    if (teamId > 0) {
      return this.updateTeamList(addObservable)
    } else {
      return this.updatePersonalList(addObservable)
    }
  }

  delete(invite: Invite): Observable<Invite[]> {
    const deleteObservable: Observable<Invite[]> = this.api.delete(this.endpoint + '/' + invite.id)
    if (invite.teamId > 0) {
      return this.updateTeamList(deleteObservable)
    } else {
      return this.updatePersonalList(deleteObservable)
    }
  }

  updateTeamList(update$: Observable<Invite[]>): Observable<Invite[]> {
    const updateSubject: Subject<Invite[]> = new Subject<Invite[]>()
    update$.subscribe(
      (results: Invite[]) => {
        this.getAllTeamInvites(true).subscribe(list => {
          updateSubject.next(FormatUtils.snakeToCamelCaseKeys(results))
        })
      }
    )
    return updateSubject.asObservable()
  }

  updatePersonalList(update$: Observable<Invite[]>): Observable<Invite[]> {
    const updateSubject: Subject<Invite[]> = new Subject<Invite[]>()
    update$.subscribe(
      (results: Invite[]) => {
        this.getAllPersonalInvites(true).subscribe(list => {
          updateSubject.next(FormatUtils.snakeToCamelCaseKeys(results))
        })
      }
    )
    return updateSubject.asObservable()
  }

  joinByInvitePlug(invitePlug: string, inviteId: number): Observable<any> {
    return this.api.post(this.endpoint + '/join_invite_plug', {
      invite_plug: invitePlug,
      invite_id: inviteId,
    })
  }

  openInviteDialog(isTeamInvite: boolean, contacts: ContactQuickSearchItem[]) {
    this.dialog.open(InviteModalComponent, {
      data: {
        isTeamInvite: isTeamInvite,
        contacts: contacts,
      }
    })
  }

}
