<div class="dialog-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile>
      Preferences for {{userOauthAccount.type | titlecase}} account, {{userOauthAccount.name}}
    </h2>
    <div class="loading-overlay" *ngIf="loading">
      <div class="loading-wrapper">
        <mat-spinner color="accent"></mat-spinner>
      </div>
    </div>
    <div *ngIf="!loading">
      <table mat-table [dataSource]="teamTable">

        <ng-container matColumnDef="team">
          <th mat-header-cell *matHeaderCellDef>Team</th>
          <td mat-cell *matCellDef="let account; let i = index;">
            {{account.teamMember.team.name}}</td>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="thin-col">
            <div class="flex-row-left">
              Allow Team Access
              <mat-icon matTooltip="Choose whether you want this team to have access to this account."
                        class="info-icon"
                        fxHide.xs
              >info</mat-icon>
            </div>
          </th>
          <td mat-cell *matCellDef="let account; let i = index" class="thin-col">
            <div class="flex-row-left">
              <mat-slide-toggle
                  color="primary"
                  [ngModel]="account.includeAccount"
                  (change)="account.includeAccount = !account.includeAccount"
              >
              </mat-slide-toggle>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="autoCreate">
          <th mat-header-cell *matHeaderCellDef [hidden]="!isSocialAccount">
            <div class="flex-row-left">
              <div>Auto Create<span fxHide.xs> Network Requests</span></div>
              <mat-icon matTooltip="Auto-create a new network request for every new X post or LinkedIn post."
                        class="info-icon"
                        fxHide.xs
              >info</mat-icon>
            </div>
          </th>
          <td mat-cell *matCellDef="let account; let i = index;" [hidden]="!isSocialAccount">
            <div class="flex-row-left" *ngIf="account.includeAccount">
              <mat-slide-toggle
                  color="primary"
                  [ngModel]="account.autoCreateShareRequest"
                  (change)="account.autoCreateShareRequest = !account.autoCreateShareRequest"
              >
              </mat-slide-toggle>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="filters">
          <th mat-header-cell *matHeaderCellDef [hidden]="!isSocialAccount">
            <div class="flex-row-left">
              <div><span fxHide.xs>Auto Create </span>Filters</div>
              <mat-icon matTooltip="Select filters to only auto-create network requests that match those keywords."
                        class="info-icon"
                        fxHide.xs
              >info</mat-icon>
            </div>
          </th>
          <td mat-cell *matCellDef="let account; let i = index;" [hidden]="!isSocialAccount">
            <div class="auto-create-filters-container" *ngIf="account.includeAccount && account.autoCreateShareRequest">
              <a (click)="account.editFilters = !account.editFilters" class="pointer" *ngIf="!account.editFilters">
                {{account.autoCreateFiltersConverted.length > 0 ? account.teamMemberOauth.autoCreateFilters.join(', ') : 'Add Filters'}}
              </a>
              <input-chip title="Filters"
                          [values]="account.autoCreateFiltersConverted"
                          [allowNegativeFilters]="false"
                          *ngIf="account.editFilters"
              ></input-chip>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let account; let i = index; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">
            <div *ngIf="!loading" class="no-table-data">No teams</div>
            <div class="loading-overlay" *ngIf="loading">
              <div class="loading-wrapper">
                <mat-spinner color="accent"></mat-spinner>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <div class="vert-margin">{{errMessage}}</div>
      <div mat-dialog-actions class="action-row">
        <button mat-raised-button
                color="primary"
                (click)="save()"
                class="action-button"
        >Save</button>
        <button mat-raised-button
                (click)="close(false)"
                class="action-button"
        >Cancel</button>
      </div>
    </div>
  </div>
</div>
