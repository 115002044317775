import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService, Registration} from "../../../services/auth/auth.service";
import {UserService} from "../../../services/user.service";
import {Company, CompanyService} from "../../../services/company.service";
import {OauthService, OauthType, OauthAction} from "../../../services/oauth.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  loading: boolean = true

  OauthType = OauthType
  OauthAction = OauthAction

  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(this.userService.passwordValidationPattern)
    ]),
  })

  errorMsg: string = ''
  returnUrl: string = window.location.origin + '/intro'

  company: Company | undefined = undefined

  constructor(private route: ActivatedRoute,
              private authService: AuthService,
              private userService: UserService,
              private oauthService: OauthService,
              private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params && params['returnUrl']) {
        this.returnUrl = window.location.origin + params['returnUrl']
      }
      if (this.authService.isAuthenticated()) {
        this.goToNext()
      }
      if (params && params['domain']) {
        this.companyService.getCompanyByDomain(params['domain']).subscribe(
          company => {
            if (company) {
              this.company = company
            }
            this.loading = false
          },
          err => {
            this.loading = false
          }
        )
      } else {
        this.loading = false
      }
    })
  }

  clearError() {
    this.errorMsg = ''
  }

  goToNext(): void {
    window.location.href = this.returnUrl
  }

  signup(){
    const rego = this.form.value as Registration
    this.authService.register(rego).subscribe(
      jwe => {
        this.goToNext()
      }, err => {
        this.errorMsg = err.message
        setTimeout(this.clearError, 3000)
      }
    )
  }

  doGoogleOauth() {
    this.oauthService.doOauth(
      OauthType.google,
      OauthAction.continue,
      this.returnUrl,
    )
  }
  
}
