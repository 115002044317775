import {Component, Input, OnChanges} from '@angular/core'
import {ContactListItem, ContactService} from "../../../services/contact.service";
import {User, UserService} from "../../../services/user.service";

@Component({
  selector: 'profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent implements OnChanges {

  @Input() contact: ContactListItem | undefined = this.contactService.new()
  @Input() user: User | undefined = this.userService.new()
  @Input() large: boolean = false

  initials: string = ''
  imageUrl: string = ''

  constructor(private contactService: ContactService,
              private userService: UserService,
  ) {
  }

  ngOnChanges() {
    if (this.contact && this.contact.id > 0) {
      this.initials = this.contactService.getInitials(this.contact)
      this.imageUrl = this.contact.imageUrl
    } else if (this.user && this.user.id > 0) {
      this.initials = this.userService.getInitials(this.user)
      this.imageUrl = this.user.profile?.imageUrl || ''
    }
  }

}
