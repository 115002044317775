import {Injectable} from '@angular/core';
import {map, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {Audience, AudienceFilterOption, AudienceResult, AudienceService} from "./audience.service";
import {User, UserResult} from "./user.service";
import {OauthAccountListItem} from "./oauth.service";
import {ShareRequest, ShareRequestResult} from "./share-request.service";
import {Company, CompanyResult} from "./company.service";

export interface Title {
  id: number
  title: string
  seniority: string
  businessFunction: string
}

export interface TitleResult {
  id: number
  title: string
  seniority: string
  business_function: string
}

export interface EmailAddress {
  id: number
  email: string
  type: string
  grade: string
}

export interface EmailAddressResult {
  id: number
  email: string
  type: string
  grade: string
}

export interface PhoneNumber {
  id: number
  phoneNumber: string
  type: string
  verified: string
}

export interface PhoneNumberResult {
  id: number
  phone_number: string
  type: string
  verified: string
}

export interface Url {
  id: number
  url: string
  type: string
}

export interface UrlResult {
  id: number
  url: string
  type: string
}

export interface Profile {
  id: number
  createdAt: number
  firstName: string
  lastName: string
  fullName: string
  primaryEmail: string
  imageUrl: string
  company?: Company
  title?: Title
  emailAddresses: EmailAddress[]
  phoneNumbers: PhoneNumber[]
  urls: Url[]
}

export interface ProfileResult {
  id: number
  created_at: number
  first_name: string
  last_name: string
  full_name: string
  primary_email: string
  image_url: string
  company?: CompanyResult
  title?: TitleResult
  email_addresses: EmailAddressResult[]
  phone_numbers: PhoneNumberResult[]
  urls: UrlResult[]
}

export interface ContactDetail {
  id: number
  userId: number
  user?: User
  contactType: string
  autoCompleteTweetRequests: boolean
  autoCompleteTwitterOauthId: number
  autoCompleteTweetFilters: string[]
  autoCompleteLinkedinPostRequests: boolean
  autoCompleteLinkedinOauthId: number
  autoCompleteLinkedinPostFilters: string[]
  createdAt: number
  lastEmailedAt: number
  profile: Profile
  userOauth: {
    id: number
    name: string
    email: string
  }
  audiences: Audience[]
  shareRequestsCreatedByMe: ShareRequest[]
  shareRequestsSharedWithMe: ShareRequest[]
  connection: number
  networkContacts: ContactDetail[],
  numMessagesFrom: number,
  numMessagesTo: number,
  firstMessageAt: number,
  lastMessageAt: number,
  relationshipStrength: number,
  networkRelationshipStrength: number,
}

export interface ContactDetailResult {
  id: number
  user_id: number
  user?: UserResult
  contact_type: string
  auto_complete_tweet_requests: boolean
  auto_complete_twitter_oauth_id: number
  auto_complete_tweet_filters: string[]
  auto_complete_linkedin_post_requests: boolean
  auto_complete_linkedin_oauth_id: number
  auto_complete_linkedin_post_filters: string[]
  created_at: number
  last_emailed_at: number
  profile: ProfileResult
  user_oauth: {
    id: number
    name: string
    email: string
  }
  audiences: AudienceResult[]
  share_requests_created_by_me: ShareRequestResult[]
  share_requests_shared_with_me: ShareRequestResult[]
  connection: number
  network_contacts: ContactDetailResult[],
  num_messages_from: number,
  num_messages_to: number,
  first_message_at: number,
  last_message_at: number,
  relationship_strength: number,
  network_relationship_strength: number,
}

export interface ContactEmail {
  id: number
  email: string
  type: string
  grade: string
}

export interface ContactListItem {
  id: number
  userId: number
  user?: User
  profileId: number
  contactType: string
  autoCompleteTweetRequests: boolean
  autoCompleteTwitterOauthId: number
  autoCompleteTweetFilters: string[]
  autoCompleteLinkedinPostRequests: boolean
  autoCompleteLinkedinOauthId: number
  autoCompleteLinkedinPostFilters: string[]
  createdAt: number
  lastEmailedAt: number
  firstName: string
  lastName: string
  fullName: string
  companyName: string
  title: string
  primaryEmail: string
  imageUrl: string
  emailAddresses: ContactEmail[]
  userOauths: OauthAccountListItem[]
  connection: number
  numMessagesFrom: number
  numMessagesTo: number
  firstMessageAt: number
  lastMessageAt: number
  relationshipStrength: number
  networkRelationshipStrength: number
}

export interface ContactListItemResult {
  id: number
  user_id: number
  user?: User
  profile_id: number
  contact_type: string
  auto_complete_tweet_requests: boolean
  auto_complete_twitter_oauth_id: number
  auto_complete_tweet_filters: string[]
  auto_complete_linkedin_post_requests: boolean
  auto_complete_linkedin_oauth_id: number
  auto_complete_linkedin_post_filters: string[]
  created_at: number
  last_emailed_at: number
  first_name: string
  last_name: string
  full_name: string
  company_name: string
  title: string
  primary_email: string
  image_url: string
  email_addresses: ContactEmail[]
  user_oauths: OauthAccountListItem[]
  connection: number
  num_messages_from: number
  num_messages_to: number
  first_message_at: number
  last_message_at: number
  relationship_strength: number
  network_relationship_strength: number
}

export interface ContactPaginationResult {
  itemsReceived: number
  curPage: number
  nextPage: number
  prevPage: number
  offset: number
  itemsTotal: number
  pageTotal: number
  items: ContactListItemResult[]
}

export interface ContactQuickSearchItem {
  id: number
  firstName: string
  lastName: string
  email: string
}

export interface ContactQuickSearchItemResult {
  id: number
  first_name: string
  last_name: string
  email: string
}

export interface ContactSummaryValue {
  value: string
  count: number
}

export interface ContactSummary {
  contactCount: number
  activeImportCount: number
  fundingStage: [ContactSummaryValue]
  companySize: [ContactSummaryValue]
  companyType: [ContactSummaryValue]
  industry: [ContactSummaryValue]
  revenue: [ContactSummaryValue]
  companyStage: [ContactSummaryValue]
  seniority: [ContactSummaryValue]
  businessFunction: [ContactSummaryValue]
}

export interface ContactSummaryResult {
  contact_count: number
  active_import_count: number
  funding_stage: [ContactSummaryValue]
  company_size: [ContactSummaryValue]
  company_type: [ContactSummaryValue]
  industry: [ContactSummaryValue]
  revenue: [ContactSummaryValue]
  company_stage: [ContactSummaryValue]
  seniority: [ContactSummaryValue]
  business_function: [ContactSummaryValue]
}

export interface NetworkSummary {
  contactId: number
  userId: number
  user: User
  numContacts: number
  autoCompleteTweetRequests: boolean
  autoCompleteTwitterOauthId: number
  autoCompleteTweetFilters: string[]
  autoCompleteLinkedinPostRequests: boolean
  autoCompleteLinkedinOauthId: number
  autoCompleteLinkedinPostFilters: string[]
  mirrorContact: ContactListItem
}

export interface NetworkSummaryResult {
  contact_id: number
  user_id: number
  user: UserResult
  num_contacts: number
  auto_complete_tweet_requests: boolean
  auto_complete_twitter_oauth_id: number
  autoComplete_twitter_filters: string[]
  auto_complete_linkedin_post_requests: boolean
  auto_complete_linkedin_oauth_id: number
  auto_complete_linkedin_post_filters: string[]
  mirror_contact: ContactListItemResult
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public list!: ContactListItem[]
  private listSubject: Subject<ContactListItem[]> = new Subject<ContactListItem[]>()
  list$: Observable<ContactListItem[]> = this.listSubject.asObservable()

  public summary!: ContactSummary
  private summarySubject: Subject<ContactSummary> = new Subject<ContactSummary>()
  summary$: Observable<ContactSummary> = this.summarySubject.asObservable()

  public networkSummary!: NetworkSummary[]
  private networkSummarySubject: Subject<NetworkSummary[]> = new Subject<NetworkSummary[]>()
  networkSummary$: Observable<NetworkSummary[]> = this.networkSummarySubject.asObservable()

  public totalCount = 0
  public filteredCount = 0

  private endpoint: string = 'contacts'

  constructor(private api: ApiService,
              private audienceService: AudienceService,
  ) {
  }

  getAll(forceGet: boolean = false): Observable<ContactListItem[]>{
    if (this.list && !forceGet) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<ContactListItem[]>()
      this.api.get<ContactListItem[]>(`my/` + this.endpoint + '/all', {}).subscribe(
        (results: ContactListItem[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        }
      )
      return resultSubject.asObservable()
    }
  }

  getPage(pageNumber: number, pageSize: number, filters: Audience, sortAttr: string, sortDirection: string): Observable<ContactListItem[]> {
    const resultSubject = new Subject<ContactListItem[]>()
    this.api.get<ContactPaginationResult>(`my/` + this.endpoint + '/search', {
      page_number: pageNumber,
      page_size: pageSize,
      contact_filters: FormatUtils.camelToSnakeCaseKeys(filters),
      sort_by: FormatUtils.camelToSnakeCase(sortAttr),
      sort_direction: sortDirection,
    }).subscribe(
      (result: ContactPaginationResult) => {
        if (this.audienceService.isAudienceEmpty(filters)) {
          this.totalCount = result.itemsTotal
          this.filteredCount = result.itemsTotal
        } else {
          this.filteredCount = result.itemsTotal
        }
        this.list = result.items.map(FormatUtils.snakeToCamelCaseKeys)
        this.listSubject.next(this.list)
        resultSubject.next(this.list)
      }
    )
    return resultSubject.asObservable()
  }

  getSummary(forceGet: boolean = false, filters?: Audience){
    if (this.summary && !forceGet) {
      this.summarySubject.next(this.summary)
    } else {
      this.api.get<ContactSummaryResult>(`my/` + this.endpoint + '/summary',
        {
          filters: FormatUtils.camelToSnakeCaseKeys(filters || this.audienceService.new()),
        }
      ).subscribe(
        summary => {
          this.summary = FormatUtils.snakeToCamelCaseKeys(summary)
          this.summarySubject.next(this.summary)
        }
      )
    }
  }

  getNetworkResults(forceGet: boolean = false, filters?: Audience){
    if (this.networkSummary && !forceGet) {
      this.networkSummarySubject.next(this.networkSummary)
    } else {
      this.api.get<NetworkSummaryResult[]>(`my/` + this.endpoint + '/network',
        {
          filters: FormatUtils.camelToSnakeCaseKeys(filters || {}),
        }
      ).subscribe(
        networkSummary => {
          this.networkSummary = FormatUtils.snakeToCamelCaseKeys(networkSummary)
          this.networkSummarySubject.next(this.networkSummary)
        }
      )
    }
  }

  getDetail(contactId: number): Observable<ContactDetail> {
    return this.api.get<ContactDetail>(this.endpoint + '/' + contactId).pipe(
      map(contact => FormatUtils.snakeToCamelCaseKeys(contact))
    )
  }

  save(contact: ContactListItem): Observable<ContactListItem> {
    const saveSubject: Subject<ContactListItem> = new Subject<ContactListItem>()
    this.api.save<ContactListItem>(this.endpoint, contact).subscribe(
      savedContact => {
        saveSubject.next(FormatUtils.snakeToCamelCaseKeys(savedContact))
      }
    )
    return saveSubject.asObservable()
  }

  delete(contact: ContactListItem) {
    return this.api.delete(this.endpoint + '/' + contact.id)
  }

  quickSearch(searchTerm: string): Observable<ContactQuickSearchItem[]> {
    return this.api.get<ContactQuickSearchItemResult[]>(`my/` + this.endpoint + '/quick_search', {
      search_term: searchTerm,
    }).pipe(
      map(
        (contacts: ContactQuickSearchItemResult[]) => {
          return FormatUtils.snakeToCamelCaseKeys(
            contacts.filter(c => c.email !== "")
          )
        }
      )
    )
  }

  getOptionCount(summaryAttr: keyof ContactSummary, value: string): number {
    if (this.summary && this.summary[summaryAttr] && Array.isArray(this.summary[summaryAttr])) {
      const countMatches = (this.summary[summaryAttr] as ContactSummaryValue[]).filter(
        o => { return o.value === value}
      )
      if (countMatches.length > 0) {
        return countMatches[0].count
      } else {
        return -1
      }
    } else {
      return -1
    }
  }

  updateDataForChart(summaryAttr: keyof ContactSummary, noObjectLabel: string, noAttrLabel: string, options: AudienceFilterOption[]) {
    const data = []
    if (this.summary && this.summary[summaryAttr]) {
      const summaryValues = this.summary[summaryAttr]
      if (Array.isArray(summaryValues) &&
          summaryValues.length > 0 &&
          summaryValues[0].value === '' &&
          summaryValues[0].count > 0
      ) {
        data.push({
          value: noAttrLabel + ' (' + summaryValues[0].count.toLocaleString('en')  + ')',
          count: summaryValues[0].count,
        })
      }
      options.forEach(o => {
        const count = this.getOptionCount(summaryAttr, o.value)
        if (count > 0) {
          data.push({
            value: o.label + ' (' + count.toLocaleString('en')  + ')',
            count: count,
          })
        }
      })
      if (Array.isArray(summaryValues) &&
          summaryValues.length > 0 &&
          summaryValues[summaryValues.length - 1].value === '' &&
          summaryValues[summaryValues.length - 1].count > 0
      ) {
        data.push({
          value: noObjectLabel + ' (' + summaryValues[summaryValues.length - 1].count.toLocaleString('en')  + ')',
          count: summaryValues[summaryValues.length - 1].count,
        })
      }
    }
    return data
  }

  getDisplayName(contact: any | undefined, defaultValue: string = ''): string {
    let displayName: string = defaultValue
    if (contact) {
      const profile = contact.profile ? contact.profile : contact
      displayName = profile.firstName
        ? (profile.firstName + ' ' + profile.lastName).trim()
        : profile.primaryEmail || profile.email
    }
    return displayName
  }

  getInitials(contact: any | undefined): string {
    let initials = ""
    if (contact) {
      const profile = contact.profile ? contact.profile : contact
      initials = FormatUtils.getInitials(profile.firstName, profile.lastName, (profile.primaryEmail || profile.email))
    }
    return initials.toUpperCase()
  }

  convertToContactListItem(contact: ContactDetail): ContactListItem {
    return {
      id: contact.id,
      userId: contact.userId,
      profileId: contact.profile.id,
      firstName: contact.profile.firstName,
      lastName: contact.profile.lastName,
      fullName: contact.profile.fullName,
      title: contact.profile.title?.title || '',
      companyName: contact.profile.company?.name || '',
      primaryEmail: contact.profile.primaryEmail,
      imageUrl: contact.profile.imageUrl,
      emailAddresses: contact.profile.emailAddresses,
      userOauths: [],
      contactType: contact.contactType,
      autoCompleteTweetRequests: contact.autoCompleteTweetRequests,
      autoCompleteTwitterOauthId: contact.autoCompleteTwitterOauthId,
      autoCompleteTweetFilters: contact.autoCompleteTweetFilters,
      autoCompleteLinkedinPostRequests: contact.autoCompleteLinkedinPostRequests,
      autoCompleteLinkedinOauthId: contact.autoCompleteLinkedinOauthId,
      autoCompleteLinkedinPostFilters: contact.autoCompleteLinkedinPostFilters,
      createdAt: contact.createdAt,
      lastEmailedAt: contact.lastEmailedAt,
      connection: contact.connection,
      numMessagesFrom: contact.numMessagesFrom,
      numMessagesTo: contact.numMessagesTo,
      firstMessageAt: contact.firstMessageAt,
      lastMessageAt: contact.lastMessageAt,
      relationshipStrength: contact.relationshipStrength,
      networkRelationshipStrength: contact.networkRelationshipStrength,
    }
  }

  convertToQuickSearchItem(contacts: ContactListItem[]): ContactQuickSearchItem[] {
    return contacts.map(
      c => {
        return {
          id: c.id,
          firstName: c.firstName,
          lastName: c.lastName,
          email: c.primaryEmail,
        }
      }
    )
  }

  new(): ContactListItem {
    return {
      id: 0,
      userId: 0,
      profileId: 0,
      firstName: '',
      lastName: '',
      fullName: '',
      title: '',
      companyName: '',
      primaryEmail: '',
      imageUrl: '',
      emailAddresses: [],
      userOauths: [],
      contactType: '',
      autoCompleteTweetRequests: false,
      autoCompleteTwitterOauthId: 0,
      autoCompleteTweetFilters: [],
      autoCompleteLinkedinPostRequests: false,
      autoCompleteLinkedinOauthId: 0,
      autoCompleteLinkedinPostFilters: [],
      connection: 1,
      numMessagesFrom: 0,
      numMessagesTo: 0,
      firstMessageAt: 0,
      lastMessageAt: 0,
      relationshipStrength: 0,
      networkRelationshipStrength: 0,
      createdAt: 0,
      lastEmailedAt: 0,
    }
  }

}
