import {Component, Input, OnChanges} from '@angular/core'
import {Company} from "../../../services/company.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'company-profile-detail',
  templateUrl: './company-profile-detail.html',
  styleUrls: ['./company-profile-detail.scss']
})
export class CompanyProfileDetail implements OnChanges {

  @Input() company!: Company

  showStartupDetails: boolean = false
  showInvestorDetails: boolean = false

  pluralize = FormatUtils.pluralize
  convertDashedText = FormatUtils.convertDashedText

  companyFields = [
    {label: 'Domains', attr: 'domains'},
    {label: 'Company Type', attr: 'companyType'},
    {label: 'Company Size', attr: 'companySize'},
    {label: 'Revenue', attr: 'revenue'},
    {label: 'Primary Industry', attr: 'primaryIndustry', convert: this.convertDashedText},
    {label: 'Industries', attr: 'industries', convert: this.convertDashedText},
  ]
  populatedCompanyFields = [...this.companyFields]

  constructor(
  ) {
  }

  ngOnChanges() {
    this.populatedCompanyFields = this.populatedCompanyFields.filter(
      f => {
        const value = this.company[f.attr as keyof Company]
        return value && (!Array.isArray(value) || value.length > 0)
      }
    )
    this.showStartupDetails = this.company &&
      (this.company.fundsRaised > 0 || this.company.fundingStage != '')
    this.showInvestorDetails = this.company &&
      (
        this.company.investorType != '' ||
        (this.company.investmentStage && this.company.investmentStage.length > 0)
      )
  }

  getCompanyField(field: any) : string {
    const value: string = this.getCompanyFieldValue(this.company[field.attr as keyof Company])
    return field.convert
      ? field.convert(value)
      : value
  }

  getCompanyFieldValue(value: any): string {
    return Array.isArray(value) ? value.join(', ') : value
  }

}
