<div class="flex-row-left field-row">
  <mat-form-field class="setup-field">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControl]="audienceControl"
                (selectionChange)="change()"
    >
      <mat-option *ngFor="let audience of audiences"
                  [value]="audience.id"
      >
        {{audience.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-mini-fab
          (click)="addAudience()"
          class="horiz-margin-1x new-audience-btn white-btn"
          matTooltip="Create a new audience"
          *ngIf="!audienceIsSet()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <button mat-mini-fab
          [matMenuTriggerFor]="audienceActionsMenu"
          class="horiz-margin-1x new-audience-btn white-btn"
          *ngIf="audienceIsSet()"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #audienceActionsMenu="matMenu">
    <button mat-menu-item (click)="addAudience()">
      New
    </button>
    <button mat-menu-item (click)="editAudience()">
      Edit
    </button>
    <button mat-menu-item (click)="deleteAudience()">
      Delete
    </button>
  </mat-menu>
</div>
