import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../../services/auth/auth.service";
import {ActivatedRoute} from "@angular/router";
import {combineLatest} from "rxjs";
import {InviteService} from "../../../services/invite.service";

@Component({
  selector: 'accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {

  returnUrl: string = '/'
  inviteId: number = 0

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private inviteService: InviteService,
  ) {
  }

  ngOnInit() {
    combineLatest([this.route.url, this.route.queryParams]).subscribe(
      ([urlSegments, queryParams]) => {
        if (queryParams) {
          this.returnUrl = window.location.origin + (queryParams['returnUrl'] || '/')
          this.inviteId = queryParams['invite_id']
        }
        if (this.authService.isAuthenticated()) {
          this.inviteService.joinByInvitePlug(urlSegments[1].toString(), this.inviteId).subscribe(
            result => {
              this.goToNext()
            }
          )
        }
      }
    )
  }

  goToNext(): void {
    window.location.href = this.returnUrl
  }
}
