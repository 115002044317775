import {Component, Input, OnInit} from '@angular/core';
import {faComment, faHeart, faRetweet} from "@fortawesome/free-solid-svg-icons";
import {LinkedInPost, LinkedInService} from "../../../services/linkedin.service";

@Component({
  selector: 'linkedin-post-metrics',
  templateUrl: './linkedin-post-metrics.component.html',
  styleUrls: ['./linkedin-post-metrics.component.scss']
})
export class LinkedinPostMetricsComponent implements OnInit {

  @Input() post: LinkedInPost = this.linkedinService.new()

  protected readonly faComment = faComment
  protected readonly faHeart = faHeart

  constructor(private linkedinService: LinkedInService,
  ) {
  }

  ngOnInit() {
  }

}
