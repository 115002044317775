<div class="flex-row-left">
  <table mat-table [dataSource]="contactImportTable">

    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef>Source</th>
      <td mat-cell *matCellDef="let import; let i = index;">{{import.source | titlecase}}</td>
    </ng-container>

    <ng-container matColumnDef="emailAccount">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let import; let i = index;">{{import.userOauth?.email}}</td>
    </ng-container>

    <ng-container matColumnDef="csvFile">
      <th mat-header-cell *matHeaderCellDef>File</th>
      <td mat-cell *matCellDef="let import; let i = index;">
        <a href="{{import.importFile?.url}}">{{import.importFile?.name}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="contacts">
      <th mat-header-cell *matHeaderCellDef>Contacts</th>
      <td mat-cell *matCellDef="let import; let i = index;">{{getImportProgressLabel(import)}}</td>
    </ng-container>

    <ng-container matColumnDef="importDate">
      <th mat-header-cell *matHeaderCellDef>Last Import Date</th>
      <td mat-cell *matCellDef="let import; let i = index;">{{getImportDateLabel(import)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let import; let i = index; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="numDisplayedColumns">
        <div *ngIf="!loading" class="no-table-data">No contact imports</div>
        <div class="loading-overlay" *ngIf="loading">
          <div class="loading-wrapper">
            <mat-spinner color="accent"></mat-spinner>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
