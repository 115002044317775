import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {Audience, AudienceService, AudienceEditorMode, AudienceType} from "../../../services/audience.service";

@Component({
  selector: 'audience-list',
  templateUrl: './audience-list.component.html',
  styleUrls: ['./audience-list.component.scss']
})
export class AudienceListComponent implements OnInit {

  selectedAudience: Audience = this.audienceService.new()
  audienceEditorMode: string = AudienceEditorMode.singular
  AudienceType = AudienceType

  loading: boolean = true

  displayedColumns: string[] = ['label', 'shareRequestCount', 'createdAt']
  audienceTable: MatTableDataSource<Audience> = new MatTableDataSource()
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('audienceListSort') audienceListSort!: MatSort

  formatDate = FormatUtils.formatDate

  constructor(private userService: UserService,
              private audienceService: AudienceService,
  ) {
  }

  ngOnInit() {
    this.audienceService.list$.subscribe(audiences => {
      this.audienceTable = new MatTableDataSource(audiences)
      setTimeout(() => {
        if (this.paginator) {
          this.audienceTable.paginator = this.paginator
          this.audienceTable.paginator.pageSize = 25
        }
      })
      this.audienceTable.sort = this.audienceListSort

      const index = audiences.findIndex(a => a.id === this.selectedAudience.id)
      if (index < 0) {
        this.selectedAudience = this.audienceService.new()
      }

      this.loading = false
    })
    this.audienceService.getAll()
  }

  addAudience(){
    this.audienceService.save(this.audienceService.new()).subscribe(
      newAudience => {
        this.editAudience(newAudience)
      },
      err => {
        console.log(err)
      }
    )
  }

  editAudience(audience: Audience){
    this.selectedAudience = audience
  }

  getShareRequestCount(audience: Audience): number {
    return FormatUtils.mergeUniqueArrays(audience.sharerAudienceList, audience.prospectAudienceList).length
  }

}
