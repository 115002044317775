import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core'
import {OauthService, OauthAction, OauthType} from "../../../services/oauth.service";
import {faGoogle, faMicrosoft, faXTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {User, UserService} from "../../../services/user.service";

@Component({
  selector: 'oauth-all-button',
  templateUrl: './oauth-all-button.component.html',
  styleUrls: ['./oauth-all-button.component.scss']
})
export class OauthAllButtonComponent implements AfterViewInit {

  @Input({ required: true }) label: string = ''
  @Input() returnUrl: string = ''

  @Output() onAdd = new EventEmitter()

  faCaretDown = faCaretDown
  faGoogle = faGoogle
  faMicrosoft = faMicrosoft
  faXTwitter = faXTwitter
  faLinkedin = faLinkedin

  user: User = this.userService.new()

  OauthType = OauthType

  constructor(private oauthService: OauthService,
              private userService: UserService,
  ) {
  }

  ngAfterViewInit() {
    this.userService.user$.subscribe(user => this.user = user)
  }

  doOauth(type: string) {
    this.oauthService.doOauth(
      type,
      OauthAction.add,
      this.returnUrl === '' ? document.location.href : this.returnUrl,
    )
  }

}
