import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core'
import {OauthService, OauthAction, OauthType} from "../../../services/oauth.service";
import {faGoogle, faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import {faFileImport, faPlus, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {AddEditContactComponent} from "../add-edit-contact/add-edit-contact.component";
import {ContactListItem} from "../../../services/contact.service";
import {MatDialog} from "@angular/material/dialog";
import {ContactImportService} from "../../../services/contact-import.service";
import {User, UserService} from "../../../services/user.service";

@Component({
  selector: 'import-button',
  templateUrl: './import-button.component.html',
  styleUrls: ['./import-button.component.scss']
})
export class ImportButtonComponent implements AfterViewInit {

  @Input({ required: true }) label: string = ''
  @Input() returnUrl: string = ''

  @Output() onAdd = new EventEmitter()

  faCaretDown = faCaretDown
  faGoogle = faGoogle
  faMicrosoft = faMicrosoft
  faPlus = faPlus
  faFileImport = faFileImport

  user: User = this.userService.new()

  OauthType = OauthType

  constructor(private oauthService: OauthService,
              private contactImportService: ContactImportService,
              private userService: UserService,
              private dialog: MatDialog,
  ) {
  }

  ngAfterViewInit() {
    this.userService.user$.subscribe(user => this.user = user)
  }

  doOauth(type: string) {
    this.oauthService.doOauth(
      type,
      OauthAction.add,
      this.returnUrl === '' ? document.location.href : this.returnUrl,
    )
  }

  openContactDialog() {
    this.dialog.open(AddEditContactComponent, {
      data: undefined
    }).afterClosed().subscribe(
      (newContact: ContactListItem) => {
        if (newContact && this.onAdd) {
          this.onAdd.emit(newContact)
        }
      }
    )
  }

  getCsvImportUrl() {
    return this.contactImportService.getCsvImportUrl(this.user)
  }

}
