<div class="flex-col-top width-100">
  <div *ngIf="loading">
    <h1>Finding your intro request</h1>
    <mat-spinner color="accent" class="margin-center"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <h1>Thank you, the introduction has been made.</h1>
    <div class="vert-margin" *ngIf="!isAuthenticated">
      <div class="vert-margin">
        If you like, you can signup to Wrollo to more easily make intros and leverage your network.
      </div>
      <button mat-raised-button
              color="primary"
              (click)="goToSignup()"
      >Sign up</button>
    </div>
    <div class="vert-margin" *ngIf="isAuthenticated">
      <button mat-raised-button
              color="primary"
              (click)="goToDashboard()"
      >Done</button>
    </div>
  </div>
</div>
