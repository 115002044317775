import {Component, EventEmitter, Input, Output} from '@angular/core'

@Component({
  selector: 'contact-profile-nav',
  templateUrl: './contact-profile-nav.component.html',
  styleUrls: ['./contact-profile-nav.component.scss']
})
export class ContactProfileNavComponent {

  @Input({ required: true }) viewIndex: number = -1
  @Input({ required: true }) minItems: number = 0

  @Output() onNav = new EventEmitter()

  constructor(
  ) {
  }

  navigate(adj: number) {
    if (this.onNav) {
      this.onNav.emit(this.viewIndex + adj)
    }
  }

  close() {
    if (this.onNav) {
      this.onNav.emit(-1)
    }
  }

}
