import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {NotificationService} from "../../../services/utilities/notification.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {map, merge, mergeMap, Observable, startWith, Subject} from "rxjs";
import {FormControl} from "@angular/forms";
import {Team, TeamService} from "../../../services/team.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'admin-team-list',
  templateUrl: './admin-team-list.component.html',
  styleUrls: ['./admin-team-list.component.scss']
})
export class AdminTeamListComponent implements OnInit, AfterViewInit {

  loading: boolean = true

  allTeams: Team[] = []
  totalUsers: number = 0

  displayedColumns: string[] = ['name', 'numMembers', 'subscription', 'actions']
  numDisplayedColumns: number = this.displayedColumns.length
  teamTable: MatTableDataSource<Team> = new MatTableDataSource()
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('sort') sort!: MatSort

  searchTermFilter: string = ""
  searchTermFilterSubject: Subject<string> = new Subject<string>()
  searchTermFilter$: Observable<string> = this.searchTermFilterSubject.asObservable()
  searchTermControl: FormControl = new FormControl()

  formatPrice = FormatUtils.formatPrice
  formatDate = FormatUtils.formatDate

  constructor(private teamService: TeamService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
  ) {
  }

  ngOnInit() {}

  ngAfterViewInit() {

    this.teamTable = new MatTableDataSource<Team>()

    this.teamTable.sort = this.sort
    const sortState: Sort = {active: 'name', direction: 'asc'}
    this.sort.active = sortState.active
    this.sort.direction = sortState.direction
    this.sort.sortChange.emit(sortState)
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))

    this.teamTable.paginator = this.paginator
    this.teamTable.paginator.pageSize = 25

    merge(this.searchTermFilter$, this.teamTable.sort.sortChange, this.teamTable.paginator.page).pipe(
      startWith({}),
      mergeMap(
        () => {
          this.teamTable = new MatTableDataSource<Team>([])
          this.loading = true
          return this.teamService.getPage(
            this.searchTermFilter,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
          )
        }
      ),
      map(teamPagination => {
        if (!teamPagination) return []
        this.totalUsers = teamPagination.itemsReceived
        this.allTeams = teamPagination.items
        this.teamTable = new MatTableDataSource<Team>(this.allTeams)
        this.loading = false
        return this.allTeams
      })
    ).subscribe(users => {})
  }

  search() {
    this.searchTermFilter = this.searchTermControl.value
    this.searchTermFilterSubject.next(this.searchTermFilter)
  }

  confirmDeleteTeam(team: Team) {
    this.dialogService.confirmDialog(
      'Delete Team',
      'Are you sure you want to delete this team?'
    ).subscribe(result => {
      if (result) {
        this.teamService.delete(team).subscribe(
          results => {
            //this.getUsers()
            this.notificationService.success('Team has been deleted.')
          }
        )
      }
    })
  }

  formatShareRequestTypes(shareRequestTypes: string[]): string {
    return shareRequestTypes.map(
      s => {
        return s.replaceAll("_", " ")
      }
    ).join(", ")
  }

}
