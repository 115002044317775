import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Location} from "@angular/common";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {
  ShareRequest,
  ShareRequestInviteStatus,
  ShareRequestService,
  ShareRequestStatus,
  ShareRequestType,
  ShareRequestWizardStatus,
} from "../../../services/share-request.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {OnboardingStep, User, UserService} from "../../../services/user.service";
import {MessageTemplate, MessageTemplateService} from "../../../services/message-template.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {
  AddOauthToObject,
  OauthAction,
  OauthService,
  OauthType,
  UserOauthAccount
} from "../../../services/oauth.service";
import {Audience, AudienceEditorMode, AudienceService} from "../../../services/audience.service";
import {MatDialog} from "@angular/material/dialog";
import {AddEditContactComponent} from "../../contacts/add-edit-contact/add-edit-contact.component";
import {ContactListItem, ContactQuickSearchItem} from "../../../services/contact.service";
import {BillingService} from "../../../services/billing.service";
import {SharerInviteCampaignComponent} from "../sharer-invite-campaign/sharer-invite-campaign.component";
import {SharerEmailStatus, SharerStatus} from "../../../services/sharer.service";
import {combineLatest, Observable, Subject} from "rxjs";
import {ShareRequestSetupComponent} from "../share-request-setup/share-request-setup.component";
import {MatStepper} from "@angular/material/stepper";
import {FormatUtils} from "../../../services/utilities/format.utilities";

import {faEnvelope, faCaretDown, faHandshake} from '@fortawesome/free-solid-svg-icons';
import {faLinkedin, faXTwitter} from "@fortawesome/free-brands-svg-icons";

@Component({
  selector: 'sharer-detail',
  templateUrl: './share-request-detail.component.html',
  styleUrls: ['./share-request-detail.component.scss']
})
export class ShareRequestDetailComponent implements OnInit {

  faEnvelope = faEnvelope
  faXTwitter = faXTwitter
  faLinkedin = faLinkedin
  faHandshake = faHandshake
  faCaretDown = faCaretDown

  @ViewChild('sharerInviteCampaign') sharerInviteCampaign!: SharerInviteCampaignComponent
  @ViewChild('stepper') stepper!: MatStepper

  tabs: string[] = ['details', 'sharers', 'share', 'feed', 'analytics']
  selectedTab = new FormControl(0)

  user: User = this.userService.new()
  shareRequest: ShareRequest = this.shareRequestService.new()
  sharersSent: number = 0
  sharersTotal: number = 0
  ShareRequestStatus = ShareRequestStatus
  ShareRequestInviteStatus = ShareRequestInviteStatus
  ShareRequestWizardStatus = ShareRequestWizardStatus
  ShareRequestType = ShareRequestType
  SharerEmailStatus = SharerEmailStatus
  public wizardStatuses = [
    ShareRequestWizardStatus.settingsAdded as string,
    ShareRequestWizardStatus.detailsAdded as string,
    ShareRequestWizardStatus.shareOptionsSelected as string,
    ShareRequestWizardStatus.sharerMessageAdded as string,
    ShareRequestWizardStatus.sharersAdded as string,
  ]

  addOauthTo!: AddOauthToObject
  OauthType = OauthType
  OauthAction = OauthAction

  audiences: Audience[] = []
  emailAccounts: UserOauthAccount[] = []
  messageTemplates: MessageTemplate[] = []

  sharerCampaignToggle: boolean = false
  shareLink: string = ''

  audienceEditorMode: string = AudienceEditorMode.editShort

  loading: boolean = true
  addingSharers: boolean = false
  audiencesLoading: boolean = true
  showWizard: boolean = false
  firstTimeWizard: boolean = false
  showEmailCampaign: boolean = false

  stepperIndex: number = 0

  pluralize = FormatUtils.pluralize

  constructor(private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private shareRequestService: ShareRequestService,
              private userService: UserService,
              private billingService: BillingService,
              private audienceService: AudienceService,
              private messageTemplateService: MessageTemplateService,
              private oauthService: OauthService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.loading = true
    combineLatest([this.route.url, this.route.queryParams, this.userService.user$]).subscribe(
      ([urlSegments, queryParams, user]) => {
        if (queryParams && queryParams['tab']) {
          const index = this.tabs.findIndex(tab => {
            return tab == queryParams['tab']
          })
          if (index >= 0) this.setTab(index)
        }
        const shareRequestId: number = parseInt(urlSegments[1].toString())

        this.user = user
        if (this.loading) {
          this.firstTimeWizard = !this.userService.onboardingStepCompleted(this.user, OnboardingStep.createdShareRequest)
        }

        this.shareRequestService.detail$.subscribe((shareRequest: ShareRequest) => {
          this.shareRequest = shareRequest
          this.shareLink = window.location.origin + '/share-request-preview/' + this.shareRequest.shareLinkPlug
          this.addOauthTo = {
            type: 'share_request',
            field: 'email_oauth_id',
            id: this.shareRequest.id,
          }

          if (this.loading) {
            this.sharerCampaignToggle = this.shareRequest.inviteStatus === ShareRequestInviteStatus.sending
            this.addingSharers = this.shareRequest.inviteStatus === ShareRequestInviteStatus.waiting

            // Check if should activate network request after subscribe
            if (queryParams && queryParams['activate']) {
              // Value is 1 if just activate, 2 if activate and send sharer invites
              this.showWizard = false
              this.activate(queryParams['activate'] === "2")
              this.setTab(3)
            } else if (this.shareRequest.status === ShareRequestStatus.pending) {
              // Check if show wizard
              if (queryParams && queryParams['showWizard'] >= 0) {
                this.showWizard = true
                this.showEmailCampaign = queryParams['showWizard'] > 2
                this.stepperIndex = queryParams['showWizard']
              } else if (
                !this.userService.onboardingStepCompleted(this.user, OnboardingStep.createdShareRequest) ||
                this.shareRequest.lastWizardStep >= 0
              ) {
                this.showWizard = true
                this.initStepper()
              } else {
                this.showWizard = false
              }
            } else {
              this.showWizard = false
            }

          }

          // Set sharers count values
          if (this.shareRequest && this.shareRequest.sharers) {
            this.sharersTotal = this.shareRequest.sharers.length
            this.sharersSent = this.shareRequest.sharers.filter(
              s => {
                return s.status !== SharerStatus.pending
              }
            ).length
          }

          this.audienceService.list$.subscribe(
            audiences => {
              this.audiences = audiences
              if (this.audiencesLoading) {
                // If first network request, add default audiences to get user started
                this.checkAddDefaultProspectAudience()
              }
              this.audiencesLoading = false
            }
          )

          this.oauthService.userList$.subscribe((
            accounts: UserOauthAccount[]) => {
              this.emailAccounts = this.oauthService.filterEmailAccounts(accounts)

              // Default ouath account if not set
              if (
                this.shareRequest.emailOauthId <= 0 &&
                (
                  this.shareRequest.type === this.ShareRequestType.email ||
                  this.shareRequest.type === this.ShareRequestType.intro
                ) && this.emailAccounts.length === 1
              ) {
                  this.shareRequest.emailOauthId = this.emailAccounts[0].id
              }

              // Add signature tag if no message content and oauth set
              if (!this.firstTimeWizard &&
                  this.shareRequest.sharerMessageContent === ''
              ) {
                this.shareRequest.sharerMessageContent = "<br><br><#viewrequesturl#>"
                if (this.shareRequest.emailOauthId && this.shareRequest.emailOauthId > 0) {
                  const emailAccount = this.emailAccounts.find(
                    a => a.id === this.shareRequest.emailOauthId
                  )
                  if (emailAccount && emailAccount.signature !== '') {
                    this.shareRequest.sharerMessageContent += "<br><br><#signature#>"
                  }
                }
              }
            }
          )

          this.messageTemplateService.list$.subscribe(
            (messageTemplates: MessageTemplate[]) => {
              this.messageTemplates = messageTemplates
            }
          )

          if (this.loading) {
            this.audienceService.getAll()
            this.oauthService.getAllForUser()
            this.messageTemplateService.getAll()
          }
          this.loading = false

        })
        if (this.loading && shareRequestId > 0) {
          this.shareRequestService.getDetail(shareRequestId, true)
        }
      }
    )
    if (this.loading) {
      this.userService.getIdentity()
    }
  }

  stepIsCompleted(wizardStatus: string): boolean {
    const stepIndex = this.wizardStatuses.indexOf(wizardStatus)
    return this.shareRequest.lastWizardStep > stepIndex
  }

  initStepper() {
    // Get stepper to the earliest incomplete step
    this.wizardStatuses.forEach((stepStatus, stepIndex) => {
      if (this.stepIsCompleted(stepStatus)) {
        if (stepIndex > 3) {
          this.showEmailCampaign = true
        }
        this.stepperIndex = stepIndex + 1
      }
    })
    this.setWizardStep()
  }

  skipWizard() {
    this.shareRequest.lastWizardStep = -1
    this.shareRequestService.save(this.shareRequest, true).subscribe(
      shareRequest => {
        //this.router.navigate([''])
        this.showWizard = false
        this.setTab(2)
        this.setWizardStep()
        this.userService.save(this.user).subscribe()
      }
    )
  }

  updateUrl(queryParams: Params) {
    const url: string = this.router.createUrlTree(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      }
    ).toString()
    this.location.go(url)
  }

  setTab(index: any){
    this.selectedTab.setValue(index)
    this.updateUrl({ tab: this.tabs[index], showWizard: null, activate: null, })
    this.sharerInviteCampaign?.redrawEditor()
  }

  setWizardStep() {
    this.updateUrl({
      tab: null,
      showWizard: (this.showWizard && this.stepperIndex >= 0) ? this.stepperIndex : null,
      activate: null,
    })
  }

  completeStep(currentStep: string, goNext: boolean = true) {
    const currentStepIndex = this.wizardStatuses.indexOf(currentStep)
    let nextStepIndex = goNext
      ? currentStepIndex + 1
      : currentStepIndex - 1
    if (this.stepper && nextStepIndex >= this.stepper.steps.length) {
      nextStepIndex = -1
    }
    this.shareRequest.lastWizardStep = nextStepIndex
    this.shareRequestService.save(this.shareRequest, true).subscribe(
      shareRequest => {
        if (nextStepIndex >= 0) {
          this.stepperIndex = nextStepIndex
          this.setWizardStep()
        } else {
          this.skipWizard()
        }
      }
    )
  }

  settingsCompleted(): boolean {
    return this.shareRequest.name !== '' && this.shareRequest.type !== ''
  }

  completeSettings() {
    this.checkAddDefaultProspectAudience()
    this.checkAddDefaultProspectMessage()
    this.completeStep(ShareRequestWizardStatus.settingsAdded)
  }

  areDetailsCompleted(): boolean {
    return (
        (
          this.shareRequest.type === this.ShareRequestType.email ||
          this.shareRequest.type === this.ShareRequestType.intro
        ) &&
        this.shareRequest.prospectAudienceId !== 0 &&
        !!this.shareRequest.prospectAudience &&
        this.audienceService.hasAnyFilters(this.shareRequest.prospectAudience) &&
        (this.shareRequest.prospectMessageSubject !== '' || this.shareRequest.prospectMessageContent === '')
      ) ||
      (this.shareRequest.type === this.ShareRequestType.xPost && this.shareRequest.tweetId > 0) ||
      (this.shareRequest.type === this.ShareRequestType.linkedinPost && this.shareRequest.linkedinPostId > 0)
  }

  completeDetails() {
    if (this.shareRequest.type === ShareRequestType.email && this.shareRequest.prospectAudience) {
      this.audienceService.save(this.shareRequest.prospectAudience).subscribe(
        audienceSaved => {
          this.shareRequest.prospectAudience = audienceSaved
          this.completeStep(ShareRequestWizardStatus.detailsAdded)
        }
      )
    } else {
      this.completeStep(ShareRequestWizardStatus.detailsAdded)
    }
  }

  areShareOptionsCompleted() {
    return this.shareRequest.shareLinkEnabled ||
      this.showEmailCampaign ||
      (this.shareRequest.type === ShareRequestType.xPost && this.user.numActiveContactsTwitter > 0) ||
      (this.shareRequest.type === ShareRequestType.linkedinPost && this.user.numActiveContactsLinkedin > 0)
  }

  completeShareOptions() {
    this.checkAddDefaultSharerMessage()
    this.checkAddDefaultSharerAudience()
    this.checkAddDefaultInviteOauth()
    this.completeStep(ShareRequestWizardStatus.shareOptionsSelected)
  }

  completeSharerMessage() {
    if (!this.hasSharerInviteLink()) {
      this.dialogService.messageDialog(
        "Add View Request Link",
        "The message to your contacts always needs a link to view the network request, so we've added one for you.  Please review the message and click Send again."
      ).subscribe(
        () => {
          this.addSharerInviteLink()
        }
      )
    } else {
      this.completeStep(ShareRequestWizardStatus.sharerMessageAdded)
    }
  }

  canSelectSharerAudience(): boolean {
    return this.user.numContacts > 0 || this.user.numActiveContactImports > 0
  }

  areSharersAdded(): boolean {
    return !!this.shareRequest.sharers && this.shareRequest.sharers.length > 0
  }

  isSharerAudienceCompleted(): boolean {
    return this.shareRequest.sharerAudienceId > 0 &&
      !!this.shareRequest.sharerAudience &&
      this.audienceService.hasAnyFilters(this.shareRequest.sharerAudience)
  }

  isSharerMessageCompleted(): boolean {
    return this.shareRequest.emailOauthId > 0 &&
           (this.shareRequest.sharerMessageSubject !== '' || this.shareRequest.sharerMessageContent !== '')
  }

  completeSharerAudience(sendInvites: boolean, waitOnImport: boolean) {
    if (sendInvites) {
      if (this.confirmSend()) {
        this.completeStep(ShareRequestWizardStatus.sharersAdded)
      }
    } else if (waitOnImport) {
      this.shareRequest.sendAfterImport = true
      this.completeStep(ShareRequestWizardStatus.sharersAdded)
    } else {
      this.skipWizard()
    }
  }

  waitingOnImport(): boolean {
    return this.shareRequest.numSharers === 0 && this.shareRequest.inviteStatus === ShareRequestInviteStatus.waiting
  }

  checkAddDefaultProspectAudience() {
    if (this.shareRequest.prospectAudienceId <= 0 && this.audiences.length === 0 && this.shareRequest.type === ShareRequestType.email) {
      this.audienceService.createFirstProspectAudience().subscribe(
        prospectAudience => {
          this.shareRequest.prospectAudienceId = prospectAudience?.id || -1
          this.shareRequest.prospectAudience = prospectAudience
          this.shareRequestService.save(this.shareRequest, true)
        }
      )
    }
  }

  checkAddDefaultSharerAudience() {
    if (this.shareRequest.sharerAudienceId <= 0 && this.audiences.length <= 1 && this.showEmailCampaign) {
      this.audienceService.createFirstSharerAudience().subscribe(
        sharerAudience => {
          this.shareRequest.sharerAudienceId = sharerAudience?.id || -1
          this.shareRequest.sharerAudience = sharerAudience
          this.shareRequestService.save(this.shareRequest, true)
        }
      )
    }
  }

  checkAddDefaultInviteOauth() {
    if (
      this.showEmailCampaign &&
      this.shareRequest.id > 0 &&
      (!this.shareRequest.emailOauthId || this.shareRequest.emailOauthId <= 0) &&
      this.emailAccounts.length === 1
    ) {
      this.shareRequest.emailOauthId = this.emailAccounts[0].id
    }
  }

  checkAddDefaultProspectMessage() {
    if (this.firstTimeWizard &&
        this.shareRequest.type === ShareRequestType.email &&
        (this.shareRequest.prospectMessageSubject === '' || this.shareRequest.prospectMessageContent === '')
    ) {
      if (this.shareRequest.prospectMessageSubject === '') {
        this.shareRequest.prospectMessageSubject = this.shareRequestService.SAMPLE_EMAIL_MESSAGES.PROSPECT_SUBJECT
      }
      if (this.shareRequest.prospectMessageContent === '') {
        this.shareRequest.prospectMessageContent = this.shareRequestService.SAMPLE_EMAIL_MESSAGES.PROSPECT_CONTENT
      }
    }
  }

  checkAddDefaultSharerMessage() {
    if (this.firstTimeWizard &&
      (this.shareRequest.sharerMessageSubject === '' || this.shareRequest.sharerMessageContent === '')
    ) {
      if (this.shareRequest.sharerMessageSubject === '') {
        this.shareRequest.sharerMessageSubject = this.shareRequestService.SAMPLE_EMAIL_MESSAGES.SHARER_SUBJECT
      }
      if (this.shareRequest.sharerMessageContent === '') {
        this.shareRequest.sharerMessageContent = this.shareRequestService.SAMPLE_EMAIL_MESSAGES.SHARER_CONTENT
      }
    }
  }

  emailOauthChange(emailOauthId: number) {
    this.shareRequest.emailOauthId = emailOauthId
    this.save()
  }

  tweetChange(shareRequest: ShareRequest) {
    if (this.showWizard && shareRequest.tweet && shareRequest.tweetId > 0) {
      this.shareRequest.name = 'Repost: ' +
        FormatUtils.truncateString(shareRequest.tweet.postContent, 30)
    }
    this.save()
  }

  linkedinPostChange(shareRequest: ShareRequest) {
    if (this.showWizard && shareRequest.linkedinPost && shareRequest.linkedinPost.id > 0) {
      this.shareRequest.name = 'Like Post: ' +
        FormatUtils.truncateString(
          shareRequest.linkedinPost.title || shareRequest.linkedinPost.description,
          30
        )
    }
    this.save()
  }

  sharerAudienceChange(audienceId: number) {
    this.shareRequest.sharerAudienceId = audienceId
    this.save()
  }

  prospectAudienceChange(audienceId: number) {
    this.shareRequest.prospectAudienceId = audienceId
    this.save()
  }

  sharerMessageChange(messageEvent: any) {
    this.shareRequest.sharerMessageSubject = messageEvent.subject
    this.shareRequest.sharerMessageContent = messageEvent.content
    this.save()
  }

  prospectMessageChange(messageEvent: any) {
    this.shareRequest.prospectMessageSubject = messageEvent.subject
    this.shareRequest.prospectMessageContent = messageEvent.content
    if (this.showWizard) {
      this.shareRequest.name = 'Send Email: ' +
        FormatUtils.truncateString(this.shareRequest.prospectMessageSubject, 30)
    }
    this.save()
  }

  addSharers() {
    this.addingSharers = true
    const audience = this.audiences.find(s => {
      return s.id === this.shareRequest.sharerAudienceId
    })
    this.shareRequestService.addSharers(this.shareRequest, [], [], audience).subscribe(
      shareRequest => {
        if (shareRequest.inviteStatus !== ShareRequestInviteStatus.waiting) {
          this.addingSharers = false
          this.notificationService.success('Contacts added.')
        } else {
          this.dialogService.messageDialog(
            "Active Contact Import",
            "You have a contact import still in progress, so the matching contacts will be added after its completed, and you'll receive an email notification."
          )
        }
      },
      err => {
        this.addingSharers = false
        this.notificationService.error(err.message)
      }
    )
  }

  addActiveContacts(filterByType: boolean): Observable<boolean> {
    const resultSubject: Subject<boolean> = new Subject<boolean>()
    this.addingSharers = true
    this.shareRequestService.addActiveContactsAsSharers(this.shareRequest, filterByType).subscribe(
      shareRequest => {
        this.addingSharers = false
        this.notificationService.success('Contacts added.')
        resultSubject.next(true)
      },
      err => {
        this.addingSharers = false
        this.notificationService.error(err.message)
        resultSubject.error(err)
      }
    )
    return resultSubject.asObservable()
  }

  openEditDialog() {
    this.dialog.open(ShareRequestSetupComponent, {
      data: this.shareRequest,
    }).afterClosed().subscribe(
      (saved: boolean) => {
        if (saved) {
          this.notificationService.success('Network request saved.')
        }
      }
    )
  }

  confirmDelete() {
    this.dialogService.confirmDialog(
      "Delete Network Request",
      "Are you sure you want to delete this network request?"
    ).subscribe(
      result => {
        if (result) {
          this.shareRequestService.delete(this.shareRequest).subscribe(
            () => {
              this.notificationService.success('Network request deleted.')
              this.router.navigate(['share-requests'])
            }
          )
        }
      }
    )
  }

  openContactDialog() {
    this.dialog.open(AddEditContactComponent, {
      data: undefined
    }).afterClosed().subscribe(
      (newContact: ContactListItem) => {
        if (newContact) {
          this.finishContactManuallyAdded(newContact)
        }
      }
    )
  }

  finishContactManuallyAdded(contact: ContactListItem) {
    this.shareRequestService.addSharers(this.shareRequest, [contact.id], [contact.primaryEmail],undefined).subscribe(
      updatedShareRequest => {
        this.notificationService.success('Contact saved and added.')
      }
    )
  }

  addContactFromSearch(contact: ContactQuickSearchItem) {
    this.shareRequestService.addSharers(this.shareRequest, [contact.id], [contact.email], undefined).subscribe(
      updatedShareRequest => {
        this.notificationService.success('Contact added.')
      }
    )
  }

  save(message: string = 'Network request saved.') {
    this.shareRequestService.save(this.shareRequest, true).subscribe(
      shareRequest => {
        //this.notificationService.success(message)
      }
    )
  }

  toggleSharerCampaign() {
    if (this.shareRequest.inviteStatus === ShareRequestInviteStatus.sending) {
      this.pauseSharerCampaign()
    } else if (this.shareRequest.inviteStatus === ShareRequestInviteStatus.paused) {
      this.resumeSharerCampaign()
    } else {
      this.sendSharerCampaign()
    }
  }

  hasSharerInviteLink() {
    return this.shareRequest.sharerMessageContent.indexOf(this.shareRequestService.viewRequestLinkTag) >= 0
  }

  addSharerInviteLink() {
    const sigIndex = this.shareRequest.sharerMessageContent.indexOf("<p>" + this.shareRequestService.signatureTag)
    const insertIndex = (sigIndex >= 0) ? sigIndex : this.shareRequest.sharerMessageContent.length
    this.shareRequest.sharerMessageContent = this.shareRequest.sharerMessageContent.slice(0, insertIndex) +
      this.shareRequestService.viewRequestLinkHTML +
      this.shareRequest.sharerMessageContent.slice(insertIndex)
    this.save()
  }

  toggleShareLinkAccess() {
    this.shareRequest.shareLinkEnabled = !this.shareRequest.shareLinkEnabled
    this.save()
  }

  copyShareLink() {
    navigator.clipboard.writeText(this.shareLink)
    this.notificationService.success('Share link copied.')
  }

  previewShareLink() {
    window.open(this.shareLink + '?preview=1', '_blank');
  }

  editSharerCampaign() {
    this.dialog.open(SharerInviteCampaignComponent, {
      data: this.shareRequest,
      height: '800px',
      width: '700px',
    }).afterClosed().subscribe(send => {
      if (send) {
        this.confirmSend()
      }
    })
  }

  confirmSend(): boolean {
    if (!this.setupIsComplete()) {
      this.warnSetupIsComplete()
      return false
    } else if (!this.hasSharerInviteLink()) {
      this.dialogService.messageDialog(
        "Add View Request Link",
        "The message to your contacts always needs a link to view the network request, so we've added one for you.  Please review the message and click Send again."
      ).subscribe(
        () => {
          this.addSharerInviteLink()
        }
      )
      return false
    } else if (!this.billingService.hasActiveSubscriptionOrTrial(this.user)) {
      this.confirmSubscribe(true, true)
      return false
    } else if (!this.billingService.hasValidProductAccess(this.user, this.shareRequest.type)) {
      this.confirmSubscribe(false, true)
      return false
    } else {
      this.sendSharerCampaign()
      return true
    }
  }

  confirmSubscribe(newSubscription: boolean, sendInvites: boolean = false) {
    const title: string = newSubscription ? 'Subscribe to Wrollo' : 'Update Your Subscription'
    const message: string = newSubscription
      ? "You need an active subscription to activate this network request.  Don't worry you can cancel any time, and your contacts don't have to subscribe to help!"
      : "You need to update your subscription to activate this network request."
    const btnLabel: string = newSubscription ? "View Options" : "View Subscription"
    this.dialogService.confirmDialog(title, message, btnLabel, "Cancel",).subscribe(
      result => {
        if (result) {
          const extras: any = { queryParams: { returnUrl: FormatUtils.addQueryParam(window.location.href, "activate=" + (!sendInvites ? 1 : 2)) }}
          if (newSubscription) {
            extras.queryParams.type = this.shareRequest.type
          }
          this.router.navigate(['subscription'], extras)
        }
      }
    )
  }

  warnSetupIsComplete() {
    this.dialogService.messageDialog(
      "Complete Setup",
      "Please complete the setup of this network request before it can be activated."
    )
  }

  setupIsComplete(): boolean {
    return this.shareRequestService.setupIsComplete(this.shareRequest)
  }

  activate(sendInvites: boolean = false) {
    if (!this.setupIsComplete()) {
      this.warnSetupIsComplete()
    } else if (!this.billingService.hasActiveSubscriptionOrTrial(this.user)) {
      this.confirmSubscribe(true, sendInvites)
    } else if (!this.billingService.hasValidProductAccess(this.user, this.shareRequest.type)) {
      this.confirmSubscribe(false, sendInvites)
    } else {
      this.shareRequest.status = ShareRequestStatus.active
      if (sendInvites) {
        this.sendSharerCampaign()
      } else if (this.showWizard) {
        this.completeStep(ShareRequestWizardStatus.shareOptionsSelected)
      } else {
        this.save('Network request activated.')
      }
    }
  }

  deactivate() {
    this.shareRequest.status = ShareRequestStatus.inactive
    this.save('Network request deactivated.')
  }

  sendSharerCampaign() {
    this.shareRequest.status = ShareRequestStatus.active
    this.shareRequest.inviteStatus = ShareRequestInviteStatus.sending
    this.sharerCampaignToggle = true
    this.save('Started emailing contacts.')
  }

  pauseSharerCampaign() {
    this.shareRequest.inviteStatus = ShareRequestInviteStatus.paused
    this.sharerCampaignToggle = false
    this.save('Paused emailing contacts.')
  }

  resumeSharerCampaign() {
    this.shareRequest.status = ShareRequestStatus.active
    this.shareRequest.inviteStatus = ShareRequestInviteStatus.sending
    this.sharerCampaignToggle = true
    this.save('Resumed emailing contacts.')
  }

  hasSent(): boolean {
    return this.shareRequestService.hasSent(this.shareRequest)
  }

  canSend(): boolean {
    return this.shareRequestService.canSend(this.shareRequest)
  }

}
