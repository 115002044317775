import {Injectable} from '@angular/core';
import {Observable, Subject, of} from "rxjs";
import {CookieService} from 'ngx-cookie-service';
import {ApiService} from "../api.service";
import {JweService} from "./jwe.service";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";


export interface Registration{
  company: string
  firstName: string
  lastName :string
  email: string
  password : string
}

export enum MagicLinkType{
  forgetPassword = 'forget_password',
  emailVerification = 'email_verification',
  login = 'login',
  userInvite = 'user_invite',
  teamInvite = 'team_invite',
  sharerInvite = 'sharer_invite',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private localTokenName: string = 'wrollo.jwe'
  private tokenEndName: string = 'wrollo.jwe.end'

  private endpoint: string = 'auth'

  private routesToSignup: string[] = ['invite']

  constructor(private cookieService: CookieService,
              private api: ApiService,
              private jweService: JweService,
              public router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.isAuthenticated()) {
      const extras = state.url !== '/' ? { queryParams: { returnUrl: state.url }} : undefined
      const newRoute: string = this.getLoggedOutRoute(route)
      this.router.navigate([newRoute], extras)
      return false
    }
    return true
  }

  getLoggedOutRoute(route: ActivatedRouteSnapshot) {
    const page: string = route.url.length > 0 ? route.url[0].path : '/'
    return this.routesToSignup.indexOf(page) >= 0 ? '/signup' : '/login'
  }

  public isAuthenticated(): boolean {
    const rawToken = localStorage.getItem(this.localTokenName)
    const expDatetime = localStorage.getItem(this.tokenEndName)
    if (rawToken && expDatetime) {
      const curDate = Date.now()
      return curDate <= parseInt(expDatetime)
    } else {
      return false
    }
  }

  getValidLocalJWE(): string | null {
    const localJWE = this.jweService.getJWE()
    return (!localJWE || this.jweService.isTokenExpired(localJWE)) ? null : localJWE
  }

  login(username: string, password: string, jweSubject: Subject<string> = new Subject<string>()): Observable<string> {
    const validLocalJWE = this.getValidLocalJWE()
    if (!validLocalJWE) {
      const params = {email: username, password: password}
      this.api.post(this.endpoint + '/login', params).subscribe(
        (resultData: any) => {
          this.jweService.setJWE(resultData.auth_token, resultData.exp_datetime, jweSubject)
        },
        (err: any) => jweSubject.error(err.error)
      )
    } else {
      jweSubject.next(validLocalJWE)
    }
    return jweSubject.asObservable()
  }

  loginViaToken(token: string): Observable<string> {
    const resultSubject = new Subject<string>()
    if (!this.getValidLocalJWE()) {
      const params = {magic_token: token}
      this.api.post(this.endpoint + '/magic-login', params).subscribe(
        (resultData: any) => {
          this.jweService.setJWE(resultData.auth_token, resultData.exp_datetime)
          resultSubject.next(resultData.type)
        },
        (err: any) => resultSubject.error(err.error)
      )
    } else {
      resultSubject.next('')
    }
    return resultSubject.asObservable()
  }

  logout(){
    localStorage.clear()
    this.cookieService.deleteAll("/")
  }

  register(reg: Registration): Observable<string> {
    const params = {
      first_name: reg.firstName,
      last_name: reg.lastName,
      email: reg.email,
      password: reg.password,
    }
    const jweSubject: Subject<string> = new Subject<string>()
    this.api.post(this.endpoint + '/signup', params).subscribe(
      result => {
        this.login(params.email, params.password, jweSubject)
      }
    )
    return jweSubject.asObservable()
  }

  sendMagicLinkEmail(email: string, type: MagicLinkType): Subject<boolean> {
    const resultSubject = new Subject<boolean>()
    this.api.get(this.endpoint + '/request-magic-link', {email: email, type: type}).subscribe(
      (resultData: any) => {
        resultSubject.next(resultData)
      },
      (err: any) => resultSubject.error(err.error)
    )
    return resultSubject
  }

}
