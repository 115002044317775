<div>
  <mat-toolbar color="primary">
    <mat-toolbar-row class="nav-padding flex-row-space-between">
      <div class="flex-row-left">
        <img src="./assets/wrollo_logo_white.png" class="header-logo">
        <div class="header-title">WROLLO</div>
      </div>
      <div *ngIf="showNav" class="flex-row-left">
        <button mat-button
                [matMenuTriggerFor]="teamMenu"
                *ngIf="user.id > 0"
                fxHide.xs
                class="horiz-margin-1x"
        >
          {{user.currentTeamMember.team.name}}
          <fa-icon [icon]="faCaretDown" size="lg"></fa-icon>
        </button>
        <mat-menu #teamMenu="matMenu">
          <a mat-menu-item
             *ngFor="let team of filteredTeamList"
             (click)="selectTeam(team.id)"
          >{{team.name}}</a>
          <mat-divider *ngIf="filteredTeamList.length > 0"></mat-divider>
          <a mat-menu-item (click)="goTo('/team')" *ngIf="canViewTeam">
            {{canMutateTeam ? 'Manage' : 'View'}} {{user.currentTeamMember.team.name}}
          </a>
          <a mat-menu-item (click)="createNewTeam()">Create New Team</a>
        </mat-menu>

        <button mat-button aria-label="Menu" [matMenuTriggerFor]="menu">
          <div class="flex-row-left">
            <profile-avatar [user]="user" class="avatar-icon"></profile-avatar>
            <fa-icon [icon]="faCaretDown" size="lg"></fa-icon>
          </div>
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item fxShow fxHide.gt-xs (click)="goTo('/')">
            Dashboard
          </a>
          <a mat-menu-item fxShow fxHide.gt-xs (click)="goTo('/share-requests')">
            Network Requests
          </a>
          <a mat-menu-item fxShow fxHide.gt-xs (click)="goTo('/contacts')">
            Contacts
          </a>
          <a mat-menu-item fxShow fxHide.gt-xs (click)="goTo('/audiences')">
            Audiences
          </a>
          <a mat-menu-item fxShow fxHide.gt-xs (click)="goTo('/message-templates')">
            Message Templates
          </a>
          <a mat-menu-item fxShow fxHide.gt-xs (click)="goTo('/invites')">
            Invite
          </a>
          <a mat-menu-item fxShow fxHide.gt-xs (click)="goTo('/admin')" *ngIf="user.isSuperAdmin">
            Admin
          </a>
          <a mat-menu-item (click)="goTo('/settings')">
            <mat-icon class="menu-icon" fxHide.xs>settings</mat-icon>
            My Settings
          </a>
          <a mat-menu-item (click)="goTo('/team')" *ngIf="canViewTeam">
            <mat-icon class="menu-icon" fxHide.xs>people</mat-icon>
            My Team
          </a>
          <a mat-menu-item (click)="goToSubscribe()" *ngIf="canMutateSubcription">
            <mat-icon class="menu-icon" fxHide.xs>account_balance</mat-icon>
            My Subscription
          </a>
          <a mat-menu-item href="http://support.wrollo.com" target="_blank">
            <mat-icon class="menu-icon" fxHide.xs>support</mat-icon>
            Support
          </a>
          <a mat-menu-item fxShow fxHide.gt-xs [matMenuTriggerFor]="teamSwitchMenu">
            Switch Team
          </a>
          <a mat-menu-item (click)="logout()">
            <mat-icon class="menu-icon" fxHide.xs>logout</mat-icon>
            Logout
          </a>
        </mat-menu>
        <mat-menu #teamSwitchMenu="matMenu">
          <a mat-menu-item
             *ngFor="let team of filteredTeamList"
             (click)="selectTeam(team.id)"
          >{{team.name}}</a>
          <mat-divider></mat-divider>
          <a mat-menu-item (click)="goTo('/team')" *ngIf="canViewTeam">
            {{canMutateTeam ? 'Manage' : 'View'}} {{user.currentTeamMember.team.name}}
          </a>
          <a mat-menu-item (click)="createNewTeam()">Create New Team</a>
        </mat-menu>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-toolbar color="accent" class="sub-nav-row" *ngIf="showNav" fxHide.xs>
    <mat-toolbar-row class="sub-nav-padding">
      <div>
        <a href="/" class="nav-link">Dashboard</a>
        <a href="/share-requests" class="nav-link">Network Requests</a>
        <a href="/contacts" class="nav-link">Contacts</a>
        <a href="/audiences" class="nav-link">Audiences</a>
        <a href="/message-templates" class="nav-link">Message Templates</a>
        <a href="/invites" class="nav-link">Invite</a>
        <a href="/admin" class="nav-link" *ngIf="user.isSuperAdmin">Admin</a>
      </div>
      <div class="notification-bar pointer"
           *ngIf="showNav && !hasSubscription && canMutateSubcription && !onSubscribePage"
           (click)="goToSubscribe()"
      >
        Try Wrollo Professional to enlist your network to help you!
      </div>
      <div class="notification-bar pointer"
           *ngIf="showNav && subscription && hasTrial && canMutateSubcription && !onSubscribePage"
           (click)="goToSubscribe()"
      >
        Your Wrollo Professional trial ends on {{formatDate(subscription.trialEnd)}}
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
