import {
  Component,
  Inject, Input,
  OnInit, Optional,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {User, UserService} from "../../../services/user.service";
import {InviteService} from "../../../services/invite.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {NotificationService} from "../../../services/utilities/notification.service";
import {ContactQuickSearchItem, ContactService} from "../../../services/contact.service";
import {Observable} from "rxjs";
import {COMMA, ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";

export interface InviteModalConfig {
  isTeamInvite: boolean
  contacts: ContactQuickSearchItem[]
}

@Component({
  selector: 'invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit {

  @Input() isTeamInvite: boolean = false

  user!: User

  inviteLink: string = ''
  inviteLinkCopied: boolean = false
  showInviteLink: boolean = true

  noteControl = new FormControl()
  showNote: boolean = true
  invitesSending: boolean = false
  invitesSent: number = 0
  invitees: ContactQuickSearchItem[] = []

  newInviteeControl = new FormControl()
  contacts: Observable<ContactQuickSearchItem[]> = new Observable<ContactQuickSearchItem[]>()
  selectedContact!: ContactQuickSearchItem | null

  errMessage: string = ''

  pluralize = FormatUtils.pluralize

  constructor(@Optional() public dialogRef: MatDialogRef<InviteModalComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: InviteModalConfig,
              private userService: UserService,
              private contactService: ContactService,
              private inviteService: InviteService,
              private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.userService.getIdentity().subscribe(
      user => {
        this.user = user
        this.inviteLink = window.location.origin + '/invite/' + this.user.inviteLinkPlug
      }
    )
    if (!this.isModal()) {
      this.userService.getIdentity()
      this.showInviteLink = !this.isTeamInvite
    } else {
      this.isTeamInvite = this.data.isTeamInvite
      this.invitees = this.data.contacts.filter(c => c.email !== "")
      this.showInviteLink = !this.isTeamInvite && this.invitees.length === 0
    }
  }

  isModal() {
    return this.data !== null && typeof this.data != 'undefined'
  }

  invite() {
    this.invitesSending = true
    this.inviteService.add(
      this.invitees.map(c => c.email),
      this.noteControl.value,
      this.isTeamInvite ? this.user.currentTeamId : 0,
    ).subscribe(
      invites => {
        this.invitesSending = false
        this.invitesSent = invites.length
        if (!this.isModal()) {
          if (this.isTeamInvite) {
            this.inviteService.getAllTeamInvites(true)
          } else {
            this.inviteService.getAllPersonalInvites(true)
          }
          this.invitees = []
          this.notificationService.success(this.invitesSent + ' ' + this.pluralize('invite', this.invitesSent) + ' sent')
          setTimeout(
            () => {
              this.invitesSent = 0
            },
            3000
          )
        }
      }, err => { this.invitesSending = false }
    )
  }

  close() {
    this.dialogRef.close()
  }

  copyInviteLink() {
    navigator.clipboard.writeText(this.inviteLink)
    this.inviteLinkCopied = true
    setTimeout(
      () => { this.inviteLinkCopied = false },
      3000
    )
    if (!this.isModal()) {
      this.notificationService.success('Invite link copied.')
    }
  }

  getDisplayName(contact: ContactQuickSearchItem): string {
    const displayName = this.contactService.getDisplayName(contact)
    return displayName +
      ((!!contact.email && contact.email !== '' && displayName !== contact.email) ? ' <' + contact.email + '>' : '')
  }

  selectContact(contact: ContactQuickSearchItem) {
    this.invitees.push(contact)
    if (!this.isModal()) {
      this.notificationService.success('Contact added')
    }
  }

  addInvitee(event: MatChipInputEvent | null): void {
    const value = (event?.value || this.newInviteeControl.value || '').trim()
    const emails: string[] = value ? value.split(/[\s,;]+/) : []

    let errorCount = 0
    emails.forEach(e => {
      if (e) {
        if (FormatUtils.isValidEmail(e)) {
          this.invitees.push({
            id: -1,
            firstName: '',
            lastName: '',
            email: e
          })
        } else {
          errorCount++
        }
      }
    })
    this.invitees = FormatUtils.dedupeObjectArray(this.invitees, 'email')

    if (errorCount == 0) {
      this.clearInput()
    } else if (emails.length == 1) {
      this.notificationService.error('Not a valid email address.')
    } else if (emails.length > 1) {
      this.notificationService.error(errorCount + ' invalid email address' + (errorCount != 1 ? 'es' : '') + ' found.')
    }

    // Clear the input value
    if (event) {
      event.chipInput!.clear()
    }
  }

  deleteInvitee(contact: ContactQuickSearchItem) {
    this.invitees = FormatUtils.removeFromArray(this.invitees, contact, 'email')
    if (!this.isModal()) {
      this.notificationService.success('Contact removed')
    }
  }

  addInviteeKeyup(event: any) {
    if ([ENTER, COMMA, SEMICOLON].indexOf(event.keyCode) >= 0) {
      this.addInvitee(null)
    } else {
      this.contacts = this.contactService.quickSearch(this.newInviteeControl.value)
    }
  }

  clearInput() {
    this.newInviteeControl.setValue(null)
    const inputElement = document?.getElementById('newValueInput')
    if (inputElement) (inputElement as HTMLInputElement).value = ''
  }

  checkClearInput() {
    setTimeout(() => {
      if (
        !this.selectedContact ||
        this.selectedContact.id !== this.newInviteeControl.value.id
      ) {
        this.clearInput()
        this.selectedContact = null
      }
    }, 500)
  }

}
