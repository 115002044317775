import {Component, OnInit, ViewChild} from '@angular/core';
import {User, UserService} from "../../../services/user.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {FormControl} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {Invite, InviteService} from "../../../services/invite.service";

@Component({
  selector: 'personal-invites',
  templateUrl: './personal-invites.component.html',
  styleUrls: ['./personal-invites.component.scss']
})
export class PersonalInvitesComponent implements OnInit {

  loading: boolean = true

  user!: User
  invites: Invite[] = []

  tabs = ['new', 'list']
  selectedTab = new FormControl(0)

  formatDate = FormatUtils.formatDate

  constructor(private userService: UserService,
              private inviteService: InviteService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
  ) {
  }

  ngOnInit() {
    this.userService.user$.subscribe(
      user => {
        this.user = user
      }
    )
    this.userService.getIdentity()

    this.inviteService.personalList$.subscribe(
      (invites: Invite[]) => {
        this.invites = invites
      }
    )
    this.inviteService.getAllPersonalInvites()

    this.route.queryParams.subscribe(params => {
      if (!!params) {
        const index = this.tabs.findIndex(tab => { return tab == params['tab'] })
        if (index >= 0) this.setTab(index)
      }
    })
  }

  setTab(index: any){
    this.selectedTab.setValue(index)
    const url: string = this.router.createUrlTree(
      [],
      {
        relativeTo: this.route,
        queryParams: { tab: this.tabs[index] },
        queryParamsHandling: 'merge'
      }
    ).toString()
    this.location.go(url)
  }

}
