import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core'
import {UserService} from "../../../services/user.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {ContactImportService} from "../../../services/contact-import.service";

@Component({
  selector: 'csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CSVImportComponent {

  @Input() label: string = 'Import File'
  @Input() shareRequestId: number = 0
  @Input() shareRequestSharerId: number = 0

  @Output() onInit = new EventEmitter()
  @Output() onFinish = new EventEmitter()

  disabled: boolean = false

  csvImportLicenseKey: string = ''
  csvImportUser = {
    user_id: 0,
    email: '',
    share_request_id: 0,
    share_request_sharer_id: 0,
  }

  constructor(private userService: UserService,
              private contactImportService: ContactImportService,
              private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.csvImportLicenseKey = this.contactImportService.getCsvImportLicenseKey()
    this.userService.user$.subscribe(
      user => {
        this.csvImportUser = {
          user_id: user.id,
          email: user.email,
          share_request_id: this.shareRequestId,
          share_request_sharer_id: this.shareRequestSharerId,
        }
      }
    )
  }

  emitFinish() {
    if (this.onFinish) {
      this.onFinish.emit()
    }
    this.disabled = false
  }

  csvImported(result: boolean, data: any) {
    if (result) {
      this.notificationService.success(data.row_success.toLocaleString('en') + ' ' + FormatUtils.pluralize('contact', data.row_success) + ' imported.')
    } else {
      this.notificationService.error("There was a problem importing the contacts.")
    }
    this.emitFinish()
  }

}
