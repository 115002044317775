import {Injectable} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {DialogService} from "./utilities/dialog.service";
import {OauthAction, OauthService} from "./oauth.service";
import {OpenGraphPreview} from "./opengraph.service";

export interface XPost {
  id: number
  xId: number
  postContent: string
  numReplies: number
  numRetweets: number
  numLikes: number
  numViews: number
  createdAt?: number
  modifiedAt?: number
}

export interface XPostResult {
  id: number
  x_id: number
  post_content: string
  num_replies: number
  num_retweets: number
  num_likes: number
  num_views: number
  created_at?: number
  modified_at?: number
}

export interface RawXPostMetrics {
  retweetCount: number
  replyCount: number
  likeCount: number
  quoteCount: number
  impressionCount: number
}

export interface RawXPostMetricsResult {
  retweet_count: number
  reply_count: number
  like_count: number
  quote_count: number
  impression_count: number
}

export interface RawXPost {
  id: string
  text: string
  createdAt: number
  publicMetrics: RawXPostMetrics
  og: OpenGraphPreview
}

export interface RawXPostResult {
  id: string
  text: string
  created_at: number
  public_metrics: RawXPostMetricsResult
  og: OpenGraphPreview
}

@Injectable({
  providedIn: 'root'
})
export class XTwitterService {
  public list!: XPost[]
  private listSubject: Subject<XPost[]> = new Subject<XPost[]>()
  list$: Observable<XPost[]> = this.listSubject.asObservable()

  private endpoint: string = 'x_posts'

  constructor(private api: ApiService,
              private dialogService: DialogService,
              private oauthService: OauthService,
  ) {
  }

  getAll(userOauthId: number, forceGet: boolean = false): Observable<XPost[]> {
    if (userOauthId === 0 || (this.list && !forceGet)) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<XPost[]>()
      this.api.get<XPost[]>('user_oauths/' + userOauthId + '/' + this.endpoint).subscribe(
        (results: XPost[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        },
        err => {
          if (err.status === 403) {
            this.askReauthenticateAccount()
          }
        }
      )
      return resultSubject.asObservable()
    }
  }

  askReauthenticateAccount() {
    this.dialogService.confirmDialog(
      "Re-authorize your X account",
      "It appears Wrollo has lost access do your X account, would you like to re-authenticate it?"
    ).subscribe(
      result => {
        if (result) {
          this.oauthService.doOauthTwitter(OauthAction.add, document.location.href)
        }
      }
    )
  }

  post(shareRequestId: number, userOauthId: number, xPost: string): Observable<XPost> {
    const resultSubject = new Subject<XPost>()
    this.api.post<XPost>(this.endpoint, {
      share_request_id: shareRequestId,
      user_oauth_id: userOauthId,
      x_post: xPost,
    }).subscribe(
      (result: XPost) => {
        resultSubject.next(FormatUtils.snakeToCamelCaseKeys(result))
      },
        err => {
        resultSubject.error(err)
      }
    )
    return resultSubject.asObservable()
  }

  formatHashtagsLinks(postContent: string): string {
    return postContent
      .replace('\n', '<br><br>')
      .replace(/(https?:\/\/[a-zA-Z0-9.\\\/?=&]*)/g, "<a href='" + "$1" + "' style='color: #35ab52;'>" + "$1" + "</a>")
      .replace(/#(\w+)/g, '<a href="https://twitter.com/hashtag/$1" style="color: #35ab52;">#$1</a>')
  }

  new(): XPost {
    return {
      id: 0,
      xId: 0,
      postContent: '',
      numReplies: 0,
      numRetweets: 0,
      numLikes: 0,
      numViews: 0,
    }
  }

}
