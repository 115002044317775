<div class="dialog-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile>
      Update Preferences for {{displayName}}
    </h2>
    <div class="loading-overlay" *ngIf="loading">
      <div class="loading-wrapper">
        <mat-spinner color="accent"></mat-spinner>
      </div>
    </div>
    <div *ngIf="!loading">
      <!--
      <mat-card *ngIf="autoCreateFilters.length > 0" class="vert-margin">
        <mat-card-content>
          <div>
            <div>
              {{displayName}} is limiting his network requests to only content that contains these keywords.
            </div>
            <div>
              {{autoCreateFilters.join(', ')}}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      -->
      <mat-card class="vert-margin-2x" *ngIf="twitterAccounts.length === 0 && linkedinAccounts.length === 0">
        <mat-card-content>
          <div>
            You do not have any X nor LinkedIn accounts integrated yet.
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="vert-margin" *ngIf="twitterAccounts.length > 0">
        <mat-card-content>
          <div>
            <auto-help-settings [type]="ShareRequestType.xPost"
                                [profileName]="displayName"
                                [accounts]="twitterAccounts"
                                [autoCompleteOauthId]="contact.autoCompleteTwitterOauthId"
                                [autoCompleteShareRequests]="contact.autoCompleteTweetRequests"
                                [autoCompleteFilters]="contact.autoCompleteTweetFilters"
                                (onOauthChange)="autoCompleteTwitterOauthChange($event)"
                                (onSettingChange)="autoCompleteTweetChange()"
                                (onFiltersChange)="autoCompleteTweetFiltersChange($event)"
            ></auto-help-settings>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="vert-margin-2x" *ngIf="linkedinAccounts.length > 0">
        <mat-card-content>
          <div>
            <auto-help-settings [type]="ShareRequestType.linkedinPost"
                                [profileName]="displayName"
                                [accounts]="linkedinAccounts"
                                [autoCompleteOauthId]="contact.autoCompleteLinkedinOauthId"
                                [autoCompleteShareRequests]="contact.autoCompleteLinkedinPostRequests"
                                [autoCompleteFilters]="contact.autoCompleteLinkedinPostFilters"
                                (onOauthChange)="autoCompleteLinkedinOauthChange($event)"
                                (onSettingChange)="autoCompleteLinkedinPostChange()"
                                (onFiltersChange)="autoCompleteLinkedinPostFiltersChange($event)"
            ></auto-help-settings>
          </div>
        </mat-card-content>
      </mat-card>
      <div class="vert-margin">{{errMessage}}</div>
      <div mat-dialog-actions class="action-row">
        <button mat-raised-button
                color="primary"
                mat-dialog-close
                (click)="close(contact)"
                class="action-button"
                [disabled]="twitterAccounts.length === 0 && linkedinAccounts.length === 0"
        >Save</button>
        <button mat-raised-button
                mat-dialog-close
                (click)="close()"
                class="action-button"
        >Cancel</button>
      </div>
    </div>
  </div>
</div>
