import {ContactService, ContactSummaryValue} from "../../../services/contact.service";
import {Component, OnInit} from "@angular/core";
import {AudienceService} from "../../../services/audience.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

export interface ContactChartData {
  value: string,
  count: number,
}

@Component({
  selector: 'contact-analytics',
  templateUrl: './contact-analytics.component.html',
  styleUrls: ['./contact-analytics.component.scss']
})
export class ContactAnalyticsComponent implements OnInit {

  loading: boolean = true

  companySizeData: ContactChartData[] = []
  companyRevenueData: ContactChartData[] = []
  companyIndustryData: ContactChartData[] = []
  companyTypeData: ContactChartData[] = []
  seniorityData: ContactChartData[] = []
  bizFunctionData: ContactChartData[] = []

  private industryLimit: number = 14

  constructor(private contactService: ContactService,
              private audienceService: AudienceService,
  ) {
  }

  ngOnInit() {
    this.contactService.summary$.subscribe(
      summary => {
        const firstIndustryValue: ContactSummaryValue = summary.industry.shift() || {value: '', count: 0}
        const lastIndustryValue: ContactSummaryValue = summary.industry.pop() || {value: '', count: 0}
        const industriesSorted = FormatUtils.sortArrayByAttr(summary.industry, 'count', false)
        if (industriesSorted.length >= this.industryLimit) {
          industriesSorted.length = this.industryLimit
          // industriesSorted.splice(this.industryLimit, industriesSorted.length - this.industryLimit - 1)
        }
        this.audienceService.getIndustryLabels(
          industriesSorted.map(i => { return i.value })
        ).subscribe(
          industryLabels => {
            this.companySizeData = this.contactService.updateDataForChart(
              'companySize', 'No Company', 'No Company Size',
              this.audienceService.companySizeOptions
            )
            this.companyRevenueData = this.contactService.updateDataForChart(
              'revenue', 'No Company', 'No Company Revenue',
              this.audienceService.companyRevenueOptions
            )
            this.contactService.summary.industry.unshift(firstIndustryValue)
            this.contactService.summary.industry.push(lastIndustryValue)
            this.companyIndustryData = this.contactService.updateDataForChart(
              'industry', 'No Company', 'No Company Industry',
              industryLabels
            )
            this.companyTypeData = this.contactService.updateDataForChart(
              'companyType', 'No Company', 'No Company Revenue',
              this.audienceService.companyTypeOptions
            )
            this.seniorityData = this.contactService.updateDataForChart(
              'seniority', 'No Title', 'No Seniority',
              this.audienceService.seniorityOptions
            )
            this.bizFunctionData = this.contactService.updateDataForChart(
              'businessFunction', 'No Title', 'No Biz Function',
              this.audienceService.bizFunctionOptions
            )
            this.loading = false
          }
        )
      }
    )
  }

}
