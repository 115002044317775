<mat-card *ngIf="post" class="vert-margin">
  <mat-card-content>
    <div class="width-100 flex-row-left-top vert-margin">
      <div class="width-50 flex-row-left-top">
        <div class="account-name">{{accountName}}</div>
      </div>
      <div class="width-50 flex-col-right">
        <fa-icon [icon]="faLinkedin" class="horiz-margin"></fa-icon>
      </div>
    </div>
    <div class="post-content" [innerHtml]="formatHashtagsLinks(post.text)"></div>
    <div class="og-container" *ngIf="post.og">
      <opengraph-display [og]="post.og"></opengraph-display>
    </div>
    <div class="timestamp">
      {{post.time}}
    </div>
    <div class="width-100 flex-row-left icon-row">
      <div class="icon-group">
        <fa-icon [icon]="faHeart" class="x-icon heart-icon"></fa-icon><span class="icon-label">Like</span>
      </div>
      <div class="icon-group">
        <fa-icon [icon]="faComment" class="x-icon reply-icon"></fa-icon><span class="icon-label">Comment</span>
      </div>
      <div class="icon-group">
        <fa-icon [icon]="faRetweet" class="x-icon"></fa-icon><span class="icon-label">Repost</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
