<div class="flex-row-space-between" ngClass.xs="flex-wrap">
  <h2>Contacts</h2>
  <!--
  <button mat-flat-button
          (click)="drawer.toggle()"
          class="action-button"
  >{{drawer.opened ? 'Hide' : 'Show'}} Filters</button>
  -->
  <div class="flex-row-left">
    <mat-spinner *ngIf="!loading && importing"
                 color="accent" [diameter]="24"
                 ngClass.gt-xs="horiz-margin-1x"
                 ngClass.xs="horiz-margin"
                 matTooltip="You have an ongoing contact import.  We'll email you when it's done."
    ></mat-spinner>

    <import-button label="Add Contacts" (onAdd)="filterContacts()"></import-button>

  </div>
</div>

<div class="contact-list-container">
  <mat-drawer-container class="width-100">
    <mat-drawer #drawer mode="side" autosize opened fixedInViewport="true" fxHide.xs>
      <audience-editor [editAudience]="filters"
                       (onFilterChange)="filterContacts($event)"
                       [mode]="audienceEditorMode"
                       #audienceEditor
      ></audience-editor>
    </mat-drawer>

    <div>
      <mat-tab-group [selectedIndex]="selectedTab.value"
                     (selectedIndexChange)="setTab($event)"
      >
        <mat-tab label="My Contacts ({{filteredCount | number}})">
          <div class="list-tab-container">
            <mat-drawer-container class="width-100">
              <mat-drawer #contactDrawer mode="over" position="end" [opened]="viewProfileIndex >= 0" fixedInViewport="true">
                <contact-profile-nav [viewIndex]="viewProfileIndex"
                                     [minItems]="minItems"
                                     (onNav)="viewContact($event)"
                ></contact-profile-nav>
                <contact-profile [contactId]="contactTable.filteredData[viewProfileIndex] ? contactTable.filteredData[viewProfileIndex].id : -1"
                                 (onDelete)="contactDeletedFromDetail()"
                ></contact-profile>
              </mat-drawer>
              <!--
              <network-summary [filters]="filters" [showTable]="true" (onFinish)="setNetworkCount($event)"></network-summary>
              -->
              <mat-card>
                <mat-card-content>

                  <h3>
                    My Contacts
                    <span *ngIf="totalCount > 0" class="contact-count">
                      ({{filteredCount !== totalCount ? filteredCount.toLocaleString('en') + ' / ' : ''}}{{(totalCount | number) + ' ' + pluralize('Contact', totalCount)}})
                    </span>
                  </h3>
                  <table mat-table [dataSource]="contactTable" matSort #sort="matSort" class="width-100">
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef class="thin-col">
                        <mat-checkbox (change)="$event ? selectAll() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                        ></mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row" class="thin-col">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null"
                                      [checked]="selection.isSelected(row)"
                        ></mat-checkbox>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell
                          *matHeaderCellDef
                          class="thin-col"
                      >
                        <button mat-icon-button
                                [matMenuTriggerFor]="contactBulkActionsMenu"
                                [disabled]="selection.selected.length === 0"
                                matTooltip="Select some or all contacts to act on"
                        >
                          <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #contactBulkActionsMenu="matMenu">
                          <button mat-menu-item *ngIf="shareRequests.length > 0" [matMenuTriggerFor]="addSelectedToShareRequestMenu">
                            Add to Network Request
                          </button>
                          <button mat-menu-item *ngIf="shareRequests.length > 0" [matMenuTriggerFor]="removeSelectedFromShareRequestMenu">
                            Remove from Network Request
                          </button>
                          <button mat-menu-item [matMenuTriggerFor]="addSelectedToAudienceMenu">
                            Add to Audience
                          </button>
                          <button mat-menu-item [matMenuTriggerFor]="removeSelectedFromAudienceMenu">
                            Remove from Audience
                          </button>
                          <button mat-menu-item (click)="confirmInviteSelectedContacts(false)">
                            Invite
                          </button>
                          <button mat-menu-item (click)="confirmInviteSelectedContacts(true)">
                            Add to Team
                          </button>
                          <button mat-menu-item (click)="removeSelectedContacts()">
                            Delete
                          </button>
                        </mat-menu>
                        <mat-menu #addSelectedToShareRequestMenu="matMenu">
                          <button mat-menu-item *ngFor="let shareRequest of shareRequests" (click)="addSelectedToShareRequest(shareRequest)">
                            {{shareRequest.name}}
                          </button>
                        </mat-menu>
                        <mat-menu #removeSelectedFromShareRequestMenu="matMenu">
                          <button mat-menu-item *ngFor="let shareRequest of shareRequests" (click)="removeSelectedFromShareRequest(shareRequest)">
                            {{shareRequest.name}}
                          </button>
                        </mat-menu>
                        <mat-menu #addSelectedToAudienceMenu="matMenu">
                          <button mat-menu-item (click)="addContactsToAudience(selection.selected)">New</button>
                          <button mat-menu-item *ngFor="let audience of audiences" (click)="addContactsToAudience(selection.selected, audience)">
                            {{audience.label}}
                          </button>
                        </mat-menu>
                        <mat-menu #removeSelectedFromAudienceMenu="matMenu">
                          <button mat-menu-item *ngFor="let audience of audiences" (click)="removeContactsFromAudience(selection.selected, audience)">
                            {{audience.label}}
                          </button>
                        </mat-menu>
                      </th>
                      <td mat-cell *matCellDef="let contact; let i = index;" class="thin-col">
                        <button mat-icon-button
                                [matMenuTriggerFor]="contactActionsMenu"
                        >
                          <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #contactActionsMenu="matMenu">
                          <button mat-menu-item *ngIf="viewProfileIndex != i" (click)="viewContact(i)">
                            View Contact
                          </button>
                          <button mat-menu-item *ngIf="contact.connection === 2" (click)="openIntroDialog(contact)">
                            Get an Intro
                          </button>
                          <button mat-menu-item *ngIf="contact.connection === 1 && shareRequests.length > 0" [matMenuTriggerFor]="addToShareRequestsMenu">
                            Add to Network Request
                          </button>
                          <button mat-menu-item *ngIf="contact.connection === 1 && shareRequests.length > 0" [matMenuTriggerFor]="removeFromShareRequestMenu">
                            Remove from Network Request
                          </button>
                          <button mat-menu-item [matMenuTriggerFor]="addToAudienceMenu">
                            Add to Audience
                          </button>
                          <button mat-menu-item [matMenuTriggerFor]="removeFromAudienceMenu">
                            Remove from Audience
                          </button>
                          <button mat-menu-item *ngIf="contact.connection === 1" (click)="inviteContacts(false, [contact])">
                            Invite
                          </button>
                          <button mat-menu-item *ngIf="contact.connection === 1" (click)="inviteContacts(true, [contact])">
                            Add to Team
                          </button>
                          <button mat-menu-item *ngIf="contact.connection === 1" (click)="confirmDeleteContact(contact)">
                            Delete
                          </button>
                        </mat-menu>
                        <mat-menu #addToShareRequestsMenu="matMenu">
                          <button mat-menu-item *ngFor="let shareRequest of shareRequests" (click)="addContactsToShareRequest(shareRequest, [contact])">
                            {{shareRequest.name}}
                          </button>
                        </mat-menu>
                        <mat-menu #removeFromShareRequestMenu="matMenu">
                          <button mat-menu-item *ngFor="let shareRequest of shareRequests" (click)="removeContactsFromShareRequest(shareRequest, [contact])">
                            {{shareRequest.name}}
                          </button>
                        </mat-menu>
                        <mat-menu #addToAudienceMenu="matMenu">
                          <button mat-menu-item (click)="addContactsToAudience([contact])">New</button>
                          <button mat-menu-item *ngFor="let audience of audiences" (click)="addContactsToAudience([contact], audience)">
                            {{audience.label}}
                          </button>
                        </mat-menu>
                        <mat-menu #removeFromAudienceMenu="matMenu">
                          <button mat-menu-item *ngFor="let audience of audiences" (click)="removeContactsFromAudience([contact], audience)">
                            {{audience.label}}
                          </button>
                        </mat-menu>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="avatar">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                      <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                        <profile-avatar [contact]="contact"></profile-avatar>
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="fullName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                      <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                        {{contact.fullName}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                      <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                        <short-label [label]="contact.title"></short-label>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="companyName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                      <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                        <short-label [label]="contact.companyName"></short-label>
                      </td>
                    </ng-container>

                    <!--
                    <ng-container matColumnDef="emailAddresses">
                      <th mat-header-cell *matHeaderCellDef>Email Addresses</th>
                      <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                        <div *ngFor="let email of contact.emailAddresses">
                          <span [ngClass]="{'alt-email': email.email !== contact.primaryEmail}">{{email.email}}</span><br>
                        </div>
                      </td>
                    </ng-container>
                    -->

                    <ng-container matColumnDef="type">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                      <td mat-cell *matCellDef="let contact; let i = index;" (click)="viewContact(i)" class="pointer">
                        {{contact.contactType | titlecase}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="relationship_strength">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="connection-col">Connection</th>
                      <td mat-cell *matCellDef="let contact; let i = index;">
                        <div class="flex-row-left">
                          <div class="connection-label" (click)="viewContact(i)">
                            {{contact.connection === 1 ? '1st' : '2nd'}}
                          </div>
                          <relationship-strength [contact]="contact"
                                                 class="connection-label"
                          ></relationship-strength>
                          <div class="connection-btn">
                            <button mat-raised-button
                                    *ngIf="contact.connection === 1 && contact.contactType === 'contact'"
                                    class="connection-btn"
                                    (click)="inviteContacts(false, [contact])"
                            >Invite</button>
                            <button mat-raised-button
                                    *ngIf="contact.connection === 2 && contact.contactType === 'contact'"
                                    class="connection-btn"
                                    (click)="openIntroDialog(contact)"
                            >Get Intro</button>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row
                        *matRowDef="let contact; let i = index; columns: displayedColumns;"
                    ></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" [colSpan]="numDisplayColumns">
                        <div *ngIf="!loading" class="no-table-data">No contacts</div>
                        <div class="loading-overlay" *ngIf="loading">
                          <div class="loading-wrapper">
                            <mat-spinner color="accent"></mat-spinner>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>

                  <mat-paginator #paginator
                                 [length]="filteredCount"
                                 [pageSizeOptions]="[25, 50, 100]"
                                 showFirstLastButtons
                  ></mat-paginator>
                </mat-card-content>
              </mat-card>
            </mat-drawer-container>
          </div>
        </mat-tab>

        <mat-tab label="My Network ({{filteredNetworkCount | number}})">
          <div class="list-tab-container">
            <network-summary [filters]="filters"
                             [showTable]="true"
                             (onFinish)="setNetworkCount($event)"
                             (onDelete)="filterContacts($event)"
            ></network-summary>
          </div>
        </mat-tab>

        <mat-tab label="Analytics">
          <div class="tab-container">
            <contact-analytics></contact-analytics>
          </div>
        </mat-tab>
      </mat-tab-group>

    </div>

  </mat-drawer-container>

</div>
