import {Component, Input, OnInit} from '@angular/core';
import {faComment, faHeart, faRetweet} from "@fortawesome/free-solid-svg-icons";
import {XPost, XTwitterService} from "../../../services/x-twitter.service";

@Component({
  selector: 'x-post-metrics',
  templateUrl: './x-post-metrics.component.html',
  styleUrls: ['./x-post-metrics.component.scss']
})
export class XPostMetricsComponent implements OnInit {

  @Input() post: XPost = this.xTwitterService.new()

  constructor(private xTwitterService: XTwitterService,
  ) {
  }

  ngOnInit() {

  }

  protected readonly faComment = faComment;
  protected readonly faRetweet = faRetweet;
  protected readonly faHeart = faHeart;
}
