<div class="top-container flex-row-space-between-top flex-wrap" *ngIf="!showWizard">
  <div class="flex-row-left flex-wrap">
    <h2>{{loading ? '' : shareRequest.name}}</h2>
    <button mat-mini-fab
            [matMenuTriggerFor]="actionsMenu"
            class="edit-btn white-btn"
            *ngIf="!loading"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #actionsMenu="matMenu">
      <button mat-menu-item (click)="openEditDialog()">
        Edit
      </button>
      <button mat-menu-item
              (click)="activate()"
              [disabled]="!setupIsComplete()"
              *ngIf="!loading && shareRequest.status !== ShareRequestStatus.active"
              fxHide.gt-xs
      >
        Activate
      </button>
      <button mat-menu-item (click)="deactivate()" *ngIf="shareRequest.status === ShareRequestStatus.active">
        Deactivate
      </button>
      <button mat-menu-item (click)="confirmDelete()" *ngIf="shareRequest.status === ShareRequestStatus.pending">
        Delete
      </button>
    </mat-menu>
    <span *ngIf="!loading && shareRequest.status !== ShareRequestStatus.active"
          [matTooltip]="setupIsComplete() ? 'Activate network request and ask your network to help.' : 'Please finish setting up this network request before you activate it.'"
          class="edit-btn-container"
          fxHide.xs
    >
      <button mat-flat-button
              color="primary"
              (click)="activate()"
              class="horiz-margin-1x edit-btn"
              [disabled]="!setupIsComplete()"
      >Activate</button>
    </span>
  </div>

  <div class="flex-col-top">
    <div class="flex-row-left-top flex-wrap" *ngIf="!loading">
      <div class="flex-row-left-top">
        <mat-card class="height-100" ngClass.gt-xs="horiz-margin-1x" *ngIf="shareRequest.status !== ShareRequestStatus.pending">
          <mat-card-content>
            <div class="flex-row-left status-row">
              <div class="status-title">Status:</div>
              <div>{{shareRequest.status | titlecase}}</div>
            </div>
            <div class="flex-row-left status-row">
              <div class="status-title">Active Contacts:</div>
              <div>{{(sharersSent | number) + ' / ' + (sharersTotal | number)}}</div>
            </div>
            <div class="flex-row-left status-row" *ngIf="shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro">
              <div class="status-title">Prospects Reached:</div>
              <div>{{(shareRequest.numProspects || 0) | number}}</div>
            </div>
            <div class="flex-row-left status-row" *ngIf="shareRequest.type === ShareRequestType.xPost && shareRequest.tweet">
              <div class="status-title">X Post Metrics:</div>
              <x-post-metrics [post]="shareRequest.tweet"></x-post-metrics>
            </div>
            <div class="flex-row-left status-row" *ngIf="shareRequest.type === ShareRequestType.linkedinPost && shareRequest.linkedinPost">
              <div class="status-title">LinkedIn Post Metrics:</div>
              <linkedin-post-metrics [post]="shareRequest.linkedinPost"></linkedin-post-metrics>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!--
      <div class="flex-col-top" ngClass.xs="vert-padding">
        <mat-card class="height-100" ngClass.gt-xs="horiz-margin-1x" *ngIf="!loading">
          <mat-card-content>
            <div class="share-option-card-header" ngClass.xs="flex-col-top-left" ngClass.gt-xs="flex-row-left">
              <div class="share-option-card-email-title width-50">Invite Contacts</div>
              <div class="flex-row-left share-link-row share-option-card-actions" ngClass.xs="vert-padding">
                <div [matTooltip]="!hasSent() ? 'Setup your invite message and add contacts first' : ''">
                  <mat-slide-toggle
                      color="primary"
                      [ngModel]="sharerCampaignToggle"
                      [disabled]="!canSend()"
                      (change)="toggleSharerCampaign()"
                  ></mat-slide-toggle>
                </div>

                <button mat-mini-fab
                        (click)="editSharerCampaign()"
                        class="horiz-margin-1x white-btn"
                        matTooltip="Setup your invite message"
                >
                  <mat-icon>settings</mat-icon>
                </button>
                <div>
                  {{shareRequest.inviteStatus | titlecase}}
                </div>
              </div>
            </div>
            <div class="share-option-card-header" ngClass.xs="flex-col-top-left" ngClass.gt-xs="flex-row-left">
              <div class="share-option-card-title">Share Link</div>
              <div class="flex-row-left share-link-row share-option-card-actions" ngClass.xs="vert-padding">
                <mat-slide-toggle
                    color="primary"
                    [ngModel]="shareRequest.shareLinkEnabled"
                    [matTooltip]="shareRequest.shareLinkEnabled ? shareLink : ''"
                    (change)="toggleShareLinkAccess()"
                >
                </mat-slide-toggle>

                <button mat-mini-fab
                        (click)="previewShareLink()"
                        class="horiz-margin-1x white-btn"
                        matTooltip="Preview landing page"
                        *ngIf="shareRequest.shareLinkEnabled"
                >
                  <mat-icon>preview</mat-icon>
                </button>
                <button mat-mini-fab
                        (click)="copyShareLink()"
                        class="horiz-margin white-btn"
                        matTooltip="Copy link"
                        *ngIf="shareRequest.shareLinkEnabled"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      -->
    </div>
  </div>
</div>

<div *ngIf="showWizard && !loading">
  <mat-stepper [selectedIndex]="stepperIndex" #stepper linear class="wizard">
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>

    <mat-step label="Settings"
              [completed]="stepIsCompleted(ShareRequestWizardStatus.settingsAdded)"
    >
      <mat-card class="wizard-step-desc" *ngIf="!loading" fxHide.xs>
        <h3>Network Request Settings</h3>
        <div>What type of request do you want to make to your contacts?</div>
      </mat-card>
      <div>
        <share-request-setup></share-request-setup>
      </div>
      <div class="flex-col-top margin-center">
        <div class="flex-row-left">
          <!--
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="skipWizard()"
          >Later</button>
          -->
          <span [matTooltip]="settingsCompleted() ? '' : 'Select a type and name first.'">
            <button mat-flat-button
                    matStepperNext
                    color="primary"
                    [disabled]="!settingsCompleted()"
                    class="horiz-margin-1x"
                    (click)="completeSettings()"
            >Next</button>
          </span>
        </div>
      </div>
    </mat-step>

    <mat-step label="Details"
              [completed]="stepIsCompleted(ShareRequestWizardStatus.detailsAdded)"
    >
      <div *ngIf="shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro">
        <mat-card class="wizard-step-desc" *ngIf="!loading" fxHide.xs>
          <h3>Network Request Details</h3>
          <div>Write a message that your contacts can use when emailing their matching contacts.  They can edit it before they send it. <br/>Then, select the audience you want your contacts to email.</div>
        </mat-card>
        <div class="flex-row-space-between-top flex-wrap">
          <div class="flex-col-left" ngClass.xs="width-100" ngClass.gt-xs="width-50">
            <h2>Ask my contacts to email this message...</h2>
            <message-editor [subject]="shareRequest.prospectMessageSubject"
                            [content]="shareRequest.prospectMessageContent"
                            [hideMessageTemplates]="false"
                            (onChange)="prospectMessageChange($event)"
                            class="width-100"
                            #prospectMessageWizard
            ></message-editor>
          </div>
          <div class="flex-col-left" ngClass.xs="width-100" ngClass.gt-xs="width-45">
            <h2>...to their contacts in this audience</h2>
            <audience-editor [editAudience]="shareRequest.prospectAudience"
                             [mode]="audienceEditorMode"
                             (onSave)="prospectAudienceChange($event)"
            ></audience-editor>
          </div>
        </div>
      </div>
      <div *ngIf="shareRequest.type === ShareRequestType.xPost">
        <mat-card class="wizard-step-desc" *ngIf="!loading" fxHide.xs>
          <h3>Network Request Details</h3>
          <div>Select a post or write a new one, and your contacts can help repost it.</div>
        </mat-card>
        <div class="tweet-detail-container">
          <x-post-request-setup [shareRequest]="shareRequest"
                                accountLabel="Select X Account"
                                (onChange)="tweetChange($event)"
          ></x-post-request-setup>
        </div>
      </div>
      <div *ngIf="shareRequest.type === ShareRequestType.linkedinPost">
        <mat-card class="wizard-step-desc" *ngIf="!loading" fxHide.xs>
          <h3>Network Request Details</h3>
          <div>Select a LinkedIn post or write a new one, and your contacts can help like it.</div>
        </mat-card>
        <div class="linkedin-detail-container">
          <linkedin-post-request-setup [shareRequest]="shareRequest"
                                       accountLabel="Select LinkedIn Account"
                                       (onChange)="linkedinPostChange($event)"
          ></linkedin-post-request-setup>
        </div>
      </div>
      <div class="flex-col-top margin-center">
        <div class="flex-row-left">
          <button mat-raised-button
                  matStepperPrevious
                  class="horiz-margin-1x"
                  (click)="completeStep(ShareRequestWizardStatus.detailsAdded, false)"
          >Previous</button>
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="skipWizard()"
          >Later</button>
          <span [matTooltip]="areDetailsCompleted() ? '' : 'Select at least 1 audience filter to continue.'">
            <button mat-flat-button
                    matStepperNext
                    color="primary"
                    [disabled]="!areDetailsCompleted()"
                    class="horiz-margin-1x"
                    (click)="completeDetails()"
            >Next</button>
          </span>
        </div>
      </div>
    </mat-step>

    <mat-step label="Share Your Request"
              [completed]="stepIsCompleted(ShareRequestWizardStatus.shareOptionsSelected)"
    >
      <mat-card class="wizard-step-desc" *ngIf="!loading" fxHide.xs>
        <h3>Share Your Request</h3>
        <div>Choose how to share your request.  Wrollo can send personalized emails to your contacts selected or you can use the share link.</div>
      </mat-card>
      <div class="flex-col-top">
        <mat-card class="wizard-share-card wizard-share-card-width"
                  [ngClass]="{'wizard-share-card-selected': user.numActiveContactsTwitter > 0}"
                  *ngIf="shareRequest.type === ShareRequestType.xPost"
        >
          <mat-card-content class="share-option-content">
            <div class="share-option-checkbox-container">
              <mat-checkbox [checked]="user.numActiveContactsTwitter > 0"
                            [disabled]="true"
                            class="share-link-checkbox checkbox-2x checkbox-2x-disabled"
              ><h6>Notify Active Contacts</h6></mat-checkbox>
            </div>
            <div class="share-opt-desc" *ngIf="user.numActiveContactsTwitter === 0">
              You don't have any contacts that have integrated their X account.  Invite more below!
            </div>
            <div class="share-opt-desc" *ngIf="user.numActiveContactsTwitter > 0">
              Notify all your contacts that have signed up with X accounts.
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="wizard-share-card wizard-share-card-width"
                  [ngClass]="{'wizard-share-card-selected': user.numActiveContactsLinkedin > 0}"
                  *ngIf="shareRequest.type === ShareRequestType.linkedinPost"
        >
          <mat-card-content class="share-option-content">
            <div class="share-option-checkbox-container">
              <mat-checkbox [checked]="user.numActiveContactsLinkedin > 0"
                            [disabled]="true"
                            class="share-link-checkbox checkbox-2x checkbox-2x-disabled"
              ><h6>Notify Active Contacts</h6></mat-checkbox>
            </div>
            <div class="share-opt-desc" *ngIf="user.numActiveContactsLinkedin === 0">
              You don't have any contacts that have integrated their LinkedIn account.  Invite more below!
            </div>
            <div class="share-opt-desc" *ngIf="user.numActiveContactsLinkedin > 0">
              Notify all your contacts that have signed up with LinkedIn accounts.
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="wizard-share-card wizard-share-card-width"
                  [ngClass]="{'wizard-share-card-selected': shareRequest.shareLinkEnabled}"
        >
          <mat-card-content class="share-option-content">
            <div class="share-option-checkbox-container">
              <mat-checkbox (change)="toggleShareLinkAccess()"
                            [checked]="shareRequest.shareLinkEnabled"
                            class="share-link-checkbox checkbox-2x"
              ><h6>Enable Share Link</h6></mat-checkbox>
            </div>
            <div class="share-opt-desc" *ngIf="!shareRequest.shareLinkEnabled">Get a share link to use in email or posts.</div>
            <div *ngIf="shareRequest.shareLinkEnabled" fxHide.xs class="share-link-panel flex-row-left">
              <a (click)="previewShareLink()" class="pointer flex-row-left" target="_blank" [matTooltip]="shareLink">
                <div class="share-link-label">{{shareLink.substring(0,50)}}...</div>
                <mat-icon class="share-link-open-icon">open_in_new</mat-icon>
              </a>
              <button mat-mini-fab
                      (click)="copyShareLink()"
                      class="white-btn"
                      matTooltip="Copy share link"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              <!--
              <mat-form-field class="horiz-margin-1x share-link-input">
                <mat-label>Share Link (click to copy)</mat-label>
                <input matInput
                       id="link-search"
                       type="text"
                       data-lpignore="true"
                       readonly
                       (click)="copyShareLink()"
                       [ngModel]="shareLink"
                >
              </mat-form-field>
              <button mat-mini-fab
                      (click)="previewShareLink()"
                      class="horiz-margin-1x white-btn"
                      matTooltip="Preview landing page"
              >
                <mat-icon>preview</mat-icon>
              </button>
              -->
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="wizard-share-card wizard-share-card-width"
                  [ngClass]="{'wizard-share-card-selected': showEmailCampaign}"
        >
          <mat-card-content class="share-option-content">
            <div class="share-option-checkbox-container">
              <mat-checkbox (change)="showEmailCampaign = !showEmailCampaign"
                            [checked]="showEmailCampaign"
                            class="share-link-checkbox checkbox-2x"
              ><h6>Invite My Contacts</h6></mat-checkbox>
            </div>
            <div class="share-opt-desc">You can write a message and invite your contacts to help.</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="flex-col-top margin-center">
        <div class="flex-row-left">
          <button mat-raised-button
                  matStepperPrevious
                  class="horiz-margin-1x"
                  (click)="completeStep(ShareRequestWizardStatus.shareOptionsSelected, false)"
          >Previous</button>
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="skipWizard()"
          >Later</button>
          <button mat-flat-button
                  matStepperNext
                  color="primary"
                  class="horiz-margin-1x"
                  (click)="completeShareOptions()"
                  *ngIf="showEmailCampaign"
          >Next</button>
          <span [matTooltip]="areShareOptionsCompleted() ? '' : 'Select a way to share your request first.'">
            <button mat-flat-button
                    color="primary"
                    class="horiz-margin-1x"
                    (click)="activate()"
                    [disabled]="!areShareOptionsCompleted()"
                    *ngIf="!showEmailCampaign"
            >Activate</button>
          </span>
        </div>
      </div>
    </mat-step>

    <mat-step label="Invite Message"
              [completed]="stepIsCompleted(ShareRequestWizardStatus.sharerMessageAdded)"
              *ngIf="showEmailCampaign"
    >
      <mat-card class="wizard-step-desc" *ngIf="!loading" fxHide.xs>
        <h3>My Invite Message</h3>
        <div>Write a message to your contacts explaining your request.</div>
      </mat-card>
      <div class="invite-message-panel">
        <select-email-account label="From Email"
                              [accounts]="this.emailAccounts"
                              [userOauthId]="shareRequest.emailOauthId"
                              [addOauthTo]="addOauthTo"
                              (onChange)="emailOauthChange($event)"
        ></select-email-account>
      </div>
      <message-editor [subject]="shareRequest.sharerMessageSubject"
                      [content]="shareRequest.sharerMessageContent"
                      [hideMessageTemplates]="false"
                      (onChange)="sharerMessageChange($event)"
                      #sharerMessageWizard
      ></message-editor>
      <div class="flex-col-top margin-center">
        <div class="flex-row-left">
          <button mat-raised-button
                  matStepperPrevious
                  class="horiz-margin-1x"
                  (click)="completeStep(ShareRequestWizardStatus.sharerMessageAdded, false)"
          >Previous</button>
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="skipWizard()"
          >Later</button>
          <span [matTooltip]="isSharerMessageCompleted() ? '' : 'Select an email account and enter in a subject and/or message to continue.'">
            <button mat-flat-button
                    matStepperNext
                    color="primary"
                    [disabled]="!isSharerMessageCompleted()"
                    class="horiz-margin-1x"
                    (click)="completeSharerMessage()"
            >Next</button>
          </span>
        </div>
      </div>
    </mat-step>

    <mat-step label="Add Invitees"
              [completed]="stepIsCompleted(ShareRequestWizardStatus.sharersAdded)"
              *ngIf="showEmailCampaign"
    >
      <mat-card class="wizard-step-desc" *ngIf="!loading" fxHide.xs>
        <h3>Add Invitees</h3>
        <div>Import new contacts or select an audience to invite to this network request.</div>
      </mat-card>
      <div class="flex-row-space-between-top flex-wrap">
        <mat-card class="vert-margin" ngClass.xs="width-100" ngClass.gt-xs="width-45">
          <mat-card-header>
            <mat-card-title>
              {{user.numActiveContactImports > 0 ? 'Select an audience' : 'Either import contacts and select an audience'}}
            </mat-card-title>
            <mat-card-subtitle>
              You currently have {{(user.numContacts | number) + ' ' + pluralize('contact', user.numContacts)}}.
              <span *ngIf="user.numActiveContactImports === 0 && user.numContacts === 0">First, import your contacts via an email account or a file, then select an audience to invite matching contacts.</span>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="flex-row-left vert-padding">
              <oauth-button [type]="OauthType.google"
                            [action]="OauthAction.add"
                            label="Add Gmail Account"
                            *ngIf="user.numActiveContactImports === 0 && user.numContacts === 0"
              ></oauth-button>

              <oauth-button [type]="OauthType.microsoft"
                            [action]="OauthAction.add"
                            label="Add Outlook Account"
                            *ngIf="user.numActiveContactImports === 0 && user.numContacts === 0"
              ></oauth-button>

              <csv-import class="horiz-margin-2x"
                          *ngIf="user.numActiveContactImports === 0 && user.numContacts === 0"
              ></csv-import>

              <span [matTooltip]="shareRequest.sharerAudienceId <= 0 ? 'Please select an audience with at least 1 filter first' : ''">
                <button mat-flat-button
                        color="primary"
                        (click)="addSharers()"
                        [disabled]="addingSharers || shareRequest.sharerAudienceId <= 0"
                        *ngIf="user.numActiveContactImports > 0 || user.numContacts > 0"
                >Add Matching Contacts</button>
              </span>

              <mat-spinner *ngIf="!loading && addingSharers"
                           color="accent" [diameter]="24"
                           class="horiz-margin-1x"
                           matTooltip="You have an ongoing contact import.  We'll email you when it's done."
              ></mat-spinner>
              <!--
              <select-audience label="Invitee Audience"
                               [audiences]="audiences"
                               [audienceId]="shareRequest.sharerAudienceId"
                               (onChange)="save()"
                               [matTooltip]="canSelectSharerAudience() ? '' : 'Please start importing contacts first'">
              ></select-audience>
              -->
            </div>
            <div>
              <audience-editor [editAudience]="shareRequest.sharerAudience"
                               [mode]="audienceEditorMode"
                               [readOnly]="!canSelectSharerAudience()"
                               [matTooltip]="canSelectSharerAudience() ? '' : 'Please start importing contacts first'"
                               (onSave)="sharerAudienceChange($event)"
              >
              ></audience-editor>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="vert-margin" ngClass.xs="width-100" ngClass.gt-xs="width-45 horiz-margin-1x">
          <mat-card-header>
            <mat-card-title>
              Or manually add invitees
            </mat-card-title>
            <mat-card-subtitle>
              You currently have {{(shareRequest.numSharers | number) + ' ' + pluralize('invitee', shareRequest.numSharers)}} added.
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="flex-row-left vert-margin">
              <button mat-flat-button
                      color="primary"
                      [matMenuTriggerFor]="addSharerMenuWizard"
                      class="add-contacts-btn"
              >
                Add
                <fa-icon [icon]="faCaretDown"></fa-icon>
              </button>
              <mat-menu #addSharerMenuWizard="matMenu">
                <a mat-menu-item
                   (click)="openContactDialog()"
                   matTooltip="Add a contact manually"
                >
                  Add New Contact
                </a>
                <a mat-menu-item
                   (click)="addActiveContacts(false)"
                   matTooltip="Add all contacts that have joined your network"
                >
                  Add Active Contacts
                </a>
              </mat-menu>
              <contact-quick-search label="Search Contacts"
                                    (onSelect)="addContactFromSearch($event)"
                                    class="horiz-margin-2x"
              ></contact-quick-search>
            </div>
            <div>
              <share-request-sharer-list [fullDisplay]="false"></share-request-sharer-list>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="flex-col-top margin-center vert-margin">
        <div class="flex-row-left">
          <button mat-raised-button
                  matStepperPrevious
                  class="horiz-margin-1x"
                  (click)="completeStep(ShareRequestWizardStatus.sharersAdded, false)"
          >Previous</button>
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="completeSharerAudience(false, false)"
                  *ngIf="!waitingOnImport()"
          >Later</button>
          <span [matTooltip]="areSharersAdded() ? '' : 'Add or import contacts to continue'"
                *ngIf="!waitingOnImport()"
          >
            <button mat-flat-button
                    matStepperNext
                    color="primary"
                    [disabled]="!areSharersAdded()"
                    class="horiz-margin-1x"
                    (click)="completeSharerAudience(true, false)"
            >Send</button>
          </span>
          <span matTooltip="Wait until import is complete and notify me to review the matching contacts."
                *ngIf="waitingOnImport()"
          >
            <button mat-flat-button
                    color="primary"
                    (click)="completeSharerAudience(false, false)"
                    class="horiz-margin-1x"
            >Wait & Notify</button>
          </span>
          <span matTooltip="Wait until import is complete and send the email to the matching contacts."
                *ngIf="waitingOnImport()"
          >
            <button mat-flat-button
                    color="primary"
                    (click)="completeSharerAudience(false, true)"
                    class="horiz-margin-1x"
            >Wait & Send</button>
          </span>
        </div>
      </div>
    </mat-step>

  </mat-stepper>
</div>

<div *ngIf="!showWizard" class="tabs-container">
  <mat-tab-group [selectedIndex]="selectedTab.value"
                 (selectedIndexChange)="setTab($event)"
  >
    <!--
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">settings</mat-icon>
        Settings
      </ng-template>
      <div class="tab-container">
        <share-request-setup></share-request-setup>
      </div>
    </mat-tab>
    -->
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon *ngIf="shareRequest.type === ShareRequestType.intro"
                 [icon]="faHandshake"
                 class="fa-xl horiz-margin"
        ></fa-icon>
        <fa-icon *ngIf="shareRequest.type === ShareRequestType.email"
                 [icon]="faEnvelope"
                 class="fa-xl horiz-margin"
        ></fa-icon>
        <fa-icon *ngIf="shareRequest.type === ShareRequestType.xPost"
                 [icon]="faXTwitter"
                 class="fa-xl horiz-margin"
        ></fa-icon>
        <fa-icon *ngIf="shareRequest.type === ShareRequestType.linkedinPost"
                 [icon]="faLinkedin"
                 class="fa-xl horiz-margin"
        ></fa-icon>
        Details
      </ng-template>
      <div class="tab-container message-container">
        <div *ngIf="shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro">
          <mat-card class="wizard-step-desc" *ngIf="!loading && shareRequest.status === ShareRequestStatus.pending" fxHide.xs>
            <h3>Network Request Details</h3>
            <div>Write a message that your contacts can use when emailing matching prospects in their network.  They can edit it before they send it. Then, select the audience you want your contacts to email.</div>
          </mat-card>
          <div class="share-request-details-row">
            <div class="prospect-message-panel">
              <h2>Ask my contacts to email this message...</h2>
              <message-editor [subject]="shareRequest.prospectMessageSubject"
                              [content]="shareRequest.prospectMessageContent"
                              [hideMessageTemplates]="false"
                              [readOnly]="shareRequest.status !== ShareRequestStatus.pending"
                              (onChange)="prospectMessageChange($event)"
                              class="width-100"
                              #prospectMessageWizard
              ></message-editor>
            </div>
            <div class="prospect-audience-panel">
              <h2 class="horiz-margin-1x">...to their contacts in this audience</h2>
              <audience-editor [editAudience]="shareRequest.prospectAudience"
                               [mode]="audienceEditorMode"
                               [readOnly]="shareRequest.status !== ShareRequestStatus.pending"
                               (onSave)="prospectAudienceChange($event)"
              ></audience-editor>
            </div>
          </div>
        </div>
        <div *ngIf="shareRequest.type === ShareRequestType.xPost">
          <mat-card class="wizard-step-desc" *ngIf="!loading && shareRequest.status === ShareRequestStatus.pending" fxHide.xs>
            <h3>Network Request Details</h3>
            <div>Select a post or write a new one, and your contacts can help repost it.</div>
          </mat-card>
          <div class="tweet-detail-container">
            <x-post-request-setup [shareRequest]="shareRequest"
                                  [readOnly]="shareRequest.status !== ShareRequestStatus.pending"
                                  [accountLabel]="shareRequest.status !== ShareRequestStatus.pending ? 'X Account' : 'Select X Account'"
                                  (onChange)="tweetChange($event)"
            ></x-post-request-setup>
          </div>
        </div>
        <div *ngIf="shareRequest.type === ShareRequestType.linkedinPost">
          <mat-card class="wizard-step-desc" *ngIf="!loading && shareRequest.status === ShareRequestStatus.pending" fxHide.xs>
            <h3>Network Request Details</h3>
            <div>Select a LinkedIn post or write a new one, and your contacts can help like it.</div>
          </mat-card>
          <div class="linkedin-detail-container">
            <linkedin-post-request-setup [shareRequest]="shareRequest"
                                         [readOnly]="shareRequest.status !== ShareRequestStatus.pending"
                                         [accountLabel]="shareRequest.status === ShareRequestStatus.pending ? 'Select LinkedIn Account' : 'LinkedIn Account'"
                                         (onChange)="linkedinPostChange($event)"
            ></linkedin-post-request-setup>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">person_add</mat-icon>
        My Contacts Helping{{(shareRequest.sharers && shareRequest.sharers.length > 0) ? ' (' + shareRequest.sharers.length + ')' : ''}}
      </ng-template>
      <div class="tab-container contact-list-container">
        <mat-card class="wizard-step-desc" *ngIf="!loading && shareRequest.status === ShareRequestStatus.pending" fxHide.xs>
          <h3>My Contacts Helping</h3>
          <div>These are your contacts that can help or are helping on share your request.  You can use Wrollo to email them and invite them personally or you can share a public link on your own.</div>
        </mat-card>

        <div class="flex-row-space-between" ngClass.xs="flex-wrap" *ngIf="shareRequest.type !== ShareRequestType.intro">
          <div class="flex-row-left vert-padding" ngClass.xs="flex-wrap">
            <select-audience label="My Contacts Audience"
                             [audiences]="audiences"
                             [audienceId]="shareRequest.sharerAudienceId"
                             (onChange)="sharerAudienceChange($event)"
            ></select-audience>
            <span matTooltip="You have an active contact import.  Once the import is complete, the matching contacts will be added, and you'll be emailed."
                  [matTooltipDisabled]="!addingSharers"
            >
            <button mat-flat-button
                    color="primary"
                    (click)="addSharers()"
                    [disabled]="shareRequest.sharerAudienceId <= 0 || addingSharers"
                    matTooltip="Add contacts from audience to network request"
                    class="my-contacts-btn"
                    ngClass.gt-xs="horiz-margin-1x"
            >
              <span class="flex-row-left">
                Add from Audience
                <mat-spinner *ngIf="addingSharers"
                             color="accent"
                             [diameter]="24"
                             class="btn-spinner-icon"
                ></mat-spinner>
              </span>
            </button>
          </span>
          </div>
          <div class="flex-row-left" ngClass.xs="flex-wrap">
            <contact-quick-search label="Search Contacts"
                                  (onSelect)="addContactFromSearch($event)"
                                  class="quick-search-field"
            ></contact-quick-search>
            <!--
            <button mat-flat-button
                    color="primary"
                    (click)="addActiveContacts(false)"
                    matTooltip="Add all of your contacts that are active users"
                    class="my-contacts-btn"
                    ngClass.gt-xs="horiz-margin-1x"
            >Add Active Contacts</button>
            -->
            <import-button label="Add New Contacts"
                           (onAdd)="finishContactManuallyAdded($event)"
                           class="add-contacts-btn"
                           ngClass.gt-xs="horiz-margin-1x"
            ></import-button>
          </div>
        </div>
        <share-request-sharer-list></share-request-sharer-list>
      </div>
    </mat-tab>

    <mat-tab *ngIf="shareRequest.type !== ShareRequestType.intro">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">share</mat-icon>
        Share
      </ng-template>
      <div class="tab-container vert-padding">
        <div class="flex-row-space-between-top" ngClass.xs="flex-wrap">
          <mat-card *ngIf="!loading">
            <mat-card-content class="share-option-card">
              <div class="flex-row-left vert-padding share-link-row">
                <div class="share-option-card-title">Invite Contacts</div>
                <div class="flex-row-left horiz-margin-1x" ngClass.xs="vert-padding">
                  <div *ngIf="!hasSent() || shareRequest.inviteStatus === ShareRequestInviteStatus.done"
                       [matTooltip]="!canSend() ? 'Setup your invite message and add contacts first' : ''"
                  >
                    <button mat-flat-button
                            color="primary"
                            (click)="sendSharerCampaign()"
                            [disabled]="!canSend()"
                            ngClass.gt-xs="horiz-margin-1x"
                    >Send</button>
                  </div>
                  <button mat-flat-button
                          *ngIf="shareRequest.inviteStatus === ShareRequestInviteStatus.sending"
                          color="primary"
                          (click)="pauseSharerCampaign()"
                          ngClass.gt-xs="horiz-margin-1x"
                  >Pause</button>
                  <button mat-flat-button
                          *ngIf="shareRequest.inviteStatus === ShareRequestInviteStatus.paused"
                          color="primary"
                          (click)="resumeSharerCampaign()"
                          ngClass.gt-xs="horiz-margin-1x"
                  >Resume</button>
                  <!--
                  <div [matTooltip]="!hasSent() ? 'Setup your invite message and add contacts first' : ''">
                    <mat-slide-toggle
                        color="primary"
                        [ngModel]="sharerCampaignToggle"
                        [disabled]="!canSend()"
                        (change)="toggleSharerCampaign()"
                    ></mat-slide-toggle>
                  </div>
                  -->
                </div>
                <div class="horiz-margin-1x">{{shareRequest.inviteStatus | titlecase}}</div>
              </div>
              <div class="share-opt-desc">You can write a message and invite your contacts to help.</div>
              <div class="vert-padding">
                <sharer-invite-campaign [shareRequest]="shareRequest" (onSend)="confirmSend()" #sharerInviteCampaign></sharer-invite-campaign>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="!loading" ngClass.xs="vert-margin">
            <mat-card-content class="share-option-card">
              <div class="flex-row-left vert-padding">
                <div class="share-option-card-title">Share Link</div>
                <div class="flex-row-left share-link-row horiz-margin-1x" ngClass.xs="vert-padding">
                  <mat-slide-toggle
                      color="primary"
                      [ngModel]="shareRequest.shareLinkEnabled"
                      [matTooltip]="shareRequest.shareLinkEnabled ? shareLink : ''"
                      (change)="toggleShareLinkAccess()"
                  >
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="share-opt-desc">Use this share link in email or posts.</div>
              <div *ngIf="shareRequest.shareLinkEnabled" class="vert-padding flex-row-left">
                <a (click)="previewShareLink()" class="pointer flex-row-left" target="_blank" [matTooltip]="shareLink">
                  <div class="share-link-label">{{shareLink.substring(0,50)}}...</div>
                  <mat-icon class="share-link-open-icon">open_in_new</mat-icon>
                </a>
                <button mat-mini-fab
                        (click)="copyShareLink()"
                        class="white-btn"
                        matTooltip="Copy share link"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
                <!--
                <mat-form-field class="share-link-input">
                  <mat-label>Share Link<span fxHide.xs> (click to copy)</span></mat-label>
                  <input matInput
                         id="link-search"
                         type="text"
                         data-lpignore="true"
                         readonly
                         (click)="copyShareLink()"
                         [ngModel]="shareLink"
                  >
                </mat-form-field>
                <button mat-mini-fab
                        (click)="copyShareLink()"
                        class="horiz-margin-1x white-btn"
                        matTooltip="Copy share link"
                        fxHide.gt-xs
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
                <button mat-mini-fab
                        (click)="previewShareLink()"
                        class="horiz-margin-1x white-btn"
                        matTooltip="Preview landing page"
                >
                  <mat-icon>preview</mat-icon>
                </button>
                -->
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <!--
    <mat-tab *ngIf="shareRequest.status !== ShareRequestStatus.pending">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">list_alt</mat-icon>
        Feed
      </ng-template>
      <div class="tab-container">
        <h3>Coming Soon</h3>
      </div>
    </mat-tab>

    <mat-tab *ngIf="shareRequest.status !== ShareRequestStatus.pending">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">dashboard</mat-icon>
        Analytics
      </ng-template>
      <div class="tab-container">
        <h3>Coming Soon</h3>
      </div>
    </mat-tab>
    -->
  </mat-tab-group>
</div>
