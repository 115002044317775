import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {OauthService, UserOauthAccount, AddOauthToObject, OauthAction} from "../../../services/oauth.service";

@Component({
  selector: 'select-email-account',
  templateUrl: './select-email-account.component.html',
  styleUrls: ['./select-email-account.component.scss']
})
export class SelectEmailAccountComponent implements AfterViewInit, OnChanges {

  @Input() label: string = ''
  @Input() accounts: UserOauthAccount[] = []
  @Input() userOauthId: number = 0
  @Input() hideAddButton: boolean = false
  @Input() addOauthTo: AddOauthToObject | undefined
  @Input() readOnly: boolean = false

  @Output() onChange = new EventEmitter()

  accountControl = new FormControl()
  accountLabel: string = ''

  constructor(private oauthService: OauthService,
  ) {
  }

  ngAfterViewInit() {
    this.setOauth()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['accounts']) {
      this.accounts = changes['accounts'].currentValue
      this.setOauth()
    }
    if (changes['userOauthId']) {
      this.userOauthId = changes['userOauthId'].currentValue
      this.setOauth()
    }
  }

  change() {
    this.userOauthId = this.accountControl.value
    this.onChange.emit(this.userOauthId)
  }

  setOauth() {
    if (this.readOnly) {
      const account = this.accounts.find(a => { return a.id === this.userOauthId })
      if (account) {
        this.accountLabel = account.username
      }
    } else {
      this.accountControl.setValue(this.userOauthId)
    }
  }

  accountIsSet() {
    return typeof this.userOauthId !== 'undefined' && this.userOauthId > 0
  }

  addAccount() {
    this.oauthService.doOauthGoogle(
      OauthAction.add,
      document.location.href,
      'force',
      this.addOauthTo
    )
  }

}
