import {Injectable} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {UserOauthAccount, UserOauthAccountResult} from "./oauth.service";
import {environment} from "../../environments/environment";
import {User} from "./user.service";

export interface ContactImport {
  id: number
  userId: number
  userOauthId: number
  userOauth?: UserOauthAccount
  apiComplete: boolean
  apiCompletedAt: number
  importComplete: boolean
  importCompletedAt: number
  importProgress: number
  totalContacts: number
  source: string
  importFile?: {
    name: string
    type: string
    size: number
    url: string
  }
  shareRequestId: number
  shareRequestSharerId: number
  createdAt: number
}

export interface ContactImportResult {
  id: number
  user_id: number
  user_oauth_id: number
  user_oauth?: UserOauthAccountResult
  api_complete: boolean
  api_completed_at: number
  import_complete: boolean
  import_completed_at: number
  import_progress: number
  total_contacts: number
  source: string
  import_file?: {
    name: string
    type: string
    size: number
    url: string
  }
  share_request_id: number
  share_request_sharer_id: number
  created_at: number
}

@Injectable({
  providedIn: 'root'
})
export class ContactImportService {
  public list!: ContactImport[]
  private listSubject: Subject<ContactImport[]> = new Subject<ContactImport[]>()
  list$: Observable<ContactImport[]> = this.listSubject.asObservable()

  private endpoint: string = 'contact_imports'

  constructor(private api: ApiService,
  ) {
  }

  getAll(forceGet: boolean = false): Observable<ContactImport[]>{
    if (this.list && !forceGet) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<ContactImport[]>()
      this.api.get<ContactImport[]>(`my/` + this.endpoint, {}).subscribe(
        (results: ContactImport[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        }
      )
      return resultSubject.asObservable()
    }
  }

  getImportProgressLabel(contactImport: ContactImport): string {
    if (!contactImport.importComplete) {
      if (contactImport.totalContacts > 0) {
        return 'Importing ' + contactImport.importProgress.toLocaleString('en') + ' / ' + contactImport.totalContacts.toLocaleString('en')
      } else {
        return 'Importing'
      }
    } else {
      return contactImport.totalContacts.toLocaleString('en')
    }
  }

  getImportDateLabel(contactImport: ContactImport): string {
    return FormatUtils.formatDate(contactImport.createdAt)
  }

  getCsvImportLicenseKey(): string {
    return environment.csvImportLicenseKey
  }

  getCsvImportUrl(user: User, shareRequestId?: number, sharerId?: number): string {
    let url = 'https://app.csvbox.io/upload/g7lzUBFbTdG4hpGCQ38slNat7oLgFT?user_id=' + user.id + '&email=' + user.email
    if (!!shareRequestId && shareRequestId > 0) {
      url += '&share_request_id=' + shareRequestId
    }
    if (!!sharerId && sharerId > 0) {
      url += '&share_request_sharer_id=' + sharerId
    }
    return url
  }
}
