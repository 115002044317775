import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import {SelectChipOption} from "../../form/select-chip/select-chip.component";
import {InputChipOption} from "../../form/input-chip/input-chip.component";
import {ContactListItem, ContactService, ContactSummary} from "../../../services/contact.service";
import {
  Audience,
  AudienceEditorMode,
  AudienceFilterOption,
  AudienceService,
  AudienceType,
  ConvertedKeywordFilters
} from "../../../services/audience.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {MatSelectChange} from "@angular/material/select";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {AutocompleteChipOption} from "../../form/autocomplete-chip/autocomplete-chip.component";
import {Observable, Subject} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";

import {faCircleXmark} from "@fortawesome/free-solid-svg-icons"

@Component({
  selector: 'audience-editor',
  templateUrl: './audience-editor.component.html',
  styleUrls: ['./audience-editor.component.scss']
})
export class AudienceEditorComponent implements OnInit, OnChanges {

  @Input() editAudience: Audience | undefined
  @Input() readOnly: boolean = false
  @Input() mode: string = AudienceEditorMode.edit

  @Output() onFilterChange = new EventEmitter()
  @Output() onSave = new EventEmitter()

  faCircleXmark = faCircleXmark

  audience: Audience = this.audienceService.new()
  audienceList: Audience[] = []
  AudienceType = AudienceType

  contacts: ContactListItem[] = []

  loading: boolean = true
  ignoreFilterChanges: boolean = false
  isDirty: boolean = false

  showAudienceNameField: boolean = false
  audienceNameControl = new FormControl()


  keywordFilters = {
    title: [] as InputChipOption[],
    personName: [] as InputChipOption[],
    emailAddress: [] as InputChipOption[],
    companyName: [] as InputChipOption[],
    companyDomain: [] as InputChipOption[],
  }

  seniorityOptions: SelectChipOption[] = this.audienceService.seniorityOptions
  bizFunctionOptions: SelectChipOption[] = this.audienceService.bizFunctionOptions
  connectionOptions: SelectChipOption[] = this.audienceService.connectionOptions
  emailTypeOptions: SelectChipOption[] = this.audienceService.emailTypeOptions
  contactTypeOptions: SelectChipOption[] = this.audienceService.contactTypeOptions
  lastEmailedOptions: SelectChipOption[] = this.audienceService.lastEmailedOptions
  companySizeOptions: SelectChipOption[] = this.audienceService.companySizeOptions
  companyRevenueOptions: SelectChipOption[] = this.audienceService.companyRevenueOptions
  companyTypeOptions: SelectChipOption[] = this.audienceService.companyTypeOptions
  companyStageOptions: SelectChipOption[] = this.audienceService.companyStageOptions
  investorTypeOptions: SelectChipOption[] = this.audienceService.investorTypeOptions
  investmentStageOptions: SelectChipOption[] = this.audienceService.investmentStageOptions

  industryOptionsSelected: AudienceFilterOption[] = []
  industryOptionsAutocompleteSubject: Subject<AudienceFilterOption[]> = new Subject<AutocompleteChipOption[]>()
  industryOptionsAutocomplete$: Observable<AutocompleteChipOption[]> = this.industryOptionsAutocompleteSubject.asObservable()

  currentBreakpoint: string = ''

  constructor(private authService: AuthService,
              private contactService: ContactService,
              private audienceService: AudienceService,
              private dialogService: DialogService,
              private notificationService: NotificationService,
              private breakpointObserver: BreakpointObserver,
              @Optional() public dialogRef: MatDialogRef<AudienceEditorComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
        this.currentBreakpoint = Breakpoints.XLarge
      } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
        this.currentBreakpoint = Breakpoints.Large
      } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
        this.currentBreakpoint = Breakpoints.Medium
      } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
        this.currentBreakpoint = Breakpoints.Small
      } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
        this.currentBreakpoint = Breakpoints.XSmall
      }
    })
  }

  ngOnInit() {
    this.loading = true

    this.audienceService.list$.subscribe(audienceList => {
      this.audienceList = audienceList
      this.setShowAudienceNameField()
      if (this.data && parseInt(this.data) > 0) {
        this.audience = this.audienceList.find(a => a.id === parseInt(this.data)) || this.audienceService.new()
        this.editAudience = this.audience
        this.audienceNameControl.setValue(this.audience.label)
      }
      if (this.loading && !!this.audience.id && this.audience.id > 0) {
        this.setAudience(this.audience)
      }
      this.loading = false
    })
    if (this.authService.isAuthenticated()) {
      this.audienceService.getAll()
    } else if (!this.authService.isAuthenticated()) {
      this.setAudience(this.audience)
      this.loading = false
    }

    this.contactService.summary$.subscribe(contactSummary => {
      this.addCountsToOptions(this.seniorityOptions, 'seniority')
      this.addCountsToOptions(this.bizFunctionOptions, 'businessFunction')
      this.addCountsToOptions(this.companySizeOptions, 'companySize')
      this.addCountsToOptions(this.companyRevenueOptions, 'revenue')
      this.addCountsToOptions(this.companyTypeOptions, 'companyType')
      this.addCountsToOptions(this.companyStageOptions, 'companyStage')
    })
    if (this.isModal()) {
      this.contactService.getSummary()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editAudience'] &&
        changes['editAudience'].currentValue &&
        changes['editAudience'].currentValue.id !== this.audience?.id
    ) {
      this.setAudience(changes['editAudience'].currentValue)
    }
  }

  setShowAudienceNameField() {
    this.showAudienceNameField = this.isSingularMode() ||
      ((this.isEditMode() || this.isEditShortMode()) && this.audienceList.length === 0) ||
      this.readOnly
  }

  addCountsToOptions(selectOptions: SelectChipOption[], summaryAttr: keyof ContactSummary) {
    selectOptions.forEach(o => {
      o.count = this.contactService.getOptionCount(summaryAttr, o.value)
    })
  }

  isModal() {
    return this.data !== null && typeof this.data != 'undefined'
  }

  isFilterMode(): boolean {
    return this.mode === AudienceEditorMode.filters
  }

  isSingularMode(): boolean {
    return this.mode === AudienceEditorMode.singular
  }

  isEditMode(): boolean {
    return this.mode === AudienceEditorMode.edit
  }

  isEditShortMode(): boolean {
    return this.mode === AudienceEditorMode.editShort
  }

  close(save: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(save ? this.audience : undefined)
    }
  }

  audienceSelected(): boolean {
    return (this.audience.id || 0) > 0
  }

  setAudience(audience: Audience) {
    this.audience = audience
    if (!!this.audience.id || this.isFilterMode()) {
      this.ignoreFilterChanges = true
      this.setShowAudienceNameField()
      this.audienceNameControl.setValue(this.audience.label)
      this.convertSearchToInputs()

      if (this.audience.companyIndustry.length > 0) {
        this.audienceService.getIndustryLabels(this.audience.companyIndustry).subscribe(
          industryOptionsSelected => {
            this.industryOptionsSelected = industryOptionsSelected
          }
        )
      }

      this.emailTypeOptions = this.audienceService.emailTypeOptions.filter(o => {
        return !this.readOnly || this.audience.emailType === o.value
      })

      this.getContacts()

      this.ignoreFilterChanges = false
      this.applyFilters()
      this.isDirty = false
    }
  }

  getContacts() {
    if (this.audience.profileIds.length > 0 && this.mode !== AudienceEditorMode.filters) {
      this.contactService.getPage(1, 5, this.audience, 'full_name', 'asc').subscribe(
        contacts => {
          this.contacts = contacts
        }
      )
    } else {
      this.contacts = []
    }
  }

  selectAudienceEvent(event: MatSelectChange): void {
    this.setAudience(event.source.value)
    if (this.onSave) {
      this.onSave.emit(this.audience.id)
    }
  }

  finishSave(skipGetAll: boolean = false) {
    this.audienceService.save(this.audience, skipGetAll).subscribe(
      newAudience => {
        this.notificationService.success('Audience has been saved.')
        this.audience.id = newAudience.id || 0
        this.isDirty = false
        if (this.onSave) {
          this.onSave.emit(this.audience.id)
        }
        if (this.isModal()) {
          this.close(true)
        } else if (this.contacts.length > 0 || this.audience.profileIds.length > 0) {
          this.getContacts()
        }
      },
      err => {
        this.notificationService.error(err.message || 'Error when creating audience.')
      }
    )
  }

  saveAsAudience(clone: boolean = false) {
    this.dialogService.inputDialog('Create New Audience', '', 'Name', '', 'Save').subscribe(
      name => {
        if (!!name && name !== '') {
          if (clone) {
            delete this.audience.id
          } else {
            this.audience = this.audienceService.new()
          }
          this.audience.label = name
          this.audienceNameControl.setValue(this.audience.label)
          this.finishSave()
          this.applyFilters()
        }
      }
    )
  }

  renameAudience() {
    this.dialogService.inputDialog(
      "Audience Name",
      "Enter the name for your audience.",
      "Audience Name",
      this.audience.label,
    ).subscribe(
      name => {
        if (!!name && name !== '') {
          this.audience.label = name
          this.finishSave()
        }
      }
    )
  }

  saveAudience(forceNew: boolean = false, clone: boolean = false) {
    this.convertInputsToSearch()
    if (!forceNew && !this.audienceService.hasAnyFilters(this.audience)) {
      this.dialogService.messageDialog(
        "Please select a filter",
        "You must add at least one filter to save the audience."
      )
    } else {
      if (forceNew) {
        this.saveAsAudience(clone)
      } else if (!this.isFilterMode()) {
        this.audience.label = this.audienceNameControl.value
        if (this.audience.label === '') {
          this.audienceNameControl.setErrors(['required'])
        } else {
          this.finishSave()
        }
      } else if (!this.audienceSelected()) {
        this.saveAsAudience(clone)
      } else {
        this.finishSave()
      }
    }
  }

  confirmDeleteAudience() {
    this.dialogService.confirmDialog(
      'Delete Audience',
      'Are you sure you want to delete this audience?'
    ).subscribe(result => {
      if (result) {
        this.audienceService.delete(this.audience).subscribe(() => {
          this.audienceNameControl.setValue('')
          this.notificationService.success('Audience deleted.')
          if (this.onSave) {
            this.onSave.emit(-1)
          }
          if (this.isModal()) {
            this.close(false)
          }
        })
      }
    })
  }

  convertFilterToSelect(allOptions: SelectChipOption[], audience: string[]): SelectChipOption[] {
    return allOptions.filter(
      opt => {
        return audience.indexOf(opt.value) >= 0
      }
    )
  }

  convertSearchToInputs() {
    this.keywordFilters.title = this.audienceService.convertSearchToKeywordFilters(this.audience.title, this.audience.titleNot)
    this.keywordFilters.personName = this.audienceService.convertSearchToKeywordFilters(this.audience.name, this.audience.nameNot)
    this.keywordFilters.emailAddress = this.audienceService.convertSearchToKeywordFilters(this.audience.emailAddress, this.audience.emailAddressNot)
    this.keywordFilters.companyName = this.audienceService.convertSearchToKeywordFilters(this.audience.companyName, this.audience.companyNameNot)
    this.keywordFilters.companyDomain = this.audienceService.convertSearchToKeywordFilters(this.audience.companyDomain, this.audience.companyDomainNot)
  }

  convertInputsToSearch() {
    const titleConvertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.keywordFilters.title)
    this.audience.title = titleConvertedFilters.posValues
    this.audience.titleNot = titleConvertedFilters.negValues

    const personNameConvertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.keywordFilters.personName)
    this.audience.name = personNameConvertedFilters.posValues
    this.audience.nameNot = personNameConvertedFilters.negValues

    const emailAddressConvertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.keywordFilters.emailAddress)
    this.audience.emailAddress = emailAddressConvertedFilters.posValues.map(FormatUtils.toLowerCase)
    this.audience.emailAddressNot = emailAddressConvertedFilters.negValues.map(FormatUtils.toLowerCase)

    const companyNameConvertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.keywordFilters.companyName)
    this.audience.companyName = companyNameConvertedFilters.posValues
    this.audience.companyNameNot = companyNameConvertedFilters.negValues

    const companyDomainConvertedFilters: ConvertedKeywordFilters = this.audienceService.convertKeywordFiltersToSearch(this.keywordFilters.companyDomain)
    this.audience.companyDomain = companyDomainConvertedFilters.posValues.map(FormatUtils.toLowerCase)
    this.audience.companyDomainNot = companyDomainConvertedFilters.negValues.map(FormatUtils.toLowerCase)
  }

  resetFilters() {
    this.ignoreFilterChanges = true
    FormatUtils.overwriteObj(this.audience, this.audienceService.new())
    this.keywordFilters.title = []
    this.keywordFilters.personName = []
    this.keywordFilters.emailAddress = []
    this.keywordFilters.companyName = []
    this.keywordFilters.companyDomain = []
    this.audience = this.audienceService.new()
    this.ignoreFilterChanges = false
    this.applyFilters()
  }

  applyFilters() {
    this.isDirty = true
    if (!this.ignoreFilterChanges && this.isFilterMode()) {
      // If in filter mode, emit changes to filter contact list
      this.convertInputsToSearch()
      this.onFilterChange.emit(this.audience)
    } else if (
      (this.isEditMode() || this.isEditShortMode()) &&
      !this.isModal() &&
      !this.loading &&
      this.audienceSelected()
    ) {
      // Otherwise check if we should auto save changes for selected audience
      this.finishSave(true)
    }
  }

  hasPersonFilters(): boolean {
    this.convertInputsToSearch()
    return this.audienceService.hasPersonFilters(this.audience)
  }

  hasCompanyFilters(): boolean {
    this.convertInputsToSearch()
    return this.audienceService.hasCompanyFilters(this.audience)
  }

  industryChange(industryOptionsSelected: AutocompleteChipOption[]) {
    this.industryOptionsSelected = industryOptionsSelected
    this.audience.companyIndustry = this.industryOptionsSelected.map(o => { return o.value })
    this.applyFilters()
  }

  industryAutocomplete(partial: string) {
    if (!partial || partial === '') {
      this.industryOptionsAutocompleteSubject.next([])
    } else {
      this.audienceService.getIndustryAutocompleteOptions(partial).subscribe(
        industryOptionsAutocomplete => {
          this.industryOptionsAutocompleteSubject.next(industryOptionsAutocomplete)
        }
      )
    }
  }

  compareAudiences(a: any, b: any) {
    return a && b && a.id === b.id
  }

  removeProfile(profileId: number) {
    this.audience.profileIds = this.audience.profileIds.filter(id => id !== profileId)
    this.saveAudience()
  }

}
