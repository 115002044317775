import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {OauthAction, OauthService, OauthType, UserOauthAccount} from "../../../services/oauth.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {User, UserService} from "../../../services/user.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'social-account-list',
  templateUrl: './social-account-list.component.html',
  styleUrls: ['./social-account-list.component.scss']
})
export class SocialAccountListComponent implements OnInit {

  loading: boolean = true

  user: User = this.userService.new()
  allUserOauths: UserOauthAccount[] = []

  displayedColumns: string[] = ['type', 'name', 'teamMemberOauths', 'actions']
  accountTable: MatTableDataSource<UserOauthAccount> = new MatTableDataSource()

  twitterLabel: string = this.breakpointObserver.isMatched(Breakpoints.XSmall) ? 'Add' : 'Add X Account'
  linkedinLabel: string = this.breakpointObserver.isMatched(Breakpoints.XSmall) ? 'Add' : 'Add Linkedin Account'

  OauthType = OauthType
  OauthAction = OauthAction

  pluralize = FormatUtils.pluralize

  constructor(private oauthService: OauthService,
              private userService: UserService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.oauthService.userList$.subscribe(accounts => {
      this.allUserOauths = accounts
      this.accountTable.data = this.oauthService.filterSocialAccounts(this.allUserOauths)
      this.loading = false
    })
    this.oauthService.getAllForUser()

    this.userService.user$.subscribe(
      user => {
        this.user = user
      }
    )
  }

  refreshSocialAccount(account: UserOauthAccount) {
    if (account.type === OauthType.linkedin) {
      this.oauthService.doOauthLinkedIn(
        OauthAction.add,
        document.location.href
      )
    } else {
      this.oauthService.doOauthTwitter(
        OauthAction.add,
        document.location.href
      )
    }
  }

  confirmDeleteAccount(account: UserOauthAccount) {
    if (!this.user.hasPassword && this.allUserOauths.length === 1) {
      this.dialogService.messageDialog(
        "Set Your Password",
        "You haven't set your password yet, and this is your last method to login.  Please set a password before you delete this account."
      )
    } else {
      this.dialogService.confirmDialog(
        'Delete Social Account',
        'Are you sure you want to delete this account?'
      ).subscribe(result => {
        if (result) {
          this.oauthService.deleteUserOauth(account).subscribe(
            results => {
              this.loading = true
              this.oauthService.getAllForUser(true)
              this.notificationService.success('Account has been deleted.')
            }
          )
        }
      })
    }
  }

  editTeamMemberOauths(account: UserOauthAccount) {
    this.oauthService.editTeamMemberOauths(account)
  }

}
