import {Component, Input, OnInit} from '@angular/core';
import {faComment, faHeart, faRetweet} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {LinkedInService, RawLinkedInPost} from "../../../services/linkedin.service";

@Component({
  selector: 'linkedin-post-display',
  templateUrl: './linkedin-post-display.component.html',
  styleUrls: ['./linkedin-post-display.component.scss']
})
export class LinkedinPostDisplayComponent implements OnInit {

  @Input({ required: true} ) post: RawLinkedInPost | undefined
  @Input() accountName: string = ''
  @Input() accountUsername: string = ''

  formatDateLong = FormatUtils.formatDateLong
  formatTime = FormatUtils.formatTime

  constructor(private linkedinService: LinkedInService,
  ) {
  }

  ngOnInit() {
  }

  formatHashtagsLinks(postContent: string): string {
    return this.linkedinService.formatHashtagsLinks(postContent)
  }


  protected readonly faComment = faComment;
  protected readonly faHeart = faHeart;
  protected readonly faLinkedin = faLinkedin;
  protected readonly faRetweet = faRetweet;
}
