import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from "../../services/utilities/dialog.service";
import {UserService, User, AccessRight} from "../../services/user.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {NotificationService} from "../../services/utilities/notification.service";
import {FormatUtils} from "../../services/utilities/format.utilities";
import {FormControl} from "@angular/forms";
import {Team, TeamMember, TeamService, TeamMemberRole} from "../../services/team.service";
import {Invite, InviteService} from "../../services/invite.service";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {Observable} from "rxjs";
import {BillingService} from "../../services/billing.service";
import {Router} from "@angular/router";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss']
})
export class TeamDetailComponent implements OnInit {

  faCaretDown = faCaretDown

  user: User = this.userService.new()
  team: Team = this.teamService.new()
  invites: Invite[] = []
  canMutateTeam: boolean = false
  hasSubscription: boolean = false
  numSeatsUsed: number = 0
  numSeatsTotal: number = 0
  numAdmins: number = 0
  loading: boolean = true

  selectedTab = new FormControl(0)

  roles: TeamMemberRole[] = [
    TeamMemberRole.admin,
    TeamMemberRole.requester,
    TeamMemberRole.member,
  ]

  teamMemberColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['name', 'email', 'role']
    : ['select', 'actions', 'name', 'email', 'role', 'createdAt']
  numTeamMemberCols: number = this.teamMemberColumns.length
  teamMemberTable: MatTableDataSource<TeamMember> = new MatTableDataSource()
  teamMemberSelection = new SelectionModel<TeamMember>(true, []);
  @ViewChild('teamMemberPaginator') teamMemberPaginator!: MatPaginator
  @ViewChild('teamMemberSort') teamMemberSort!: MatSort

  formatDate = FormatUtils.formatDate
  pluralize = FormatUtils.pluralize
  getDisplayName = this.userService.getDisplayName

  constructor(private userService: UserService,
              private teamService: TeamService,
              private billingService: BillingService,
              private inviteService: InviteService,
              private dialogService: DialogService,
              private notificationService: NotificationService,
              private router: Router,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.teamService.team$.subscribe(
      (team: Team) => {
        this.team = team
        this.numSeatsUsed = this.teamService.getNumRequesters(this.team)
        this.numAdmins = this.team.teamMembers.filter(m => m.role === TeamMemberRole.admin).length

        this.teamMemberTable = new MatTableDataSource(this.team.teamMembers)
        this.teamMemberTable.sort = this.teamMemberSort
        setTimeout(() => {
          if (this.teamMemberPaginator) {
            this.teamMemberTable.paginator = this.teamMemberPaginator
            this.teamMemberTable.paginator.pageSize = 25
          }
        })
        this.loading = false
      }
    )

    this.inviteService.teamList$.subscribe(
      (invites: Invite[]) => {
        this.invites = invites
      }
    )
    this.inviteService.getAllTeamInvites()

    this.userService.user$.subscribe(
      (user: User) => {
        this.user = user
        this.hasSubscription = this.billingService.hasSubscription(this.user)
        this.numSeatsTotal = this.billingService.getTotalSeats(this.user)
        this.teamService.getTeam(this.user.currentTeamId, true)
        this.canMutateTeam = this.userService.hasAccessRight(this.user, AccessRight.mutateTeam)
      }
    )
    this.userService.getIdentity()

  }

  canAdminChangeRole(teamMember: TeamMember): boolean {
    // Only admins can change team members
    return teamMember.role !== TeamMemberRole.admin || this.numAdmins > 1
  }

  areAllTeamMembersSelected() {
    const numSelected = this.teamMemberSelection.selected.length;
    const numRows = this.teamMemberTable.data.length;
    return numSelected === numRows;
  }

  isTeamMemberSelected(teamMember: TeamMember) {
    return this.teamMemberSelection.selected.filter(s => s.id === teamMember.id).length > 0
  }

  selectAllTeamMembers() {
    this.areAllTeamMembersSelected() ?
      this.teamMemberSelection.clear() :
      this.teamMemberTable.data.forEach(row => this.teamMemberSelection.select(row));
  }

  deleteSelectedTeamMembers() {
    this.dialogService.confirmDialog(
      'Delete Selected Team Members',
      'Are you sure you want to delete ' + this.teamMemberSelection.selected.length + this.pluralize(' team member', this.teamMemberSelection.selected.length) + '?'
    ).subscribe(
      (result: boolean) => {
        if (result) {
          this.deleteTeamMembers(this.teamMemberSelection.selected).subscribe(
            team => {
              this.notificationService.success(
                this.teamMemberSelection.selected.length +
                this.pluralize(' team member', this.teamMemberSelection.selected.length) +
                ' deleted.'
              )
            }
          )
        }
      }
    )
  }

  deleteTeamMembers(teamMembers: TeamMember[]): Observable<Team> {
    return this.teamService.deleteTeamMembers(this.team, teamMembers)
  }

  confirmDeleteTeamMember(teamMember: TeamMember) {
    this.dialogService.confirmDialog(
      'Delete Team Member',
      'Are you sure you want to delete this team member?'
    ).subscribe(
      (result: boolean) => {
        if (result) {
          this.deleteTeamMembers([teamMember]).subscribe(
            team => {
              this.notificationService.success('Team member deleted.')
            }
          )
        }
      }
    )
  }

  renameTeam() {
    this.dialogService.inputDialog(
      'Rename Team',
      'Enter in a new name for your team',
      'Team Name',
      this.team.name,
    ).subscribe(
      result => {
        if (!!result && result !== '') {
          this.team.name = result
          this.teamService.save(this.team).subscribe(
            team => {
              this.userService.getIdentity(true)
              this.notificationService.success('Team name updated.')
            }
          )
        }
      }
    )
  }

  finishChangeRole(teamMember: TeamMember, role: TeamMemberRole) {
    teamMember.role = role
    this.teamService.saveTeamMember(teamMember).subscribe(
      team => {
        this.notificationService.success("Team member's role updated.")
      }
    )
  }

  changeRoleTo(teamMember: TeamMember, role: TeamMemberRole) {
    // Check if there are enough seats
    const needMoreSeats = teamMember.role === TeamMemberRole.member &&
      (role === TeamMemberRole.requester || role === TeamMemberRole.admin) &&
      this.numSeatsUsed === this.numSeatsTotal
    if (this.hasSubscription && needMoreSeats) {
      this.dialogService.confirmDialog(
        "Need More Seats",
        "You need to add seats your subscription to change this role since your currently using all of your subscription's seats.  Do you want to add more seats now?"
      ).subscribe(
        result => {
          if (result) {
            this.router.navigate(['subscription']);
          }
        }
      )
    } else {
      this.finishChangeRole(teamMember, role)
    }
  }

  filterRoleList(teamMember: TeamMember): TeamMemberRole[] {
    return this.roles.filter(r => r !== teamMember.role)
  }

  openInviteDialog() {
    this.inviteService.openInviteDialog(true, [])
  }

}
