import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthService} from "./services/auth/auth.service";
import {LoginComponent} from "./components/auth/login/login.component";
import {SignupComponent} from "./components/auth/signup/signup.component";
import {CompanyLandingComponent} from "./components/auth/company-landing/company-landing.component";
import {ForgetPasswordComponent} from "./components/auth/forget-password/forget-password.component";
import {OauthSuccessComponent} from "./components/auth/oauth-success/oauth-success.component";
import {ShareRequestListComponent} from "./components/share-requests/share-request-list/share-request-list.component";
import {
  ShareRequestDetailComponent
} from "./components/share-requests/share-request-detail/share-request-detail.component";
import {SharerDetailComponent} from "./components/sharer/sharer-detail/sharer-detail.component";
import {ContactListComponent} from "./components/contacts/contact-list/contact-list.component";
import {AudienceListComponent} from "./components/audiences/audience-list/audience-list.component";
import {
  MessageTemplateListComponent
} from "./components/messages/message-template-list/message-template-list.component";
import {AllSettingsComponent} from "./components/settings/all-settings/all-settings.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {AcceptInviteComponent} from "./components/invite/accept-invite/accept-invite.component";
import {
  AcceptIntroRequestComponent
} from "./components/share-requests/accept-intro-request/accept-intro-request.component";
import {SignupIntroComponent} from "./components/onboarding/signup-intro/signup-intro.component";
import {SubscriptionDetailComponent} from "./components/subscription/subscription-detail/subscription-detail.component";
import {TeamDetailComponent} from "./components/team/team-detail.component";
import {PersonalInvitesComponent} from "./components/invite/personal-invites/personal-invites.component";
import {AllAdminComponent} from "./components/admin/all-admin/all-admin.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'company', component: CompanyLandingComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'oauth-success', component: OauthSuccessComponent },
  { path: 'share-request-preview/:id', component: SharerDetailComponent },
  { path: '', component: DashboardComponent, canActivate: [AuthService] },
  { path: 'intro', component: SignupIntroComponent, canActivate: [AuthService] },
  { path: 'share-requests', component: ShareRequestListComponent, canActivate: [AuthService] },
  { path: 'share-request/:id', component: ShareRequestDetailComponent, canActivate: [AuthService] },
  { path: 'sharer/:id', component: SharerDetailComponent, canActivate: [AuthService] },
  { path: 'contacts', component: ContactListComponent, canActivate: [AuthService] },
  { path: 'audiences', component: AudienceListComponent, canActivate: [AuthService] },
  { path: 'message-templates', component: MessageTemplateListComponent, canActivate: [AuthService] },
  { path: 'settings', component: AllSettingsComponent, canActivate: [AuthService] },
  { path: 'subscription', component: SubscriptionDetailComponent, canActivate: [AuthService] },
  { path: 'team', component: TeamDetailComponent, canActivate: [AuthService] },
  { path: 'invites', component: PersonalInvitesComponent, canActivate: [AuthService] },
  { path: 'invite/:id', component: AcceptInviteComponent, canActivate: [AuthService] },
  { path: 'admin', component: AllAdminComponent, canActivate: [AuthService] },
  { path: 'intro-request/:id', component: AcceptIntroRequestComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
