<div class="flex-row-left">
  <h2>Settings</h2>
</div>

<mat-tab-group [selectedIndex]="selectedTab.value"
               (selectedIndexChange)="setTab($event)"
>

  <mat-tab label="Account">
    <div class="tab-container">
      <account-settings></account-settings>
    </div>
  </mat-tab>

  <mat-tab label="Social Accounts">
    <div class="tab-container">
      <social-account-list></social-account-list>
    </div>
  </mat-tab>

  <mat-tab label="Email Accounts">
    <div class="tab-container">
      <email-account-list></email-account-list>
    </div>
  </mat-tab>

  <mat-tab label="Contact Imports">
    <div class="tab-container">
      <contact-import-list></contact-import-list>
    </div>
  </mat-tab>

  <mat-tab label="Unsubscribe List">
    <div class="tab-container">
      <unsubscribe-list></unsubscribe-list>
    </div>
  </mat-tab>

  <mat-tab label="API Keys">
    <div class="tab-container">
      <api-key-list></api-key-list>
    </div>
  </mat-tab>

</mat-tab-group>
