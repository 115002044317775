import {Injectable} from "@angular/core";
import {Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {MatDialog} from "@angular/material/dialog";

export interface ApiKey {
  id: number
  apiKey: string
  label: string
  teamId: number
  createdAt: number
  createdByUserId: number
}

export interface ApiKeyResult {
  id: number
  api_key: string
  label: string
  team_id: number
  createdAt: number
  created_by_user_id: number
}

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {

  public list!: ApiKey[]
  private listSubject: Subject<ApiKey[]> = new Subject<ApiKey[]>()
  list$: Observable<ApiKey[]> = this.listSubject.asObservable()

  private endpoint: string = 'api_keys'

  constructor(private api: ApiService,
              private dialog: MatDialog,
  ) {
  }

  getAll(forceGet: boolean = false): Observable<ApiKey[]> {
    if (this.list && !forceGet) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<ApiKey[]>()
      this.api.get<ApiKeyResult[]>('my/' + this.endpoint, {}).subscribe(
        (results: ApiKeyResult[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        }
      )
      return resultSubject.asObservable()
    }
  }

  add(label: string): Observable<ApiKey[]> {
    const addObservable: Observable<ApiKey[]> = this.api.post(this.endpoint, {
      label: label,
    })
    return this.updateList(addObservable)
  }

  edit(apiKey: ApiKey): Observable<ApiKey[]> {
    const editObservable: Observable<ApiKey[]> = this.api.put(this.endpoint + '/' + apiKey.id, {
      label: apiKey.label,
    })
    return this.updateList(editObservable)
  }

  delete(apiKey: ApiKey): Observable<ApiKey[]> {
    const deleteObservable: Observable<ApiKey[]> = this.api.delete(this.endpoint + '/' + apiKey.id)
    return this.updateList(deleteObservable)
  }

  updateList(update$: Observable<ApiKey[]>): Observable<ApiKey[]> {
    const updateSubject: Subject<ApiKey[]> = new Subject<ApiKey[]>()
    update$.subscribe(
      (results: ApiKey[]) => {
        this.getAll(true).subscribe(list => {
          updateSubject.next(FormatUtils.snakeToCamelCaseKeys(results))
        })
      }
    )
    return updateSubject.asObservable()
  }

}
