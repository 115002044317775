<table mat-table [dataSource]="inviteTable" matSort #inviteSort="matSort">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef class="thin-col">
      <mat-checkbox (change)="$event ? selectAllInvites() : null"
                    [checked]="inviteSelection.hasValue() && areAllInvitesSelected()"
                    [indeterminate]="inviteSelection.hasValue() && !areAllInvitesSelected()"
      ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? inviteSelection.toggle(row) : null"
                    [checked]="inviteSelection.isSelected(row)"
      ></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell
        *matHeaderCellDef
        matTooltip="Select some or all team members to act on"
        class="thin-col"
    >
      <button mat-icon-button
              [matMenuTriggerFor]="bulkActionsMenu"
              [disabled]="inviteSelection.selected.length === 0"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #bulkActionsMenu="matMenu">
        <button mat-menu-item (click)="resendSelectedInvites()">
          Resend
        </button>
        <button mat-menu-item (click)="deleteSelectedInvites()">
          Delete
        </button>
      </mat-menu>
    </th>
    <td mat-cell *matCellDef="let invite; let i = index;">
      <button mat-icon-button
              [matMenuTriggerFor]="actionsMenu"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        <button mat-menu-item (click)="resendInvite(invite)">
          Resend
        </button>
        <button mat-menu-item (click)="confirmDeleteInvite(invite)">
          Delete
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td mat-cell *matCellDef="let invite; let i = index;">
      {{invite.contactEmail || invite.contact.profile.primaryEmail}}
    </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
    <td mat-cell *matCellDef="let invite; let i = index;">
      {{formatDate(invite.createdAt)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let invite; let i = index;">
      {{invite.status | titlecase}}
    </td>
  </ng-container>

  <ng-container matColumnDef="acceptedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Accepted</th>
    <td mat-cell *matCellDef="let invite; let i = index;">
      {{invite.acceptedAt !== null ? formatDate(invite.acceptedAt) : ''}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="inviteColumns"></tr>
  <tr mat-row
      *matRowDef="let invite; let i = index; columns: inviteColumns;"
      class="table-row"
      [class.table-row-selected]="isInviteSelected(invite)"
  ></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [colSpan]="numInviteCols">
      <div *ngIf="!loading" class="no-table-data">No pending invites</div>
      <div class="loading-overlay" *ngIf="loading" >
        <div class="loading-wrapper">
          <mat-spinner color="accent"></mat-spinner>
        </div>
      </div>
    </td>
  </tr>
</table>

<mat-paginator #invitePaginator
               [pageSizeOptions]="[25, 50, 100]"
               showFirstLastButtons
               *ngIf="inviteTable.data.length > 25"
></mat-paginator>
