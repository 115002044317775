import {Injectable} from '@angular/core';
import {Subject, Observable, of, map, mergeMap} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {Subscription, SubscriptionResult} from "./billing.service";
import {AccessRight, User, UserPagination, UserPaginationResult, UserResult, UserService} from "./user.service";
import {Audience, AudienceResult} from "./audience.service";
import {ContactListItem} from "./contact.service";
import {ShareRequest} from "./share-request.service";

export interface Team {
  id: number
  name: string
  subscriptions: Subscription[]
  numTeamMembers: number
  teamMembers: TeamMember[]
}

export interface TeamResult {
  id: number
  name: string
  subscriptions: SubscriptionResult[]
  num_team_members: number
  team_members: TeamMemberResult[]
}

export interface TeamPagination {
  itemsReceived: number
  curPage: number
  nextPage: number
  prevPage: number
  offset: number
  itemsTotal: number
  pageTotal: number
  items: Team[]
}

export interface TeamPaginationResult {
  itemsReceived: number
  curPage: number
  nextPage: number
  prevPage: number
  offset: number
  itemsTotal: number
  pageTotal: number
  items: TeamResult[]
}


export interface TeamMember {
  id: number
  teamId: number
  team: Team
  userId: number
  user?: User
  role: TeamMemberRole
  accessRights: AccessRight[]
}

export interface TeamMemberResult {
  id: number
  team_id: number
  team: TeamResult
  user_id: number
  user?: UserResult
  role: TeamMemberRole
  access_rights: AccessRight[]
}

export enum TeamMemberRole {
  admin = 'admin',
  requester = 'requester',
  member = 'member',
}

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  public team: Team = this.new()
  private teamSubject = new Subject<Team>()
  team$: Observable<Team> = this.teamSubject.asObservable()

  private teamEndpoint: string = 'teams'
  private teamMemberEndpoint: string = 'team_members'

  constructor(private api: ApiService,
  ) {
  }

  getTeam(teamId: number, forceGet: boolean = false): Observable<Team> {
    if (this.team && this.team.id > 0 && !forceGet) {
      this.teamSubject.next(this.team)
      return of(this.team)
    } else {
      const resultSubject: Subject<Team> = new Subject<Team>()
      this.api.get<TeamResult>('teams/' + teamId, {}).subscribe(
        (team: TeamResult) => {
          resultSubject.next(FormatUtils.snakeToCamelCaseKeys(team))
          this.setTeam(team)
        }
      )
      return resultSubject.asObservable()
    }
  }

  setTeam(teamResult: TeamResult | Team, convertResult: boolean = true): Team {
    this.team = convertResult ? FormatUtils.snakeToCamelCaseKeys(teamResult) : teamResult
    this.teamSubject.next(this.team)
    return this.team
  }

  getPage(
    searchTerm: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ): Observable<TeamPagination> {
    return this.api.get<TeamPaginationResult>(
      this.teamEndpoint,
      {
        search_term: searchTerm,
        page_number: pageNumber,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
      }
    ).pipe(
      map(
        (teamPaginationResults: TeamPaginationResult) => {
          return Object.assign(
            teamPaginationResults,
            { items: FormatUtils.snakeToCamelCaseKeys(teamPaginationResults.items) }
          )
        }
      )
    )
  }

  save(team: Team): Observable<Team> {
    return this.api.save<TeamResult>(this.teamEndpoint, {
      id: team.id,
      name: team.name,
    }).pipe(
      map(teamResult => this.setTeam(teamResult))
    )
  }

  delete(team: Team) {
    return this.api.delete<boolean>(this.teamEndpoint + "/" + team.id)
  }

  saveTeamMember(teamMember: TeamMember): Observable<Team> {
    return this.api.save<TeamResult>(this.teamMemberEndpoint, teamMember).pipe(
      map(teamResult => this.setTeam(teamResult, true))
    )
  }

  deleteTeamMembers(team: Team, teamMembers: TeamMember[]): Observable<Team> {
    return this.api.post<Team>(
      this.teamEndpoint + '/' + team.id + '/delete_team_members',
      { team_member_ids: teamMembers.map( m => { return m.id }) }
    ).pipe(
      map(teamResult => this.setTeam(teamResult, true))
    )
  }

  getNumRequesters(team: Team): number {
    return team.teamMembers.filter(
      m => { return m.role === TeamMemberRole.admin || m.role === TeamMemberRole.requester }
    ).length
  }

  new(): Team {
    return {
      id: 0,
      name: '',
      subscriptions: [],
      numTeamMembers: 0,
      teamMembers: [],
    }
  }

}
