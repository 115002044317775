<mat-drawer-container class="width-100">
  <mat-drawer #drawer
              mode="side"
              position="end"
              autosize
              fixedInViewport="true"
              [opened]="!onboardingCompleted()"
              fxHide.lt-lg
              class="horiz-padding onboarding-drawer"
  >
    <onboarding-steps class="width-100"></onboarding-steps>
  </mat-drawer>
  <div class="section-panel">
    <div class="flex-row-space-between section-title-row">
      <h1>Network Requests</h1>
      <button mat-flat-button
              color="primary"
              (click)="addShareRequest()"
              aria-label="Create network request"
              class="horiz-margin-2x"
      >Create<span fxHide.xs> Network Request</span></button>
    </div>
    <div class="flex-row-space-between-top flex-wrap">
      <mat-card class="card-panel" ngClass.xs="width-100" ngClass.gt-xs="card-panel-normal-width">
        <mat-card-header>
          <mat-card-title>Created by My Team</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="vert-padding">
            <table mat-table [dataSource]="createdByTableData" matSort #createdBySort="matSort">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.intro"
                           [icon]="faHandshake"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.email"
                           [icon]="faEnvelope"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.xPost"
                           [icon]="faXTwitter"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.linkedinPost"
                           [icon]="faLinkedin"
                  ></fa-icon>
                  {{shareRequest.name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.intro"
                           [icon]="faHandshake"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.email"
                           [icon]="faEnvelope"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.xPost"
                           [icon]="faXTwitter"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.linkedinPost"
                           [icon]="faLinkedin"
                  ></fa-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editShareRequest(shareRequest)">
                  {{shareRequest.status | titlecase}}
                </td>
              </ng-container>

              <ng-container matColumnDef="numSharers">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header">Active Contacts</th>
                <td mat-cell
                    *matCellDef="let shareRequest; let i = index;"
                    (click)="editShareRequest(shareRequest)"
                    class="text-align-center"
                >
                  {{shareRequest.numSharers}}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                <td mat-cell
                    *matCellDef="let shareRequest; let i = index;"
                    (click)="editShareRequest(shareRequest)"
                >
                  {{formatDate(shareRequest.createdAt)}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="createdByColumns"></tr>
              <tr mat-row
                  *matRowDef="let shareRequest; let i = index; columns: createdByColumns;"
                  class="table-row pointer"
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="createdByNumCols">
                  <div *ngIf="!loading" class="no-table-data">
                    <span *ngIf="!canViewShareRequests">
                      You don't have access to view this team's network requests.<br>Switch the selected team or ask your admin to change your role.
                    </span>
                    <span *ngIf="canViewShareRequests">
                      No network requests created yet
                    </span>
                  </div>
                  <div class="loading-overlay" *ngIf="loading">
                    <div class="loading-wrapper">
                      <mat-spinner color="accent"></mat-spinner>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <mat-paginator #createdByPaginator
                           *ngIf="createdByTableData.data.length > 5"
            ></mat-paginator>

          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="card-panel" ngClass.xs="width-100" ngClass.gt-xs="card-panel-normal-width">
        <mat-card-header>
          <mat-card-title>Requested Of Me</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="vert-padding">
            <table mat-table [dataSource]="requestedOfTableData" matSort #requestedOfSort="matSort" #requestedOfTable>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.intro"
                           [icon]="faHandshake"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.email"
                           [icon]="faEnvelope"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.xPost"
                           [icon]="faXTwitter"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.linkedinPost"
                           [icon]="faLinkedin"
                  ></fa-icon>
                  {{shareRequest.name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.intro"
                           [icon]="faHandshake"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.email"
                           [icon]="faEnvelope"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.xPost"
                           [icon]="faXTwitter"
                  ></fa-icon>
                  <fa-icon *ngIf="shareRequest.type === ShareRequestType.linkedinPost"
                           [icon]="faLinkedin"
                  ></fa-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let shareRequest; let i = index;" (click)="editSharer(shareRequest)">
                  {{getSharerStatusLabel(shareRequest, shareRequest.sharers[0]) | titlecase}}
                </td>
              </ng-container>

              <ng-container matColumnDef="numProspects">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header"># of Prospects</th>
                <td mat-cell
                    *matCellDef="let shareRequest; let i = index;"
                    (click)="editSharer(shareRequest)"
                    class="text-align-center"
                >
                  {{shareRequest.sharers[0].numProspects}}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Requested By</th>
                <td mat-cell
                    *matCellDef="let shareRequest; let i = index;"
                    (click)="editSharer(shareRequest)"
                >
                  {{getUserDisplayName(shareRequest.createdByUser)}}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                <td mat-cell
                    *matCellDef="let shareRequest; let i = index;"
                    (click)="editShareRequest(shareRequest)"
                >
                  {{formatDate(shareRequest.createdAt)}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="requestedOfColumns"></tr>
              <tr mat-row
                  *matRowDef="let shareRequest; let i = index; columns: requestedOfColumns;"
                  class="table-row pointer"
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="requestedOfNumCols">
                  <div *ngIf="!loading" class="no-table-data">
                    No network requests have been requested yet
                  </div>
                  <div class="loading-overlay" *ngIf="loading">
                    <div class="loading-wrapper">
                      <mat-spinner color="accent"></mat-spinner>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <mat-paginator #requestedOfPaginator
                           *ngIf="requestedOfTableData.data.length > 5"
            ></mat-paginator>

          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="section-panel">
    <div class="flex-row-space-between section-title-row">
      <h1>My Network</h1>
      <button mat-flat-button
              color="primary"
              (click)="openInviteDialog()"
              class="horiz-margin-2x"
      >Invite<span fxHide.xs> Contacts</span></button>
    </div>
    <div class="horiz-padding">
      <network-summary></network-summary>
    </div>

    <div class="flex-row-space-between-top flex-wrap">
      <mat-card class="card-panel" ngClass.xs="width-100" ngClass.gt-xs="card-panel-normal-width">
        <mat-card-header>
          <mat-card-title>Top Contacts Helping Me</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="vert-padding">
            <table mat-table [dataSource]="helpingMeTableData" matSort #helpingMeSort="matSort">

              <ng-container matColumnDef="avatar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let summary; let i = index;">
                  <profile-avatar [contact]="summary.contact"></profile-avatar>
                </td>
              </ng-container>

              <ng-container matColumnDef="contactName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
                <td mat-cell *matCellDef="let summary; let i = index;">
                  {{getContactDisplayName(summary.contact)}}
                </td>
              </ng-container>

              <ng-container matColumnDef="numShareRequests">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header">
                  <span fxShow.gt-xs fxHide.xs># of Network Requests</span>
                  <span fxShow.xs fxHide.gt-xs>#</span>
                </th>
                <td mat-cell *matCellDef="let summary; let i = index;" class="text-align-center">
                  {{summary.shareRequestCount | number}}
                </td>
              </ng-container>

              <ng-container matColumnDef="lastHelpedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Helped</th>
                <td mat-cell *matCellDef="let summary; let i = index;">
                  {{formatDate(summary.lastHelpedAt)}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="helpingMeColumns"></tr>
              <tr mat-row
                  *matRowDef="let shareRequest; let i = index; columns: helpingMeColumns;"
                  class="table-row pointer"
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="helpingMeColumns.length">
                  <div *ngIf="!loading" class="no-table-data">Nobody is helping you yet</div>
                  <div class="loading-overlay" *ngIf="loading">
                    <div class="loading-wrapper">
                      <mat-spinner color="accent"></mat-spinner>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <mat-paginator #helpingMePaginator
                           *ngIf="helpingMeTableData.data.length > 5"
            ></mat-paginator>

          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="card-panel" ngClass.xs="width-100" ngClass.gt-xs="card-panel-normal-width">
        <mat-card-header>
          <mat-card-title>Top Contacts I'm Helping</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="vert-padding">
            <table mat-table [dataSource]="helpingThemTableData" matSort #helpingThemSort="matSort">

              <ng-container matColumnDef="avatar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let summary; let i = index;">
                  <profile-avatar [contact]="summary.contact"></profile-avatar>
                </td>
              </ng-container>

              <ng-container matColumnDef="contactName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
                <td mat-cell *matCellDef="let summary; let i = index;">
                  {{getContactDisplayName(summary.contact)}}
                </td>
              </ng-container>

              <ng-container matColumnDef="numShareRequests">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header">
                  <span fxShow.gt-xs fxHide.xs># of Network Requests</span>
                  <span fxShow.xs fxHide.gt-xs>#</span>
                </th>
                <td mat-cell *matCellDef="let summary; let i = index;" class="text-align-center">
                  {{summary.shareRequestCount | number}}
                </td>
              </ng-container>

              <ng-container matColumnDef="lastHelpedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Helped</th>
                <td mat-cell *matCellDef="let summary; let i = index;">
                  {{formatDate(summary.lastHelpedAt)}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="helpingThemColumns"></tr>
              <tr mat-row
                  *matRowDef="let shareRequest; let i = index; columns: helpingThemColumns;"
                  class="table-row pointer"
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="helpingThemColumns.length">
                  <div *ngIf="!loading" class="no-table-data">You're not helping anybody yet</div>
                  <div class="loading-overlay" *ngIf="loading">
                    <div class="loading-wrapper">
                      <mat-spinner color="accent"></mat-spinner>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <mat-paginator #helpingThemPaginator
                           *ngIf="helpingThemTableData.data.length > 5"
            ></mat-paginator>

          </div>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

  <div class="flex-row-space-between section-title-row">
    <h1>
      My Contacts
      <span fxHide.xs> {{totalContacts >= 0 ? ' (' + totalContacts.toLocaleString('en') + ')' : ''}}</span>
    </h1>
    <div class="flex-row-left">
      <mat-spinner *ngIf="loading && !importing" color="accent" [diameter]="24" class="horiz-margin-2x"></mat-spinner>
      <mat-spinner *ngIf="!loading && importing"
                   fxHide.xs
                   color="accent" [diameter]="24"
                   class="horiz-margin-1x"
                   matTooltip="You have an ongoing contact import.  We'll email you when it's done."
      ></mat-spinner>
      <import-button label="Add Contacts" (onAdd)="refreshContactData()" class="horiz-margin-2x"></import-button>
    </div>
  </div>
  <contact-analytics></contact-analytics>
</mat-drawer-container>
