<div ngClass="flex-row-left width-100">
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput
           id="search"
           type="text"
           data-lpignore="true"
           [formControl]="searchTermControl"
           (blur)="search()"
    >
  </mat-form-field>
</div>
<div class="width-100">
  <table mat-table [dataSource]="userTable" matSort #sort="matSort">

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
      <td mat-cell *matCellDef="let user; let i = index;">{{user.firstName}}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
      <td mat-cell *matCellDef="let user; let i = index;">{{user.lastName}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let user; let i = index;">{{user.email}}</td>
    </ng-container>

    <ng-container matColumnDef="onboardingCompleted">
      <th mat-header-cell *matHeaderCellDef>Onboarding</th>
      <td mat-cell *matCellDef="let user; let i = index;">
        <div *ngFor="let onboardingStep of user.onboardingCompleted">
          {{formatOnboardingStep(onboardingStep) | titlecase}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="numContacts">
      <th mat-header-cell *matHeaderCellDef class="center-table-header"># of Contacts</th>
      <td mat-cell *matCellDef="let user; let i = index;" class="text-align-center">
        {{user.numContacts | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="numUserOauths">
      <th mat-header-cell *matHeaderCellDef class="center-table-header"># of User Oauths</th>
      <td mat-cell *matCellDef="let user; let i = index;" class="text-align-center">
        {{user.userOauths.length | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="numTeams">
      <th mat-header-cell *matHeaderCellDef class="center-table-header"># of Teams</th>
      <td mat-cell *matCellDef="let user; let i = index;" class="text-align-center">
        {{user.teamMembers.length | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="createDate">
      <th mat-header-cell *matHeaderCellDef class="center-table-header">Date Created</th>
      <td mat-cell *matCellDef="let user; let i = index;">
        {{formatDate(user.createdAt)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="thin-col"></th>
      <td mat-cell *matCellDef="let user; let i = index;">
        <button mat-icon-button
                [matMenuTriggerFor]="userActionMenu"
                class="action-button">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #userActionMenu="matMenu">
          <button mat-menu-item (click)="confirmDeleteUser(user)">
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let user; let i = index; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="numDisplayedColumns">
        <div *ngIf="!loading" class="no-table-data">No users</div>
        <div class="loading-overlay" *ngIf="loading">
          <div class="loading-wrapper">
            <mat-spinner color="accent"></mat-spinner>
          </div>
        </div>
      </td>
    </tr>
  </table>

  <mat-paginator #paginator
                 [length]="totalUsers"
                 [pageSizeOptions]="[25, 50, 100]"
                 showFirstLastButtons
  ></mat-paginator>
</div>
