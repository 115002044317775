import {Component, OnInit, ViewChild} from '@angular/core';
import {
  ShareRequest,
  ShareRequestRole,
  ShareRequestService,
  ShareRequestStatus,
  ShareRequestType
} from "../../../services/share-request.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {AccessRight, User, UserService} from "../../../services/user.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {NotificationService} from "../../../services/utilities/notification.service";
import {Router} from "@angular/router";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {FormControl} from "@angular/forms";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'share-request-list',
  templateUrl: './share-request-list.component.html',
  styleUrls: ['./share-request-list.component.scss']
})
export class ShareRequestListComponent implements OnInit {

  user: User = this.userService.new()
  canMutateShareRequests: boolean = true
  canViewShareRequests: boolean = true

  loading: boolean = true
  selectedTab = new FormControl(0)

  createdByColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['name', 'type', 'status']
    : ['select', 'actions', 'name', 'type', 'status', 'numSharers', 'metrics', 'createdAt']
  createdByNumCols: number = this.createdByColumns.length
  createdByTable: MatTableDataSource<ShareRequest> = new MatTableDataSource()
  createdBySelection = new SelectionModel<ShareRequest>(true, []);
  @ViewChild('createdByPaginator') createdByPaginator!: MatPaginator
  @ViewChild('createdBySort') createdBySort!: MatSort

  requestedOfColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['name', 'type', 'status']
    : ['name', 'type', 'status', 'metrics', 'createdBy', 'createdAt']
  requestedOfNumCols: number = this.requestedOfColumns.length
  requestedOfTable: MatTableDataSource<ShareRequest> = new MatTableDataSource()
  @ViewChild('requestedOfPaginator') requestedOfPaginator!: MatPaginator
  @ViewChild('requestedOfSort') requestedOfSort!: MatSort

  formatDate = FormatUtils.formatDate
  pluralize = FormatUtils.pluralize
  getDisplayName = this.userService.getDisplayName

  ShareRequestType = ShareRequestType
  ShareRequestStatus = ShareRequestStatus

  constructor(private userService: UserService,
              private shareRequestService: ShareRequestService,
              private dialogService: DialogService,
              private notificationService: NotificationService,
              private router: Router,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.userService.user$.subscribe(
      user => {
        this.user = user
        this.canMutateShareRequests = this.userService.hasAccessRight(this.user, AccessRight.mutateShareRequests)
        this.canViewShareRequests = this.userService.hasAccessRight(this.user, AccessRight.viewShareRequests)

        this.shareRequestService.list$.subscribe(
          shareRequests => {
            const createdShareRequests = shareRequests.filter(s => {
              return s.role === ShareRequestRole.creator
            })
            this.createdByTable = new MatTableDataSource(createdShareRequests)
            this.createdByTable.sort = this.createdBySort
            setTimeout(() => {
              if (this.createdByPaginator) {
                this.createdByTable.paginator = this.createdByPaginator
                this.createdByTable.paginator.pageSize = 25
              }
            })

            const sharedShareRequests = shareRequests.filter(s => {
              return s.role === 'sharer'
            })
            this.requestedOfTable = new MatTableDataSource(sharedShareRequests)
            this.requestedOfTable.sort = this.requestedOfSort
            setTimeout(() => {
              if (this.requestedOfPaginator) {
                this.requestedOfTable.paginator = this.requestedOfPaginator
                this.requestedOfTable.paginator.pageSize = 25
              }
            })

            if (createdShareRequests.length === 0 && sharedShareRequests.length > 0) {
              this.selectedTab.setValue(1)
            }

            this.loading = false
          }
        )
        this.shareRequestService.getAll(true)
      }
    )
    this.userService.getIdentity()
  }

  addShareRequest(){
    if (this.canMutateShareRequests) {
      const newShareRequest = this.shareRequestService.new()
      this.shareRequestService.save(newShareRequest).subscribe(
        savedSharedRequest => {
          this.userService.getIdentity(true)
          this.editShareRequest(savedSharedRequest)
        }
      )
    } else {
      this.dialogService.messageDialog(
        'Need More Access',
        "You do not have access to create network requests on this team.  Please ask your admin to change your role or change your current team selected."
      )
    }
  }

  editSharer(shareRequest: ShareRequest){
    if (shareRequest.sharers && shareRequest.sharers.length > 0)
      this.router.navigate(['sharer', shareRequest.sharers[0].id])
  }

  editShareRequest(shareRequest: ShareRequest){
    this.router.navigate(['share-request', shareRequest.id])
  }

  activateShareRequest(shareRequest: ShareRequest) {
    shareRequest.status = ShareRequestStatus.active
    this.shareRequestService.save(shareRequest).subscribe(
      shareRequest => {
        this.notificationService.success('Network request activated.')
      }
    )
  }

  deactivateShareRequest(shareRequest: ShareRequest) {
    shareRequest.status = ShareRequestStatus.inactive
    this.shareRequestService.save(shareRequest).subscribe(
      shareRequest => {
        this.notificationService.success('Network request deactivated.')
      }
    )
  }

  isAllSelected() {
    const numSelected = this.createdBySelection.selected.length;
    const numRows = this.createdByTable.data.length;
    return numSelected === numRows;
  }

  isSelected(shareRequest: ShareRequest) {
    return this.createdBySelection.selected.filter(s => s.id === shareRequest.id).length > 0
  }

  /** Selects all rows if they are not all selected; otherwise clear createdBySelection. */
  selectAll() {
    this.isAllSelected() ?
      this.createdBySelection.clear() :
      this.createdByTable.data.forEach(row => this.createdBySelection.select(row));
  }

  deleteSelectedShareRequests() {
    this.dialogService.confirmDialog(
      'Delete Selected Network Requests',
      'Are you sure you want to try and delete ' + this.createdBySelection.selected.length + this.pluralize(' network request', this.createdBySelection.selected.length) + '?'
    ).subscribe(
      result => {
        if (result) {
          let count = 0
          this.createdBySelection.selected.forEach(shareRequest => {
            if (this.deleteShareRequest(shareRequest)) {
              count++
              //this.createdBySelection.deselect(shareRequest)
            }
          })
          if (count > 0) {
            this.createdBySelection.clear()
          }
          this.notificationService.success(count + this.pluralize(' network request', count) + ' deleted.')
        }
      }
    )
  }

  deleteShareRequest(shareRequest: ShareRequest): boolean {
    if (!this.hasSent(shareRequest)) {
      this.shareRequestService.delete(shareRequest)
      return true
    } else {
      return false
    }
  }

  confirmDeleteShareRequest(shareRequest: ShareRequest) {
    this.dialogService.confirmDialog(
      'Delete Network Request',
      'Are you sure you want to delete this network request?'
    ).subscribe(result => {
      if (result) {
        this.deleteShareRequest(shareRequest)
      }
    })
  }

  hasSent(shareRequest: ShareRequest): boolean {
    return this.shareRequestService.hasSent(shareRequest)
  }

  getTypeLabel(type: string): string {
    return this.shareRequestService.getTypeLabel(type)
  }

}
