import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {EditorComponent} from "@tinymce/tinymce-angular";
import {DialogService} from "../../../services/utilities/dialog.service";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MessageTemplate, MessageTemplateService} from "../../../services/message-template.service";

@Component({
  selector: 'message-editor',
  templateUrl: './message-editor.component.html',
  styleUrls: ['./message-editor.component.scss']
})
export class MessageEditorComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(EditorComponent, { static: true }) public editorComponent!: EditorComponent;

  @Input() subject: string = ''
  @Input() content: string = ''
  @Input() messageTemplateId: number = 0
  @Input() hideMessageTemplates: boolean = false
  @Input() readOnly: boolean = false

  @Output() onChange = new EventEmitter()

  messageTemplates: MessageTemplate[] = []

  messageForm = new FormGroup({
    nameControl: new FormControl(),
    subjectControl: new FormControl(),
    contentControl: new FormControl(),
    messageTemplateControl: new FormControl(),
  })

  apiKey: string = 'fieuueqdd8vqr7ssk86srao0ln28fykemnxianf5vbctvtgh'
  editorConfig = {
    branding: false,
    menubar: false,
    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen powerpaste advtable advcode editimage mergetags fullscreen',
    toolbar: 'undo redo | mergetags | formatting | link image media table emoticons | numlist bullist indent outdent | spellcheckdialog code fullscreen help',
    toolbar_groups: {
      formatting: {
        icon: 'format',
        tooltip: 'Formatting',
        items: 'blocks fontfamily fontsize | bold italic underline blockquote | align lineheight | removeformat'
      }
    },
    mergetags_list: [
      { value: 'firstname', title: 'First Name' },
      { value: 'lastname', title: 'Last Name' },
      { value: 'company', title: 'Company' },
      { value: 'title', title: 'Title' },
      { value: 'viewrequesturl', title: 'View Request URL' },
      { value: 'signature', title: 'Signature' },
      { value: 'contactId', title: 'Contact Id' },
    ],
    mergetags_prefix: '<#',
    mergetags_suffix: '#>',
    height: 350,
    readonly: false,
    setup: (ed: any) => {
      ed.on('init', (args: any) => {
        this.setReadOnly()
      })
    }
  }

  constructor(private dialogService: DialogService,
              private notificationService: NotificationService,
              private messageTemplateService: MessageTemplateService,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.messageTemplateService.list$.subscribe(
      messageTemplates => {
        this.messageTemplates = messageTemplates
        this.selectMessageTemplateById()
      }
    )
    this.messageTemplateService.getAll()

    this.messageForm.patchValue({
      subjectControl: this.subject as string,
      contentControl: this.content as string,
    })
    this.setReadOnly(this.readOnly)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['subject']) {
      const subChange = changes['subject'].currentValue
      this.messageForm.patchValue({'subjectControl': subChange})
    }

    if (changes['content']) {
      const contentChange = changes['content'].currentValue
      this.messageForm.patchValue({'contentControl': contentChange})
    }

    if (changes['messageTemplateId']) {
      this.messageTemplateId = changes['messageTemplateId'].currentValue
      this.selectMessageTemplateById()
    }

    if (changes['readOnly']) {
      this.setReadOnly(changes['readOnly'].currentValue)
    }

  }

  setMessageForm(subject: string, content: string) {
    this.messageForm.patchValue({
      subjectControl: subject as string,
      contentControl: content as string,
    })
  }

  setReadOnly(readOnly: boolean = this.readOnly) {
    this.readOnly = readOnly
    this.editorConfig.readonly = this.readOnly
    if (this.editorComponent && this.editorComponent.editor) {
      this.editorComponent.setDisabledState(this.readOnly)
      //this.editorComponent.editor.getBody().setAttribute('contenteditable', 'false')
      //this.editorComponent.editor.mode.set('readonly')
    }
  }

  redrawEditor(){
    this.editorComponent?.editor?.remove()
    this.editorComponent?.initialise()
    this.editorComponent.initialValue = this.messageForm.value.contentControl
  }

  change() {
    this.onChange.emit({
      subject: this.messageForm.value.subjectControl,
      content: this.messageForm.value.contentControl,
    })
  }

  selectMessageTemplateById() {
    const index = this.messageTemplates.findIndex(m => m.id === this.messageTemplateId)
    if (index >= 0) {
      this.messageForm.patchValue({
        nameControl: this.messageTemplates[index].name,
        messageTemplateControl: this.messageTemplates[index],
      })
      this.selectMessageTemplate()
    }
  }

  selectMessageTemplate() {
    if (this.messageTemplateId <= 0 && (
      this.messageForm.value.subjectControl !== '' || this.messageForm.value.contentControl !== '')
    ) {
      this.dialogService.confirmDialog(
        'Overwrite Existing Message',
        'You already have written a message.  Are you sure you want to replace it with this message template?'
      ).subscribe(result => {
        if (result) {
          this.setMessageForm(
            this.messageForm.value.messageTemplateControl.subject,
            this.messageForm.value.messageTemplateControl.content
          )
          this.change()
        } else {
          this.messageForm.patchValue({
            nameControl: undefined,
            messageTemplateControl: undefined,
          })
        }
      })
    } else {
      this.setMessageForm(
        this.messageForm.value.messageTemplateControl.subject,
        this.messageForm.value.messageTemplateControl.content
      )
      this.change()
    }
  }

  finishSaveMessageTemplate(name: string, id?: number) {
    let messageTemplate: MessageTemplate = {
      id: id,
      name: name,
      subject: this.messageForm.value.subjectControl,
      content: this.messageForm.value.contentControl,
    }
    if (!id)
      delete messageTemplate.id
    this.messageTemplateService.save(messageTemplate).subscribe(newMessageTemplate => {
      this.messageForm.patchValue({
        nameControl: newMessageTemplate.name,
        messageTemplateControl: newMessageTemplate,
      })
      this.notificationService.success('Message template saved.')
    })
  }

  saveMessageTemplate(saveAs: boolean) {
    if (saveAs) {
      this.dialogService.inputDialog(
        'Save Message Template',
        'Please enter a name for your message template.',
        'Name',
        '',
        'Save',
        'Cancel',
      ).subscribe(name => {
        if (name) {
          this.finishSaveMessageTemplate(name)
        }
      })
    } else if (this.messageTemplateId > 0) {
      this.finishSaveMessageTemplate(
        this.messageForm.value.nameControl,
        this.messageForm.value.messageTemplateControl.id,
      )
    } else {
      this.finishSaveMessageTemplate(
        this.messageForm.value.messageTemplateControl.name,
        this.messageForm.value.messageTemplateControl.id,
      )
    }
  }

  confirmDeleteMessageTemplate() {
    this.dialogService.confirmDialog(
      'Delete Message Template',
      'Are you sure you want to delete this message template?'
    ).subscribe(result => {
      if (result) {
        this.messageTemplateService.delete(this.messageForm.value.messageTemplateControl).subscribe(() => {
          this.messageForm.patchValue({
            messageTemplateControl: undefined
          })
          this.notificationService.success('Message template deleted.')
        })
      }
    })
  }

  clearMessageTemplate() {
    this.messageForm.patchValue({
      messageTemplateControl: undefined
    })
  }

  compareMessageTemplates(a: any, b: any) {
    return a && b && a.id === b.id
  }

}
