import {Injectable} from '@angular/core';
import {Observable, of, Subject, mergeMap, switchMap, merge, startWith, map} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {ContactListItem} from "./contact.service";
import {Params} from "@angular/router";

export interface KeywordFilter {
  value: string,
  not: boolean,
}

export interface ConvertedKeywordFilters {
  posValues: string[],
  negValues: string[],
}

export interface AudienceFilterOption {
  label: string,
  value: string,
  count?: number,
}

export enum AudienceType {
  user = 'user',
  system = 'system',
}

export interface Audience {
  id?: number
  label: string
  title: string[]
  titleNot: string[]
  seniority: string[]
  bizFunction: string[]
  connection: string
  name: string[]
  nameNot: string[]
  emailAddress: string[]
  emailAddressNot: string[]
  emailType: string
  lastEmailedAt: string
  neverEmailed: boolean
  contactType: string
  companyName: string[]
  companyNameNot: string[]
  companyDomain: string[]
  companyDomainNot: string[]
  companySize: string[]
  companyRevenue: string[]
  companyIndustry: string[]
  companyType: string[]
  companyStage: string[]
  investorType: string[]
  investmentStage: string[]
  profileIds: number[]
  profileIdsNot: number[]
  sharerAudienceList: []
  prospectAudienceList: []
  type: AudienceType
  createdAt?: number
  createdByUserId?: number
  teamId?: number
  modifiedAt?: number
  modified_by_user_id?: number
}

export interface AudienceResult {
  id?: number
  label: string
  title: string[]
  title_not: string[]
  seniority: string[]
  biz_function: string[]
  connection: string
  name: string[]
  name_not: string[]
  email_address: string[]
  email_address_not: string[]
  email_type: string
  last_emailed_at: string
  never_emailed: boolean
  contact_type: string
  company_name: string[]
  company_name_not: string[]
  company_domain: string[]
  company_domain_not: string[]
  company_size: string[]
  company_industry: string[]
  company_type: string[]
  company_stage: string[]
  investor_type: string[]
  investment_stage: string[]
  contact_ids: number[]
  contact_ids_not: number[]
  sharer_audience_list: []
  prospect_audience_list: []
  type: AudienceType
  created_at?: number
  created_by_user_id?: number
  team_id?: number
  modified_at?: number
  modified_by_user_id?: number
}

export interface SerializedAudience {
  id?: string | null
  title?: string | null
  titleNot?: string | null
  seniority?: string | null
  bizFunction?: string | null
  connection?: string | null
  name?: string | null
  nameNot?: string | null
  emailAddress?: string | null
  emailAddressNot?: string | null
  emailType?: string | null
  lastEmailedAt?: string | null
  neverEmailed?: string | null
  contactType?: string | null
  companyName?: string | null
  companyNameNot?: string | null
  companyDomain?: string | null
  companyDomainNot?: string | null
  companySize?: string | null
  companyRevenue?: string | null
  companyIndustry?: string | null
  companyType?: string | null
  companyStage?: string | null
  investorType?: string | null
  investmentStage?: string | null
}

export enum AudienceEditorMode {
  filters = 'filters', // slim column of search filters for contact
  singular = 'singular', // force only 1 audience to be edited
  edit = 'edit', // can create new or select existing audience and edit
  editShort = 'editShort', // same as edit but only used when creating network requests so some fields are hidden to simplify the UI
}

@Injectable({
  providedIn: 'root'
})
export class AudienceService {

  public list!: Audience[]
  private listSubject: Subject<Audience[]> = new Subject<Audience[]>()
  list$: Observable<Audience[]> = this.listSubject.asObservable()

  private endpoint: string = 'audiences'

  public seniorityOptions: AudienceFilterOption[] = [
    { "label": "Owner", "value": "owner" },
    { "label": "Partner", "value": "partner" },
    { "label": "CXO", "value": "cxo" },
    { "label": "VP", "value": "vp" },
    { "label": "Director", "value": "director" },
    { "label": "Manager", "value": "manager" },
    { "label": "Senior", "value": "senior" },
    { "label": "Entry", "value": "entry" },
    { "label": "Training", "value": "training" },
    { "label": "Unpaid", "value": "unpaid" },
  ]

  public bizFunctionOptions: AudienceFilterOption[] = [
    { "label": "Customer Service", "value": "customer service" },
    { "label": "Design", "value": "design" },
    { "label": "Education", "value": "education" },
    { "label": "Engineering", "value": "engineering" },
    { "label": "Finance", "value": "finance" },
    { "label": "Health", "value": "health" },
    { "label": "Human Resources", "value": "human resources" },
    { "label": "Legal", "value": "legal" },
    { "label": "Marketing", "value": "marketing" },
    { "label": "Media", "value": "media" },
    { "label": "Operations", "value": "operations" },
    { "label": "Public Relations", "value": "public relations" },
    { "label": "Real Estate", "value": "real estate" },
    { "label": "Sales", "value": "sales" },
    { "label": "Trades", "value": "trades" },
  ]

  public connectionOptions: AudienceFilterOption[] = [
    { "label": "All Connections", "value": "0" },
    { "label": "1st Degree", "value": "1" },
    { "label": "2nd Degree", "value": "2" },
  ]

  public emailTypeOptions: AudienceFilterOption[] = [
    { "label": "Any Email Type", "value": "" },
    { "label": "Work Emails Only", "value": "work" },
    { "label": "Personal Emails Only", "value": "personal" },
  ]

  public contactTypeOptions: AudienceFilterOption[] = [
    { "label": "Any Contact Type", "value": "" },
    { "label": "Users Only", "value": "user" },
    { "label": "Contacts Only", "value": "contact" },
  ]

  public lastEmailedOptions: AudienceFilterOption[] = [
    { "label": "", "value": "" },
    { "label": "Over 7 Days Ago", "value": "7" },
    { "label": "Over 14 Days Ago", "value": "14" },
    { "label": "Over 1 Month Ago", "value": "30" },
    { "label": "Over 3 Months Ago", "value": "90" },
    { "label": "Over 6 Months Ago", "value": "180" },
    { "label": "Over 1 Year Ago", "value": "365" },
  ]

  public companySizeOptions: AudienceFilterOption[] = [
    { "label": "1 - 10", "value": "1-10" },
    { "label": "10 - 50", "value": "10-50" },
    { "label": "50 - 200", "value": "50-200" },
    { "label": "200 - 500", "value": "200-500" },
    { "label": "500 - 1,000", "value": "500-1k" },
    { "label": "1,000 - 5,000", "value": "1k-5k" },
    { "label": "5,000 - 10,000", "value": "5k-10k" },
    { "label": "> 10,000", "value": "over-10k" },
  ]

  public companyRevenueOptions: AudienceFilterOption[] = [
    { "label": "< $1M", "value": "under-1m" },
    { "label": "$1M - $10M", "value": "1m-10m" },
    { "label": "$10M - $50M", "value": "10m-50m" },
    { "label": "$50M - $100M", "value": "50m-100m" },
    { "label": "$100M - $200M", "value": "100m-200m" },
    { "label": "200M - $1B", "value": "200m-1b" },
    { "label": "> $1B", "value": "over-1b" },
  ]

  public companyStageOptions: AudienceFilterOption[] = [
    "Debt",
    "Pre-Seed",
    "Seed",
    "Series A",
    "Series B",
    "Series C",
    "Series D",
  ].map(o => { return { label: o, value: o } })

  public companyTypeOptions: AudienceFilterOption[] = [
    { "label": "Educational Institution", "value": "educational-institution" },
    { "label": "Government Agency", "value": "government-agency" },
    { "label": "Non-Profit", "value": "nonprofit" },
    { "label": "Partnership", "value": "partnership" },
    { "label": "Private", "value": "privately-held" },
    { "label": "Public", "value": "public-company" },
    { "label": "Self-Employed", "value": "self-employed" },
    { "label": "Sole-Proprietorship", "value": "sole-proprietorship" },
  ]

  public investorTypeOptions: AudienceFilterOption[] = [
    "Accelerator",
    "Angel Group",
    "Venture Capital",
    "Micro VC",
    "Private Equity Firm",
    "Corporate Venture Capital",
    "Family Investment Office",
    "Investment Bank",
    "Entrepreneurship Program",
    "Venture Debt",
    "Fund of Funds",
    "Hedge Fund",
    "Incubator",
    "Secondary Purchaser",
    "University Program",
    "Co-Working Space",
    "Government Office",
    "Syndicate",
    "Pension Funds",
  ].map(o => { return { label: o, value: o } })

  public investmentStageOptions: AudienceFilterOption[] = [
    "Seed",
    "Early Stage Venture",
    "Late Stage Venture",
    "Debt",
    "Private Equity",
    "Convertible Note",
    "Venture",
    "Crowdfunding",
    "Grant",
    "Non Equity Assistance",
    "Secondary Market",
    "Post-Ipo",
    "Initial Coin Offering",
  ].map(o => { return { label: o, value: o } })

  constructor(private api: ApiService,
  ) {
  }

  getAll(forceGet: boolean = false): Observable<Audience[]> {
    if (this.list && !forceGet) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject: Subject<Audience[]> = new Subject<Audience[]>()
      this.api.get<AudienceResult[]>('my_current_team/' + this.endpoint, {}).subscribe(
        (results: AudienceResult[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        }
      )
      return resultSubject.asObservable()
    }
  }

  save(audience: Audience, skipGetAll: boolean = false): Observable<Audience> {
    return this.update(
      this.api.save<Audience>(this.endpoint, audience),
      skipGetAll,
    )
  }

  delete(audience: Audience, skipGetAll: boolean = false) {
    return this.update(
      this.api.delete(this.endpoint + '/' + audience.id),
      skipGetAll
    )
  }

  update(update$: Observable<Audience>, skipGetAll: boolean = false): Observable<Audience> {
    const updateSubject: Subject<Audience> = new Subject<Audience>()
    update$.subscribe(
      (result: Audience) => {
        if (!skipGetAll) {
          this.getAll(true)
        }
        updateSubject.next(FormatUtils.snakeToCamelCaseKeys(result))
      }
    )
    return updateSubject.asObservable()
  }

  convertKeywordFiltersToSearch(keywordFilters: KeywordFilter[]): ConvertedKeywordFilters {
    const convertedFilters: ConvertedKeywordFilters = { posValues: [], negValues: []}
    keywordFilters.map(f => {
      if (f.not) {
        convertedFilters.negValues.push(f.value)
      } else {
        convertedFilters.posValues.push(f.value)
      }
    })
    return convertedFilters
  }

  convertSearchToKeywordFilters(posValues: string[], negValues: string[]): KeywordFilter[] {
    return posValues.map(v => { return { value: v, not: false } }).concat(
      negValues.map(v => { return { value: v, not: true } })
    )
  }

  isAudienceEmpty(audience: Audience): boolean {
    const ignoreKeys = ['id', 'createdAt', 'label', 'type', 'prospectAudienceList', 'sharerAudienceList', 'connection']
    for (const key in audience) {
      if (ignoreKeys.indexOf(key) < 0) {
        if (
          (Array.isArray(audience[key as keyof Audience]) && (audience[key as keyof Audience] as string[]).length > 0) ||
          (typeof audience[key as keyof Audience] === 'string' && audience[key as keyof Audience] !== '') ||
          (audience.connection !== '0')
        ) {
          return false
        }
      }
    }
    return true
  }

  getIndustryAutocompleteOptions(partial: string): Observable<AudienceFilterOption[]> {
    return this.api.get<AudienceFilterOption[]>(`industry/autocomplete`, {
      partial_label: partial
    })
  }

  getIndustryLabels(industryValues: string[]): Observable<AudienceFilterOption[]> {
    return this.api.post<AudienceFilterOption[]>(`industry/labels`, {
      values: industryValues
    })
  }

  deleteContacts(audience: Audience, contacts: ContactListItem[]): Observable<Audience> {
    return this.update(
      this.api.post<Audience>(
        this.endpoint + '/' + audience.id + '/delete_contacts',
        { profile_ids: contacts.map( c => { return c.profileId }) }
      )
    )
  }

  addContacts(audience: Audience, contacts: ContactListItem[]): Observable<Audience> {
    return this.update(
      this.api.post<Audience>(
        this.endpoint + '/' + audience.id + '/add_contacts',
        { profile_ids: contacts.map( c => { return c.profileId }) }
      )
    )
  }

  hasAnyFilters(audience: Audience): boolean {
    return this.hasPersonFilters(audience) || this.hasCompanyFilters(audience)
  }

  hasPersonFilters(audience: Audience): boolean {
    return audience &&
      (
        audience.seniority.length > 0 ||
        audience.bizFunction.length > 0 ||
        audience.title.length > 0 ||
        audience.titleNot.length > 0 ||
        audience.name.length > 0 ||
        audience.nameNot.length > 0 ||
        audience.emailAddress.length > 0 ||
        audience.emailAddressNot.length > 0 ||
        audience.emailType != '' ||
        audience.connection != '0' ||
        audience.lastEmailedAt != '' ||
        audience.neverEmailed ||
        audience.profileIds.length > 0
      )
  }

  hasCompanyFilters(audience: Audience): boolean {
    return audience &&
      (
        audience.companyName.length > 0 ||
        audience.companyNameNot.length > 0 ||
        audience.companyDomain.length > 0 ||
        audience.companyDomainNot.length > 0 ||
        audience.companySize.length > 0 ||
        audience.companyRevenue.length > 0 ||
        audience.companyType.length > 0 ||
        audience.companyStage.length > 0 ||
        audience.companyIndustry.length > 0 ||
        audience.investorType.length > 0 ||
        audience.investmentStage.length > 0
      )
  }

  getNewSerializedAudience(): SerializedAudience {
    return {
      id: null,
      title: null,
      titleNot: null,
      seniority: null,
      bizFunction: null,
      connection: null,
      name: null,
      nameNot: null,
      emailAddress: null,
      emailAddressNot: null,
      emailType: null,
      contactType: null,
      lastEmailedAt: null,
      neverEmailed: null,
      companyName: null,
      companyNameNot: null,
      companyDomain: null,
      companyDomainNot: null,
      companySize: null,
      companyRevenue: null,
      companyIndustry: null,
      companyType: null,
      companyStage: null,
      investorType: null,
      investmentStage: null,
    }
  }

  convertAudienceToUrlParams(audience: Audience): any {
    let urlAudience: SerializedAudience = this.getNewSerializedAudience()
    Object.keys(audience).map(
      key => {
        if (urlAudience.hasOwnProperty(key)) {
          if (FormatUtils.isEmpty(audience[key as keyof Audience])) {
            urlAudience[key as keyof SerializedAudience] = null
          } else {
            urlAudience[key as keyof SerializedAudience] = JSON.stringify(audience[key as keyof Audience])
          }
        }
      }
    )
    if (audience.connection === '0') {
      delete urlAudience.connection
    }
    return urlAudience
  }

  updateAudienceFromUrlParams(audience: Audience, params: Params): Audience {
    if (!!audience && !!params) {
      let urlAudience: SerializedAudience = this.getNewSerializedAudience()
      Object.keys(params).map(
        (key: string) => {
          if (urlAudience.hasOwnProperty(key)) {
            this.setAudienceProperty(JSON.parse(params[key]), audience, key as keyof Audience)
          }
        }
      )
    }
    return audience
  }

  createFirstProspectAudience(): Observable<Audience> {
    const audience = this.new()
    audience.label = 'My First Prospect Audience'
    return this.save(audience)
  }

  createFirstSharerAudience(): Observable<Audience> {
    const audience = this.new()
    audience.label = 'My First Contacts Audience'
    return this.save(audience)
  }

  setAudienceProperty<
    key extends keyof Audience,
    value extends Audience[key]
  >(
    value: value,
    obj: Audience,
    prop: key
  ) {
    obj[prop] = value
  }

  new(): Audience {
    return {
      id: 0,
      label: 'New Audience',
      title: [],
      titleNot: [],
      seniority: [],
      bizFunction: [],
      connection: '0',
      name: [],
      nameNot: [],
      emailAddress: [],
      emailAddressNot: [],
      emailType: '',
      contactType: '',
      lastEmailedAt: '',
      neverEmailed: false,
      companyName: [],
      companyNameNot: [],
      companyDomain: [],
      companyDomainNot: [],
      companySize: [],
      companyRevenue: [],
      companyIndustry: [],
      companyType: [],
      companyStage: [],
      investorType: [],
      investmentStage: [],
      profileIds: [],
      profileIdsNot: [],
      sharerAudienceList: [],
      prospectAudienceList: [],
      type: AudienceType.user,
      createdAt: 0,
    }
  }
}
