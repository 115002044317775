<div ngClass="flex-row-left width-100">
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput
           id="search"
           type="text"
           data-lpignore="true"
           [formControl]="searchTermControl"
           (blur)="search()"
    >
  </mat-form-field>
</div>
<div class="width-100">
  <table mat-table [dataSource]="teamTable" matSort #sort="matSort">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let team; let i = index;">{{team.name}}</td>
    </ng-container>

    <ng-container matColumnDef="numMembers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-table-header"># of Members</th>
      <td mat-cell *matCellDef="let team; let i = index;" class="text-align-center">{{team.numTeamMembers}}</td>
    </ng-container>

    <ng-container matColumnDef="subscription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscription</th>
      <td mat-cell *matCellDef="let team; let i = index;">
        <div *ngIf="team.subscriptions && team.subscriptions.length > 0">
          <div>{{team.subscriptions[0].status | titlecase}}</div>
          <div>{{'$' + formatPrice(team.subscriptions[0].totalPrice) + ' / ' + team.subscriptions[0].interval}}</div>
          <div>{{formatShareRequestTypes(team.subscriptions[0].shareRequestTypes) | titlecase}}</div>
          <div>{{formatDate(team.subscriptions[0].startDate) + ' - ' + (team.subscriptions[0].cancelAt ? formatDate(team.subscriptions[0].cancelAt) : 'Today')}}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="thin-col"></th>
      <td mat-cell *matCellDef="let team; let i = index;">
        <button mat-icon-button
                [matMenuTriggerFor]="userActionMenu"
                class="action-button">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #userActionMenu="matMenu">
          <button mat-menu-item (click)="confirmDeleteTeam(team)">
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let team; let i = index; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="numDisplayedColumns">
        <div *ngIf="!loading" class="no-table-data">No teams</div>
        <div class="loading-overlay" *ngIf="loading">
          <div class="loading-wrapper">
            <mat-spinner color="accent"></mat-spinner>
          </div>
        </div>
      </td>
    </tr>
  </table>

  <mat-paginator #paginator
                 [length]="totalUsers"
                 [pageSizeOptions]="[25, 50, 100]"
                 showFirstLastButtons
  ></mat-paginator>
</div>
