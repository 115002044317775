import {ContactListItem, ContactService, ContactSummaryValue, NetworkSummary} from "../../../services/contact.service";
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {Audience, AudienceService} from "../../../services/audience.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {InviteService} from "../../../services/invite.service";
import {User, UserService} from "../../../services/user.service";
import {faLinkedin, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {OauthAccountListItem, OauthService} from "../../../services/oauth.service";
import {MatDialog} from "@angular/material/dialog";
import {AutoHelpEditModalComponent} from "../auto-help-edit-modal/auto-help-edit-modal.component";
import {NotificationService} from "../../../services/utilities/notification.service";
import {MatDrawer} from "@angular/material/sidenav";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'network-summary',
  templateUrl: './network-summary.component.html',
  styleUrls: ['./network-summary.component.scss']
})
export class NetworkSummaryComponent implements OnInit, OnChanges {

  @Input() filters: Audience = this.audienceService.new()
  @Input() showTable: boolean = false

  @Output() onFinish = new EventEmitter()
  @Output() onDelete = new EventEmitter()

  protected readonly faXTwitter = faXTwitter
  protected readonly faEnvelope = faEnvelope
  protected readonly faLinkedin = faLinkedin
  protected readonly faCircleInfo = faCircleInfo

  networkResults: NetworkSummary[] = []
  user!: User

  contactSum: number = 0
  noFilters: boolean = true
  loading: boolean = true

  displayedColumns: string[] = (
    this.breakpointObserver.isMatched(Breakpoints.XSmall) ||
    this.breakpointObserver.isMatched(Breakpoints.Small) ||
    this.breakpointObserver.isMatched(Breakpoints.Medium)
  )
    ? ['actions', 'fullName', 'numContacts', 'autoHelpingThem', 'autoHelpingMe']
    : ['actions', 'fullName', 'title', 'companyName', 'numContacts', 'hasIntegrated', 'autoHelpingThem', 'autoHelpingMe']
  numDisplayColumns: number = this.displayedColumns.length
  networkResultsTable: MatTableDataSource<NetworkSummary> = new MatTableDataSource()
  @ViewChild('sort') sort!: MatSort
  @ViewChild('paginator') paginator!: MatPaginator
  selection = new SelectionModel<NetworkSummary>(true, [])

  viewProfileIndex: number = -1
  minItems: number = 0
  @ViewChild('networkContactDrawer') networkContactDrawer!: MatDrawer

  pluralize = FormatUtils.pluralize
  getDisplayName = this.userService.getDisplayName

  constructor(private contactService: ContactService,
              private audienceService: AudienceService,
              private inviteService: InviteService,
              private userService: UserService,
              private oauthService: OauthService,
              private breakpointObserver: BreakpointObserver,
              private dialog: MatDialog,
              private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.userService.user$.subscribe(
      user => { this.user = user }
    )

    this.noFilters = this.audienceService.isAudienceEmpty(this.filters)
    this.contactService.networkSummary$.subscribe(
      (networkResults: NetworkSummary[]) => {
        this.networkResults = networkResults
        this.contactSum = 0
        this.networkResults.forEach((c: NetworkSummary) => this.contactSum += c.numContacts)

        if (this.onFinish) {
          this.onFinish.emit(this.contactSum)
        }

        if (this.showTable) {
          this.networkResultsTable = new MatTableDataSource<NetworkSummary>(this.networkResults)
          this.networkResultsTable.sort = this.sort
          const sortState: Sort = {active: 'fullName', direction: 'asc'}
          this.sort.active = sortState.active
          this.sort.direction = sortState.direction
          this.sort.sortChange.emit(sortState)
          setTimeout(() => {
            if (this.paginator) {
              this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))
              this.networkResultsTable.paginator = this.paginator
              this.networkResultsTable.paginator.pageSize = 10
            }
          })
        }

        this.loading = false
      }
    )
    this.contactService.getNetworkResults(true, this.filters)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filters = changes['filters'].currentValue
    this.noFilters = this.audienceService.isAudienceEmpty(this.filters)
    if (!this.loading) {
      this.loading = true
      this.contactService.getNetworkResults(true, this.filters)
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.networkResultsTable.data.length
    return numSelected === numRows
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.networkResultsTable.data.forEach(contact => this.selection.select(contact))
  }

  editAutoHelpPreferences(userContact: NetworkSummary) {
    const contact = this.contactService.new()
    contact.id = userContact.contactId
    contact.profileId = userContact.user.profile?.id || -1
    contact.userId = userContact.userId
    contact.firstName = userContact.user.firstName
    contact.lastName = userContact.user.lastName
    contact.contactType = 'user'
    contact.autoCompleteTweetRequests = userContact.autoCompleteTweetRequests
    contact.autoCompleteTwitterOauthId = userContact.autoCompleteTwitterOauthId
    contact.autoCompleteTweetFilters = userContact.autoCompleteTweetFilters
    contact.autoCompleteLinkedinPostRequests = userContact.autoCompleteLinkedinPostRequests
    contact.autoCompleteLinkedinOauthId = userContact.autoCompleteLinkedinOauthId
    contact.autoCompleteLinkedinPostFilters = userContact.autoCompleteLinkedinPostFilters
    contact.userOauths = userContact.user.userOauths

    this.dialog.open(AutoHelpEditModalComponent, {
      data: contact
    }).afterClosed().subscribe(
      (updatedContact: ContactListItem) => {
        if (updatedContact) {
          this.contactService.save(updatedContact).subscribe(
            savedContact => {
              userContact.autoCompleteTweetRequests = savedContact.autoCompleteTweetRequests
              userContact.autoCompleteTwitterOauthId = savedContact.autoCompleteTwitterOauthId
              userContact.autoCompleteTweetFilters = savedContact.autoCompleteTweetFilters
              userContact.autoCompleteLinkedinPostRequests = savedContact.autoCompleteLinkedinPostRequests
              userContact.autoCompleteLinkedinOauthId = savedContact.autoCompleteLinkedinOauthId
              userContact.autoCompleteLinkedinPostFilters = savedContact.autoCompleteLinkedinPostFilters
              this.notificationService.success('Preference saved.')
            }
          )
        }
      }
    )
  }

  openInviteDialog() {
    this.inviteService.openInviteDialog(false, [])
  }

  hasEmailAccount(userOauths: OauthAccountListItem[]) {
    return this.oauthService.hasEmailAccount(userOauths)
  }

  hasTwitterAccount(userOauths: OauthAccountListItem[]) {
    return this.oauthService.hasTwitterAccount(userOauths)
  }

  hasLinkedInAccount(userOauths: OauthAccountListItem[]) {
    return this.oauthService.hasLinkedInAccount(userOauths)
  }

  viewContact(index: number) {
    this.viewProfileIndex = index
    if (this.viewProfileIndex >= 0) {
      this.networkContactDrawer.open()
    }
  }

  contactDeletedFromDetail() {
    this.onDelete.emit()
  }

}
