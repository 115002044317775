import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {Audience} from "./audience.service";
import {ContactListItem, ContactListItemResult, ContactService} from "./contact.service";
import {ShareRequest, ShareRequestType} from "./share-request.service";

export interface Sharer {
  id: number
  shareRequestId: number
  contactId: number
  contact: ContactListItem
  contactEmail: string
  status: SharerStatus
  subjectOverride: string
  contentOverride: string
  numProspects: number
  prospects: Prospect[]
  userId: number
  userOauthId: number
  emailStatus: SharerEmailStatus
  sendAfterImport: boolean
  lastWizardStep: number
  createdAt: number
  createdByUserId: number
  completedAt: number
}

export interface SharerResult {
  id: number
  share_request_id: number
  contact_id: number
  contact: ContactListItem
  contact_email: string
  status: SharerStatus
  subject_override: string
  content_override: string
  num_prospects: number
  prospects: ProspectResult[]
  user_id: number
  user_oauth_id: number
  email_status: SharerEmailStatus
  send_after_import: boolean
  last_wizard_step: number
  created_at: number
  created_by_user_id: number
  completed_at: number
}

export interface Prospect {
  id: number
  shareRequestSharerId: number
  contactId: number
  contact: ContactListItem
  status: ProspectStatus
  createdAt: number
  createdByUserId: number
}

export interface ProspectResult {
  id: number
  share_request_sharer_id: number
  contact_id: number
  contact: ContactListItemResult
  status: ProspectStatus
  created_at: number
  created_by_user_id: number
}

export enum ProspectStatus {
  pending = 'pending',
  unsubscribed = 'unsubscribed',
  sent = 'sent',
  completed = 'completed',
}

export enum SharerWizardStatus {
  messageReview = 'message reviewed',
  emailConnected = 'email connected',
  contactsAdded = 'contacts added',
}

export enum SharerEmailStatus {
  pending = 'pending',
  waiting = 'waiting to add contacts',
  sending = 'emailing contacts',
  paused = 'paused',
  done = 'all contacts emailed',
}

export enum SharerStatus {
  pending = 'pending',
  requestSent = 'request sent',
  unsubscribed = 'unsubscribed',
  requestViewed = 'request viewed',
  completed = 'completed',
}

@Injectable({
  providedIn: 'root'
})
export class SharerService {
  public detail!: Sharer
  private detailSubject: Subject<Sharer> = new Subject<Sharer>()
  detail$: Observable<Sharer> = this.detailSubject.asObservable()

  private endpoint: string = 'share_request_sharers'

  constructor(private api: ApiService,
              private contactService: ContactService,
  ) {
  }

  getDetail(id: number, forceGet: boolean = false) {
    if (this.detail && !forceGet) {
      this.detailSubject.next(this.detail)
    } else {
      this.api.get<SharerResult>(`${this.endpoint}/${id}`).subscribe(
        (result: SharerResult) => {
          this.setDetail(FormatUtils.snakeToCamelCaseKeys(result))
        }
      )
    }
  }

  setDetail(sharer: Sharer) {
    this.detail = sharer
    this.detailSubject.next(this.detail)
  }

  save(sharer: Sharer): Observable<Sharer> {
    return this.update(
      this.api.save<Sharer>(this.endpoint, sharer)
    )
  }

  delete(sharer: Sharer) {
    return this.update(
      this.api.delete(this.endpoint + '/' + sharer.id)
    )
  }

  update(update$: Observable<Sharer>): Observable<Sharer> {
    const updateSubject: Subject<Sharer> = new Subject<Sharer>()
    update$.subscribe(
      (result: Sharer) => {
        if (result) {
          this.detail = FormatUtils.snakeToCamelCaseKeys(result)
          this.detailSubject.next(this.detail)
        }
        updateSubject.next(FormatUtils.snakeToCamelCaseKeys(result))
      }
    )
    return updateSubject.asObservable()
  }

  deleteProspects(sharer: Sharer, contacts: ContactListItem[]): Observable<Sharer> {
    return this.update(
      this.api.post<Sharer>(
        this.endpoint + '/' + sharer.id + '/delete_prospects',
        { contact_ids: contacts.map( c => { return c.id }) }
      )
    )
  }

  addProspects(sharer: Sharer, contactIds: number[], audience: Audience | undefined): Observable<Sharer> {
    return this.update(
      this.api.post<Sharer>(
        this.endpoint + '/' + sharer.id + '/add_prospects',
        {
          contact_ids: contactIds,
          filters: FormatUtils.camelToSnakeCaseKeys(audience),
        }
      )
    )
  }

  retweet(sharer: Sharer): Observable<Sharer> {
    const resultSubject = new Subject<Sharer>()
    this.api.post<Sharer>(this.endpoint + '/' + sharer.id + '/retweet', {}).subscribe(
      (result: Sharer) => {
        this.getDetail(sharer.id, true)
        resultSubject.next(FormatUtils.snakeToCamelCaseKeys(result))
      }
    )
    return resultSubject.asObservable()
  }

  likeLinkedInPost(sharer: Sharer): Observable<Sharer> {
    const resultSubject = new Subject<Sharer>()
    this.api.post<Sharer>(this.endpoint + '/' + sharer.id + '/like_linkedin_post', {}).subscribe(
      (result: Sharer) => {
        this.getDetail(sharer.id, true)
        resultSubject.next(FormatUtils.snakeToCamelCaseKeys(result))
      }
    )
    return resultSubject.asObservable()
  }

  acceptIntroRequest(prospectId: number): Observable<boolean> {
    return this.api.post<boolean>(
      this.endpoint + '/accept_intro_request',
      {
        prospect_id: prospectId
      }
    )
  }

  canSend(sharer: Sharer): boolean {
    return !!sharer &&
      !!sharer.userOauthId &&
      sharer.userOauthId > 0 &&
      !!sharer.prospects &&
      sharer.prospects.length > 0
  }

  getSharerStatusLabel(shareRequest: ShareRequest, sharer: Sharer) {
    return (
      (shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro) &&
      sharer.status === SharerStatus.requestViewed
    ) ? sharer.emailStatus
      : sharer.status
  }

  new(): Sharer {
    return {
      id: 0,
      shareRequestId: 0,
      contactId: 0,
      contact: this.contactService.new(),
      contactEmail: '',
      status: SharerStatus.pending,
      subjectOverride: '',
      contentOverride: '',
      numProspects: 0,
      prospects : [],
      userId: 0,
      userOauthId: 0,
      emailStatus: SharerEmailStatus.pending,
      sendAfterImport: false,
      lastWizardStep: 0,
      createdAt: 0,
      createdByUserId: 0,
      completedAt: 0,
    }
  }

}
