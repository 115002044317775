<div>
  <mat-card class="card-container">
    <mat-card-content>
      <div class="form-container">
        <form [formGroup]="settingsForm" autocomplete="off">
          <div class="flex-row-left">
            <profile-avatar [user]="user" [large]="true" class="vert-margin horiz-margin-right"></profile-avatar>
            <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
            <input hidden (change)="onFileSelected($event)" #fileInput type="file" accept="image/*, .jpg, .png">
            <span class="horiz-margin-left">{{imageFile?.name}}</span>
          </div>
          <div class="flex-row-left" ngClass.xs="flex-wrap">
            <mat-form-field class="settings-field" >
              <mat-label>First Name</mat-label>
              <input matInput type="text" formControlName="firstName" required>
            </mat-form-field>
            <mat-form-field class="settings-field" >
              <mat-label>Last Name</mat-label>
              <input matInput type="text" formControlName="lastName" required>
            </mat-form-field>
          </div>
          <div class="flex-row-left" ngClass.xs="flex-wrap">
            <mat-form-field class="settings-field" >
              <mat-label>Title</mat-label>
              <input matInput type="text" formControlName="title">
            </mat-form-field>
            <mat-form-field class="settings-field" >
              <mat-label>Company</mat-label>
              <input matInput type="text" formControlName="companyName">
            </mat-form-field>
          </div>
          <div class="flex-row-left" ngClass.xs="flex-wrap">
            <mat-form-field class="settings-field" >
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" required>
            </mat-form-field>
          </div>
          <div class="flex-row-space-between">
            <button mat-raised-button (click)="changePassword()">
              {{user && user.hasPassword ? 'Change': 'Set'}} Password
            </button>
            <button mat-flat-button color="primary" (click)="save()" class="horiz-margin-right">
              Save
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
