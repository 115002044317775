import {Injectable} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {FormatUtils} from "./utilities/format.utilities";
import {OauthAction, OauthService} from "./oauth.service";
import {DialogService} from "./utilities/dialog.service";
import {OpenGraphPreview} from "./opengraph.service";

export interface LinkedInOrganization {
  id: number
  linkedinId: string
  name: string
}

export interface LinkedInOrganizationResult {
  id: number
  linkedin_id: string
  name: string
}

export interface LinkedInPost {
  id: number
  linkedinId: string
  linkedinOrganizationId: number
  linkedinOrganization?: LinkedInOrganization
  title: string
  description: string
  postContent: string
  source: string
  numComments: number
  numLikes: number
  createdAt?: number
  modifiedAt?: number
}

export interface LinkedInPostResult {
  id: number
  linkedin_id: string
  linkedin_organization_id: number
  linkedin_organization?: LinkedInOrganizationResult
  title: string
  description: string
  post_content: string
  source: string
  num_comments: number
  num_likes: number
  created_at?: number
  modified_at?: number
}

export interface RawLinkedInPost {
  id: string
  title: string
  text: string
  og: OpenGraphPreview
  likesCount: number
  commentsCount: number
  externalLink: string
  time: string
}

export interface RawLinkedInPostResult {
  id: string
  title: string
  text: string
  og: OpenGraphPreview
  likes_count: number
  comments_count: number
  external_link: string
  time: string
}

@Injectable({
  providedIn: 'root'
})
export class LinkedInService {
  public list!: LinkedInPost[]
  private listSubject: Subject<LinkedInPost[]> = new Subject<LinkedInPost[]>()
  list$: Observable<LinkedInPost[]> = this.listSubject.asObservable()

  private endpoint: string = 'linkedin_posts'

  constructor(private api: ApiService,
              private dialogService: DialogService,
              private oauthService: OauthService,
  ) {
  }

  getAllPosts(userOauthId: number, forceGet: boolean = false): Observable<LinkedInPost[]> {
    if (userOauthId === 0 || (this.list && !forceGet)) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<LinkedInPost[]>()
      this.api.get<LinkedInPost[]>('user_oauths/' + userOauthId + '/' + this.endpoint).subscribe(
        (results: LinkedInPost[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        },
        err => {
          if (err.code === 403) {
            this.askReauthenticateAccount()
          }
        }
      )
      return resultSubject.asObservable()
    }
  }

  getOrgPosts(linkedinOrgId: number): Observable<LinkedInPost[]> {
    const resultSubject = new Subject<LinkedInPost[]>()
    this.api.get<LinkedInPost[]>(this.endpoint + '/org/' + linkedinOrgId).subscribe(
      (results: LinkedInPost[]) => {
        resultSubject.next(results.map(FormatUtils.snakeToCamelCaseKeys))
      },
      err => {
        if (err.status === 403) {
          this.askReauthenticateAccount()
        }
      }
    )
    return resultSubject.asObservable()
  }

  askReauthenticateAccount() {
    this.dialogService.confirmDialog(
      "Re-authorize your LinkedIn account",
      "It appears Wrollo has lost access do your LinkedIn account, would you like to re-authenticate it?"
    ).subscribe(
      result => {
        if (result) {
          this.oauthService.doOauthLinkedIn(OauthAction.add, document.location.href)
        }
      }
    )
  }

  createLinkedInPost(shareRequestId: number, userOauthId: number, linkedinOrganizationId: number, title: string, postContent: string): Observable<LinkedInPost> {
    const resultSubject = new Subject<LinkedInPost>()
    this.api.post<LinkedInPost>(this.endpoint, {
      share_request_id: shareRequestId,
      user_oauth_id: userOauthId,
      linkedin_organization_id: linkedinOrganizationId,
      title: title,
      post_content: postContent,
    }).subscribe(
      (result: LinkedInPost) => {
        resultSubject.next(FormatUtils.snakeToCamelCaseKeys(result))
      },
      err => {
        resultSubject.error(err)
      }
    )
    return resultSubject.asObservable()
  }

  formatHashtagsLinks(postContent: string): string {
    return postContent
      .replace('\n', '<br><br>')
      .replace(/(https?:\/\/[a-zA-Z0-9.\\\/?=&]*)/g, "<a href='" + "$1" + "' style='color: #35ab52;'>" + "$1" + "</a>")
      .replace(/#(\w+)/g, '<a href="https://www.linkedin.com/feed/hashtag/?keywords=$1" style="color: #35ab52;">#$1</a>')
  }

  new(): LinkedInPost {
    return {
      id: 0,
      linkedinId: '',
      linkedinOrganizationId: 0,
      title: '',
      description: '',
      postContent: '',
      source: '',
      numComments: 0,
      numLikes: 0,
    }
  }

}
