import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MessageTemplate, MessageTemplateService} from "../../../services/message-template.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'message-template-list',
  templateUrl: './message-template-list.component.html',
  styleUrls: ['./message-template-list.component.scss']
})
export class MessageTemplateListComponent implements OnInit {

  selectedMessageTemplateId: number = 0
  messageTemplates: MessageTemplate[] = []

  loading: boolean = true

  displayedColumns: string[] = ['name', 'subject', 'createdAt']
  messageTemplateTable: MatTableDataSource<MessageTemplate> = new MatTableDataSource()
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('messageTemplateListSort') messageTemplateListSort!: MatSort

  formatDate = FormatUtils.formatDate

  constructor(private userService: UserService,
              private messageTemplateService: MessageTemplateService,
  ) {
  }

  ngOnInit() {
    this.messageTemplateService.list$.subscribe(messageTemplates => {
      this.messageTemplates = messageTemplates
      this.messageTemplateTable = new MatTableDataSource(this.messageTemplates)
      setTimeout(() => {
        if (this.paginator) {
          this.messageTemplateTable.paginator = this.paginator
          this.messageTemplateTable.paginator.pageSize = 25
        }
      })
      this.messageTemplateTable.sort = this.messageTemplateListSort

      if (this.selectedMessageTemplateId > 0) {
        const index = this.messageTemplates.findIndex(
          m => m.id === this.selectedMessageTemplateId
        )
        if (index < 0) {
          this.selectedMessageTemplateId = 0
        }
      }

      this.loading = false
    })
    this.messageTemplateService.getAll()

    this.userService.getIdentity()
  }

  addMessageTemplate(){
    this.messageTemplateService.save(this.messageTemplateService.new()).subscribe(
      newMessageTemplate => {
        this.editMessageTemplate(newMessageTemplate)
      }
    )
  }

  editMessageTemplate(messageTemplate: MessageTemplate){
    this.selectedMessageTemplateId = messageTemplate.id || -1
  }

}
