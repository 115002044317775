<div ngClass="flex-row-left width-100">
  <button mat-flat-button color="primary" (click)="createApiKey()" class="horiz-margin-right">
    Create API Key
  </button>
</div>
<div class="width-100">
  <table mat-table [dataSource]="apiKeyTable">

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>Label</th>
      <td mat-cell *matCellDef="let apiKey; let i = index;">{{apiKey.label}}</td>
    </ng-container>

    <ng-container matColumnDef="apiKey">
      <th mat-header-cell *matHeaderCellDef>API Key</th>
      <td mat-cell *matCellDef="let apiKey; let i = index;">{{apiKey.apiKey}}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let apiKey; let i = index;">{{formatDate(apiKey.createdAt)}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="thin-col"></th>
      <td mat-cell *matCellDef="let apiKey; let i = index;">
        <button mat-icon-button
                [matMenuTriggerFor]="apiKeyActionMenu"
                class="action-button">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #apiKeyActionMenu="matMenu">
          <button mat-menu-item (click)="editApiKey(apiKey)">
            Edit
          </button>
          <button mat-menu-item (click)="confirmDeleteApiKey(apiKey)">
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let apiKey; let i = index; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="numDisplayedColumns">
        <div *ngIf="!loading" class="no-table-data">No API keys</div>
        <div class="loading-overlay" *ngIf="loading">
          <div class="loading-wrapper">
            <mat-spinner color="accent"></mat-spinner>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
