<div class="dialog-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile>Change Password</h2>
    <div>
      <form [formGroup]="changePasswordForm">
        <div class="form-row" *ngIf="!hideOldPassword">
          <mat-form-field>
            <mat-label>Old Password</mat-label>
            <input matInput type="password" formControlName="oldPassword" required cdkFocusInitial>
            <mat-error *ngIf="changePasswordForm.get('oldPassword')?.touched && changePasswordForm.value.oldPassword === ''"
                       class="error-row"
            >Your old password is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field>
            <mat-label>New Password</mat-label>
            <input matInput type="password" formControlName="newPassword" required>
            <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('required')"
                       class="error-row"
            >A new password is required.</mat-error>
            <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('pattern')"
                       class="error-row"
                       matTooltip="Password must contain at least 1 number, 1 uppercase and 1 lowercase letter, 1 special character, and at least 8 characters."
            >Password is not strong enough.</mat-error>
          </mat-form-field>
        </div>
        <div class="error-row">

        </div>
        <div class="form-row">
          <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input matInput type="password" formControlName="confirmPassword" required>
            <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.hasError('required')">Please confirm your password.</mat-error>
            <mat-error *ngIf="changePasswordForm.value.newPassword !== changePasswordForm.value.confirmPassword">The passwords do not match.</mat-error>
          </mat-form-field>
        </div>
      </form>
      <div>{{errMessage}}</div>
    </div>
  </div>
  <div mat-dialog-actions class="action-row">
    <button mat-flat-button
            color="primary"
            (click)="save()"
            class="action-button"
            [disabled]="!changePasswordForm.valid || changePasswordForm.value.newPassword !== changePasswordForm.value.confirmPassword"
    >Save</button>
    <button mat-flat-button
            mat-dialog-close
            (click)="close(false)"
            class="action-button"
    >Cancel</button>
  </div>
</div>
