<mat-form-field *ngIf="readOnly" class="width-100">
  <mat-label>{{title}}</mat-label>
  <mat-chip-grid #readOnlyChips>
    <mat-chip-row *ngFor="let value of values" class="chip-row">
      {{value.label}}
    </mat-chip-row>
    <input matInput
           id="input-search"
           type="text"
           data-lpignore="true"
           [matChipInputFor]="readOnlyChips"
           [readonly]="true"
           class="read-only-input"
    />
  </mat-chip-grid>
</mat-form-field>
<mat-form-field *ngIf="!readOnly" class="width-100">
  <mat-label>{{title}}</mat-label>
  <mat-chip-grid #valueChips>
    <mat-chip-row *ngFor="let value of values" (removed)="removeValue(value)" class="chip-row">
      {{value.label}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input matInput
           type="text"
           id="value-search"
           data-lpignore="true"
           [matChipInputFor]="valueChips"
           [formControl]="newValueControl"
           [matAutocomplete]="auto"
           (keyup)="updateAutocompleteOptions($event)"
           (blur)="checkClearInput()"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of options | async"
                  [value]="option"
                  (onSelectionChange)="selectOption($event)"
      >
        {{option.label}}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>
