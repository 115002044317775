<mat-spinner color="accent" *ngIf="loading" class="width-100 margin-center"></mat-spinner>
<div *ngIf="!loading" class="signup-container">
  <div class="text-align-center" *ngIf="company">
    <img [src]="company.logoUrl || 'https://logo.clearbit.com/'+company.domains[0] + '?size=48'"
         onerror="this.src='../../../../assets/white_background.png'"
         class="company-logo"
    >
  </div>
  <h1 class="text-align-center">Log in to Wrollo</h1>
  <div *ngIf="!loading">
    <div class="oauth-btn-container">
      <oauth-button [type]="OauthType.google"
                    [action]="OauthAction.continue"
                    label="Log in with Google"
                    [redirectUri]="returnUrl"
      ></oauth-button>
      <!--
      <div (click)="doGoogleOauth()"
              class="google-oauth-btn"
      >
        <img src="assets/btn_google_signin_dark_normal_web.png"/>
      </div>
      -->
    </div>
    <div class="oauth-btn-container">
      <oauth-button [type]="OauthType.microsoft"
                    [action]="OauthAction.continue"
                    label="Log in with Outlook"
                    [redirectUri]="returnUrl"
      ></oauth-button>
    </div>
    <div class="oauth-btn-container">
      <oauth-button [type]="OauthType.twitter"
                    [action]="OauthAction.continue"
                    label="Log in with X"
                    [redirectUri]="returnUrl"
      ></oauth-button>
    </div>
    <div class="oauth-btn-container">
      <oauth-button [type]="OauthType.linkedin"
                    [action]="OauthAction.continue"
                    label="Log in with LinkedIn"
                    [redirectUri]="returnUrl"
      ></oauth-button>
    </div>
    <div class="text-align-center vert-padding">OR</div>
    <div>
      <form [formGroup]="form">
        <p>
          <mat-form-field class="login-field" >
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" required >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="login-field" >
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" required >
          </mat-form-field>
          <a href="/forget-password" class="signup-link">Forgot password?</a>
        </p>
        <div>{{errorMsg}}</div>

        <div class="action-row-container flex-row-space-between">
          <button mat-flat-button color="primary"  (click)="login()">Log in</button>
          <a href="/signup" class="signup-link">Sign up!</a>
        </div>
      </form>
    </div>
  </div>


</div>
