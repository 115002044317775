<div class="flex-row-left field-row" *ngIf="accounts.length === 0">
  <oauth-button [type]="OauthType.twitter"
                [action]="OauthAction.add"
                label="Add X account"
  ></oauth-button>
</div>
<div class="flex-row-left field-row" *ngIf="accounts.length > 0">
  <mat-form-field class="setup-field" *ngIf="readOnly">
    <mat-label>{{label}}</mat-label>
    <input matInput
           id="label-search"
           type="text"
           data-lpignore="true"
           readonly
           [value]="accountLabel"
    >
  </mat-form-field>
  <mat-form-field class="setup-field" *ngIf="!readOnly">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControl]="accountControl"
                (selectionChange)="change()"
    >
      <mat-option *ngFor="let account of accounts"
                  [value]="account.id"
      >
        {{account.username}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-mini-fab
          (click)="addAccount()"
          class="horiz-margin-1x new-btn white-btn"
          matTooltip="Add a new X account"
          *ngIf="!hideAddButton && !readOnly"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
