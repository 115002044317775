<div class="dialog-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile>
      Request Intro to {{contact.profile.fullName}}
    </h2>

    <div>
      <h4>My Contacts Who Know {{contact.profile.fullName}}</h4>
      <div *ngFor="let networkContact of contact.networkContacts; let i = index" class="flex-row-left">
        <div>
          <mat-checkbox [checked]="contactsSelected[i]"
                        [(ngModel)]="contactsSelected[i]"
          >
            <div class="flex-row-left">
              <profile-avatar [user]="networkContact.user" class="avatar"></profile-avatar>
              {{networkContact.user?.profile?.fullName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div *ngIf="!showAdvanced" class="vert-margin">
      <a (click)="showAdvanced = !showAdvanced" class="pointer">
        Advanced <fa-icon [icon]="faCaretDown"></fa-icon>
      </a>
    </div>

    <div *ngIf="showAdvanced">
      <div>
        <h4>Send From Email Account</h4>
        <select-email-account label=""
                              [accounts]="emailAccounts"
                              [userOauthId]="emailOauthId"
                              [hideAddButton]="true"
                              (onChange)="emailOauthChange($event)"
        ></select-email-account>    </div>

      <div>
        <h4>Message to My Contacts</h4>
        <div class="width-100">
          <mat-form-field class="width-100">
            <textarea matInput
                      role="textbox"
                      rows="4"
                      [(ngModel)]="sharerMessage"
                      class="message-field"
            ></textarea>
          </mat-form-field>
        </div>
      </div>

      <div>
        <h4>Message to {{contact.profile.fullName}}</h4>
        <div class="width-100">
          <mat-form-field class="width-100">
            <textarea matInput
                      role="textbox"
                      rows="4"
                      [(ngModel)]="prospectMessage"
                      class="message-field"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="vert-padding flex-row-left">
      <div [matTooltip]="canSend() ? '' : 'Please have at least one email account and select at least one person to introduce you.'">
        <button mat-raised-button
                color="primary"
                (click)="send()"
                [disabled]="!canSend()"
        >Send</button>
      </div>
      <button mat-raised-button
              mat-dialog-close
              (click)="close()"
              class="horiz-margin-1x"
      >Cancel</button>
    </div>
  </div>
</div>
