<div class="width-100">
  <h3>Get Started with Wrollo</h3>
  <mat-spinner color="accent" *ngIf="loading" class="margin-center"></mat-spinner>
  <div *ngIf="!loading">
    <mat-card class="width-100 vert-margin onboarding-card">
      <div class="flex-row-left-top">
        <div class="flex-col-top action-panel">
          <mat-icon *ngIf="addedEmailAccount"
                    class="step-checkbox"
          >check_circle</mat-icon>
          <button mat-mini-fab
                  color="primary"
                  (click)="doOauth(OauthType.google)"
                  matTooltip="Add a Gmail account"
                  *ngIf="!addedEmailAccount"
          >
            <fa-icon [icon]="faGoogle"></fa-icon>
          </button>
          <button mat-mini-fab
                  color="primary"
                  (click)="doOauth(OauthType.microsoft)"
                  class="vert-margin"
                  matTooltip="Add an Outlook account"
                  *ngIf="!addedEmailAccount"
          >
            <fa-icon [icon]="faMicrosoft"></fa-icon>
          </button>
        </div>
        <div class="flex-col-left">
          <div class="step-label"
               [ngClass]="{'step-label-completed': addedEmailAccount}"
          >Add an email account</div>
          <div *ngIf="!addedEmailAccount">
            <a (click)="skipStep(OnboardingStep.addedEmailAccount)" class="pointer skip-step-link">Skip</a>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="width-100 vert-margin onboarding-card">
      <div class="flex-row-left-top">
        <div class="flex-col-top action-panel">
          <mat-icon *ngIf="addedSocialAccount"
                    class="step-checkbox"
          >check_circle</mat-icon>
          <button mat-mini-fab
                  color="primary"
                  (click)="doOauth(OauthType.twitter)"
                  matTooltip="Add an X account"
                  *ngIf="!addedSocialAccount"
          >
            <fa-icon [icon]="faXTwitter"></fa-icon>
          </button>
          <button mat-mini-fab
                  color="primary"
                  (click)="doOauth(OauthType.linkedin)"
                  class="vert-margin"
                  matTooltip="Add a LinkedIn account"
                  *ngIf="!addedSocialAccount"
          >
            <fa-icon [icon]="faLinkedin"></fa-icon>
          </button>
        </div>
        <div class="flex-col-left">
          <div class="step-label"
               [ngClass]="{'step-label-completed': addedSocialAccount}"
          >Add a social account</div>
          <div *ngIf="!addedSocialAccount">
            <a (click)="skipStep(OnboardingStep.addedSocialAccount)" class="pointer skip-step-link">Skip</a>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="width-100 vert-margin onboarding-card">
      <div class="flex-row-left-top">
        <div class="flex-col-top action-panel">
          <mat-icon *ngIf="createdShareRequest"
                    class="step-checkbox"
          >check_circle</mat-icon>
          <button mat-mini-fab
                  color="primary"
                  (click)="addShareRequest()"
                  matTooltip="Create a network request"
                  *ngIf="!createdShareRequest"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="flex-col-left">
          <div class="step-label"
               [ngClass]="{'step-label-completed': createdShareRequest}"
          >Create a network request</div>
          <div *ngIf="!createdShareRequest">
            <a (click)="skipStep(OnboardingStep.createdShareRequest)" class="pointer skip-step-link">Skip</a>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="width-100 vert-margin onboarding-card">
      <div class="flex-row-left-top">
        <div class="flex-col-top action-panel">
          <mat-icon *ngIf="activatedShareRequest"
                    class="step-checkbox"
          >check_circle</mat-icon>
          <button mat-mini-fab
                  color="primary"
                  (click)="editFirstShareRequest()"
                  matTooltip="Create a network request"
                  *ngIf="!activatedShareRequest"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
        <div class="flex-col-left">
          <div class="step-label"
               [ngClass]="{'step-label-completed': activatedShareRequest}"
          >Activate your network request</div>
          <div *ngIf="!activatedShareRequest">
            <a (click)="skipStep(OnboardingStep.activatedShareRequest)" class="pointer skip-step-link">Skip</a>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="width-100 vert-margin onboarding-card">
      <div class="flex-row-left-top">
        <div class="flex-col-top action-panel">
          <mat-icon *ngIf="invitedColleagues"
                    class="step-checkbox"
          >check_circle</mat-icon>
          <button mat-mini-fab
                  color="primary"
                  (click)="openInviteDialog()"
                  matTooltip="See options to send invites"
                  *ngIf="!invitedColleagues"
          >
            <mat-icon>email</mat-icon>
          </button>
        </div>
        <div class="flex-col-left">
          <div class="step-label"
               [ngClass]="{'step-label-completed': invitedColleagues}"
          >Invite friends or colleagues</div>
          <div *ngIf="!invitedColleagues">
            <a (click)="skipStep(OnboardingStep.invitedColleagues)" class="pointer skip-step-link">Skip</a>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="width-100 vert-margin onboarding-card">
      <div class="flex-row-left-top">
        <div class="flex-col-top action-panel">
          <mat-icon *ngIf="setupAutomation"
                    class="step-checkbox"
          >check_circle</mat-icon>
          <button mat-mini-fab
                  color="primary"
                  (click)="goToSettings()"
                  matTooltip="Setup rules for auto-creation of network requests to your network"
                  *ngIf="!setupAutomation"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </div>
        <div class="flex-col-left">
          <div class="step-label"
               [ngClass]="{'step-label-completed': setupAutomation}"
          >Set up automation</div>
          <div *ngIf="!setupAutomation">
            <a (click)="skipStep(OnboardingStep.setupAutomation)" class="pointer skip-step-link">Skip</a>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
