<div class="flex-row-left">
  <h2>Message Templates</h2>

  <button mat-flat-button
          color="primary"
          (click)="addMessageTemplate()"
          aria-label="Create message template"
          class="action-button"
  >Create<span fxHide.xs> Message Template</span></button>
</div>

<div class="flex-row-left" ngClass.xs="flex-wrap">

  <div class="table-container" ngClass.xs="width-100" ngClass.gt-xs="width-50">
    <table mat-table [dataSource]="messageTemplateTable" matSort #messageTemplateListSort="matSort">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let messageTemplate; let i = index;">
          {{messageTemplate.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
        <td mat-cell *matCellDef="let messageTemplate; let i = index;">
          {{messageTemplate.subject}}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let messageTemplate; let i = index;">
          {{formatDate(messageTemplate.createdAt)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let messageTemplate; columns: displayedColumns;"
          (click)="editMessageTemplate(messageTemplate)"
          class="table-row pointer"
          [class.table-row-selected]="messageTemplate.id === selectedMessageTemplateId"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns.length">
          <div *ngIf="!loading" class="no-table-data">No message templates</div>
          <div class="loading-overlay" *ngIf="loading">
            <div class="loading-wrapper">
              <mat-spinner color="accent"></mat-spinner>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <mat-paginator #paginator
                   [pageSizeOptions]="[25, 50, 100]"
                   showFirstLastButtons
                   *ngIf="messageTemplateTable.data.length > 25"
    ></mat-paginator>
  </div>

  <div class="editor-container"
       ngClass.xs="width-100 vert-padding"
       ngClass.gt-xs="width-50"
       *ngIf="selectedMessageTemplateId > 0"
  >
    <div ngClass.xs="message-editor-border"></div>
    <message-editor [messageTemplateId]="selectedMessageTemplateId"
    ></message-editor>
  </div>

</div>
