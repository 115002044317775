<div class="progress-bar-container">
  <div *ngIf="!!contact && contact.connection == 1 && contact.relationshipStrength >= 0"
       [matTooltip]="'Relationship Strength: ' + contact.relationshipStrength + '%\nMessages Sent: ' + contact.numMessagesTo + '\nMessages Received: ' + contact.numMessagesFrom + (!!contact.firstMessageAt ? '\nFirst Message At: ' + formatDate(contact.firstMessageAt || 0) : '') + (!!contact.lastMessageAt ? '\nLast Message At: ' + formatDate(contact.lastMessageAt || 0) : '')"
       matTooltipClass="multiline-tooltip"
  >
    <mat-progress-bar color="primary" value="{{contact.relationshipStrength}}"></mat-progress-bar>
  </div>
  <div *ngIf="!!contact && contact.connection == 2 && contact.networkRelationshipStrength >= 0">
    <mat-progress-bar color="primary" value="{{contact.networkRelationshipStrength}}"></mat-progress-bar>
  </div>
</div>
