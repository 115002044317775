import {User} from "./user.service";
import {Injectable} from "@angular/core";
import {map, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {ShareRequestType} from "./share-request.service";
import {FormatUtils} from "./utilities/format.utilities";

export interface Subscription {
  id: number
  status: SubscriptionStatus
  startDate: number
  trialEnd: number
  shareRequestTypes: string[]
  quantity: number
  totalPrice: number
  interval: string
  cancelAt: number
  createdAt: number
  createdByUserId: number
}

export interface SubscriptionResult {
  id: number
  status: SubscriptionStatus
  start_date: number
  trial_end: number
  share_request_types: string[]
  quantity: number
  total_price: number
  interval: string
  cancelAt: number
  created_at: number
  created_by_user_id: number
}

export interface Product {
  id: number
  shareRequestType: string
  stripeProductId: string
  stripeDefaultPriceId: string
  defaultPrice: number
}

export interface ProductResult {
  id: number
  share_request_type: string
  stripe_product_id: string
  stripe_default_price_id: string
  default_price: number
}

export interface PlanOption {
  type: string,
  label: string
  description: string
  icon: string
  price: number
  selected: boolean
  numActiveShareRequests: number
  numPendingShareRequests: number
}

export enum SubscriptionStatus {
  incomplete = 'incomplete',                // requires payment method to complete setup
  incompleteExpired = 'incomplete_expired', // after 23 hours of being incomplete
  trialing = 'trialing',                    // in trial (not currently used)
  active = 'active',                        // valid
  pastDue = 'past_due',                     // past due invoice
  canceled = 'canceled',                    // canceled
  unpaid = 'unpaid',                        // latest invoice is open and unpaid, should revoke access
  paused = 'paused',                        // ended trial period without payment method (not currently used)
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private paymentLink: string = 'https://buy.stripe.com/test_eVaaItgfb9ba5Zm9AA'
  private emailParam: string = 'prefilled_email='
  private referenceIdParam: string = 'client_reference_id='

  private endpoint: string = 'billing/'
  private checkoutSessionsEndpoint: string = 'checkout_sessions'
  private portalSessionsEndpoint: string = 'portal_sessions'
  private subscriptionEndpoint: string = 'subscription'
  private productsEndpoint: string = 'products'

  public PLAN_OPTIONS: PlanOption[] = [
    {
      type: ShareRequestType.email,
      label: 'Email Requests',
      description: 'Ask your network to send an email to their contacts',
      icon: 'faEnvelope',
      price: 0,
      selected: false,
      numActiveShareRequests: 0,
      numPendingShareRequests: 0,
    },
    {
      type: ShareRequestType.xPost,
      label: 'X Repost Requests',
      description: 'Ask your network to repost your X posts',
      icon: 'faXTwitter',
      price: 0,
      selected: false,
      numActiveShareRequests: 0,
      numPendingShareRequests: 0,
    },
    {
      type: ShareRequestType.linkedinPost,
      label: 'LinkedIn Post Requests',
      description: 'Ask your network to like your LinkedIn posts',
      icon: 'faLinkedin',
      price: 0,
      selected: false,
      numActiveShareRequests: 0,
      numPendingShareRequests: 0,
    },
  ]

  constructor(private api: ApiService,
  ) {
  }

  getCheckoutLink(user: User, products: string[], quantity: number,  trial_days: number, returnUrl?: string): Observable<string> {
    return this.api.post(
        this.endpoint + this.checkoutSessionsEndpoint,
        {
          success_url: returnUrl || window.location.href,
          cancel_url: returnUrl || window.location.href,
          team_id: user.currentTeamId,
          user_id: user.id,
          products: products,
          quantity: quantity,
          trial_days: trial_days,
        }
    ).pipe(
      map((session: any) => session.url)
    )
  }

  getPaymentLink(user: User): string {
    return this.paymentLink + "?" + this.emailParam + encodeURIComponent(user.email) + "&" + this.referenceIdParam + user.currentTeamId + "_" + user.id
  }

  getPortalLink(user: User, returnUrl?: string): Observable<string> {
    return this.api.post(
        this.endpoint + this.portalSessionsEndpoint,
        {
          return_url: returnUrl || window.location.href,
          team_id: user.currentTeamId,
          user_id: user.id,
        }
    ).pipe(
      map((session: any) => session.url)
    )
  }

  getProducts(): Observable<Product[]> {
    return this.api.get(
      this.endpoint + this.productsEndpoint,
      {}
    ).pipe(
      map(products => FormatUtils.snakeToCamelCaseKeys(products))
    )
  }

  createOrUpdateSubscription(
    user: User,
    products: string[],
    quantity: number,
    trial_days: number
  ): Observable<Subscription | undefined> {
    const subscription = this.getUserSubscription(user)
    if (!subscription) {
      return this.api.post(
        this.endpoint + this.subscriptionEndpoint,
        {
          share_request_types: products,
          quantity: quantity,
          trial_days: trial_days,
        }
      ).pipe(
        map(subscription => FormatUtils.snakeToCamelCaseKeys(subscription))
      )
    } else {
      return this.updateSubscription(user, products, quantity)
    }
  }

  updateSubscription(
    user: User,
    products: string[],
    quantity: number
  ): Observable<Subscription | undefined> {
    const subscription = this.getUserSubscription(user)
    if (!!subscription) {
      return this.api.patch(
        this.endpoint + this.subscriptionEndpoint + '/' + subscription.id,
        {
          share_request_types: products,
          quantity: quantity,
        }
      ).pipe(
        map(subscription => FormatUtils.snakeToCamelCaseKeys(subscription))
      )
    } else {
      return of(undefined)
    }
  }

  getTotalSeats(user: User): number {
    const subscription = this.getUserSubscription(user)
    return subscription ? subscription.quantity : 0
  }

  getSubscriptionStatusLabel(status: SubscriptionStatus): string {
    return status.replace("_", " ")
  }

  getUserSubscription(user: User): Subscription | undefined {
    if (user.currentTeamMember.team && user.currentTeamMember.team.subscriptions.length > 0) {
      return user.currentTeamMember.team.subscriptions[0]
    } else {
      return
    }
  }

  hasSubscription(user: User): boolean {
    const subscription = this.getUserSubscription(user)
    return !!subscription &&
           subscription.status !== SubscriptionStatus.incomplete &&
           subscription.status !== SubscriptionStatus.incompleteExpired
  }

  hasActiveSubscription(user: User): boolean {
    const subscription = this.getUserSubscription(user)
    return !!subscription && subscription.status === SubscriptionStatus.active
  }

  hasActiveTrial(user: User): boolean {
    const subscription = this.getUserSubscription(user)
    return !!subscription && subscription.status === SubscriptionStatus.trialing
  }

  hasActiveSubscriptionOrTrial(user: User): boolean {
    const subscription = this.getUserSubscription(user)
    return !!subscription &&
      (
        subscription.status === SubscriptionStatus.active ||
        subscription.status === SubscriptionStatus.trialing
      )
  }

  hasValidProductAccess(user: User, shareRequestType: string) {
    const subscription = this.getUserSubscription(user)
    return !!subscription &&
           (
             subscription.status === SubscriptionStatus.active ||
             subscription.status === SubscriptionStatus.trialing
           ) &&
           subscription.shareRequestTypes.indexOf(shareRequestType) >= 0
  }

}
