<mat-form-field *ngIf="readOnly" class="width-100">
  <mat-label>{{title}}</mat-label>
  <mat-chip-grid #readOnlyChips>
    <mat-chip-row *ngFor="let value of getSelectedOptions()" class="chip-row">
      {{value.label}}
    </mat-chip-row>
    <input [matChipInputFor]="readOnlyChips"
           [readonly]="true"
           class="read-only-input"
    />
  </mat-chip-grid>
</mat-form-field>
<mat-form-field *ngIf="!readOnly" class="width-100">
  <mat-label>{{title}}</mat-label>
  <mat-select multiple [formControl]="multiSelectControl">
    <mat-select-trigger>
      <mat-chip-set>
        <mat-chip *ngFor="let value of getSelectedOptions()">
          {{value.label}}
        </mat-chip>
      </mat-chip-set>
    </mat-select-trigger>
    <mat-option *ngFor="let option of allOptions"
                [value]="option.value"
                (onSelectionChange)="optionSelected($event)"
    >
      {{option.label + ((option.count &&  option.count > 0) ? ' (' + option.count + ')' : '')}}
    </mat-option>
  </mat-select>
</mat-form-field>
