<div *ngIf="!loading && shareRequest.status !== ShareRequestStatus.active && !isPreview">
  <div class="flex-row-left">
    <h2 class="text-align-center">Sorry, the network request was not found or is not active</h2>
  </div>
</div>

<div *ngIf="!loading && showWizard && !isPreview">
  <mat-stepper [selectedIndex]="stepperIndex" #stepper linear class="wizard">
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>

    <mat-step label="Review Message" [completed]="stepIsCompleted(SharerWizardStatus.messageReview)">
      <mat-card class="wizard-step-desc" *ngIf="!loading">
        {{getDisplayName(shareRequest.createdByUser, 'This user')}} asked you to share this message with your network, but you can edit it if you like.
      </mat-card>
      <div *ngIf="emailAccounts.length > 0">
        <select-email-account label="From Email"
                              [accounts]="this.emailAccounts"
                              [userOauthId]="sharer.userOauthId"
                              (onChange)="userOauthChange($event)"
        ></select-email-account>
      </div>
      <message-editor [subject]="sharer.subjectOverride"
                      [content]="sharer.contentOverride"
                      [hideMessageTemplates]="true"
                      [readOnly]="!isAuthenticated"
                      (onChange)="prospectMessageChange($event)"
                      #prospectMessage
      ></message-editor>
      <div class="flex-col-top margin-center">
        <div class="flex-row-left">
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="skipWizard()"
          >Later</button>
          <button mat-flat-button
                  matStepperNext
                  color="primary"
                  (click)="completeStep(SharerWizardStatus.messageReview)"
                  class="horiz-margin-1x"
          >Next</button>
        </div>
      </div>
    </mat-step>

    <mat-step label="Connect your email" [completed]="stepIsCompleted(SharerWizardStatus.emailConnected)">
      <mat-card class="wizard-step-desc">Connect to find matching contacts for the targets {{getDisplayName(shareRequest.createdByUser, 'your contact')}} selected.  Don't worry your data is only visible to you.</mat-card>
      <div class="flex-col-top width-100">
        <audience-editor [editAudience]="shareRequest.prospectAudience"
                         [readOnly]="true"
                         [mode]="audienceEditorMode"
                         class="horiz-margin"
        ></audience-editor>
        <div class="flex-row-left oauth-btn-container" ngClass.xs="vert-padding flex-wrap">
          <button mat-flat-button
                  color="primary"
                  class="vert-margin horiz-margin"
                  (click)="addProspects()"
                  [disabled]="addingProspects"
                  *ngIf="emailAccounts.length > 0"
          >Find Matching Contacts</button>
          <oauth-button [type]="OauthType.google"
                        [action]="OauthAction.add"
                        [label]="gmailLabel"
                        [addOauthTo]="addOauthTo"
                        *ngIf="emailAccounts.length === 0"
                        class="horiz-margin"
          ></oauth-button>
          <oauth-button [type]="OauthType.microsoft"
                        [action]="OauthAction.add"
                        [label]="outlookLabel"
                        [addOauthTo]="addOauthTo"
                        *ngIf="emailAccounts.length === 0"
                        class="horiz-margin"
          ></oauth-button>
          <mat-spinner *ngIf="addingProspects"
                       [diameter]="24"
                       matTooltip="Your contacts are being imported and matched.  We'll email you when they're done"
                       color="accent"
                       class="spinner-icon text-align-center"
          ></mat-spinner>
        </div>
      </div>
      <div class="flex-col-top margin-center vert-margin">
        <div class="flex-row-left">
          <button mat-raised-button
                  matStepperPrevious
                  class="horiz-margin-1x"
                  (click)="completeStep(SharerWizardStatus.emailConnected, false)"
          >Previous</button>
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="skipWizard()"
                  *ngIf="!waitingOnImport()"
          >Later</button>
          <span [matTooltip]="emailAccounts.length === 0 ? 'Add an email account to continue.' : ''"
                *ngIf="!waitingOnImport() && sharer.numProspects > 0"
          >
            <button mat-flat-button
                    matStepperNext
                    color="primary"
                    [disabled]="sharer.numProspects === 0 && sharer.emailStatus !== SharerEmailStatus.waiting"
                    (click)="completeStep(SharerWizardStatus.emailConnected)"
                    class="horiz-margin-1x"
            >Next</button>
          </span>
          <span matTooltip="Wait until import is complete and notify me to review the matching prospects."
                *ngIf="waitingOnImport()"
          >
            <button mat-flat-button
                    color="primary"
                    (click)="waitOnImport(false)"
                    class="horiz-margin-1x"
            >Wait & Notify</button>
          </span>
          <span matTooltip="Wait until import is complete and send the email to the matching prospects."
                *ngIf="waitingOnImport()"
          >
            <button mat-flat-button
                    color="primary"
                    (click)="waitOnImport(true)"
                    class="horiz-margin-1x"
            >Wait & Send</button>
          </span>
        </div>
      </div>
    </mat-step>

    <mat-step label="Review Matching Prospects"
              [completed]="stepIsCompleted(SharerWizardStatus.contactsAdded)"
    >
      <sharer-prospect-list [sharer]="sharer"></sharer-prospect-list>
      <div class="flex-col-top margin-center">
        <div class="flex-row-left">
          <button mat-raised-button
                  matStepperPrevious
                  class="horiz-margin-1x"
                  (click)="completeStep(SharerWizardStatus.contactsAdded, false)"
          >Previous</button>
          <button mat-raised-button
                  class="horiz-margin-1x"
                  (click)="completeStep(SharerWizardStatus.contactsAdded)"
          >Later</button>
          <span [matTooltip]="canSend() ? '' : 'Select an email account and add contacts to send.'">
            <button mat-flat-button
                    color="primary"
                    [disabled]="!canSend()"
                    class="horiz-margin-1x"
                    (click)="send()"
            >Send</button>
          </span>
        </div>
      </div>
    </mat-step>

  </mat-stepper>
</div>

<div *ngIf="!loading && !showWizard && (shareRequest.status === ShareRequestStatus.active || isPreview)">
  <div class="flex-row-left" ngClass.xs="flex-wrap">
    <h2>{{shareRequest.name + ' requested by ' + getDisplayName(shareRequest.createdByUser, 'your contact')}}</h2>
    <!--<button mat-icon-button (click)="showGuide = true" *ngIf="!showGuide" class="help-icon small-icon-btn"><mat-icon>help</mat-icon></button>-->
    <div class="add-email-btn flex-row-left"
         ngClass.gt-xs="horiz-margin-2x"
         *ngIf="(shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro) && (!isAuthenticated || emailAccounts.length === 0)"
    >
      <!--
      <div (click)="doOauthGoogle()"
           class="google-oauth-btn"
      >
        <img src="assets/btn_google_signin_dark_normal_web.png"/>
      </div>
      -->
      <oauth-button [type]="OauthType.google"
                    [action]="isAuthenticated ? OauthAction.add : OauthAction.continue"
                    label="Connect My Gmail"
                    [addOauthTo]="addOauthTo"
      ></oauth-button>
      <oauth-button [type]="OauthType.microsoft"
                    [action]="isAuthenticated ? OauthAction.add : OauthAction.continue"
                    label="Connect My Outlook"
                    [addOauthTo]="addOauthTo"
                    class="horiz-margin-1x"
      ></oauth-button>
    </div>
    <div class="flex-row-left"
         ngClass.gt-xs="horiz-margin-2x"
         *ngIf="shareRequest.type === ShareRequestType.xPost && (!isAuthenticated || twitterAccounts.length === 0)"
    >
      <oauth-button [type]="OauthType.twitter"
                    [action]="isAuthenticated ? OauthAction.add : OauthAction.continue"
                    label="Connect My X"
                    [addOauthTo]="addOauthTo"
      ></oauth-button>
    </div>
    <div class="flex-row-left"
         ngClass.gt-xs="horiz-margin-2x"
         *ngIf="shareRequest.type === ShareRequestType.linkedinPost && (!isAuthenticated || linkedinAccounts.length === 0)"
    >
      <oauth-button [type]="OauthType.linkedin"
                    [action]="isAuthenticated ? OauthAction.add : OauthAction.continue"
                    label="Connect My LinkedIn"
                    [addOauthTo]="addOauthTo"
      ></oauth-button>
    </div>
    <span *ngIf="!loading && !hasSent() && isAuthenticated && (shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro)"
          class="send-btn-container"
          matTooltip="After your email is connected, and your matching contacts are added, click to send the emails."
    >
      <button mat-flat-button
              color="primary"
              (click)="send()"
              [disabled]="!canSend()"
              class="status-btn"
              ngClass.gt-xs="horiz-margin-2x"
      >Send</button>
    </span>
    <span *ngIf="!loading && isAuthenticated && (shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro)" class="send-btn-container">
      <button mat-flat-button
              *ngIf="sharer.emailStatus === SharerEmailStatus.sending"
              color="primary"
              (click)="pause()"
              class="status-btn"
              ngClass.gt-xs="horiz-margin-2x"
      >Pause</button>
      <button mat-flat-button
              *ngIf="sharer.emailStatus === SharerEmailStatus.paused"
              color="primary"
              (click)="resume()"
              class="status-btn"
              ngClass.gt-xs="horiz-margin-2x"
      >Resume</button>
    </span>
    <span *ngIf="!loading && isAuthenticated" class="status-panel" ngClass.gt-xs="horiz-margin-1x">
      <span class="status-title">Status:</span>{{getSharerStatusLabel() | titlecase}}
    </span>
    <span *ngIf="!loading && hasSent() && isAuthenticated && (shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro)" class="status-panel horiz-margin-2x">
      <span class="status-title">Progress:</span>{{prospectsSent + ' / ' + prospectsTotal}}
    </span>
  </div>
  <mat-card class="wizard-step-desc" *ngIf="!loading && sharer.status !== SharerStatus.completed">
    <h3>How to Help</h3>
    <div *ngIf="shareRequest.type === ShareRequestType.xPost">
      {{getDisplayName(shareRequest.createdByUser, 'This user')}} has requested your help to repost the post below.  You can quickly connect your X account to do this automatically!
    </div>
    <div *ngIf="shareRequest.type === ShareRequestType.linkedinPost">
      {{getDisplayName(shareRequest.createdByUser, 'This user')}} has requested your help to like the LinkedIn post below.  You can quickly connect your LinkedIn account to do this automatically!
    </div>
    <div *ngIf="shareRequest.type === ShareRequestType.email">
      {{getDisplayName(shareRequest.createdByUser, 'This user')}} has requested help to share the message to the audience specified.  You can quickly connect your email account to find matching contacts and help out {{getDisplayName(shareRequest.createdByUser)}}!
    </div>
    <div *ngIf="shareRequest.type === ShareRequestType.intro">
      {{getDisplayName(shareRequest.createdByUser, 'This user')}} has requested an intro.  You can quickly connect your email account to easily make the intro.
    </div>
  </mat-card>

  <div>

    <div class="social-details-container" *ngIf="shareRequest.type === ShareRequestType.xPost">
      <mat-card class="auto-retweet-card" *ngIf="isAuthenticated && sharer.status === SharerStatus.completed">
        <mat-card-content>
          <auto-help-settings [type]="ShareRequestType.xPost"
                              [profileName]="getDisplayName(shareRequest.createdByUser)"
                              [accounts]="twitterAccounts"
                              [autoCompleteOauthId]="sharer.contact.autoCompleteTwitterOauthId"
                              [autoCompleteShareRequests]="sharer.contact.autoCompleteTweetRequests"
                              (onOauthChange)="autoCompleteTwitterOauthChange($event)"
                              (onSettingChange)="autoCompleteTweetChange()"
          ></auto-help-settings>
        </mat-card-content>
      </mat-card>
      <h2>{{sharer.status !== SharerStatus.completed ? 'Please repost this' : 'Reposted on ' + formatDate(sharer.completedAt)}}</h2>
      <x-post-request-setup [shareRequest]="shareRequest"
                            [readOnly]="true"
                            [accountLabel]="twitterAccountLabel"
      ></x-post-request-setup>
      <div class="flex-row-left vert-margin"
           *ngIf="isAuthenticated && sharer.status !== SharerStatus.completed"
      >
        <div *ngIf="twitterAccounts.length > 0">
          <select-x-account label="My X Account"
                            [accounts]="twitterAccounts"
                            [userOauthId]="sharer.userOauthId"
                            [hideAddButton]="true"
                            (onChange)="twitterOauthChange($event)"
          ></select-x-account>
        </div>
        <div class="complete-btn-container"
             [matTooltip]="twitterAccounts.length === 0 ? 'After your X account is connected, Wrollo can repost this post for you.' : ''"
        >
          <button mat-flat-button
                  color="primary"
                  (click)="retweet()"
                  [disabled]="!sharer.userOauthId"
                  [ngClass]="{'retweet-btn': twitterAccounts.length > 0}"
          >Repost</button>
        </div>
      </div>
    </div>
    <div class="social-details-container" *ngIf="shareRequest.type === ShareRequestType.linkedinPost">
      <mat-card class="auto-retweet-card" *ngIf="isAuthenticated && sharer.status === SharerStatus.completed">
        <mat-card-content>
          <auto-help-settings [type]="ShareRequestType.linkedinPost"
                              [profileName]="getDisplayName(shareRequest.createdByUser)"
                              [accounts]="linkedinAccounts"
                              [autoCompleteOauthId]="sharer.contact.autoCompleteLinkedinOauthId"
                              [autoCompleteShareRequests]="sharer.contact.autoCompleteLinkedinPostRequests"
                              (onOauthChange)="autoCompleteLinkedinOauthChange($event)"
                              (onSettingChange)="autoCompleteLinkedinPostChange()"
          ></auto-help-settings>
        </mat-card-content>
      </mat-card>
      <h2>{{sharer.status !== SharerStatus.completed ? 'Please like this post' : 'Liked on ' + formatDate(sharer.completedAt)}}</h2>
      <linkedin-post-request-setup [shareRequest]="shareRequest"
                                   [readOnly]="true"
                                   [accountLabel]="linkedinAccountLabel"
      ></linkedin-post-request-setup>
      <div class="flex-row-left vert-margin"
           *ngIf="isAuthenticated && sharer.status !== SharerStatus.completed"
      >
        <select-linkedin-account label="My LinkedIn Account"
                                [accounts]="linkedinAccounts"
                                [userOauthId]="sharer.userOauthId"
                                [hideAddButton]="true"
                                (onChange)="linkedinOauthChange($event)"
        ></select-linkedin-account>
        <div class="complete-btn-container"
             [matTooltip]="linkedinAccounts.length === 0 ? 'After your LinkedIn is connected, Wrollo can like this post for you.' : ''"
        >
          <button mat-flat-button
                  color="primary"
                  (click)="likeLinkedInPost()"
                  [disabled]="!sharer.userOauthId"
                  [ngClass]="{'retweet-btn': linkedinAccounts.length > 0}"
          >Like</button>
        </div>
      </div>
    </div>

    <mat-tab-group [selectedIndex]="selectedTab.value"
                   (selectedIndexChange)="setTab($event)"
                   class="tabs-container"
                   *ngIf="shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">share</mat-icon>
          Overview
        </ng-template>
        <div class="tab-container flex-row-space-between" ngClass.xs="flex-wrap">
          <div class="email-container">
            <div class="flex-row-left container-title" ngClass.xs="flex-wrap">
              <h2>Please email this message...</h2>
              <div ngClass.gt-xs="horiz-margin-1x" *ngIf="isAuthenticated && this.emailAccounts.length === 0">
                <button mat-flat-button
                        color="primary"
                        [matMenuTriggerFor]="actionMenu"
                >
                  Add Email
                  <fa-icon [icon]="faCaretDown"></fa-icon>
                </button>
                <mat-menu #actionMenu="matMenu">
                  <a mat-menu-item (click)="doOauth(OauthType.google)">
                    <fa-icon [icon]="faGoogle" class="horiz-margin"></fa-icon>
                    Add Google Account
                  </a>
                  <a mat-menu-item (click)="doOauth(OauthType.microsoft)">
                    <fa-icon [icon]="faMicrosoft" class="horiz-margin"></fa-icon>
                    Add Outlook Account
                  </a>
                </mat-menu>
              </div>
            </div>
            <div class="flex-row-left field-row" *ngIf="isAuthenticated">
              <select-email-account label="From Email"
                                    [accounts]="this.emailAccounts"
                                    [userOauthId]="sharer.userOauthId"
                                    [hideAddButton]="true"
                                    (onChange)="userOauthChange($event)"
              ></select-email-account>
            </div>
            <message-editor [subject]="sharer.subjectOverride || shareRequest.prospectMessageSubject"
                            [content]="sharer.contentOverride || shareRequest.prospectMessageContent"
                            [hideMessageTemplates]="true"
                            [readOnly]="!isAuthenticated"
                            (onChange)="prospectMessageChange($event)"
                            #prospectMessage
            ></message-editor>
          </div>
          <div class="audience-container">
            <div class="flex-row-left container-title" ngClass.xs="flex-wrap">
              <h2>...to your contacts in this audience</h2>
              <span matTooltip="Once you add your email account, click here to automatically find your matching contacts."
                    ngClass.gt-xs="horiz-margin-1x"
                    *ngIf="!addingProspects && sharer.prospects.length === 0"
              >
                <button mat-flat-button
                        color="primary"
                        (click)="addProspects()"
                        [disabled]="emailAccounts.length === 0"
                        *ngIf="isAuthenticated"
                >Find Matching Contacts</button>
              </span>
              <mat-spinner *ngIf="addingProspects"
                           matTooltip="Your contacts are being imported and matched.  We'll email you when they're done"
                           color="accent"
                           [diameter]="24"
                           class="spinner-icon"
              ></mat-spinner>
            </div>
            <audience-editor [editAudience]="shareRequest.prospectAudience"
                             [readOnly]="true"
                             [mode]="audienceEditorMode"
            ></audience-editor>
          </div>
        </div>
      </mat-tab>

      <mat-tab *ngIf="isAuthenticated && (shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro)">
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">contacts</mat-icon>
          Matching Contacts{{(sharer.prospects && sharer.prospects.length > 0) ? ' (' + sharer.prospects.length + ')' : ''}}
        </ng-template>
        <div class="tab-container">
          <sharer-prospect-list [sharer]="sharer"></sharer-prospect-list>
        </div>
      </mat-tab>
      <!--
      <mat-tab *ngIf="hasSent() && isAuthenticated">
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">list_alt</mat-icon>
          Feed
        </ng-template>
        <div class="tab-container">
          <h3>Coming Soon</h3>
        </div>
      </mat-tab>

      <mat-tab *ngIf="hasSent() && isAuthenticated">
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">dashboard</mat-icon>
          Analytics
        </ng-template>
        <div class="tab-container">
          <h3>Coming Soon</h3>
        </div>
      </mat-tab>
      -->
    </mat-tab-group>
  </div>
</div>
