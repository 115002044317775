import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ContactImport, ContactImportService} from "../../../services/contact-import.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'contact-import-list',
  templateUrl: './contact-import-list.component.html',
  styleUrls: ['./contact-import-list.component.scss']
})
export class ContactImportListComponent implements OnInit {

  loading: boolean = true

  displayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['source', 'emailAccount', 'importDate']
    : ['source', 'emailAccount', 'csvFile', 'contacts', 'importDate']
  numDisplayedColumns: number = this.displayedColumns.length
  contactImportTable: MatTableDataSource<ContactImport> = new MatTableDataSource()

  getImportProgressLabel = this.contactImportService.getImportProgressLabel
  getImportDateLabel = this.contactImportService.getImportDateLabel

  constructor(private contactImportService: ContactImportService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.contactImportService.list$.subscribe(contactImports => {
      this.contactImportTable.data = contactImports
      this.loading = false
    })
    this.contactImportService.getAll()
  }

}
