import {Component, Input} from '@angular/core'
import {ContactListItem, ContactDetail} from "../../../services/contact.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'relationship-strength',
  templateUrl: './relationship-strength.component.html',
  styleUrls: ['./relationship-strength.component.scss']
})
export class RelationshipStrengthComponent {

  @Input() contact: ContactListItem | ContactDetail | undefined

  formatDate = FormatUtils.formatDate

  constructor() {
  }

}
