import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {User, UserResult, UserService} from "./user.service";
import {FormatUtils} from "./utilities/format.utilities";
import {Audience, AudienceResult, AudienceService} from "./audience.service";
import {UserOauthAccount, UserOauthAccountResult} from "./oauth.service";
import {Sharer, SharerResult, SharerStatus} from "./sharer.service";
import {ContactListItem} from "./contact.service";
import {XPost, XPostResult} from "./x-twitter.service";
import {LinkedInPost} from "./linkedin.service";
import {TeamService} from "./team.service";

export interface ShareRequest {
  id: number
  name: string
  type: ShareRequestType | string
  category: string
  tags: string[]
  role: ShareRequestRole
  status: ShareRequestStatus
  inviteStatus: ShareRequestInviteStatus
  sendAfterImport: boolean
  lastWizardStep: number
  shareLinkEnabled: boolean
  shareLinkPlug: string
  emailOauthId: number
  emailOauth?: UserOauthAccount
  sharerAudienceId: number
  sharerAudience?: Audience
  prospectAudienceId: number
  prospectAudience?: Audience
  sharerMessageSubject: string
  sharerMessageContent: string
  prospectMessageSubject: string
  prospectMessageContent: string
  twitterOauthId: number
  twitterOauth?: UserOauthAccount
  tweetId: number
  tweet?: XPost
  linkedinOauthId: number
  linkedinOauth?: UserOauthAccountResult
  linkedinPostId: number
  linkedinPost?: LinkedInPost
  sharers?: Sharer[]
  numSharers: number
  numProspects: number
  createdAt?: number
  createdByUserId?: number
  createdByUser?: User
  teamId?: number
  modifiedAt?: number
  modified_by_user_id?: number
}

export interface ShareRequestResult {
  id?: number
  name: string
  type: ShareRequestType | string
  category: string
  tags: string[]
  role: ShareRequestRole
  status: ShareRequestStatus
  invite_status: ShareRequestInviteStatus
  send_after_import: boolean
  last_wizard_step: number
  share_link_enabled: boolean
  share_link_plug: string
  email_oauth_id: number
  email_oauth?: UserOauthAccountResult
  sharer_audience_id: number
  sharer_audience?: AudienceResult
  prospect_audence_id: number
  prospect_audience?: AudienceResult
  sharer_message_subject: string
  sharer_message_content: string
  prospect_message_subject: string
  prospect_message_content: string
  twitter_oauth_id: number
  twitter_oauth?: UserOauthAccountResult
  x_post_id: number
  xPost?: XPostResult
  linkedin_oauth_id: number
  linkedin_oauth?: UserOauthAccountResult
  linkedin_post_id: number
  linkedin_post?: LinkedInPost
  sharers?: SharerResult[]
  num_sharers: number
  num_prospects: number
  created_at?: string
  created_by_user_id?: number
  created_by_user: UserResult
  team_id: number
  modified_at?: number
  modifiedByUserId?: number
}

export interface ShareRequestSummary {
  contactId: number
  shareRequestCount: number
  lastHelpedAt: number
  role: string
  contact: ContactListItem
}

export interface ShareRequestSummaryResult {
  contact_id: number
  share_request_count: number
  last_helped_at: number
  role: string
  contact: ContactListItem
}

export enum ShareRequestType {
  intro = 'intro',
  email = 'email',
  xPost = 'x_post',
  linkedinPost = 'linkedin_post',
}

export enum ShareRequestInviteStatus {
  pending = 'pending',
  emailConnected = 'email connected',
  waiting = 'waiting to add contacts',
  contactsAdded = 'contacts added',
  sending = 'emailing contacts',
  paused = 'paused',
  done = 'all contacts emailed',
}

export enum ShareRequestWizardStatus {
  settingsAdded = 'network request settings added',
  detailsAdded = 'network request details added',
  shareOptionsSelected = 'share options selected',
  sharerMessageAdded = 'my contacts message added',
  sharersAdded = 'sharers added',
}

export enum ShareRequestStatus {
  pending = 'pending',
  active = 'active',
  inactive = 'inactive',
}

export enum ShareRequestRole {
  creator = 'creator',
  sharer = 'sharer',
}

@Injectable({
  providedIn: 'root'
})
export class ShareRequestService {

  public categoryOptions = [
    { "label": "Leads", "value": "leads" },
    { "label": "Investors", "value": "investors" },
    { "label": "Candidates", "value": "candidates" },
    { "label": "Partners", "value": "partners" },
    { "label": "Vendors", "value": "vendor" },
  ]

  public typeOptions = [
    {
      "label": "Request Intro",
      "value": ShareRequestType.intro,
      "icon": "faHandshake",
      "description": "Request an introduction to someone within your network.",
      "visible": false,
      "requireSubscription": false,
    },
    {
      "label": "Send Email",
      "value": ShareRequestType.email,
      "icon": "faEnvelope",
      "description": "Email my message to their matching contacts.",
      "visible": true,
      "requireSubscription": true,
    },
    {
      "label": "X Post",
      "value": ShareRequestType.xPost,
      "icon": "faXTwitter",
      "description": "Repost my post on X.",
      "visible": true,
      "requireSubscription": true,
    },
    {
      "label": "LinkedIn Post",
      "value": ShareRequestType.linkedinPost,
      "icon": "faLinkedin",
      "description": "Like my post on LinkedIn.",
      "visible": true,
      "requireSubscription": true,
    },
  ]

  public SAMPLE_EMAIL_MESSAGES = {
    SHARER_SUBJECT: "SAMPLE SUBJECT: Can you help?",
    SHARER_CONTENT: "SAMPLE MESSAGE<br>Hello <#firstname#>,<br><br>I hope all is well!  I'm asking my network to spread the word about Acme.com, and we've made it super simple to help.  Just click the link below to easily share with your network.<br><br><#viewrequesturl#><br><br><#signature#>",
    PROSPECT_SUBJECT: "SAMPLE SUBJECT: You should check out Acme.com",
    PROSPECT_CONTENT: "SAMPLE MESSAGE<br>Hello <#firstname#>,<br><br>My colleague has a great offering at Acme.com that I thought you'd be interested in.  You can check it out here: <a href='acme.com'>acme.com</a>.<br><br><#signature#>",
  }

  public list!: ShareRequest[]
  private listSubject: Subject<ShareRequest[]> = new Subject<ShareRequest[]>()
  list$: Observable<ShareRequest[]> = this.listSubject.asObservable()

  public detail!: ShareRequest
  private detailSubject: Subject<ShareRequest> = new Subject<ShareRequest>()
  detail$: Observable<ShareRequest> = this.detailSubject.asObservable()

  public summary!: ShareRequestSummary[]
  private summarySubject: Subject<ShareRequestSummary[]> = new Subject<ShareRequestSummary[]>()
  summary$: Observable<ShareRequestSummary[]> = this.summarySubject.asObservable()

  private endpoint: string = 'share_requests'

  public signatureTag = "&lt;#signature#&gt;"
  public viewRequestLinkTag = "viewrequesturl"
  public viewRequestLinkHTML = "<a href='&lt;#" + this.viewRequestLinkTag + "#&gt;'>View Network Request</a><br><br>"

  constructor(private api: ApiService,
              private userService: UserService,
              private teamService: TeamService,
              private audienceService: AudienceService,
  ) {
  }

  getAll(forceGet: boolean = false): Observable<ShareRequest[]> {
    if (this.list && !forceGet) {
      this.listSubject.next(this.list)
      return of(this.list)
    } else {
      const resultSubject = new Subject<ShareRequest[]>()
      this.api.get<ShareRequestResult[]>('my_current_team/' + this.endpoint, {}).subscribe(
        (results: ShareRequestResult[]) => {
          this.list = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.listSubject.next(this.list)
          resultSubject.next(this.list)
        }
      )
      return resultSubject.asObservable()
    }
  }

  getDetail(id: number = 0, forceGet: boolean = false) {
    if (this.detail && (id <= 0 || !forceGet)) {
      this.detailSubject.next(this.detail)
    } else if (id > 0) {
      const url = this.endpoint + '/' + id
      this.api.get<ShareRequestResult>(url).subscribe(
        (result: ShareRequestResult) => {
          this.detail = FormatUtils.snakeToCamelCaseKeys(result)
          this.detailSubject.next(this.detail)
        }
      )
    }
  }

  getDetailByPlug(plug: string, isAuthenticated: boolean, forceGet: boolean = false) {
    if (this.detail && !forceGet) {
      this.detailSubject.next(this.detail)
    } else {
      const url = isAuthenticated
        ? this.endpoint + '_preview/' + plug + '/find_my_sharer'
        : this.endpoint + '_preview/' + plug
      this.api.get<ShareRequestResult>(url).subscribe(
        (result: ShareRequestResult) => {
          this.detail = FormatUtils.snakeToCamelCaseKeys(result)
          this.detailSubject.next(this.detail)
        }
      )
    }
  }

  getDetailBySharerId(sharerId: number, forceGet: boolean = false) {
    if (this.detail && !forceGet) {
      this.detailSubject.next(this.detail)
    } else {
      this.api.get<ShareRequestResult>(`${this.endpoint}/sharers/${sharerId}`).subscribe(
        (result: ShareRequestResult) => {
          this.detail = FormatUtils.snakeToCamelCaseKeys(result)
          this.detailSubject.next(this.detail)
        }
      )
    }
  }

  getSummary(forceGet: boolean = false): Observable<ShareRequestSummary[]> {
    if (this.summary && !forceGet) {
      this.summarySubject.next(this.summary)
      return of(this.summary)
    } else {
      const resultSubject = new Subject<ShareRequestSummary[]>()
      this.api.get<ShareRequestSummaryResult[]>('my/' + this.endpoint + '/summary', {}).subscribe(
        (results: ShareRequestSummaryResult[]) => {
          this.summary = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.summarySubject.next(this.summary)
          resultSubject.next(this.summary)
        }
      )
      return resultSubject.asObservable()
    }
  }

  save(shareRequest: ShareRequest, skipGetAll: boolean = false): Observable<ShareRequest> {
    return this.update(
      this.api.save<ShareRequest>(this.endpoint, shareRequest),
      skipGetAll
    )
  }

  delete(shareRequest: ShareRequest, skipGetAll: boolean = false): Observable<ShareRequest> {
    return this.update(
      this.api.delete(this.endpoint + '/' + shareRequest.id),
      skipGetAll
    )
  }

  update(update$: Observable<ShareRequest>, skipGetAll: boolean = false): Observable<ShareRequest> {
    const updateSubject: Subject<ShareRequest> = new Subject<ShareRequest>()
    update$.subscribe(
      (result: ShareRequest) => {
        if (!skipGetAll) {
          this.getAll(true)
        }
        if (result) {
          this.detail = FormatUtils.snakeToCamelCaseKeys(result)
          this.detailSubject.next(this.detail)
        }
        updateSubject.next(FormatUtils.snakeToCamelCaseKeys(result))
      }
    )
    return updateSubject.asObservable()
  }

  deleteSharers(shareRequest: ShareRequest, contacts: ContactListItem[]): Observable<ShareRequest> {
    return this.update(
      this.api.post<ShareRequest>(
        this.endpoint + '/' + shareRequest.id + '/delete_sharers',
        { contact_ids: contacts.map( c => { return c.id }) }
      )
    )
  }

  addSharers(shareRequest: ShareRequest, contactIds: number[], emailAddresses: string[], audience: Audience | undefined): Observable<ShareRequest> {
    return this.update(
      this.api.post<ShareRequest>(
        this.endpoint + '/' + shareRequest.id + '/add_sharers',
        {
          contact_ids: contactIds,
          emails: emailAddresses,
          filters: FormatUtils.camelToSnakeCaseKeys(audience),
        }
      )
    )
  }

  createIntroRequest(targetProfileId: number, introducerContactIds: number[], emailOauthId: number, sharerMessage: string, prospectMessage: string): Observable<ShareRequest> {
    return this.update(
      this.api.post<ShareRequest>(
        this.endpoint + '/request_intro',
        {
          target_profile_id: targetProfileId,
          introducer_contact_ids: introducerContactIds,
          email_oauth_id: emailOauthId,
          sharer_message: sharerMessage.replaceAll('\n', '<br>'),
          prospect_message: prospectMessage.replaceAll('\n', '<br>'),
        }
      )
    )
  }

  canSend(shareRequest: ShareRequest): boolean {
    return !!shareRequest &&
           shareRequest.status === ShareRequestStatus.active &&
           !!shareRequest.emailOauthId &&
           shareRequest.emailOauthId > 0 &&
           shareRequest.sharerMessageContent !== '' &&
           !!shareRequest.sharers &&
           shareRequest.sharers.filter(s => s.status === SharerStatus.pending).length > 0 &&
           shareRequest.inviteStatus !== ShareRequestInviteStatus.sending
  }



  hasSent(shareRequest: ShareRequest): boolean {
    return [
      ShareRequestInviteStatus.sending as string,
      ShareRequestInviteStatus.paused as string,
      ShareRequestInviteStatus.done as string,
    ].indexOf(shareRequest.inviteStatus) >= 0
  }

  addUserAsSharer(shareRequest: ShareRequest): Observable<Sharer> {
    const resultSubject: Subject<Sharer> = new Subject<Sharer>()
    this.userService.getIdentity().subscribe(
      user => {
        this.api.post<Sharer>(
          this.endpoint + '/' + shareRequest.id + '/add_user_sharer',
          {
            share_request_id: shareRequest.id,
            user_id: user.id,
          }
        ).subscribe(
          sharer => {
            resultSubject.next(sharer)
          }, err => {
            resultSubject.error(err)
          }
        )
      }, err => {
        resultSubject.error(err)
      }
    )
    return resultSubject.asObservable()
  }

  addActiveContactsAsSharers(shareRequest: ShareRequest, filterByType: boolean): Observable<ShareRequest> {
    return this.update(
      this.api.post<ShareRequest>(
        this.endpoint + '/' + shareRequest.id + '/add_user_sharers',
        {
          share_request_id: shareRequest.id,
          filter_by_type: filterByType,
        }
      )
    )
  }

  addDefaultAudiences(shareRequest: ShareRequest) {
    const prospectAudience = this.audienceService.new()
    prospectAudience.label = 'My First Prospect Audience'
    const sharerAudience = this.audienceService.new()
    sharerAudience.label = 'My First Contacts Audience'
    combineLatest([
      this.audienceService.save(prospectAudience),
      this.audienceService.save(sharerAudience)
    ]).subscribe(
      ([prospectAudienceSaved, sharerAudienceSaved]) => {
        shareRequest.prospectAudienceId = prospectAudienceSaved?.id || -1
        shareRequest.prospectAudience = prospectAudienceSaved
        shareRequest.sharerAudienceId = sharerAudienceSaved?.id || -1
        shareRequest.sharerAudience = sharerAudienceSaved
        this.save(shareRequest, true)
      }
    )
  }

  getTypeLabel(type: string): string {
    const typeOption = this.typeOptions.find(t => t.value === type)
    return typeOption ? typeOption.label : ''
  }

  setupIsComplete(shareRequest: ShareRequest): boolean {
    const hasType: boolean = shareRequest.type !== ''
    const emailTypeComplete: boolean = shareRequest.type === ShareRequestType.email &&
                                       shareRequest.prospectAudienceId > 0 &&
                                       (shareRequest.prospectMessageSubject !== '' || shareRequest.prospectMessageContent !== '')
    const twitterTypeComplete: boolean = shareRequest.type === ShareRequestType.xPost && shareRequest.tweetId > 0
    const linkedinTypeComplete: boolean = shareRequest.type === ShareRequestType.linkedinPost && shareRequest.linkedinPostId > 0
    return hasType && (emailTypeComplete || twitterTypeComplete || linkedinTypeComplete)
  }

  new(firstTime: boolean = false): ShareRequest{
    return {
      id: 0,
      name: firstTime ? "My First Network Request" : "New Network Request",
      type: '',
      category: '',
      tags: [],
      role: ShareRequestRole.creator,
      sharerAudienceId: 0,
      prospectAudienceId: 0,
      status: ShareRequestStatus.pending,
      inviteStatus: ShareRequestInviteStatus.pending,
      sendAfterImport: false,
      lastWizardStep: 0,
      shareLinkEnabled: false,
      shareLinkPlug: '',
      emailOauthId: 0,
      sharerMessageSubject: '',
      sharerMessageContent: '',
      prospectMessageSubject: '',
      prospectMessageContent: '',
      twitterOauthId: 0,
      tweetId: 0,
      linkedinOauthId: 0,
      linkedinPostId: 0,
      numSharers: 0,
      numProspects: 0,
    }
  }

}
