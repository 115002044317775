<div class="component-container">
  <div mat-dialog-content>
    <h2 mat-dialog-tile *ngIf="isModal()">{{shareRequest.id <= 0 ? 'New' : 'Edit'}} Network Request</h2>
    <div *ngIf="loading">
      <mat-spinner color="accent" class="margin-center"></mat-spinner>
    </div>
    <form [formGroup]="setupForm" *ngIf="!loading" autocomplete="off">
      <div class="card-container">
        <mat-card>
          <mat-card-content>
            <div class="flex-row-left field-row" *ngIf="isModal()">
              <mat-form-field class="setup-field">
                <mat-label>Name</mat-label>
                <input matInput
                       id="name-search"
                       type="text"
                       data-lpignore="true"
                       formControlName="name"
                       required
                       (blur)="save()"
                >
              </mat-form-field>
            </div>
            <div class="radio-group-field-row">
              <div class="radio-group-label">I want to ask my contacts to...</div>
              <div ngClass.gt-xs="flex-row-left" ngClass.xs="flex-row-col">
                <mat-card *ngFor="let typeOption of typeOptions"
                          (click)="selectType(typeOption.value)"
                          class="type-option-card pointer"
                          [ngClass]="{'type-option-card-selected': typeOption.value === shareRequest.type}"
                >
                  <mat-card-content>
                    <div>
                      <fa-icon [icon]="getTypeIcon(typeOption.icon)" class="type-option-icon"></fa-icon>
                      {{typeOption.label}}
                    </div>
                    <div class="type-option-desc">
                      {{typeOption.description}}
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
            <div class="advanced-container" *ngIf="isModal()">
              <div>
                <a (click)="showAdvanced = !showAdvanced"
                   class="pointer advanced-link"
                >{{showAdvanced ? 'Hide' : 'Show'}} advanced settings</a>
              </div>
              <div *ngIf="showAdvanced">
                <div class="flex-row-left field-row"
                     *ngIf="shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro"
                >
                  <mat-form-field class="setup-field width-100">
                    <mat-label>I'm looking for...</mat-label>
                    <mat-select formControlName="category" (selectionChange)="save()">
                      <mat-option *ngFor="let categoryOption of categoryOptions"
                                  [value]="categoryOption.value"
                      >
                        {{categoryOption.label}}
                      </mat-option>
                      <mat-option value="other">Other</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="flex-row-left field-row"
                     *ngIf="(shareRequest.type === ShareRequestType.email || shareRequest.type === ShareRequestType.intro) && setupForm.value.category === 'other'"
                >
                  <mat-form-field class="setup-field width-100">
                    <mat-label>Other Target</mat-label>
                    <input matInput
                           id="target-search"
                           type="text"
                           data-lpignore="true"
                           formControlName="otherCategory"
                           required
                           (blur)="save()"
                    >
                  </mat-form-field>
                </div>
                <div class="flex-row-left field-row">
                  <autocomplete-chip title="Tags"
                                     [values]="industryOptionsSelected"
                                     [options]="industryOptionsAutocomplete$"
                                     (onChange)="industryChange($event)"
                                     (onAutocomplete)="industryAutocomplete($event)"
                                     class="setup-field"
                  ></autocomplete-chip>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div mat-dialog-actions class="action-row" *ngIf="isModal()">
        <button mat-flat-button
                color="primary"
                (click)="close(true)"
                class="action-button"
                [disabled]="shareRequest.name === '' || shareRequest.type === ''"
        >Save</button>
        <button mat-flat-button
                mat-dialog-close
                (click)="close(false)"
                class="action-button"
        >Cancel</button>
      </div>
    </form>
  </div>
</div>
