import {Component, Input} from '@angular/core'
import {ContactListItem, ContactDetail} from "../../../services/contact.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'short-label',
  templateUrl: './short-label.component.html',
  styleUrls: ['./short-label.component.scss']
})
export class ShortLabelComponent {

  @Input() label: string = ''
  @Input() len: number = 40

  constructor() {
  }

}
