import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {OauthService, UserOauthAccount, AddOauthToObject, OauthType} from "../../../services/oauth.service";
import {ShareRequest, ShareRequestService} from "../../../services/share-request.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {LinkedInOrganization, LinkedInPost, LinkedInService} from "../../../services/linkedin.service";
import {Observable, Subject} from "rxjs";

@Component({
  selector: 'linkedin-post-request-setup',
  templateUrl: './linkedin-post-request-setup.component.html',
  styleUrls: ['./linkedin-post-request-setup.component.scss']
})
export class LinkedinPostRequestSetupComponent implements OnInit, OnChanges, AfterViewInit {

  @Input({required: true}) shareRequest!: ShareRequest
  @Input() readOnly: boolean = false
  @Input() accountLabel: string = 'LinkedIn Account'

  @Output() onChange = new EventEmitter()

  addOauthTo!: AddOauthToObject
  linkedinAccounts: UserOauthAccount[] = []
  linkedinOrganizations: LinkedInOrganization[] = []
  linkedinPosts: LinkedInPost[] = []
  linkedinPost: LinkedInPost = this.linkedinService.new()

  linkedinOrgIdControl = new FormControl()
  linkedinPostIdControl = new FormControl()
  linkedinPostTitleControl = new FormControl()
  linkedinPostDescControl = new FormControl()

  loading: boolean = true

  formatDate = FormatUtils.formatDate
  truncateString = FormatUtils.truncateString

  constructor(private shareRequestService: ShareRequestService,
              private linkedinService: LinkedInService,
              private oauthService: OauthService,
  ) {
  }

  ngOnInit() {
    if (this.shareRequest) {
      this.setShareRequest(this.shareRequest)
    }
  }

  ngAfterViewInit() {
    this.oauthService.userList$.subscribe((accounts: UserOauthAccount[]) => {
      this.linkedinAccounts = accounts.filter(a => {
        return a.type === OauthType.linkedin
      })
      this.checkLinkedInOauth()
      this.loading = false
    })
    if (!this.readOnly) {
      this.oauthService.getAllForUser()
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shareRequest']) {
      this.setShareRequest(changes['shareRequest'].currentValue)
    }
  }

  setShareRequest(shareRequest: ShareRequest) {
    this.shareRequest = shareRequest
    this.addOauthTo = {
      type: 'share_request',
      field: 'linkedin_oauth_id',
      id: this.shareRequest.id,
    }
    this.checkLinkedInOauth()
  }

  checkLinkedInOauth() {
    if (this.shareRequest.linkedinOauthId <= 0 &&
        this.linkedinAccounts.length === 1 &&
        !this.readOnly
    ) {
      // Default ouath account if not set and only one account
      this.shareRequest.linkedinOauthId = this.linkedinAccounts[0].id
    }
    if (this.shareRequest.linkedinOauthId > 0 && !this.readOnly) {
      const linkedinOauth = this.linkedinAccounts.find(a => a.id === this.shareRequest.linkedinOauthId)
      this.setLinkedInOrganizations(linkedinOauth?.linkedinOrganizations || [])
      if (this.shareRequest.linkedinPost && this.shareRequest.linkedinPost?.linkedinOrganizationId > 0) {
        this.linkedinOrgIdControl.setValue(this.shareRequest.linkedinPost?.linkedinOrganizationId)
        this.orgChange(false)
      }
      if (this.shareRequest.linkedinPostId > 0) {
        this.linkedinPostIdControl.setValue(this.shareRequest.linkedinPostId)
        this.setPost()
      }
    } else if (this.readOnly) {
      // If read only, get post details from network request
      this.setPost()
      this.loading = false
    }
  }

  setLinkedInOrganizations(linkedinOrganizations: LinkedInOrganization[]) {
    this.linkedinOrganizations = linkedinOrganizations
    if (this.linkedinOrganizations.length === 1) {
      this.linkedinOrgIdControl.setValue(this.linkedinOrganizations[0].id)
      this.updateOrgPosts()
    }
  }

  linkedinOauthChange(linkedinOauthId: number) {
    this.shareRequest.linkedinOauthId = linkedinOauthId
    const linkedinOauth = this.linkedinAccounts.find(a => a.id === linkedinOauthId)
    this.setLinkedInOrganizations(linkedinOauth?.linkedinOrganizations || [])
    this.change()
  }

  postChange(triggerSave: boolean = true) {
    if (this.linkedinPostIdControl.value > 0) {
      this.shareRequest.linkedinPostId = this.linkedinPostIdControl.value
      this.setPost()
    } else if (triggerSave) {
      this.shareRequest.linkedinPostId = 0
      this.linkedinPostTitleControl.setValue('')
      this.linkedinPostDescControl.setValue('')
    }
    if (triggerSave) this.change()
  }

  updateOrgPosts(): Observable<LinkedInPost[]> {
    const resultSubject = new Subject<LinkedInPost[]>()
    this.linkedinService.getOrgPosts(this.linkedinOrgIdControl.value).subscribe(
      linkedinPosts => {
        this.linkedinPosts = linkedinPosts
        resultSubject.next(this.linkedinPosts)
      }
    )
    return resultSubject.asObservable()
  }

  orgChange(triggerSave: boolean = true) {
    this.updateOrgPosts().subscribe(
      () => {
        if (triggerSave) {
          this.linkedinPostIdControl.setValue(-1)
        }
        this.postChange(triggerSave)
      }
    )
  }

  setPost() {
    const linkedinPost = this.linkedinPosts.find(p => { return p.id === this.shareRequest.linkedinPostId })
    if (linkedinPost) {
      this.linkedinPost = linkedinPost
    } else if (this.shareRequest.linkedinPost) {
      this.linkedinPost = this.shareRequest.linkedinPost
      if (this.linkedinOrganizations.length === 0 && !!this.shareRequest.linkedinPost.linkedinOrganization) {
        this.linkedinOrganizations = [this.shareRequest.linkedinPost.linkedinOrganization]
      }
    } else {
      this.linkedinPost = this.linkedinService.new()
    }
    this.linkedinPostIdControl.setValue(this.linkedinPost.id)
    this.linkedinOrgIdControl.setValue(this.linkedinPost.linkedinOrganizationId)
    this.linkedinPostTitleControl.setValue(this.linkedinPost.title)
    this.linkedinPostDescControl.setValue(this.linkedinPost.postContent)
  }

  change() {
    if (!this.loading && this.onChange) {
      this.shareRequest.linkedinPost = this.linkedinPost
      this.onChange.emit(this.shareRequest)
    }
  }

  post() {
    this.linkedinService.createLinkedInPost(
      this.shareRequest.id,
      this.shareRequest.linkedinOauthId,
      this.linkedinOrgIdControl.value,
      this.linkedinPostTitleControl.value,
      this.linkedinPostDescControl.value
    ).subscribe(
      post => {
        this.updateOrgPosts().subscribe(
          () => {
            this.linkedinPost = post
            this.shareRequest.linkedinPostId = this.linkedinPost.id
            this.shareRequest.linkedinPost = this.linkedinPost
            this.linkedinPostIdControl.setValue(this.linkedinPost.id)
            this.linkedinPostTitleControl.setValue(this.linkedinPost.title)
            this.linkedinPostDescControl.setValue(this.linkedinPost.postContent)
            this.change()
          }
        )
      }
    )
  }

}
