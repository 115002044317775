import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ContactListItem, ContactService, NetworkSummary} from "../../../services/contact.service";
import {ShareRequestType} from "../../../services/share-request.service";
import {UserOauthAccount, OauthService, OauthType} from "../../../services/oauth.service";
import {faFilter} from "@fortawesome/free-solid-svg-icons"

@Component({
  selector: 'add-help-edit-modal',
  templateUrl: './auto-help-edit-modal.component.html',
  styleUrls: ['./auto-help-edit-modal.component.scss']
})
export class AutoHelpEditModalComponent implements OnInit {

  faFilter = faFilter

  contact!: ContactListItem
  twitterAccounts: UserOauthAccount[] = []
  linkedinAccounts: UserOauthAccount[] = []
  autoCreateFilters: string[] = []
  displayName: string = ''

  loading = true
  errMessage: string = ''

  ShareRequestType = ShareRequestType

  constructor(public dialogRef: MatDialogRef<AutoHelpEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ContactListItem,
              private oauthService: OauthService,
              private contactService: ContactService,
  ) {
  }

  ngOnInit() {
    this.contact = this.data
    this.displayName = this.contactService.getDisplayName(this.contact)
    /* Show filters for auto-create
    this.contact.userOauths.forEach(a => {
      if (a.autoCreateShareRequest) {
        this.autoCreateFilters = this.autoCreateFilters.concat(a.autoCreateFilters)
      }
    })
    this.autoCreateFilters = FormatUtils.removeDupesFromArray(this.autoCreateFilters)
    */
    this.oauthService.userList$.subscribe((accounts: UserOauthAccount[]) => {
      this.twitterAccounts = accounts.filter(a => {
        return a.type === OauthType.twitter
      })
      this.linkedinAccounts = accounts.filter(a => {
        return a.type === OauthType.linkedin
      })
      this.loading = false
    })
    this.oauthService.getAllForUser()
  }

  close(contact?: ContactListItem) {
    this.dialogRef.close(contact)
  }

  autoCompleteTwitterOauthChange(userOauthId: number) {
    this.contact.autoCompleteTwitterOauthId = userOauthId
  }

  autoCompleteTweetChange() {
    this.contact.autoCompleteTweetRequests = !this.contact.autoCompleteTweetRequests
    if (this.contact.autoCompleteTweetRequests) {
      if (
        (!this.contact.autoCompleteTwitterOauthId || this.contact.autoCompleteTwitterOauthId <= 0) &&
        this.twitterAccounts.length === 1
      ) {
        this.contact.autoCompleteTwitterOauthId = this.twitterAccounts[0].id
      }
    } else {
      this.contact.autoCompleteTwitterOauthId = 0
    }
  }

  autoCompleteTweetFiltersChange(autoCompleteFilters: string[]) {
    this.contact.autoCompleteTweetFilters = autoCompleteFilters
  }

  autoCompleteLinkedinOauthChange(userOauthId: number) {
    this.contact.autoCompleteLinkedinOauthId = userOauthId
  }

  autoCompleteLinkedinPostChange() {
    this.contact.autoCompleteLinkedinPostRequests = !this.contact.autoCompleteLinkedinPostRequests
    if (this.contact.autoCompleteLinkedinPostRequests) {
      if (
        (!this.contact.autoCompleteLinkedinOauthId || this.contact.autoCompleteLinkedinOauthId <= 0) &&
        this.linkedinAccounts.length === 1
      ) {
        this.contact.autoCompleteLinkedinOauthId = this.linkedinAccounts[0].id
      }
    } else {
      this.contact.autoCompleteLinkedinOauthId = 0
    }
  }

  autoCompleteLinkedinPostFiltersChange(autoCompleteFilters: string[]) {
    this.contact.autoCompleteLinkedinPostFilters = autoCompleteFilters
  }

}
