import {Component, Input, OnInit} from '@angular/core';
import {faComment, faHeart, faRetweet} from "@fortawesome/free-solid-svg-icons";
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {RawXPost, XTwitterService} from "../../../services/x-twitter.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'x-post-display',
  templateUrl: './x-post-display.component.html',
  styleUrls: ['./x-post-display.component.scss']
})
export class XPostDisplayComponent implements OnInit {

  @Input({ required: true} ) post: RawXPost | undefined
  @Input() accountName: string = ''
  @Input() accountUsername: string = ''

  formatDateLong = FormatUtils.formatDateLong
  formatTime = FormatUtils.formatTime

  constructor(private xTwitterService: XTwitterService,
  ) {
  }

  ngOnInit() {
  }

  formatHashtagsLinks(postContent: string): string {
    return this.xTwitterService.formatHashtagsLinks(postContent)
  }

  protected readonly faComment = faComment;
  protected readonly faRetweet = faRetweet;
  protected readonly faHeart = faHeart;
  protected readonly faXTwitter = faXTwitter;
}
