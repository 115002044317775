import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NotificationService} from "../../../services/utilities/notification.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Invite, InviteService} from "../../../services/invite.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {FormatUtils} from "../../../services/utilities/format.utilities";
import {ContactQuickSearchItem} from "../../../services/contact.service";

@Component({
  selector: 'invite-list',
  templateUrl: './invite-list.component.html',
  styleUrls: ['./invite-list.component.scss']
})
export class InviteListComponent implements OnInit, OnChanges {

  @Input() fullDisplay: boolean = true
  @Input() isTeamInvite: boolean = false
  @Input() invites: Invite[] = []

  loading: boolean = true

  inviteColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['email', 'createdAt']
    : ['select', 'actions', 'email', 'createdAt', 'status', 'acceptedAt']
  numInviteCols: number = this.inviteColumns.length
  inviteTable: MatTableDataSource<Invite> = new MatTableDataSource()
  inviteSelection = new SelectionModel<Invite>(true, []);
  @ViewChild('invitePaginator') invitePaginator!: MatPaginator
  @ViewChild('inviteSort') inviteSort!: MatSort

  pluralize = FormatUtils.pluralize
  formatDate = FormatUtils.formatDate

  constructor(private inviteService: InviteService,
              private notificationService: NotificationService,
              private breakpointObserver: BreakpointObserver,
              private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.setInvites(this.invites)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['invites']) {
      this.setInvites(changes['invites'].currentValue)
    }
  }

  setInvites(invites: Invite[]) {
    this.inviteTable = new MatTableDataSource(invites)
    if (this.inviteSort) {
      this.inviteTable.sort = this.inviteSort
      const sortState: Sort = {active: 'createdAt', direction: 'desc'}
      this.inviteSort.active = sortState.active
      this.inviteSort.direction = sortState.direction
      this.inviteSort.sortChange.emit(sortState)
    }
    setTimeout(() => {
      if (this.invitePaginator) {
        this.inviteTable.paginator = this.invitePaginator
        this.inviteTable.paginator.pageSize = 25
      }
    })
    this.loading = false
  }

  areAllInvitesSelected() {
    const numSelected = this.inviteSelection.selected.length;
    const numRows = this.inviteTable.data.length;
    return numSelected === numRows;
  }

  isInviteSelected(invite: Invite) {
    return this.inviteSelection.selected.filter(s => s.id === invite.id).length > 0
  }

  selectAllInvites() {
    this.areAllInvitesSelected() ?
      this.inviteSelection.clear() :
      this.inviteTable.data.forEach(row => this.inviteSelection.select(row));
  }

  resendSelectedInvites() {
    const contacts: ContactQuickSearchItem[] = this.inviteSelection.selected.map(
      i => {
        return {
          id: i.contactId,
          firstName: i.contact.profile.firstName,
          lastName: i.contact.profile.lastName,
          email: i.contact.profile.primaryEmail,
        }
      }
    )
    this.inviteService.openInviteDialog(this.isTeamInvite, contacts)
  }

  resendInvite(invite: Invite) {
    this.inviteService.openInviteDialog(this.isTeamInvite,
      [{
        id: invite.contactId,
        firstName: invite.contact.profile.firstName,
        lastName: invite.contact.profile.lastName,
        email: invite.contact.profile.primaryEmail,
      }]
    )
  }

  deleteSelectedInvites() {
    this.dialogService.confirmDialog(
      'Delete Selected Invites',
      'Are you sure you want to delete ' + this.inviteSelection.selected.length + this.pluralize(' invite', this.inviteSelection.selected.length) + '?'
    ).subscribe(
      (result: boolean) => {
        if (result) {
          this.deleteInvites(this.inviteSelection.selected)
          this.notificationService.success(this.inviteSelection.selected.length + this.pluralize(' invite', this.inviteSelection.selected.length) + ' deleted.')
        }
      }
    )
  }

  deleteInvites(invites: Invite[]) {
    invites.forEach(
      invite => {
        this.inviteService.delete(invite)
      }
    )
  }

  confirmDeleteInvite(invite: Invite) {
    this.dialogService.confirmDialog(
      'Delete Invite',
      'Are you sure you want to delete this invite?'
    ).subscribe(
      (result: boolean) => {
        if (result) {
          this.deleteInvites([invite])
          this.notificationService.success('Invite deleted.')
        }
      }
    )
  }

}
