import {Injectable} from "@angular/core"
import {ApiService} from "./api.service";
import {JweService} from "./auth/jwe.service";
import {NotificationService} from "./utilities/notification.service";
import {Observable, of, Subject} from "rxjs";
import {FormatUtils} from "./utilities/format.utilities";
import {ContactImport, ContactImportResult, ContactImportService} from "./contact-import.service";
import {LinkedInOrganization, LinkedInOrganizationResult} from "./linkedin.service";
import {TeamMember, TeamMemberResult} from "./team.service";
import {TeamMemberOauthsModal} from "../components/settings/team-member-oauths-modal/team-member-oauths-modal";
import {MatDialog} from "@angular/material/dialog";

export interface TeamMemberOauthAccount {
  id: number
  userOauthId: number
  userOauth?: UserOauthAccount
  teamMemberId: number
  teamMember?: TeamMember
  autoCreateShareRequest: boolean
  autoCreateFilters: string[]
}

export interface TeamMemberOauthAccountResult {
  id: number
  user_oauth_id: number
  user_oauth?: UserOauthAccountResult
  team_member_id: number
  teamMember?: TeamMemberResult
  auto_create_share_request: boolean
  auto_create_filters: string[]
}

export interface UserOauthAccount {
  id: number
  type: OauthType
  name: string
  email: string
  username: string
  signature: string
  replyTo: string
  createdAt: number
  contactImports: ContactImport[]
  contactCount: number
  linkedinOrganizations?: LinkedInOrganization[]
  teamMemberOauths: TeamMemberOauthAccount[]
}

export interface UserOauthAccountResult {
  id: number
  type: OauthType
  name: string
  email: string
  username: string
  signature: string
  reply_to: string
  created_at: number
  contact_imports: ContactImportResult[]
  contact_count: number
  linkedin_organizations?: LinkedInOrganizationResult[]
  team_member_oauths: TeamMemberOauthAccountResult[]
}

export interface OauthAccountListItem {
  id: number
  type: string
  teamMemberOauths: TeamMemberOauthAccount[]
}

export interface OauthAccountListItemResult {
  id: number
  type: string
  team_member_oauths: TeamMemberOauthAccountResult[]
}

export interface AddOauthToObject {
  type: string
  field: string
  id: number
}

export enum OauthType {
  google = 'google',
  microsoft = 'microsoft',
  twitter = 'twitter',
  linkedin = 'linkedin',
}

export enum OauthAction {
  continue = 'continue',
  add = 'add',
}

@Injectable({
  providedIn: 'root'
})
export class OauthService {

  private oauthEndpoint: string = 'oauth/'

  public userList!: UserOauthAccount[]
  private userListSubject: Subject<UserOauthAccount[]> = new Subject<UserOauthAccount[]>()
  userList$: Observable<UserOauthAccount[]> = this.userListSubject.asObservable()

  public teamList!: TeamMemberOauthAccount[]
  private teamListSubject: Subject<TeamMemberOauthAccount[]> = new Subject<TeamMemberOauthAccount[]>()
  teamList$: Observable<TeamMemberOauthAccount[]> = this.teamListSubject.asObservable()

  private userOauthEndpoint: string = 'user_oauths'
  private teamMemberOauthEndpoint: string = 'team_member_oauths'

  constructor(private api: ApiService,
              private jweService: JweService,
              private notificationService: NotificationService,
              private contactImportService: ContactImportService,
              private dialog: MatDialog,
  ) {
  }

  finishOauth = (code: string,
                 type: string,
                 action: string,
                 addOauthTo?: AddOauthToObject
  ): Observable<any> => {
    const resultSubject: Subject<any> = new Subject<any>()
    if (code) {
      const params: any = { code: code }
      if (addOauthTo) {
        params.add_to_type = addOauthTo.type
        params.add_to_field = addOauthTo.field
        params.add_to_id = addOauthTo.id
      }
      this.api.get<any>(
        this.oauthEndpoint + type + '/' + action,
        params
      ).subscribe(
        (response: any) => {
          if (response.auth_token) {
            this.jweService.setJWE(response.auth_token, response.exp_datetime)
          }
          if (response.contact_import) {
            this.getAllForUser(true)
            if (!response.auth_token) {
              this.notificationService.success("You're contacts are importing.  We'll email you when it is done.")
            }
          }
          resultSubject.next(response.user_oauth_id)
        },
        (err: any) => {
          this.notificationService.error(err.error.message)
          resultSubject.error(err)
        }
      )
    } else {
      resultSubject.next(null)
    }
    return resultSubject.asObservable()
  }

  doOauth(type: string,
          action: string,
          redirectUri: string,
          approvalPrompt: string = 'auto',
          addOauthTo?: AddOauthToObject
  ) {
    const params: any = {
      redirect_uri: encodeURIComponent(redirectUri),
      action: action,
      approval_prompt: approvalPrompt,
    }
    if (addOauthTo) {
      params.add_to_type = addOauthTo.type
      params.add_to_field = addOauthTo.field
      params.add_to_id = addOauthTo.id
    }
    this.api.get<string>(this.oauthEndpoint + type + '/init', params).subscribe(
      (authUrl: string) => {
        window.location.href = authUrl
      }, err => {
        this.notificationService.error(err.message)
      }
    )
  }

  doOauthGoogle(action: string, redirectUri: string, approvalPrompt: string = 'auto', addOauthTo?: AddOauthToObject) {
    this.doOauth(OauthType.google, action, redirectUri, approvalPrompt, addOauthTo)
  }

  doOauthTwitter(action: string, redirectUri: string, addOauthTo?: AddOauthToObject) {
    this.doOauth(OauthType.twitter, action, redirectUri, 'auto', addOauthTo)
  }

  doOauthLinkedIn(action: string, redirectUri: string, addOauthTo?: AddOauthToObject) {
    this.doOauth(OauthType.linkedin, action, redirectUri, 'auto', addOauthTo)
  }

  getAllForUser(forceGet: boolean = false): Observable<UserOauthAccount[]> {
    if (this.userList && !forceGet) {
      this.userListSubject.next(this.userList)
      return of(this.userList)
    } else {
      const resultSubject = new Subject<UserOauthAccount[]>()
      this.api.get<UserOauthAccountResult[]>(`my/` + this.userOauthEndpoint, {}).subscribe(
        (results: UserOauthAccountResult[]) => {
          this.userList = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.userListSubject.next(this.userList)
          resultSubject.next(this.userList)
        }
      )
      return resultSubject.asObservable()
    }
  }

  saveUserOauth(account: UserOauthAccount): Observable<UserOauthAccount[]> {
    return this.updateUserOauth(
      this.api.save<UserOauthAccount>(this.userOauthEndpoint, account)
    )
  }

  deleteUserOauth(account: UserOauthAccount): Observable<UserOauthAccount[]> {
    return this.updateUserOauth(
      this.api.delete(this.userOauthEndpoint + '/' + account.id)
    )
  }

  updateUserOauth(update$: Observable<UserOauthAccount>): Observable<UserOauthAccount[]> {
    const updateSubject: Subject<UserOauthAccount[]> = new Subject<UserOauthAccount[]>()
    update$.subscribe(
      (results: UserOauthAccount) => {
        this.getAllForUser(true).subscribe(
          list => {
            updateSubject.next(FormatUtils.snakeToCamelCaseKeys(results))
          }
        )
      }
    )
    return updateSubject.asObservable()
  }

  getAllForTeam(forceGet: boolean = false): Observable<TeamMemberOauthAccount[]> {
    if (this.teamList && !forceGet) {
      this.teamListSubject.next(this.teamList)
      return of(this.teamList)
    } else {
      const resultSubject = new Subject<TeamMemberOauthAccount[]>()
      this.api.get<TeamMemberOauthAccountResult[]>('my_current_team/' + this.teamMemberOauthEndpoint, {}).subscribe(
        (results: TeamMemberOauthAccountResult[]) => {
          this.teamList = results.map(FormatUtils.snakeToCamelCaseKeys)
          this.teamListSubject.next(this.teamList)
          resultSubject.next(this.teamList)
        }
      )
      return resultSubject.asObservable()
    }
  }

  saveTeamMemberOauth(account: TeamMemberOauthAccount): Observable<TeamMemberOauthAccount> {
    return this.api.save<TeamMemberOauthAccount>(this.teamMemberOauthEndpoint, account)
  }

  deleteTeamMemberOauth(account: TeamMemberOauthAccount): Observable<any> {
    return this.api.delete(this.teamMemberOauthEndpoint + '/' + account.id)
  }

  getLastImportProgressLabel(account: UserOauthAccount): string {
    if (account.contactImports.length > 0 && !account.contactImports[0].importComplete) {
      return this.contactImportService.getImportProgressLabel(account.contactImports[0])
    } else {
      return account.contactCount.toLocaleString('en')
    }
  }

  getLastImportDateLabel(account: UserOauthAccount): string {
    if (account.contactImports.length > 0) {
      return this.contactImportService.getImportDateLabel(account.contactImports[0])
    } else {
      return ""
    }
  }

  getLastImportCompleted(account: UserOauthAccount): boolean {
    if (account.contactImports.length > 0) {
      return account.contactImports[0].importComplete
    } else {
      return true
    }
  }

  hasAccountType(accountType: string, oauthAccounts?: OauthAccountListItem[]): boolean {
    oauthAccounts = oauthAccounts || this.userList
    return !!oauthAccounts &&
           oauthAccounts.filter(
             a => { return a.type === accountType }
           ).length > 0
  }

  hasEmailAccount(oauthAccounts?: OauthAccountListItem[]): boolean {
    return this.hasAccountType(OauthType.google, oauthAccounts) ||
      this.hasAccountType(OauthType.microsoft, oauthAccounts)
  }

  hasTwitterAccount(oauthAccounts?: OauthAccountListItem[]): boolean {
    return this.hasAccountType(OauthType.twitter, oauthAccounts)
  }

  hasLinkedInAccount(oauthAccounts?: OauthAccountListItem[]): boolean {
    return this.hasAccountType(OauthType.linkedin, oauthAccounts)
  }

  filterEmailAccounts(oauthAccounts: UserOauthAccount[]): UserOauthAccount[] {
    return oauthAccounts.filter(a => {
      return a.type === OauthType.google || a.type === OauthType.microsoft
    })
  }

  filterSocialAccounts(oauthAccounts: UserOauthAccount[]): UserOauthAccount[] {
    return oauthAccounts.filter(a => {
      return a.type === OauthType.twitter || a.type === OauthType.linkedin
    })
  }

  editTeamMemberOauths(account: UserOauthAccount) {
    this.dialog.open(TeamMemberOauthsModal, {
      data: account
    }).afterClosed().subscribe(
      (saved: boolean) => {
        if (saved) {
          this.getAllForUser(true)
        }
      }
    )
  }

}
