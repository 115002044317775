<button mat-flat-button
        color="primary"
        (click)="doOauth()"
        ngClass.gt-xs="oauth-btn-width"
>
  <div class="flex-row-left">
    <fa-icon [icon]="getIcon()" class="icon"></fa-icon>
    {{label}}
  </div>
</button>
