import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService, MagicLinkType} from "../../../services/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OauthService, OauthType, OauthAction} from "../../../services/oauth.service";
import {Company, CompanyService} from "../../../services/company.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = true
  errorMsg: string = ''

  OauthType = OauthType
  OauthAction = OauthAction

  returnUrl: string = window.location.origin + '/'

  company: Company | undefined = undefined

  form = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
  })

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private oauthService: OauthService,
              private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.loading = true
    this.route.queryParams.subscribe(params => {
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl'].indexOf(window.location.origin) >= 0
          ? params['returnUrl']
          : window.location.origin + params['returnUrl']
      }
      if (!!params && params['token']) {
        this.loginViaToken(params['token'])
      } else if (this.authService.isAuthenticated()) {
        this.goToNext()
      } else if (!!params && params['domain']) {
        this.companyService.getCompanyByDomain(params['domain']).subscribe(
          company => {
            if (company) {
              this.company = company
            }
            this.loading = false
          },
          err => {
            this.loading = false
          }
        )
      } else {
        this.loading = false
      }
    })
  }

  clearError() {
    this.errorMsg = ''
  }

  goToNext(): void {
    window.location.href = this.returnUrl
  }

  login(){
    this.authService.login(this.form.value.email, this.form.value.password).subscribe(
      jwe => {
        this.goToNext()
      },
      err => {
        this.loading = false
        this.errorMsg = err.message
        setTimeout(this.clearError, 3000)
      }
    )
  }

  loginViaToken(token: string) {
    this.authService.loginViaToken(token).subscribe(
      type => {
        if (type === MagicLinkType.userInvite || type === MagicLinkType.teamInvite) {
          this.router.navigate(['intro'])
        } else {
          this.goToNext()
        }
      },
      err => {
        this.loading = false
        this.errorMsg = err.message
        setTimeout(this.clearError, 3000)
      }
    )
  }

  doGoogleOauth() {
    this.oauthService.doOauth(
      OauthType.google,
      OauthAction.continue,
      this.returnUrl,
    )
  }

}
