import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {User, UserService} from "../../../services/user.service";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  user!: User
  hideOldPassword: boolean = false

  errMessage: string = ''

  changePasswordForm = new FormGroup({
    oldPassword: new FormControl('', []),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(this.userService.passwordValidationPattern)
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(this.userService.passwordValidationPattern)
    ]),
  })

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.user = this.data.user
    this.hideOldPassword = this.data.hideOldPassword
  }

  close(saved: boolean) {
    this.dialogRef.close(saved)
  }

  save() {
    this.userService.changePassword(
      this.user,
      this.changePasswordForm.value.oldPassword || '',
      this.changePasswordForm.value.newPassword || '',
      this.changePasswordForm.value.confirmPassword || '',
    ).subscribe(
      user => {
        this.user = user
        this.close(true)
      },
      err => {
        this.errMessage = err ? err.message : 'There was an error saving your password.'
        setTimeout(() => {
          this.errMessage = ''
        }, 5000)
      }
    )
  }

}
