import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth/auth.service";
import {ActivatedRouteSnapshot, Router, RoutesRecognized} from "@angular/router";
import {BillingService, Subscription} from "../../services/billing.service";
import {AccessRight, User, UserService} from "../../services/user.service";
import {DialogService} from "../../services/utilities/dialog.service";
import {Team, TeamService} from "../../services/team.service";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {mergeMap} from "rxjs";
import {FormatUtils} from "../../services/utilities/format.utilities";

@Component({
  selector: 'nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  faCaretDown = faCaretDown

  user: User = this.userService.new()
  filteredTeamList: Team[] = []
  canMutateSubcription: boolean = true
  canMutateTeam: boolean = true
  canViewTeam: boolean = true

  showNav: boolean = false
  hideNavForPage: boolean = false

  hasSubscription: boolean = true
  hasTrial: boolean = false
  subscription: Subscription | undefined = undefined
  onSubscribePage: boolean = false

  formatDate = FormatUtils.formatDate

  constructor(private authService: AuthService,
              private userService: UserService,
              private teamService: TeamService,
              private billingService: BillingService,
              private dialogService: DialogService,
              private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized && data.state.root.firstChild) {
        const route: ActivatedRouteSnapshot = data.state.root.firstChild
        this.hideNavForPage = (
          route.url &&
          route.url.length > 0 &&
          (
            (
              route.url[0].path === 'share-request-preview' &&
              route.queryParams &&
              route.queryParams['preview']
            ) ||
            route.url[0].path === 'intro'
          )
        )
        this.showNav = this.authService.isAuthenticated() && !this.hideNavForPage
        this.onSubscribePage = (
          route.url &&
          route.url.length > 0 &&
          route.url[0].path === 'subscription'
        )
      }
    })

    this.userService.user$.subscribe(
      user => {
        this.user = user
        this.filteredTeamList = user.teamMembers.map(t => t.team)
          .filter(t => t && t.id !== this.user.currentTeamId)
        this.hasSubscription = this.billingService.hasSubscription(this.user)
        this.hasTrial = this.billingService.hasActiveTrial(this.user)
        this.subscription = this.billingService.getUserSubscription(this.user)
        this.canMutateSubcription = this.userService.hasAccessRight(this.user, AccessRight.mutateSubscription)
        this.canMutateTeam = this.userService.hasAccessRight(this.user, AccessRight.mutateTeam)
        this.canViewTeam = this.userService.hasAccessRight(this.user, AccessRight.viewTeam)
        this.showNav = this.authService.isAuthenticated() && !this.hideNavForPage
      }
    )
    // Each individual page should call getIdentity()
    //this.userService.getIdentity()
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }

  goToSubscribe() {
    //window.location.href = this.billingService.getPaymentLink(this.userService.user)
    //this.billingService.getCheckoutLink(this.userService.user).subscribe(url => {
    //  window.location.href = url
    //})
    this.router.navigate(['subscription'], { queryParams: { returnUrl: window.location.href.replace(window.location.origin, "") }})
  }

  logout(){
    this.authService.logout()
    this.router.navigate(['login'], {
      queryParams: { returnUrl: window.location.href.replace(window.location.origin, "") }
    })
    this.showNav = false
  }

  selectTeam(teamId: number) {
    if (teamId !== this.user.currentTeamId) {
      this.user.currentTeamId = teamId
      this.userService.save(this.user).subscribe(user => this.user = user)
    }
  }

  createNewTeam() {
    this.dialogService.inputDialog(
      'Create New Team',
      'Enter a name for your new team',
      'Team Name',
      '',
    ).subscribe(
      name => {
        if (!!name && name !== '') {
          const newTeam: Team = this.teamService.new()
          newTeam.name = name
          this.teamService.save(newTeam).pipe(
            mergeMap(
              (team: Team) => {
                return this.userService.getIdentity(true)
              }
            )
          ).subscribe()
        }
      }
    )
  }

}
