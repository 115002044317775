import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { JwtModule } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { EditorModule } from "@tinymce/tinymce-angular";
import { CSVBoxAngularModule } from "@csvbox/angular";

import {tokenGetter} from "./services/auth/jwe.service";
import {ApiService} from "./services/api.service";
import {DialogService} from "./services/utilities/dialog.service";
import {NotificationService} from "./services/utilities/notification.service";

import {NavComponent} from "./components/nav/nav.component";
import {LoginComponent} from "./components/auth/login/login.component";
import {SignupComponent} from "./components/auth/signup/signup.component";
import {CompanyLandingComponent} from "./components/auth/company-landing/company-landing.component";
import {ForgetPasswordComponent} from "./components/auth/forget-password/forget-password.component";
import {AcceptInviteComponent} from "./components/invite/accept-invite/accept-invite.component";
import {InviteModalComponent} from "./components/invite/invite-modal/invite-modal.component";
import {PersonalInvitesComponent} from "./components/invite/personal-invites/personal-invites.component";
import {InviteListComponent} from "./components/invite/invite-list/invite-list.component";
import {OauthSuccessComponent} from "./components/auth/oauth-success/oauth-success.component";
import {SignupIntroComponent} from "./components/onboarding/signup-intro/signup-intro.component";
import {OnboardingStepsComponent} from "./components/onboarding/onboarding-steps/onboarding-steps.component";
import {OauthButtonComponent} from "./components/auth/oauth-button/oauth-button.component";
import {OauthAllButtonComponent} from "./components/auth/oauth-all-button/oauth-all-button.component";
import {DialogComponent} from "./components/dialog/dialog.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {ShareRequestListComponent} from "./components/share-requests/share-request-list/share-request-list.component";
import {
  ShareRequestDetailComponent
} from "./components/share-requests/share-request-detail/share-request-detail.component";
import {
  ShareRequestSetupComponent
} from "./components/share-requests/share-request-setup/share-request-setup.component";
import {
  ShareRequestSharerListComponent
} from "./components/share-requests/share-request-sharer-list/share-request-sharer-list.component";
import {SharerDetailComponent} from "./components/sharer/sharer-detail/sharer-detail.component"
import {SharerProspectListComponent} from "./components/sharer/sharer-prospect-list/sharer-prospect-list.component";
import {
  SharerInviteCampaignComponent
} from "./components/share-requests/sharer-invite-campaign/sharer-invite-campaign.component";
import {
  IntroRequestModalComponent
} from "./components/share-requests/intro-request-modal/intro-request-modal.component";
import {
  AcceptIntroRequestComponent
} from "./components/share-requests/accept-intro-request/accept-intro-request.component";
import {ContactListComponent} from "./components/contacts/contact-list/contact-list.component";
import {ContactAnalyticsComponent} from "./components/contacts/contact-analytics/contact-analytics.component";
import {NetworkSummaryComponent} from "./components/contacts/network-summary/network-summary.component";
import {AddEditContactComponent} from "./components/contacts/add-edit-contact/add-edit-contact.component";
import {ProfileAvatarComponent} from "./components/contacts/profile-avatar/profile-avatar.component";
import {
  RelationshipStrengthComponent
} from "./components/contacts/relationship-strength/relationship-strength.component";
import {ShortLabelComponent} from "./components/contacts/short-label/short-label.component";
import {AutoHelpSettingsComponent} from "./components/contacts/auto-help-settings/auto-help-settings.component"
import {AutoHelpEditModalComponent} from "./components/contacts/auto-help-edit-modal/auto-help-edit-modal.component";
import {ContactQuickSearchComponent} from "./components/contacts/contact-quick-search/contact-quick-search.component";
import {ContactProfile} from "./components/contacts/contact-profile/contact-profile";
import {ContactProfileDetail} from "./components/contacts/contact-profile-detail/contact-profile-detail";
import {CompanyProfileDetail} from "./components/contacts/company-profile/company-profile-detail";
import {ContactProfileNavComponent} from "./components/contacts/contact-profile-nav/contact-profile-nav.component";
import {TeamMemberOauthsModal} from "./components/settings/team-member-oauths-modal/team-member-oauths-modal";
import {ImportButtonComponent} from "./components/contacts/import-button/import-button.component";
import {CSVImportComponent} from "./components/contacts/csv-import/csv-import.component";
import {UnsubscribeListComponent} from "./components/messages/unsubscribe-list/unsubscribe-list.component";
import {AudienceEditorComponent} from "./components/audiences/audience-editor/audience-editor.component";
import {SelectAudienceComponent} from "./components/audiences/select-audience/select-audience.component";
import {AllSettingsComponent} from "./components/settings/all-settings/all-settings.component";
import {AccountSettingsComponent} from "./components/settings/account-settings/account-settings.component";
import {ChangePasswordComponent} from "./components/settings/change-password/change-password.component";
import {SubscriptionDetailComponent} from "./components/subscription/subscription-detail/subscription-detail.component";
import {TeamDetailComponent} from "./components/team/team-detail.component";
import {EmailAccountListComponent} from "./components/settings/email-account-list/email-account-list.component";
import {SocialAccountListComponent} from "./components/settings/social-account-list/social-account-list.component";
import {ContactImportListComponent} from "./components/settings/contact-import-list/contact-import-list.component";
import {ApiKeyListComponent} from "./components/settings/api-key-list/api-key-list.component";
import {SelectEmailAccountComponent} from "./components/messages/select-email-account/select-email-account.component";
import {
  SelectXAccountComponent
} from "./components/x-twitter/select-x-account/select-x-account.component";
import {
  SelectLinkedinAccountComponent
} from "./components/linkedin/select-linkedin-account/select-linkedin-account.component";
import {XPostRequestSetupComponent} from "./components/x-twitter/x-post-request-setup/x-post-request-setup.component";
import {XPostMetricsComponent} from "./components/x-twitter/x-post-metrics/x-post-metrics.component";
import {XPostDisplayComponent} from "./components/x-twitter/x-post-display/x-post-display.component";
import {LinkedinPostRequestSetupComponent} from "./components/linkedin/linkedin-post-request-setup/linkedin-post-request-setup.component";
import {LinkedinPostMetricsComponent} from "./components/linkedin/linkedin-post-metrics/linkedin-post-metrics.component";
import {LinkedinPostDisplayComponent} from "./components/linkedin/linkedin-post-display/linkedin-post-display.component";
import {OpenGraphDisplayComponent} from "./components/common/opengraph-display/opengraph-display.component";
import {InputChipComponent} from "./components/form/input-chip/input-chip.component";
import {SelectChipComponent} from "./components/form/select-chip/select-chip.component";
import {AutocompleteChipComponent} from "./components/form/autocomplete-chip/autocomplete-chip.component";
import {MessageEditorComponent} from "./components/messages/message-editor/message-editor.component";
import {
  MessageTemplateListComponent
} from "./components/messages/message-template-list/message-template-list.component";
import {AudienceListComponent} from "./components/audiences/audience-list/audience-list.component";
import {DoughnutChartComponent} from "./components/analytics/doughnut-chart/doughnut-chart.component";
import {AllAdminComponent} from "./components/admin/all-admin/all-admin.component";
import {AdminUserListComponent} from "./components/admin/admin-user-list/admin-user-list.component";
import {AdminTeamListComponent} from "./components/admin/admin-team-list/admin-team-list.component";
import {ProgressBarTimerComponent} from "./components/common/progress-bar-timer/progress-bar-timer.component";

import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSelectModule} from "@angular/material/select";
import {MatChipsModule} from "@angular/material/chips";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatCardModule} from "@angular/material/card";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatRadioModule} from "@angular/material/radio";
import {MatStepperModule} from "@angular/material/stepper";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatDividerModule} from "@angular/material/divider";

@NgModule(
  {
    declarations: [
      AppComponent,
      NavComponent,
      LoginComponent,
      SignupComponent,
      CompanyLandingComponent,
      ForgetPasswordComponent,
      AcceptInviteComponent,
      InviteModalComponent,
      PersonalInvitesComponent,
      InviteListComponent,
      OauthButtonComponent,
      OauthAllButtonComponent,
      OauthSuccessComponent,
      SignupIntroComponent,
      OnboardingStepsComponent,
      DialogComponent,
      DashboardComponent,
      ShareRequestListComponent,
      ShareRequestDetailComponent,
      ShareRequestSetupComponent,
      ShareRequestSharerListComponent,
      SharerDetailComponent,
      SharerProspectListComponent,
      SharerInviteCampaignComponent,
      IntroRequestModalComponent,
      AcceptIntroRequestComponent,
      ContactListComponent,
      ContactAnalyticsComponent,
      NetworkSummaryComponent,
      AddEditContactComponent,
      ProfileAvatarComponent,
      RelationshipStrengthComponent,
      ShortLabelComponent,
      AutoHelpSettingsComponent,
      AutoHelpEditModalComponent,
      ContactQuickSearchComponent,
      ContactProfile,
      ContactProfileDetail,
      CompanyProfileDetail,
      ContactProfileNavComponent,
      TeamMemberOauthsModal,
      ImportButtonComponent,
      CSVImportComponent,
      UnsubscribeListComponent,
      AudienceEditorComponent,
      SelectAudienceComponent,
      AllSettingsComponent,
      AccountSettingsComponent,
      ChangePasswordComponent,
      SubscriptionDetailComponent,
      TeamDetailComponent,
      EmailAccountListComponent,
      SocialAccountListComponent,
      ContactImportListComponent,
      ApiKeyListComponent,
      SelectEmailAccountComponent,
      SelectXAccountComponent,
      SelectLinkedinAccountComponent,
      XPostRequestSetupComponent,
      XPostMetricsComponent,
      XPostDisplayComponent,
      LinkedinPostRequestSetupComponent,
      LinkedinPostMetricsComponent,
      LinkedinPostDisplayComponent,
      OpenGraphDisplayComponent,
      InputChipComponent,
      SelectChipComponent,
      AutocompleteChipComponent,
      MessageEditorComponent,
      MessageTemplateListComponent,
      AudienceListComponent,
      DoughnutChartComponent,
      AllAdminComponent,
      AdminUserListComponent,
      AdminTeamListComponent,
      ProgressBarTimerComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
      FontAwesomeModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      RouterModule,
      FlexLayoutModule,
      JwtModule.forRoot(
        {
          config: {
            tokenGetter: tokenGetter,
            allowedDomains: [
              "localhost:8081",
              "staging.wrollo.com",
              "wrollo.com"
            ]
          }
        }
      ),
      FormsModule,
      ReactiveFormsModule,
      MatDialogModule,
      MatFormFieldModule,
      MatInputModule,
      MatTabsModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatToolbarModule,
      MatIconModule,
      MatMenuModule,
      MatCheckboxModule,
      MatProgressSpinnerModule,
      MatProgressBarModule,
      MatSnackBarModule,
      MatSidenavModule,
      MatSelectModule,
      MatChipsModule,
      MatExpansionModule,
      MatCardModule,
      MatTooltipModule,
      MatAutocompleteModule,
      MatRadioModule,
      MatStepperModule,
      MatSlideToggleModule,
      MatDividerModule,
      EditorModule,
      CSVBoxAngularModule,
    ],
    providers: [
      CookieService,
      ApiService,
      DialogService,
      NotificationService,
      provideHttpClient(withInterceptorsFromDi()),
    ]
  }
)
export class AppModule { }
