import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {NotificationService} from "../../../services/utilities/notification.service";
import {DialogService} from "../../../services/utilities/dialog.service";
import {User, UserPagination, UserService} from "../../../services/user.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {map, merge, mergeMap, Observable, startWith, Subject} from "rxjs";
import {FormControl} from "@angular/forms";
import {FormatUtils} from "../../../services/utilities/format.utilities";

@Component({
  selector: 'admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit, AfterViewInit {

  loading: boolean = true

  allUsers: User[] = []
  totalUsers: number = 0

  displayedColumns: string[] = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    ? ['firstName', 'lastName', 'email']
    : ['firstName', 'lastName', 'email', 'onboardingCompleted', 'numContacts', 'numUserOauths', 'numTeams', 'createDate', 'actions']
  numDisplayedColumns: number = this.displayedColumns.length
  userTable: MatTableDataSource<User> = new MatTableDataSource()
  @ViewChild('paginator') paginator!: MatPaginator
  @ViewChild('sort') sort!: MatSort

  teamFilter: number = 0
  teamFilterSubject: Subject<number> = new Subject<number>()
  teamFilter$: Observable<number> = this.teamFilterSubject.asObservable()

  searchTermFilter: string = ""
  searchTermFilterSubject: Subject<string> = new Subject<string>()
  searchTermFilter$: Observable<string> = this.searchTermFilterSubject.asObservable()
  searchTermControl: FormControl = new FormControl()

  formatDate = FormatUtils.formatDate

  constructor(private userService: UserService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              public breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.userTable = new MatTableDataSource<User>()

    this.userTable.sort = this.sort
    const sortState: Sort = {active: 'first_name', direction: 'asc'}
    this.sort.active = sortState.active
    this.sort.direction = sortState.direction
    this.sort.sortChange.emit(sortState)
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))

    this.userTable.paginator = this.paginator
    this.userTable.paginator.pageSize = 25

    merge(this.teamFilter$, this.searchTermFilter$, this.userTable.sort.sortChange, this.userTable.paginator.page).pipe(
      startWith({}),
      mergeMap(
        () => {
          this.userTable = new MatTableDataSource<User>([])
          this.loading = true
          return this.userService.getPage(
            this.teamFilter,
            this.searchTermFilter,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
          )
        }
      ),
      map(userPagination => {
        return this.processUserPagination(userPagination)
      })
    ).subscribe(users => {})
  }

  processUserPagination(userPagination: UserPagination): User[] {
    if (!userPagination) return []
    this.totalUsers = userPagination.itemsReceived
    this.allUsers = userPagination.items
    this.userTable = new MatTableDataSource<User>(this.allUsers)
    this.loading = false
    return this.allUsers
  }

  search() {
    this.searchTermFilter = this.searchTermControl.value
    this.searchTermFilterSubject.next(this.searchTermFilter)
  }

  confirmDeleteUser(user: User) {
    this.dialogService.confirmDialog(
      'Delete User',
      'Are you sure you want to delete this user?'
    ).subscribe(result => {
      if (result) {
        this.userService.delete(user).subscribe(
          results => {
            this.loading = true
            this.userService.getPage(
              this.teamFilter,
              this.searchTermFilter,
              this.paginator.pageIndex + 1,
              this.paginator.pageSize,
              this.sort.active,
              this.sort.direction,
            ).subscribe(userPagination => {
              return this.processUserPagination(userPagination)
            })
            this.notificationService.success('User has been deleted.')
          }
        )
      }
    })
  }

  formatOnboardingStep(step: string): string {
    return step.replaceAll("_", " ")
  }

}
