<div class="flex-row-space-between nav-btn-row">
  <div class="flex-row-left">
    <div class="nav-btn">
      <button mat-mini-fab
              type="button"
              class="white-btn horiz-margin"
              *ngIf="viewIndex > 0"
              (click)="navigate(-1)"
      ><mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="nav-btn">
      <button mat-mini-fab
              type="button"
              class="white-btn horiz-margin"
              *ngIf="viewIndex < (minItems - 1)"
              (click)="navigate(1)"
      ><mat-icon>arrow_forward</mat-icon></button>
    </div>
  </div>
  <button mat-mini-fab
          type="button"
          class="white-btn horiz-margin"
          (click)="close()"
  ><mat-icon>close</mat-icon></button>
</div>
