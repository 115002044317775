
<div class="flex-row-left" ngClass.xs="flex-wrap">

  <div class="table-container">
    <table mat-table [dataSource]="unsubscribeTable" matSort #listSort="matSort">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="thin-col">
          <mat-checkbox (change)="$event ? selectAll() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="thin-col">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell
            *matHeaderCellDef
            class="thin-col"
        >
          <button mat-icon-button
                  matTooltip="Delete entries"
                  (click)="confirmDeleteSelected()"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let entry; let i = index;" class="thin-col">
          <button mat-icon-button
                  (click)="confirmDeleteUnsubscribeEntry(entry)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let entry; let i = index;">
          {{entry.emailAddress}}
        </td>
      </ng-container>

      <ng-container matColumnDef="domain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
        <td mat-cell *matCellDef="let entry; let i = index;">
          {{entry.domain}}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let entry; let i = index;">
          {{formatDate(entry.createdAt)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let entry; columns: displayedColumns;"
          class="table-row pointer"
      >
        <button mat-icon-button
                (click)="confirmDeleteUnsubscribeEntry(entry)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colSpan]="numDisplayedCols">
          <div *ngIf="!loading" class="no-table-data">No unsubscribe entries.</div>
          <div class="loading-overlay" *ngIf="loading">
            <div class="loading-wrapper">
              <mat-spinner color="accent"></mat-spinner>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <mat-paginator #paginator
                   [pageSizeOptions]="[25, 50, 100]"
                   showFirstLastButtons
                   *ngIf="unsubscribeTable.data.length > 25"
    ></mat-paginator>
  </div>

  <div class="form-container" ngClass.xs="unsubscribe-form-border">
    <mat-form-field class="width-50 add-textarea">
      <mat-label>Unsubscribe Entries (one per line)</mat-label>
      <textarea matInput
                role="textbox"
                [formControl]="addEntriesControl"
                rows="6"
      ></textarea>
    </mat-form-field>
    <div>
      <button mat-flat-button
              color="primary"
              (click)="addUnsubscribeEntry()"
              class="action-button"
              [disabled]="addEntriesControl.value === '' || addEntriesControl.value === null"
      >Add</button>
    </div>
  </div>

</div>
